import React, { Component } from "react";

let _UserSession = localStorage.getItem("vs_UserSession");
let _businessSetting = {};

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _businessSetting = _UserSession.businessSetting;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
}


export default class GeneralSettingPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      TransportDetailField1: "",
      TransportDetailField2: "",
      TransportDetailField3: "",
      TransportDetailField4: "",
      TransportDetailField5: "",
      TransportDetailField6: "",
      errors: {},
    };
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "TransportDetailField1":
        this.setState({ TransportDetailField1: value });
        console.log(this.state.TransportDetailField1 + "==gg==");
        break;

      case "TransportDetailField2":
        this.setState({ TransportDetailField2: value });
        console.log(this.state.TransportDetailField2 + "==hh=");
        break;

      case "TransportDetailField3":
        this.setState({ TransportDetailField3: value });
        break;

      case "TransportDetailField4":
        this.setState({ TransportDetailField4: value });
        break;

      case "TransportDetailField5":
        this.setState({ TransportDetailField5: value });
        break;

      case "TransportDetailField6":
        this.setState({ TransportDetailField6: value });
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="col-md-12">
          <div className="block block-transparent">
            <div className="block-content">
              <h5 className="content-heading">Transportation Details</h5>
              <div className="block">
                <div className="row">
                  <div className="col-md-12">
                    <div className="block block-transparent">
                      <div className="block-content" id="formSideLeft">
                        <div className="form-group row">
                          {_businessSetting.TransportationDetailsField1Enable ==
                            1 && (
                            <div className="col-4">
                              <div className="form-material open">
                                <input
                                  type="text"
                                  className="form-control search-box"
                                  placeholder={
                                    _businessSetting.TransportationDetailsField1Name
                                  }
                                  autoComplete="off"
                                  name="TransportDetailField1"
                                  id="TransportDetailField1"
                                  value={this.state.TransportDetailField1}
                                  onChange={this.handleChange1}
                                />
                                <label htmlFor="login-username">
                                  {
                                    _businessSetting.TransportationDetailsField1Name
                                  }
                                </label>
                              </div>
                            </div>
                          )}
                          {_businessSetting.TransportationDetailsField2Enable ==
                            1 && (
                            <div className="col-4">
                              <div className="form-material open">
                                <input
                                  type="text"
                                  className="form-control search-box"
                                  placeholder={
                                    _businessSetting.TransportationDetailsField2Name
                                  }
                                  autoComplete="off"
                                  name="TransportDetailField2"
                                  id="TransportDetailField2"
                                  value={this.state.TransportDetailField2}
                                  onChange={this.handleChange}
                                />
                                <label htmlFor="login-username">
                                  {
                                    _businessSetting.TransportationDetailsField2Name
                                  }
                                </label>
                              </div>
                            </div>
                          )}
                          {_businessSetting.TransportationDetailsField3Enable ==
                            1 && (
                            <div className="col-4">
                              <div className="form-material open">
                                <input
                                  type="text"
                                  className="form-control search-box"
                                  placeholder={
                                    _businessSetting.TransportationDetailsField3Name
                                  }
                                  autoComplete="off"
                                  name="TransportDetailField3"
                                  id="TransportDetailField3"
                                  value={this.state.TransportDetailField3}
                                  onChange={this.handleChange}
                                />
                                <label htmlFor="login-username">
                                  {
                                    _businessSetting.TransportationDetailsField3Name
                                  }
                                </label>
                              </div>
                            </div>
                          )}
                          {_businessSetting.TransportationDetailsField4Enable ==
                            1 && (
                            <div className="col-4">
                              <div className="form-material open">
                                <input
                                  type="text"
                                  className="form-control search-box"
                                  placeholder={
                                    _businessSetting.TransportationDetailsField4Name
                                  }
                                  autoComplete="off"
                                  name="TransportDetailField4"
                                  id="TransportDetailField4"
                                  value={this.state.TransportDetailField4}
                                  onChange={this.handleChange}
                                />
                                <label htmlFor="login-username">
                                  {
                                    _businessSetting.TransportationDetailsField4Name
                                  }
                                </label>
                              </div>
                            </div>
                          )}
                          {_businessSetting.TransportationDetailsField5Enable ==
                            1 && (
                            <div className="col-4">
                              <div className="form-material open">
                                <input
                                  type="text"
                                  className="form-control search-box"
                                  placeholder={
                                    _businessSetting.TransportationDetailsField5Name
                                  }
                                  autoComplete="off"
                                  name="TransportDetailField5"
                                  id="TransportDetailField5"
                                  value={this.state.TransportDetailField5}
                                  onChange={this.handleChange}
                                />
                                <label htmlFor="login-username">
                                  {
                                    _businessSetting.TransportationDetailsField5Name
                                  }
                                </label>
                              </div>
                            </div>
                          )}
                          {_businessSetting.TransportationDetailsField6Enable ==
                            1 && (
                            <div className="col-4">
                              <div className="form-material open">
                                <input
                                  type="text"
                                  className="form-control search-box"
                                  placeholder={
                                    _businessSetting.TransportationDetailsField6Name
                                  }
                                  autoComplete="off"
                                  name="TransportDetailField6"
                                  id="TransportDetailField6"
                                  value={this.state.TransportDetailField6}
                                  onChange={this.handleChange}
                                />
                                <label htmlFor="login-username">
                                  {
                                    _businessSetting.TransportationDetailsField6Name
                                  }
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
