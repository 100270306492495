import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { da, tr } from "date-fns/locale";

const SelectBatchQuntity = ({ callBack, data, businessSetting }) => {

    const [itemBatch, setItemBatch] = useState({});
    const [batchData, setBatchData] = useState([]);
    const [newBatchData, setNewBatchData] = useState([]);
    const handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        console.log('data.itemBatchNo', JSON.stringify(data));
        data.selectedData = [];
        setItemBatch(data);
        let itemBatchNo = data.itemBatchNo;
        setBatchData(itemBatchNo);
        if (itemBatchNo.length == 0) {
            let newBatch = {
                batch_id: new Date().getTime() * -1,
                batch_no: '',
                model_no: '',
                exp_date: moment(new Date()).format('MM/DD/YYYY'),
                mfg_date: moment(new Date()).format('MM/DD/YYYY'),
                size: '',
                batchNoAdditionalValue1: '',
                batchNoAdditionalValue2: '',
                opening_stock: 0,
                free_quantity: 0,
                used_stock: 0,
                quantity: 0,
                isNewItem: true
            };
            setNewBatchData([...newBatchData, newBatch]);
        }

    }, []);

    const getInStock = (element) => {
        let freeQuantity = element.free_quantity ? parseInt(element.free_quantity) : 0;
        let usedQuantity = element.used_stock ? parseInt(element.used_stock) : 0;
        let stock = element.opening_stock - (freeQuantity + usedQuantity);
        return stock;
    }

    const addNewBatchData = () => {
        const hasEmptyValues = newBatchData.some(obj =>
            (businessSetting.BatchNoChk == 1 && !obj.batch_no) ||
            (businessSetting.ModelNoChk == 1 && !obj.model_no) ||
            (businessSetting.ExpDateChk == 1 && !obj.exp_date) ||
            (businessSetting.MfgDateChk == 1 && !obj.mfg_date) ||
            (businessSetting.SizeChk == 1 && !obj.size) ||
            (businessSetting.BatchNoAdditionalChk1 == 1 && !obj.batchNoAdditionalValue1) ||
            (businessSetting.BatchNoAdditionalChk2 == 1 && !obj.batchNoAdditionalValue2) ||
            (!obj.used_stock)
        );
        const seen = new Set();
        let data = [...batchData, ...newBatchData];
        const duplicates = data.filter(item => {
            if (seen.has(item.batch_no)) {
                return true;
            }
            seen.add(item.batch_no);
            return false;
        });
        if (hasEmptyValues) {
            alert('Some values have empty of new added batch.');
        } else if (duplicates.length > 0) {
            alert('This ' + businessSetting.BatchNoTxt.toLowerCase() + ' (' + duplicates[0].batch_no + ') is already in use.');
        } else {
            let newBatch = {
                batch_id: new Date().getTime() * -1,
                batch_no: '',
                model_no: '',
                exp_date: moment(new Date()).format('MM/DD/YYYY'),
                mfg_date: moment(new Date()).format('MM/DD/YYYY'),
                size: '',
                batchNoAdditionalValue1: '',
                batchNoAdditionalValue2: '',
                opening_stock: 0,
                free_quantity: 0,
                used_stock: 0,
                quantity: 0,
                isNewItem: true
            };
            setNewBatchData([...newBatchData, newBatch]);
        }
    }
    const deleteBatchData = (index) => {
        let updatedBatchData = [...newBatchData];
        updatedBatchData.splice(index, 1);
        setNewBatchData(updatedBatchData);
    }
    const saveBatchData = (batch) => {
        batch.free_quantity = batch.free_quantity ? batch.free_quantity : 0;
        const hasEmptyValues = newBatchData.some(obj =>
            (businessSetting.BatchNoChk == 1 && !obj.batch_no) ||
            (businessSetting.ModelNoChk == 1 && !obj.model_no) ||
            (businessSetting.ExpDateChk == 1 && !obj.exp_date) ||
            (businessSetting.MfgDateChk == 1 && !obj.mfg_date) ||
            (businessSetting.SizeChk == 1 && !obj.size) ||
            (businessSetting.BatchNoAdditionalChk1 == 1 && !obj.batchNoAdditionalValue1) ||
            (businessSetting.BatchNoAdditionalChk2 == 1 && !obj.batchNoAdditionalValue2) ||
            (!obj.used_stock)
        );

        let _batchData = [...batchData, ...newBatchData];
        const seen = new Set();
        const duplicates = _batchData.filter(item => {
            if (seen.has(item.batch_no)) {
                return true;
            }
            seen.add(item.batch_no);
            return false;
        });
        const usedQty = batch.used_stock;
        const freeQty = batch.free_quantity;
        const inStock = getInStock(batch);
        console.log('usedQty', usedQty);
        if (hasEmptyValues) {
            alert('Some values have empty of new added batch.');
        } else if (usedQty <= 0) {
            alert('Please enter at least one quantity.');
        } else if (inStock < 0) {
            alert('Sum of quantity and free quantity can not be greater than opening stock.');
        } else if (duplicates.length > 0) {
            alert('This ' + businessSetting.BatchNoTxt.toLowerCase() + ' (' + duplicates[0].batch_no + ') is already in use.');
        } else {
            let _itemBatchNo = itemBatch.itemBatchNo;
            let remaingBatchData = _itemBatchNo.filter((data) => data.batch_id !== batch.batch_id);
            remaingBatchData = remaingBatchData.map((data) => ({
                ...data,
                used_stock: 0,
                free_quantity: 0
            }))
            let selectedBatch = {
                ...batch,
                opening_stock: inStock,
            }

            let selectedData = [selectedBatch];
            let updatedData = {
                ...itemBatch,
                itemBatchNo: remaingBatchData,
                selectedData: selectedData,
                quantity: parseInt(usedQty),
                free_quantity: parseInt(freeQty),
                trackingType: 'Batch',
                isNewQty: selectedData.isNewItem ? 'NEW' : 'OLD'
            }
            console.log('updatedData', JSON.stringify(updatedData));

            callBack(updatedData);
        }
    }
    const handleCallBackExpireDate = (date, index) => {
        let _date = moment(date).format("MM/DD/YYYY");
        let batchData = [...newBatchData];
        batchData[index].exp_date = _date;
        setNewBatchData(batchData);
    }
    const viewExpireDate = (expireDate, index) => {
        const slectedDate = moment(expireDate).toDate();
        return (
            <DatePicker
                selected={slectedDate}
                className="form-control search-box"
                onChange={date => handleCallBackExpireDate(date, index)}
            />
        );
    }
    const handleCallBackMfgDate = (date, index) => {
        let _date = moment(date).format("MM/DD/YYYY");
        let batchData = [...newBatchData];
        batchData[index].mfg_date = _date;
        setNewBatchData(batchData);
    }
    const viewMfgDate = (mfgDate, index) => {
        const slectedDate = moment(mfgDate).toDate();
        return (
            <DatePicker
                selected={slectedDate}
                className="form-control search-box"
                onChange={date => handleCallBackMfgDate(date, index)}
            />
        );
    }

    return (
        <div className="webForm px-30" style={{ height: 300, maxHeight: 800, overflowY: 'auto', marginBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
                {batchData.map((element, index) => (
                    <div key={index}>
                        <div className="form-group row">
                            {businessSetting.BatchNoChk == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={{ border: 'none', outline: "none", background: "transparent", maxWidth: 100,  }}
                                            value={element.batch_no}
                                            placeholder={businessSetting.BatchNoTxt}
                                            readOnly
                                            autoComplete="off"
                                        />
                                        <label htmlFor="login-username">{businessSetting.BatchNoTxt}</label>
                                    </div>
                                </div>
                            }


                            {businessSetting.ExpDateChk == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={{ border: 'none', outline: "none", background: "transparent" }}
                                            value={moment(element.exp_date).format('MM/DD/YYYY')}
                                            placeholder="Enter Value 2"
                                            readOnly
                                            autoComplete="off" />
                                        <label htmlFor="login-username">{businessSetting.ExpDateTxt}</label>
                                    </div>
                                </div>
                            }

                            {businessSetting.MfgDateChk == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={{ border: 'none', outline: "none", background: "transparent" }}
                                            value={moment(element.mfg_date).format('MM/DD/YYYY')}
                                            placeholder="Enter Value 2"
                                            readOnly
                                            autoComplete="off" />
                                        <label htmlFor="login-username">{businessSetting.MfgDateTxt}</label>
                                    </div>
                                </div>
                            }

                            {businessSetting.ModelNoChk == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={{ border: 'none', outline: "none", background: "transparent", maxWidth: 100 }}
                                            value={element.model_no}
                                            placeholder={businessSetting.ModelNoTxt}
                                            maxLength="30"
                                            readOnly
                                            autoComplete="off" />
                                        <label htmlFor="login-username">{businessSetting.ModelNoTxt}</label>
                                    </div>
                                </div>
                            }

                            {businessSetting.SizeChk == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={{ border: 'none', outline: "none", background: "transparent" }}
                                            value={element.size}
                                            placeholder={businessSetting.SizeTxt}
                                            readOnly
                                            autoComplete="off"
                                        />
                                        <label htmlFor="login-username">{businessSetting.SizeTxt}</label>
                                    </div>
                                </div>
                            }

                            {businessSetting.BatchNoAdditionalChk1 == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={{ border: 'none', outline: "none", background: "transparent", maxWidth: 100 }}
                                            value={element.batchNoAdditionalValue1}
                                            placeholder="Enter Value 1"
                                            readOnly
                                            autoComplete="off" />
                                        <label htmlFor="login-username">{businessSetting.BatchNoAdditionalLabel1}</label>

                                    </div>
                                </div>
                            }
                            {businessSetting.BatchNoAdditionalChk2 == 1 &&
                                <div className="col-2" >
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={{ border: 'none', outline: "none", background: "transparent", maxWidth: 100 }}
                                            value={element.batchNoAdditionalValue2}
                                            placeholder="Enter Value 2"
                                            readOnly
                                            autoComplete="off" />
                                        <label htmlFor="login-username">{businessSetting.BatchNoAdditionalLabel2}</label>
                                    </div>
                                </div>
                            }
                            <div className="col-2" >
                                <div className="form-material open">
                                    <input
                                        type="text"
                                        style={{ border: 'none', outline: "none", background: "transparent" }}
                                        value={element.opening_stock}
                                        placeholder="Enter Value 2"
                                        readOnly
                                        autoComplete="off" />
                                    <label htmlFor="login-username">Opening Stock</label>
                                </div>
                            </div>
                            <div className="col-2" >
                                <div className="form-material open">
                                    <input
                                        type="text"
                                        style={{ border: 'none', outline: "none", background: "transparent" }}
                                        value={getInStock(element)}
                                        placeholder="Enter Value 2"
                                        readOnly
                                        autoComplete="off" />
                                    <label htmlFor="login-username">In Stock</label>
                                </div>
                            </div>
                            {businessSetting.FreeItemQuantity == 1 &&
                                <div className="col-2" >
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            maxLength="3"
                                            style={{ maxWidth: 100 }}
                                            onKeyPress={(e) => handleKeyPress(e)}
                                            value={element.free_quantity}
                                            placeholder="Free Quantity"
                                            onChange={e => {
                                                let value = e.target.value;
                                                value = value * 1;
                                                let updatedData = [...batchData];
                                                updatedData[index].free_quantity = value;
                                                setBatchData(updatedData);
                                            }}
                                            autoComplete="off" />
                                        <label htmlFor="login-username">Free Quantity</label>
                                    </div>
                                </div>
                            }
                            <div className="col-2" >
                                <div className="form-material open">
                                    <input
                                        type="text"
                                        maxLength="3"
                                        style={{ maxWidth: 100 }}
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        value={element.used_stock}
                                        placeholder="Quantity"
                                        onChange={e => {
                                            let value = e.target.value;
                                            value = value * 1;
                                            let updatedData = [...batchData];
                                            updatedData[index].used_stock = value;
                                            setBatchData(updatedData);
                                        }}
                                        autoComplete="off" />
                                    <label htmlFor="login-username">Quantity
                                        <span className="text-danger"> * </span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-2" >
                                <div className="form-material open">
                                    <div className="wrapperRadio">
                                        <button
                                            type="submit"
                                            onClick={() => {
                                                saveBatchData(element);
                                            }}
                                            className="btn btn-alt-info mb-10 mx-5">
                                            Select
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))
                }
                {
                    newBatchData.length > 0 &&
                    <div>
                        <div
                            style={{
                                width: '100%',
                                height: '1px',
                                backgroundColor: '#e2e2e2',
                                margin: '20px 0',
                            }}
                        ></div>
                        <div style={{ marginTop: 10 }}>
                            <h5> New Batch </h5>
                        </div>
                    </div>
                }
                {
                    newBatchData.length > 0 && newBatchData.map((element, index) => {
                        return (
                            <div key={index}>
                                <div className="form-group row" style={{ border: '2px solid !important', borderColor: '#cacaca !important' }}>
                                    <div className="claerfix"></div>
                                    {businessSetting.BatchNoChk == 1 &&
                                        <div className="col-2">
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    style={{maxWidth: 100}}
                                                    value={element.batch_no}
                                                    placeholder={businessSetting.BatchNoTxt}
                                                    onChange={(e) => {
                                                        let batchData = [...newBatchData];
                                                        batchData[index].batch_no = e.target.value;
                                                        setNewBatchData(batchData);
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <label htmlFor="login-username">{businessSetting.BatchNoTxt}</label>
                                            </div>
                                        </div>
                                    }


                                    {businessSetting.ExpDateChk == 1 &&
                                        <div className="col-2">
                                            <div className="form-material open">
                                                {viewExpireDate(element.exp_date, index)}
                                                <label htmlFor="login-username">{businessSetting.ExpDateTxt}</label>
                                            </div>
                                        </div>
                                    }

                                    {businessSetting.MfgDateChk == 1 &&
                                        <div className="col-2">
                                            <div className="form-material open">
                                                {viewMfgDate(element.mfg_date, index)}
                                                <label htmlFor="login-username">{businessSetting.MfgDateTxt}</label>
                                            </div>
                                        </div>
                                    }

                                    {businessSetting.ModelNoChk == 1 &&
                                        <div className="col-2">
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    style={{maxWidth: 100}}
                                                    value={element.model_no}
                                                    placeholder={businessSetting.ModelNoTxt}
                                                    maxLength="30"
                                                    onChange={(e) => {
                                                        let batchData = [...newBatchData];
                                                        batchData[index].model_no = e.target.value;
                                                        setNewBatchData(batchData);
                                                    }}
                                                    autoComplete="off" />
                                                <label htmlFor="login-username">{businessSetting.ModelNoTxt}</label>
                                            </div>
                                        </div>
                                    }

                                    {businessSetting.SizeChk == 1 &&
                                        <div className="col-2">
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    style={{maxWidth: 100}}
                                                    maxLength={3}
                                                    value={element.size}
                                                    onKeyPress={(e) => handleKeyPress(e)}
                                                    placeholder={businessSetting.SizeTxt}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        value = value * 1;
                                                        let batchData = [...newBatchData];
                                                        batchData[index].size = value;
                                                        setNewBatchData(batchData);
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <label htmlFor="login-username">{businessSetting.SizeTxt}</label>
                                            </div>
                                        </div>
                                    }

                                    {businessSetting.BatchNoAdditionalChk1 == 1 &&
                                        <div className="col-2">
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    style={{maxWidth: 100}}
                                                    value={element.batchNoAdditionalValue1}
                                                    placeholder="Enter Value 1"
                                                    onChange={(e) => {
                                                        let batchData = [...newBatchData];
                                                        batchData[index].batchNoAdditionalValue1 = e.target.value;
                                                        setNewBatchData(batchData);
                                                    }}
                                                    autoComplete="off" />
                                                <label htmlFor="login-username">{businessSetting.BatchNoAdditionalLabel1}</label>

                                            </div>
                                        </div>
                                    }
                                    {businessSetting.BatchNoAdditionalChk2 == 1 &&
                                        <div className="col-2" >
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    style={{maxWidth: 100}}
                                                    value={element.batchNoAdditionalValue2}
                                                    placeholder="Enter Value 2"
                                                    onChange={(e) => {
                                                        let batchData = [...newBatchData];
                                                        batchData[index].batchNoAdditionalValue2 = e.target.value;
                                                        setNewBatchData(batchData);
                                                    }}
                                                    autoComplete="off" />
                                                <label htmlFor="login-username">{businessSetting.BatchNoAdditionalLabel2}</label>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-2" >
                                        <div className="form-material open">
                                            <input
                                                type="text"
                                                style={{ border: 'none', outline: "none", background: "transparent" }}
                                                value={element.opening_stock}
                                                placeholder="Enter Value 2"
                                                readOnly
                                                autoComplete="off" />
                                            <label htmlFor="login-username">Opening Stock</label>
                                        </div>
                                    </div>
                                    <div className="col-2" >
                                        <div className="form-material open">
                                            <input
                                                type="text"
                                                style={{ border: 'none', outline: "none", background: "transparent" }}
                                                value={getInStock(element)}
                                                placeholder="Enter Value 2"
                                                readOnly
                                                autoComplete="off" />
                                            <label htmlFor="login-username">In Stock</label>
                                        </div>
                                    </div>
                                    {businessSetting.FreeItemQuantity == 1 &&
                                        <div className="col-2" >
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    maxLength={3}
                                                    style={{ maxWidth: 100 }}
                                                    onKeyPress={(e) => handleKeyPress(e)}
                                                    value={element.free_quantity}
                                                    placeholder="Free Quantity"
                                                    onChange={(e) => {
                                                        let value = e.target.value ? parseInt(e.target.value) : 0;
                                                        value = value * 1;
                                                        let batchData = [...newBatchData];
                                                        batchData[index].free_quantity = value;
                                                        batchData[index].opening_stock = parseInt(batchData[index].used_stock) + value;
                                                        setNewBatchData(batchData);
                                                    }}
                                                    autoComplete="off" />
                                                <label htmlFor="login-username">Free Quantity</label>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-2" >
                                        <div className="form-material open">
                                            <input
                                                type="text"
                                                maxLength={3}
                                                style={{ maxWidth: 100 }}
                                                onKeyPress={(e) => handleKeyPress(e)}
                                                value={element.used_stock}
                                                placeholder="Quantity"
                                                onChange={(e) => {
                                                    let value = e.target.value ? parseInt(e.target.value) : 0;
                                                    value = value * 1;
                                                    let batchData = [...newBatchData];
                                                    batchData[index].used_stock = value;
                                                    batchData[index].opening_stock = parseInt(batchData[index].free_quantity) + value;
                                                    setNewBatchData(batchData);
                                                }}
                                                autoComplete="off" />
                                            <label htmlFor="login-username">Quantity
                                                <span className="text-danger"> * </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-material open">
                                            <div className="wrapperRadio">
                                                <button
                                                    type="submit"
                                                    onClick={() => saveBatchData(element)}
                                                    className="btn btn-alt-info mb-10 mx-5">
                                                    Select
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-material open">
                                            <div className="wrapperRadio">
                                                <button
                                                    type="submit"
                                                    onClick={() => deleteBatchData(index)}
                                                    className="btn btn-alt-info mb-10 mx-5">
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <button
                type="button"
                style={{ marginLeft: 20 }}
                className="btn btn-alt-primary"
                onClick={() => addNewBatchData()} >
                <i className="fa fa-check mr-5"></i>
                Add more
            </button>
        </div>
    )
}
export default SelectBatchQuntity