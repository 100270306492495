import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { numberFormat } from '../../../config/numberFormat';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import moment from 'moment';
import QuickViewTransactionDetail from '../viewTransaction';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Link } from 'react-router-dom';

import ButtonPdf from '../htmlPdfCreation';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _businessSetting = {};


let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Date',
        field: 'invoice_date',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Date',
        },
    },
    {
        label: 'Due Date',
        field: 'due_date',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Due Date',
        },
    },
    {
        label: 'Order No.',
        field: 'invoice_no',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Order No.',
        },
    },
    {
        label: 'Party Name',
        field: 'party_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Party Name',
        },
    },
    {
        label: 'Type',
        field: 'payment_type',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Type',
        },
    },
    {
        label: 'Amount',
        field: 'total_amount',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Amount',
        },
    },
    {
        label: 'Balance Due',
        field: 'balance',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Balance Due',
        },
    },
    {
        label: 'Status',
        field: 'status',
        width: 150
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
    ,
    {
        label: '',
        field: 'extraAction',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';

export default class userSaleListPanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _businessSetting = _UserSession.businessSetting;
            if (_businessSetting!=undefined || _businessSetting!=null) {
            
            }
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            buttonTitle:'Add Sale',
            isLoaded : true,
            transactionArray :[],
            transactionArrayFilter:[],
            businessId:_businessId,
            company_id:_companyId,
            userId : _signingId,
            name :  null,

            paidAmount:0,
            unpaidAmount:0,   
            typeFilter : 'Both',
            total:0,

            pageCount : 10,
            page : 1,
            totalCount  : 0,
            pageShow  : 0,
            setPage : 1,
            searchInvNo : '',

            errors: {
                name : '',
            }
        }
    }

    async componentDidMount ()
    {   
        this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount); 
    }

    getInvoiceSearch = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let _valueSend = value;
        this.setState({searchInvNo:_valueSend})
        if(_valueSend.length >= 3 || _valueSend == ''){
            this.getSaleListData(this.state.page,_valueSend,this.state.pageCount);
        }
    }

    getSaleListData(_page,searchInvSend,pageCount){
        let _transArray = [];

        let _paidAmt = 0;
        let _unpaidAmt = 0;
        let _totalAmt = 0;

        const requestData = JSON.stringify({
            business_id:this.state.businessId,
            company_id:this.state.company_id,
            type:"SALE ORDER",
            pageCount:pageCount,
            search_inv_no:searchInvSend,
            page:_page
        });

        PostData(global.userSaleTransactionList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray,isLoaded:true,paidAmount:_paidAmt,unpaidAmount:_unpaidAmt,total:_totalAmt,isLoaded: true});
            }
            else
            {   
                if (responseJson.response) {
                    _transArray = responseJson.response;
                    _transArray.map((itm, i) =>{
                        if(itm.payment_paid == 'UNPAID'){
                            _unpaidAmt = parseFloat(_unpaidAmt) + parseFloat(itm.total_amount);
                        }
                        else{
                            _paidAmt = parseFloat(_paidAmt) + parseFloat(itm.total_amount);
                        }
                        //itm.payType = 'Sale Order';
                        
                        itm.payType = 'Order Open';
                        if(itm.open_status == 0)
                        {
                            itm.payType = 'Order Open';
                        }
                        else if(itm.open_status == 1){
                            itm.payType = 'Order Completed';
                        }
                    });
                }
                _totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray,isLoaded:true,paidAmount:_paidAmt,unpaidAmount:_unpaidAmt,total:_totalAmt,totalCount:responseJson.totalCount,pageShow:responseJson.pageShow});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }
    deleteSaleOrder(value,type){
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        sale_id: value,
                        type_value: type
                    });
                    PostData(global.deleteSaleTransaction, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount);
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    convertToSaleChallan(value,type){
        confirmAlert({
            title: 'Confirm to convert into sale',
            message: 'Are you sure you want to convert into sale?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        sale_id: value,
                        type_value: type
                    });
                    PostData(global.convertToSaleTransaction, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount);
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    loadTranscListing(){
        let dataList = [];
        let rows = [];
        if(this.state.isLoaded == true)
        {
            let _transDetails = this.state.transactionArrayFilter;
            if(_transDetails.length > 0){

                let _filterType = 'Both';
                if(this.state.typeFilter == 'Order Open')
                {
                    _filterType = 'Order Open';
                }
                else if(this.state.typeFilter == 'Order Completed'){
                    _filterType = 'Order Completed';
                }
                let _i = 0;
                _transDetails.map((value,index) => {
                    _i++
                    let _trans_duplicate_normal = value.trans_duplicate_normal ? value.trans_duplicate_normal : ''; 
                    let _type = "&tType="+value.type;
                    let _status = <span className="text-info">Order Open</span>;
                    let _currentDate = moment().format('MMMM D, YYYY');
                    var msDiff = new Date(value.due_date).getTime() - new Date().getTime();    //Future date - current date
                    var daysTill = Math.floor(msDiff / (1000 * 60 * 60 * 24));
                    
                    if(value.saleOrderStatus == 'Order Overdue' && _businessSetting.DueDatesPaymentTerms == 1){
                        _status = <span className="text-danger">Order Overdue </span>;
                    }   
                    // if(daysTill < 0)
                    // {
                    //     _status = <span className="text-danger">Order Overdue</span>;
                    // }
                   
                    let _action = <a href={global.webUrl+'business/add-sale/?auth='+value.id+'&tType=ORDER'}><span className="btn btn-sm btn-secondary" >Convert To Sale</span></a>;
                    if(_trans_duplicate_normal === 'Duplicate'){
                        _action = 'NA';
                    }
                    let _extraAction = (<div className="btn-group" role="group">
                        <button type="button" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-ellipsis-v"></i>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                            {_trans_duplicate_normal == 'Normal' &&
                                <a className="dropdown-item" href={global.webUrl+'business/add-sale-order/?auth='+value.id+_type}>
                                    View / Edit Details
                                </a>
                            }
                            {_trans_duplicate_normal == 'Normal' &&
                                <a className="dropdown-item" href={global.webUrl+'business/add-sale-duplicate-order/?auth='+value.id}>
                                    Duplicate
                                </a>
                            }
                            <a className="dropdown-item" onClick={this.deleteSaleOrder.bind(this,value.id, 'Sale Order')}>
                                Delete
                            </a>
                            <a className="dropdown-item" href={global.webUrl+'business/download-pdf/?auth='+value.id} target="_blank">
                                Download PDF
                            </a>
                            <a className="dropdown-item" href={global.webUrl+'business/download-pdf/?auth='+value.id} target="_blank">
                                Preview
                            </a>
                            <a className="dropdown-item" href={global.webUrl+'business/download-pdf/?auth='+value.id} target="_blank">
                                Print
                            </a>
                            {/* <ButtonPdf transactionId={value.id}/> */}
                        </div>
                    </div>);

                    if(value.open_status == '1' || value.open_status == '2'){
                        _status = <span className="text-successDark">Order Completed</span>;
                        _action = <span className="text-secondary">Sale Invoice No. {value.against_invoice_no}</span>;
                        if(value.open_status == "2"){
                            _action = (<span className="text-secondary"><b>Convert to sale is deleted</b></span>);
                          }
                        //_extraAction = '';
                        _extraAction = (<div className="btn-group" role="group">
                        <button type="button" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-ellipsis-v"></i>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                            
                            {_trans_duplicate_normal == 'Normal' &&
                                <a className="dropdown-item" onClick={this.openModalHandler.bind(this,'modalAdjustViewTransaction',value.id)}>
                                    View Details
                                </a>
                            }
                            {_trans_duplicate_normal == 'Normal' &&
                                <a className="dropdown-item" href={global.webUrl+'business/add-sale-duplicate-order/?auth='+value.id}>
                                    Duplicate
                                </a>
                            }
                            <a className="dropdown-item" onClick={this.deleteSaleOrder.bind(this,value.id, 'Sale Order')}>
                                Delete
                            </a>
                            <a className="dropdown-item" href={global.webUrl+'business/download-pdf/?auth='+value.id} target="_blank">
                                Download PDF
                            </a>
                            <a className="dropdown-item" href={global.webUrl+'business/download-pdf/?auth='+value.id} target="_blank">
                                Preview
                            </a>
                            <a className="dropdown-item" href={global.webUrl+'business/download-pdf/?auth='+value.id} target="_blank">
                                Print
                            </a>
                            {/* <ButtonPdf transactionId={value.id}/> */}
                        </div>
                    </div>);
                    }
                    
                    let _pageAK = this.state.setPage;
                    
                    let _showDupL = '';
                    if(_trans_duplicate_normal == 'Duplicate'){
                        _showDupL = ' (Duplicate)';
                    }
                    let _showInv = value.customPrefix+' #'+value.invoice_no_web;
                    if(_filterType == value.payType){
                        
                        rows.push({
                            srNo: ((_pageAK - 1) * this.state.pageCount) + _i,//value.srNo,//_xx,
                            invoice_date: value.invoice_date,
                            due_date:value.due_date,
                            invoice_no:_showInv+_showDupL,
                            party_name:value.party_name,
                            payment_type: 'Sale Order',//value.payType,
                            total_amount:numberFormat(value.total_amount),
                            balance: numberFormat(value.balance),
                            status: _status,
                            action: _action,
                            extraAction:_extraAction
                        });
                    }
                    else if(_filterType == 'Both')
                    {
                        rows.push({
                            srNo: ((_pageAK - 1) * this.state.pageCount) + _i,//value.srNo,//_xx,
                            invoice_date: value.invoice_date,
                            due_date:value.due_date,
                            invoice_no:_showInv+_showDupL,
                            party_name:value.party_name,
                            payment_type: 'Sale Order',//value.payType,
                            total_amount:numberFormat(value.total_amount),
                            balance: numberFormat(value.balance),
                            status: _status,
                            action: _action,
                            extraAction:_extraAction
                        });
                    }
                    /*rows.push({
                        srNo: value.srNo,
                        invoice_date: value.invoice_date,
                        due_date:value.due_date,
                        invoice_no:value.customPrefix+' #'+value.invoice_no,
                        party_name:value.party_name,
                        payment_type: value.payType,
                        total_amount:numberFormat(value.total_amount),
                        balance: numberFormat(value.balance),
                        status: _status,
                        action: _action,
                        extraAction:_extraAction
                    });*/
                });
            }
        }

        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }
        return dataList;
    }
    
    /*For Modal open close*/
    openModalHandler(e,transId)
    {
        if(e == 'modalAdjustViewTransaction'){
            this.setState({modalIsOpen_SP: true,transactionIdActiveView:transId});
        }
    }
    closeModalHandler = (e) => {
        if(e == 'modalAdjustViewTransaction'){
            this.setState({modalIsOpen_SP: false,transactionIdActiveView:0});
        }
    }    
    /*End Modal open close*/


    handleCallBackFilter(event){
        event.preventDefault();
        let _typeFilter = event.target.value;
        this.setState({typeFilter:_typeFilter});
    }
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Sale Order List<br/>
                            </h1>
                            
                            
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"business/dashboard/"}>Dashboard</a>
                        <a className="breadcrumb-item" href={global.webUrl+"business/add-sale-order/"}>Create New Sale Order</a>                        
                        <span className="breadcrumb-item active">Manage Sale Order List</span>
                    </nav>
                    
                    <div className="block">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content">
                                        <div className="row items-push">
                                            <div className="row col-lg-12">
                                                <div className="col-lg-9">
                                                    <span>Filter : </span>
                                                    
                                                    <select name="" onChange={this.handleCallBackFilter.bind(this)} className="form-control col-3 ml-20" style={{display: 'inline-block'}}>
                                                        <option value="Both">All Order</option>
                                                        <option value="Order Open">Open Order</option>
                                                        <option value="Order Completed">Closed Order</option>
                                                    </select>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-content">
                                        <h2 className="block-title ml-10">Transactions</h2>
                                        <input type="text" className="form-control col-6" style={{display:'inline-block'}} placeholder='search order number (type at least three characters)'onChange={this.getInvoiceSearch}/>
                                        <Link to={"/business/add-sale-order/"} style={{}} className="pull-right btn btn-sm btn-primary">
                                            <i className="fa fa-plus-circle"></i> Add Sale Order
                                        </Link>
                                    </div>
                                    <div className="block-content">
                                        <MDBDataTableV5 
                                            hover
                                            span
                                            displayEntries={false}
                                            entries={100}
                                            pagingTop={false}
                                            searchTop={false}
                                            searchBottom={false}
                                            info={false}
                                            barReverse
                                            data={this.loadTranscListing()} 
                                        />
                                    </div>
                                                      
                                    <div style={{ float: "right", marginTop: "-15px" }}>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <div className="d-flex align-items-center">
                                            <div role="status" aria-live="polite"> Rows per page:</div>
                                            </div>
                                            <div className="d-flex align-items-center" >
                                            <select className=" d-flex align-items-center custom-select custom-select-sm form-control form-control-sm"
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        const { name, value } = event.target;
                                                        //this.setState({ pageCount: value, setPage: 1 });
                                                        this.setState(prevState => ({
                                                                pageCount : value,
                                                                setPage : 1,
                                                            }),
                                                            () => {
                                                                this.getSaleListData(1,this.state.searchInvNo,
                                                                    value
                                                                );
                                                            });
                                                    }}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            </div>
                                            <div className="d-flex align-items-center">
                                            <div role="status" aria-live="polite"> Total: {this.state.totalCount}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-content">
                                        <div style={{ float: "left", marginTop: "-45px" }}>
                                        <PaginationControl
                                            between={this.state.pageCount}
                                            total={this.state.totalCount}
                                            limit={this.state.pageCount}
                                            changePage={(page) => {
                                            this.setState({ setPage: page });
                                            this.getSaleListData(page,this.state.searchInvNo,this.state.pageCount
                                            );
                                            }}
                                            ellipsis={1}
                                            activeClass={this.state.setPage}
                                            page={this.state.setPage}
                                        />
                                        </div>
                                    </div>
                                    {/* <div className="block-content">
                                        <div style={{float : 'left', marginTop : '-45px'}}>
                                            <PaginationControl
                                                between={this.state.pageCount}
                                                total={this.state.totalCount}
                                                limit={this.state.pageCount}
                                                changePage={(page) => {
                                                this.setState({setPage:page}); 
                                                this.getSaleListData(page,this.state.searchInvNo,this.state.pageCount); 
                                                }}
                                                ellipsis={1}
                                                activeClass={this.state.setPage}
                                                page={this.state.setPage}
                                            />
                                        </div>
                                    </div> */}
                                 </div>
                            </div>
                        </div>
                    </div>

                </div>

                

                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>

               
                {/* Modal Edit Item Details Begins */}
                <Modal isOpen={this.state.modalIsOpen_SP} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalAdjustViewTransaction">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">View Transaction Detail</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalAdjustViewTransaction')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="block-content">
                                    <QuickViewTransactionDetail transactionIdActiveView={this.state.transactionIdActiveView}/>
                                </div>
                                <br/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAdjustViewTransaction')}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Modal End Edit Item Details*/}

            </React.Fragment>
        )    
    }
}