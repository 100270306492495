import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";

import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import QuickTransactionOIItemSection from "./quickTransactionOIItemSection";
import QuickAddNewOICategory from "./quickAddOtherIncomeCategory";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";

let maxNumber = 999999;
let _invoiceNumber = Math.floor(Math.random() * maxNumber + 1);

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _bankArray = [];
_bankArray.push(
  { bank_id: "0", bank_name: "", paymentType: "Cash" },
  { bank_id: "0", bank_name: "", paymentType: "Cheque" }
);

let _businessSetting = {};
let _Tran_BillingNameParty = "1";
/*For State Data*/

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class QuickAddOtherIncome extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
      _businessSetting = _UserSession.businessSetting;
      _Tran_BillingNameParty = _businessSetting.Tran_BillingNameParty;
      if (_businessSetting!=undefined || _businessSetting!=null) {
      }
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;

      let bankArrayData = _companySession.bankDetails
        ? _companySession.bankDetails
        : [];
      bankArrayData.map((bank, index) => {
        if (
          !(
            _bankArray.filter((item) => item.bank_name == bank.bank_name)
              .length > 0
          )
        ) {
          _bankArray.push(bank);
        }
      });
    }

    this.state = {
      _categoryReg: "iti__hide",
      _timeReg: "iti__hide",
      buttonTitle: "Add Sale",
      itemArray: [],
      rows: [],
      fields: [],
      modalIsOpen_SBANK: false,
      modalIsOpenAddNewOICat: false,

      billing_name: "",
      invoiceTime: _businessSetting.Tran_AddTime > 0 ? moment().format("h:mm A") : moment().format("h:mm A"),
      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      isLoaded: true,
      isMakePayment: false,
      isDateUpdate: false,
      receivedHide: "iti__hide",
      rowHtml: "",
      countryCode: _countryCode,
      countryName: _countryName,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      paymentType: "Cash",
      invoiceDate: moment().format("MM/DD/YYYY"),
      paymentRefNo: "",
      description: "",
      descImage: "",
      imagePreviewUrl: "",
      bankId: "0",
      bankName: "",
      totalAmount: 0,
      totalQuantity: 0,
      totalDiscount: 0,
      transactionId: 0,
      categoryArray: [],
      categoryArrayFilter: [],
      category_name: "",
      item_name_err: "",
      category_id: 0,
      handleItemSalePurchasePrice: "",
      handleAddDisable: "",
      errors: {
        invoiceDate: "",
        category_name: "",
        billing_name: "",
        item_name_err: "",
      },
    };
  }

  async componentDidMount() {
    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let transactionId = _handleParam[1] ? _handleParam[1] : 0;
    if (transactionId > 0) {
      _isEditMode = true;
    }
    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.otherIncomeTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            //this.setState({unitArray:_unitArray,isLoaded:true});
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href = global.webUrl + "business/other-income/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let transAmout = _transactionArray.totalSummary;
              let itemsData = _transactionArray.items;

              let _imgPath = "";
              if (_transactionArray.descImage!="") {
                _imgPath =
                  global.LIVE_URL +
                  "uploadDirectory/transaction/" +
                  _transactionArray.descImage;
              }

              let _viaAgainst = 0;
              if (_transactionArray.fromInvoiceNo > 0) {
                _viaAgainst = _transactionArray.fromInvoiceNo;
              } else if (_transactionArray.againstInvoiceNo > 0) {
                _viaAgainst = _transactionArray.againstInvoiceNo;
              }
              this.setState({
                billing_name: _transactionArray.billing_name
                  ? _transactionArray.billing_name
                  : "",
                invoiceTime: _transactionArray.invoice_time,
                category_id: _transactionArray.category_id,
                category_name: _transactionArray.category_name,
                buttonTitle: "Edit Other Income",
                bankId: _transactionArray.bankId,
                bankName: _transactionArray.bankName,
                invoiceDate: moment(_transactionArray.invoice_date).format(
                  "MM/DD/YYYY"
                ),
                businessId: _transactionArray.businessId,
                companyId: _transactionArray.company_id,
                description: _transactionArray.description,
                paymentType: _transactionArray.paymentType,
                paymentRefNo: _transactionArray.paymentRefNo,
                isLoaded: true,
                transactionId: transactionId,
                descImage: _transactionArray.descImage,
                imagePreviewUrl: _imgPath,
                isDateUpdate: true,
                party_contact_no: _transactionArray.party_contact_no,
                fields: itemsData,
                totalQuantity: transAmout.totalQty,
                subtotal: transAmout.subtotal,
                totalAmount: transAmout.totalAmount,
                totalAmountOld: transAmout.totalAmount,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }

    this.getCategoryListData();
  }

  getCategoryListData() {
    let errors = this.state.errors;
    errors["category_name"] = "";
    this.setState({ errors: errors });
    let _categoryArray = [];
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });

    PostData(global.userOIItemCatList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _categoryArray = responseJson.response;
          }
          this.setState({
            categoryArrayFilter: _categoryArray,
            categoryArray: _categoryArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "invoiceDate":
        errors.invoiceDate =
          value.length < 5 ? "* Invoice date must be selected" : "";
        this.setState({ invoiceDate: value });
        break;

      case "paymentType":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        let _valType = "";

        if (this.state.transactionId > 0) {
          this.setState({ paymentRefNo: "" });
        }
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        this.setState({
          paymentType: _valType,
          bankId: _bankId,
          bankName: _bankName,
        });
        break;
      case "totalAmount":
        let _remainAmount = 0;
        let _paidAmt = value;
        if (_paidAmt == "") {
          _paidAmt = 0;
        }
        this.setState({ totalAmount: _paidAmt });
        break;
      case "paymentRefNo":
        errors.paymentRefNo =
          value.length < 1 ? "* Payment  must be selected" : "";
        this.setState({ paymentRefNo: value });
        break;
      case "description":
        this.setState({ description: value });
        break;

      case "billing_name":
        this.setState({ billing_name: value });
        break;
      case "invoiceTime":
        this.setState({ invoiceTime: value });
        break;

      default:
        break;
    }
  };

  getReadyCategoryList() {
    let data = [];
    if (this.state.isLoaded == true && this.state.isMakePayment == false) {
      let _categoryArray = this.state.categoryArrayFilter;
      if (_categoryArray.length > 0) {
        data = this.state.categoryArrayFilter.map((catData, i) => {
          return (
            <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.selectCategories.bind(
                this,
                catData.category,
                catData.id
              )}
            >
              <span className="iti__party-name">{catData.category}</span>
            </li>
          );
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAddOICategory")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Category
            </span>
          </li>
        );
      }
    }
    return data;
  }

  selectCategories(name, e) {
    this.setState({
      category_name: name,
      category_id: e,
      _categoryReg: "iti__hide",
      fields: [],
      totalAmount: 0
    });
    this.getCategoryListData(e);
  }

  onMouseLeaveHandler(key) {
    if (key == "I") {
      this.setState({ _categoryReg: "iti__hide" });
    }
  }

  manageShowCategoryFilter(key) {
    if (key == "I") {
      this.setState({ _categoryReg: "" });
    }
  }

  onCategoryFilterName = (e) => {
    const data = this.state.categoryArray;
    console.log("==length==", data.length);
    //if(data.length >0){
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) => typeof val == "string" && val.includes(keyword ? keyword.toLocaleLowerCase() : keyword)
      )
    );
    this.setState({ categoryArrayFilter: filtered, category_name: keyword,  });
    this.getReadyCategoryList();
    //}
  };

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    } else if (e == "modalAddOICategory") {
      this.setState({ modalIsOpenAddNewOICat: true });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    } else if (e == "modalAddOICategory") {
      this.setState({ modalIsOpenAddNewOICat: false });
    }
  };
  /*End Modal open close*/

  /* Image Code */
  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }
  /* End Here*/

  /* Click Submit Button */
  clickToAddSale = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    let itemLen = JSON.stringify(this.state.fields);
    //console.log(JSON.stringify(this.state.fields));
    //console.log( JSON.stringify(this.state.fields.length) +"===>--aa-->----" + itemLen.length);
    
    if (this.state.category_name == "") {
      _isError = true;
      errors["category_name"] = "*Please Enter Category Name";
      this.setState({ errors: errors });
    }
    if (this.state.invoiceNo == "") {
      _isError = true;
      errors["invoiceNo"] = "*Please Enter Receipt No.";
      this.setState({ errors: errors });
    }

    let item = this.state.fields;
    console.log('item', JSON.stringify(item))
    let isAllDataFill = true;
    let errorRateField = '';
    item.map((data) => {
      if(data.rate === 0){
        isAllDataFill = false;
        errorRateField = "Please enter price/unit."
      }
    })
    if(!isAllDataFill){
      alert(errorRateField);
      return;
    }
    // if(itemLen.length < 5)
    // {
    //     _isError = true;
    //     errors['item_name_err'] = "Please select any item";
    //     this.setState({errors: errors});
    // }
    // if(itemLen.length > 5)
    // {
    //     _isError = true;
    //     errors['item_name_err'] = "";
    //     this.setState({errors: errors});
    // }

    if (_isError == false) {
      if (this.state.transactionId > 0) {
        this.editSaleTransactionMethodCall();
      } else {
        this.addOtherIncomeTransactionMethodCall();
      }
    }
  };

  addOtherIncomeTransactionMethodCall() {
    let isConditionTrue = true;
    let errorText = '';
    let fields = this.state.fields;
    fields.map((data) => {
      console.log('data', JSON.stringify(data));
      if(data.length === 0){
        isConditionTrue = false;
        errorText = "Cannot submit empty item row, please select item.";
      }else{
        errorText = '';
      }
      for(let key in data) {
        if(key != 'item_id' && (data[key] === "" || data[key] === '0' || data[key] === '0.00'|| data[key] === 0 || data[key] === 0.00)) {
          console.log(key + " is blank. Deleting it");
          isConditionTrue = false;
          let name = key.replace('item_name', 'Item').replace('total_amount', 'Total Amount');
          errorText = errorText + " " + name + " is blank."
        }
      }
    })
    /*if (this.state.handleAddDisable.length > 2) {
      alert("Cannot submit empty item row, please select item.");
      return false;
    }*/
    if(!isConditionTrue){
      alert(errorText);
      return;
    }

    let _totalSummary = JSON.stringify({
      subtotal: this.state.totalAmount,
      totalQty: this.state.totalQuantity,
      totalAmount: this.state.totalAmount,
    });

    var otherIncomeData = new FormData();
    otherIncomeData.append("businessId", this.state.businessId);
    otherIncomeData.append("user_id", this.state.userId);
    otherIncomeData.append("company_id", this.state.company_id);
    otherIncomeData.append("billing_name", this.state.billing_name);
    otherIncomeData.append("cat_id", this.state.category_id);
    otherIncomeData.append("cat_name", this.state.category_name);

    otherIncomeData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );
    otherIncomeData.append("invoiceTime", this.state.invoiceTime);
    otherIncomeData.append("totalSummary", _totalSummary);
    otherIncomeData.append("items", JSON.stringify(this.state.fields));
    otherIncomeData.append("paymentType", this.state.paymentType);
    otherIncomeData.append("paymentRefNo", this.state.paymentRefNo);
    otherIncomeData.append("description", this.state.description);
    otherIncomeData.append("descImage", this.state.descImage);
    otherIncomeData.append("bankId", this.state.bankId);
    otherIncomeData.append("bankName", this.state.bankName);
    new Promise((resolve, reject) => {
      fetch(global.createOtherIncomeTransaction, {
        method: "POST",
        body: otherIncomeData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href = global.webUrl + "business/other-income/";
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  editSaleTransactionMethodCall() {
    if (this.state.handleAddDisable.length > 2) {
      alert("Cannot submit empty item row, please select item....");
      return false;
    }
    let _totalSummary = JSON.stringify({
      subtotal: this.state.totalAmount,
      totalQty: this.state.totalQuantity,
      totalAmount: this.state.totalAmount,
    });

    var otherIncomeData = new FormData();
    otherIncomeData.append("transaction_id", this.state.transactionId);
    otherIncomeData.append("businessId", this.state.businessId);
    otherIncomeData.append("user_id", this.state.userId);
    otherIncomeData.append("company_id", this.state.company_id);
    otherIncomeData.append("billing_name", this.state.billing_name);
    otherIncomeData.append("cat_id", this.state.category_id);
    otherIncomeData.append("cat_name", this.state.category_name);
    otherIncomeData.append("invoiceNo", this.state.invoiceNo);
    otherIncomeData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );

    otherIncomeData.append("invoiceTime", this.state.invoiceTime);

    otherIncomeData.append("totalSummary", _totalSummary);
    otherIncomeData.append("items", JSON.stringify(this.state.fields));
    otherIncomeData.append("paymentType", this.state.paymentType);
    otherIncomeData.append("paymentRefNo", this.state.paymentRefNo);
    // otherIncomeData.append("selectedCountry", this.state.countryName);
    // otherIncomeData.append("countryState", this.state.countryState);
    otherIncomeData.append("description", this.state.description);
    otherIncomeData.append("descImage", this.state.descImage);
    otherIncomeData.append("bankId", this.state.bankId);
    otherIncomeData.append("bankName", this.state.bankName);

    new Promise((resolve, reject) => {
      fetch(global.editOtherIncomeTransaction, {
        method: "POST",
        body: otherIncomeData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href = global.webUrl + "business/other-income/";
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }
  /* End Submit button */

  handleCallBackDate = (start) => {
    this.setState({ invoiceDate: start.format("MM/DD/YYYY") });
  };
  handleApplyDate = (event, picker) => {
    this.setState({ invoiceDate: moment(picker.startDate).format("MM/DD/YYYY")});
    };

  settleAmountType() {
    let _payData = [];

    //_payData.push(<option value="NewBank">Add New Bank</option>);
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          _payData.push(
            <option
              value={_valOption}
              selected={_selBank}
              data-bankName={_disOption}
              data-bankId={_valOption}
            >
              {_disOption}
            </option>
          );

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }

    return _payData;
  }

  isDateRefresh() {
    let _invoiceDate = this.state.invoiceDate;

    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: this.state.invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
          onApply={this.handleApplyDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceDate}
          />
        </DateRangePicker>
      );
    }
  }

  /* Invoice Time Slot */
  onMouseLeaveHandler_Time(key) {
    if (key == "I") {
      this.setState({ _timeReg: "iti__hide" });
    }
  }

  onTimeFilterName = (e) => {
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    this.setState({ invoiceTime: keyword });
    this.getTimeSlotList();
  };

  manageShowTimeFilter(key) {
    if (key == "I") {
      this.setState({ _timeReg: "" });
    }
  }

  selectInvoiceTime(e) {
    this.setState({
      _timeReg: "iti__hide",
      invoiceTime: e,
    });
  }

  getTimeSlotList() {
    let i = 1;
    let menuItems = [];
    while (i <= 24) {
      let amPmCaption = i < 12 ? "AM" : "PM";
      let timeValue = i <= 12 ? i : i - 12;

      let _timeInvoice = `${timeValue}:00 ${amPmCaption}`;
      menuItems.push(
        <li
          key={i}
          className="iti__party iti__standard"
          id="iti-item-in"
          onClick={this.selectInvoiceTime.bind(this, _timeInvoice)}
        >
          <span className="iti__party-name">{_timeInvoice}</span>
        </li>
      );
      i++;
    }
    return menuItems;
  }
  /* Invoice Time Slot End */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideRows = "iti__hide";
    let _readonlyInput = false;
    let _readonlyCss = "";
    if (this.state.fields.length > 0) {
      _hideRows = "";
      _readonlyInput = true;
      _readonlyCss = "disabled";
    }

    let _disableTrans = false;
    if (this.state.viaAgainst > 0) {
      _disableTrans = true;
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    if (this.state.transactionId > 0) {
      _readonlyInput = "readOnly";
    }

    const optionsData = [
      { value: "showall", label: "Show All" },
      { value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Triplicate", label: "Triplicate" },
    ];

    let _readonlyTotalInput = false;
    if (this.state.fields.length > 0) {
      _readonlyTotalInput = true;
    }
    let _handleParamItemPage = this.props.location.search;
    _handleParamItemPage = _handleParamItemPage.split("?auth=");
    let _transId = _handleParamItemPage[1] ? _handleParamItemPage[1] : 0;
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Add / Edit Other Income
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <Link className="breadcrumb-item" to={"/business/dashboard/"}>
              Dashboard
            </Link>
            <Link className="breadcrumb-item" to={"/business/other-income/"}>
              Manage Other Income List
            </Link>
            <span className="breadcrumb-item active">
              Add / Edit Other Income
            </span>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="block-content" id="formSideLeft">
                      <div className="form-group row">
                        <div
                          className="col-6"
                          id="paddingOff"
                          style={{ padding: "0 0 0 0 !important" }}
                        >
                          <div
                            className="form-material open"
                            onMouseLeave={this.onMouseLeaveHandler.bind(
                              this,
                              "I"
                            )}
                          >
                            <input
                              type="text"
                              className="form-control search-box"
                              onChange={this.onCategoryFilterName}
                              onClick={this.manageShowCategoryFilter.bind(
                                this,
                                "I"
                              )}
                              onKeyDown={this.manageShowCategoryFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Search Other Income Category Here..."
                              autoComplete="off"
                              name="category_name"
                              id="category_name"
                              value={this.state.category_name}
                            />
                            <label htmlFor="login-username">
                              Other Income Category
                              <span className="text-danger">*</span>
                            </label>
                            <ul
                              className={
                                this.state._categoryReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getReadyCategoryList()}
                            </ul>

                            <div
                              id="category_name-error"
                              className="animated fadeInDown"
                            >
                              {errors.category_name.length > 0 && (
                                <span className="error">
                                  {errors.category_name}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        {_Tran_BillingNameParty == 1 && (
                          <div className="col-6">
                            <div className="form-material open">
                              <input
                                type="text"
                                className="form-control search-box"
                                placeholder="Billing Name Here..."
                                autoComplete="off"
                                name="billing_name"
                                id="billing_name"
                                value={this.state.billing_name}
                                onChange={this.handleChange}
                              />
                              <label htmlFor="login-username">
                                Billing Name (Optional){" "}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="" id="formSideRight">
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Date<span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          {this.isDateRefresh()}
                        </div>
                      </div>
                      {_businessSetting.Tran_AddTime == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Time
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                            onMouseLeave={this.onMouseLeaveHandler_Time.bind(
                              this,
                              "I"
                            )}
                          >
                            <input
                              readOnly={_readonlyInput}
                              type="text"
                              className="form-control search-box"
                              onChange={this.onTimeFilterName}
                              onClick={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              onKeyDown={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Search Time Slot Here..."
                              autoComplete="off"
                              name="invoiceTime"
                              id="invoiceTime"
                              value={this.state.invoiceTime}
                            />
                            <ul
                              className={
                                this.state._timeReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getTimeSlotList()}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Item Data Here by other pages */}
            {this.state.category_name!="" && this.state.category_id > 0 && (
              <QuickTransactionOIItemSection
                category_id={this.state.category_id}
                fields={this.state.fields}
                transId={_transId}
                callBackItem={(
                  fieldArray,
                  _totalAmountOldSend,
                  _totalAmountNewSend,
                  _totalAmt,
                  _totalQuantity,
                  handleAddDisable,
                  handleItemSalePurchasePrice
                ) => {

                  this.setState({
                    fields: fieldArray,
                    totalAmountOld: _totalAmountOldSend,
                    totalAmountNew: _totalAmountNewSend,
                    totalAmount: _totalAmt,
                    totalQuantity: _totalQuantity,
                    handleAddDisable: handleAddDisable,
                    handleItemSalePurchasePrice: handleItemSalePurchasePrice,
                    item_name_err: "",
                  });
                }}
              />
            )}
            {/* Item Data Here by other pages */}
            {/* <div id="item_name_err-error" className="animated fadeInDown" style={{marginLeft:'5%'}}>{errors.item_name_err.length > 0 && <span className='error'>{errors.item_name_err}</span>}</div> */}

            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="form-material open" id="paddingOff">
                          <select
                            className="col-8 form-control"
                            defaultValue={
                              this.state.bankId > 0
                                ? this.state.bankId
                                : this.state.paymentType
                            }
                            id="paymentType"
                            name="paymentType"
                            onChange={this.handleChange}
                          >
                            {this.settleAmountType()}
                          </select>
                        </div>
                        {this.state.paymentType!="Cash" && (
                          <div className="form-material open">
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                required="required"
                                id="paymentRefNo"
                                name="paymentRefNo"
                                value={this.state.paymentRefNo}
                                onChange={this.handleChange}
                                placeholder="Enter Reference No.."
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        )}

                        <div className="form-material open">
                          <div className="col-12 form-material" id="paddingOff">
                            <input
                              type="text"
                              className="form-control"
                              required="required"
                              id="description"
                              name="description"
                              onChange={this.handleChange}
                              placeholder="Enter a description"
                              autoComplete="off"
                              value={this.state.description}
                            />
                          </div>
                        </div>
                        <div className="form-material open">
                          <div className="col-8 form-material" id="paddingOff">
                            <input
                              type="file"
                              accept="image/*"
                              style={{ width: "60%", display: "inline-block" }}
                              autoComplete="false"
                              className="form-control"
                              id="descImage"
                              name="descImage"
                              onChange={this.onChangeFileUploadHandler}
                            />
                            {this.previewImagesListing()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="col-md-12" id="formSideRight">
                      {
                        (this.state.category_name!="" && this.state.category_id > 0) &&
                        <div className="">
                          <div className="form-group row">
                            <label
                                style={{ textAlign: "right" }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalAmount"
                            >
                              Total Amount
                            </label>
                            <div
                                className="col-lg-8 form-material"
                                id="paddingOff"
                            >
                              <input
                                  type="number"
                                  readOnly={_readonlyTotalInput}
                                  className="form-control"
                                  id="totalAmount"
                                  name="totalAmount"
                                  placeholder=""
                                  value={this.state.totalAmount}
                                  onChange={this.handleChange}
                                  autoComplete="off"
                                  onWheel={() => document.activeElement.blur()}
                              />
                            </div>
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row justify-content-center text-right">
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      onClick={this.clickToAddSale}
                      disabled={isDisabled}
                      className="btn btn-alt-primary mb-10 mx-5"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Add Bank Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SBANK}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewBank"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Bank</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewBank"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewBankDetail
                      isRedirectTo={"business/add-sale/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewBank"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Bank Details*/}

          {/* Modal Edit Other Income Category Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpenAddNewOICat}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAddOICategory"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add Income Category</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAddOICategory"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewOICategory
                      callBackCategory={(category_name, category_id) => {
                        this.setState({
                          category_name: category_name,
                          category_id: category_id,
                          modalIsOpenAddNewOICat: false,
                          handleItemSalePurchasePrice: "",
                          handleAddDisable: "Disabled",
                        });
                      }}
                      category_name={this.state.category_name}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAddOICategory"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Other Income Category Details*/}
        </div>
      </React.Fragment>
    );
  }
}
