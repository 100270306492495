import React, { Component } from "react";
import { PostData } from "../../../../service/postData";
import { sendNotification } from "../../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ButtonPdf from "../../htmlPdfCreation";

let _UserSession = localStorage.getItem("vs_UserSession");
let _signingId = "0";
let _businessId = "0";
let _companyId = "0";

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _companyId = _companySession.company_id;
}

let columns = [
  {
    label: "#",
    field: "sr_no",
    width: 100,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Item Name",
    field: "item_name",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Item Name",
    },
  },
  {
    label: "Sale Amount",
    field: "sale_amount",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Sale Amount",
    },
  },
  {
    label: "Purchase Amount",
    field: "purchase_amount",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Purchase Amount",
    },
  },
  {
    label: "Stock Qty",
    field: "stock_qty",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Stock Qty",
    },
  },
  {
    label: "Stock Value",
    field: "stock_value",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Stock Value",
    },
  },
];

let _loader = "glb-ldr-prt active";
let _currentYear = moment().format("YYYY");
let _startDateYaer = "01/01/" + _currentYear;
let _endDateYaer = "12/31/" + _currentYear;
export default class dayBookReportPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      transactionArray: [],
      transactionArrayFilter: [],
      activeTransactionKey: "ALL",
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      name: null,

      paidAmount: 0,
      unpaidAmount: 0,
      total: 0,

      startDate: moment(_startDateYaer).format("MMMM D, YYYY"),
      endDate: moment(_endDateYaer).format("MMMM D, YYYY"),
      dateLabel: "",
      errors: {
        name: "",
      },
    };
  }

  async componentDidMount() {
    this.getSaleListData(this.state.activeTransactionKey);
  }

  getSaleListData(_type) {
    let _transArray = [];
    let _paidAmt = 0;
    let _unpaidAmt = 0;
    let _totalAmt = 0;

    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      type: _type,
      isDateFilter: true,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
    });

    PostData(global.userDayBookTransactionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            transactionArrayFilter: _transArray,
            itemArray: _transArray,
            isLoaded: true,
            paidAmount: _paidAmt,
            unpaidAmount: _unpaidAmt,
            total: _totalAmt,
            countList: 0,
            totalTransAmt: 0,
            totalBalanceAmt: 0,
          });
        } else {
          if (responseJson.response) {
            _transArray = responseJson.response;
            _transArray.map((itm, i) => {
              _unpaidAmt = parseFloat(_unpaidAmt) + parseFloat(itm.balance);
              _paidAmt = parseFloat(_paidAmt) + parseFloat(itm.received_amount);
              _totalAmt = parseFloat(_totalAmt) + parseFloat(itm.total_amount);
            });
          }
          //_totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);
          this.setState({
            transactionArrayFilter: _transArray,
            itemArray: _transArray,
            isLoaded: true,
            paidAmount: _paidAmt,
            unpaidAmount: _unpaidAmt,
            total: _totalAmt,
            countList: responseJson.countList,
            totalTransAmt: responseJson.totalTransAmt,
            totalBalanceAmt: responseJson.totalBalanceAmt,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadTranscListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.transactionArrayFilter;
      if (_transDetails.length > 0) {
        _transDetails.map((value, index) => {
          let _textShow = "Credit Note";
          if (value.type == "SALE") {
            _textShow = "Sale";
          }

          let _editActionUrl =
            global.webUrl + "business/add-sale/?auth=" + value.id;
          let _editDuplicateActionUrl =
            global.webUrl + "business/add-sale/?auth=" + value.id + '&tType=DUPLICATE';
          if (value.type == "SALE") {
            _editActionUrl =
              global.webUrl + "business/add-sale/?auth=" + value.id;
            _editDuplicateActionUrl =
              global.webUrl + "business/add-sale/?auth=" + value.id + '&tType=DUPLICATE';
          } else if (value.type == "CREDIT NOTE") {
            _editActionUrl =
              global.webUrl + "business/add-sale-credit-note/?auth=" + value.id;
            _editDuplicateActionUrl =
              global.webUrl +
              "business/add-sale-return-duplicate/?auth=" +
              value.id;
          }
          let _actionExtra = "";
          if (value.type == "SALE") {
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <a className="dropdown-item" href={_editDuplicateActionUrl}>
                    Duplicate
                  </a>
                  <a className="dropdown-item" href={_editActionUrl}>
                    View/Edit Details
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "business/add-receive-payment-in/?auth=" +
                      value.id
                    }
                  >
                    Receive Payment
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +'business/add-sale-credit-note?auth='+value.id+"&tType=CREDIT NOTE&isConvert=true"
                    }
                  >
                    Convert to Return
                  </a>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDelete.bind(this, value.id, "Sale")}
                  >
                    Delete
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview as Delivery Challan
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                  {/* <ButtonPdf transactionId={value.id} isChallanPreview={true}/> */}
                </div>
              </div>
            );
          } else if (value.type == "CREDIT NOTE") {
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <a className="dropdown-item" href={_editActionUrl}>
                    View/Edit Details
                  </a>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDelete.bind(
                      this,
                      value.id,
                      "CREDIT NOTE"
                    )}
                  >
                    Delete
                  </a>
                  <a className="dropdown-item" href={_editDuplicateActionUrl}>
                    Duplicate
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "business/add-make-payment-out/?auth=" +
                      value.id
                    }
                  >
                    Make Payment
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                  {/* <ButtonPdf transactionId={value.id} isChallanPreview={true}/> */}
                </div>
              </div>
            );
          } else {
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <a className="dropdown-item" href={_editDuplicateActionUrl}>
                    Duplicate
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "business/add-make-payment-out/?auth=" +
                      value.id
                    }
                  >
                    Make Payment
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                  {/* <ButtonPdf transactionId={value.id}/> */}
                </div>
              </div>
            );
          }

          let _saleType = <span className="badge badge-warning">UNPAID</span>;

          rows.push({
            srNo: value.srNo,
            invoice_date: moment(value.invoice_date).format("DD/MM/YYYY"),
            ref_no: value.invoice_no, //value.customPrefix+' #'+value.invoice_no,
            name: value.party_name,
            sale_type: _textShow,
            payment_type: value.payment_type,
            total_amount: numberFormat(value.total_amount),
            money_in: numberFormat(value.totalMoneyIn),
            money_out: numberFormat(value.totalMoneyOut),
            actionExtra: _actionExtra,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  handleCallBackDate = (start, end) => {
    this.setState({
      startDate: start.format("MMMM D, YYYY"),
      endDate: end.format("MMMM D, YYYY"),
    });
    this.getSaleListData();
  };

  clickToDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getSaleListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-primary",
        },
      ],
    });
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="block">
            <div className="row">
              <div className="col-md-12">
                <h5># Stock Summary</h5>
                <div className="block">
                  <div className="block-content" style={{ marginTop: "-20px" }}>
                    <div className="row items-push">
                      <div className="row col-lg-12">
                        <div className="col-lg-9">
                          <span>Filter : </span>
                          <DateRangePicker
                            initialSettings={{
                              startDate: this.state.startDate,
                              endDate: this.state.endDate,
                              locale: {
                                format: "MMMM D, YYYY",
                              },
                              ranges: {
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                  moment().subtract(1, "days").toDate(),
                                  moment().subtract(1, "days").toDate(),
                                ],
                                "This Week": [
                                  moment()
                                    .startOf("week")
                                    .format("MMM DD, YYYY"),
                                  moment().endOf("week").format("MMM DD, YYYY"),
                                ],
                                "This Month": [
                                  moment().startOf("month").toDate(),
                                  moment().endOf("month").toDate(),
                                ],
                                "Last Month": [
                                  moment()
                                    .subtract(1, "month")
                                    .startOf("month")
                                    .toDate(),
                                  moment()
                                    .subtract(1, "month")
                                    .endOf("month")
                                    .toDate(),
                                ],
                                "This Quarter": [
                                  moment().startOf("month").toDate(),
                                  moment()
                                    .add(2, "month")
                                    .endOf("month")
                                    .toDate(),
                                ],
                                "This Financial Year": [
                                  moment()
                                    .month("April")
                                    .startOf("month")
                                    .format("MMM DD, YYYY"),
                                  moment()
                                    .add(1, "year")
                                    .month("March")
                                    .endOf("month")
                                    .format("MMM DD, YYYY"),
                                ],
                              },
                            }}
                            onCallback={this.handleCallBackDate}
                          >
                            <input
                              type="text"
                              className="form-control col-7"
                              style={{ display: "inline-block" }}
                            />
                          </DateRangePicker>
                        </div>
                        <div className="col-lg-3">
                          <div className="row pb-20 pull-right">
                            <div className="text-right">
                              <div className="js-appear-enabled text-center">
                                <div className="text-info">
                                  <i className="fa fa-file-excel-o"></i>
                                </div>
                                <div className="font-size-sm text-muted">
                                  Excel Export
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="block" style={{ marginTop: "-60px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  <div className="block-content">
                    <h2 className="block-title ml-10">Transactions</h2>
                    {/* <a href={global.webUrl+"business/add-sale/"} style={{marginTop: '35px'}} className="pull-right pull-right btn btn-sm btn-primary mb-10">
                                            <i className="fa fa-plus-circle"></i> Add Sale
                                        </a> */}
                  </div>
                  <div className="block-content" id="tableData">
                    <MDBDataTableV5
                      hover
                      entriesOptions={[25, 50, 100]}
                      entries={25}
                      pagesAmount={4}
                      span
                      pagingTop
                      searchTop
                      searchBottom={false}
                      barReverse
                      data={this.loadTranscListing()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
