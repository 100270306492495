import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';
let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};
let _SerialNoChk = 0;
let _SerialNoTxt = '';
let _SerialNoAdditionalChk1 = 0;
let _SerialNoAdditionalChk2 = 0;
let _SerialNoAdditionalLabel1 = '';
let _SerialNoAdditionalLabel2 = '';
let _loader = 'glb-ldr-prt active';

export default class quickAddTransSaleSerialNumber extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                        _SerialNoAdditionalChk1 = _businessSetting.SerialNoAdditionalChk1;
                        _SerialNoAdditionalChk2 = _businessSetting.SerialNoAdditionalChk2;
                        _SerialNoAdditionalLabel1 = _businessSetting.SerialNoAdditionalLabel1;
                        _SerialNoAdditionalLabel2 = _businessSetting.SerialNoAdditionalLabel2;
                        _SerialNoChk = _businessSetting.SerialNoChk;
                        _SerialNoTxt = _businessSetting.SerialNoTxt;
                    }
                }
            } 
        }
        this.state = {
            itemSerialNoAll : this.props.itemSerialNoAll,    
            itemId : this.props.itemId ? this.props.itemId : '0',  
            openingStock : this.props.opening_stock ? this.props.opening_stock : '0',  
            isLoaded : true, 
            modalIsOpen_SerialNo : true,
            itemBatchNo : [],
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            SerialNoAdditionalLabel1 : _SerialNoAdditionalLabel1,
            SerialNoAdditionalLabel2 : _SerialNoAdditionalLabel2,
            SerialNoAdditionalChk1 : _SerialNoAdditionalChk1,
            SerialNoAdditionalChk2 : _SerialNoAdditionalChk2,
            SerialNoChk : _SerialNoChk,
            SerialNoTxt : _SerialNoTxt,
            //opSCount : (this.props.itemSerialNoAll.length > 0) ? this.props.itemSerialNoAll.length : 0,
            totalAmountOld : this.props.totalAmountOld ? this.props.totalAmountOld : 0,
            totalQuantity : this.props.totalQuantity ? this.props.totalQuantity : 0,
            itemPrice : this.props.itemPrice ? this.props.itemPrice : 0,
            transactionId : this.props.transactionId ?  this.props.transactionId : 0,
            itemFields :  this.props.itemFields ? this.props.itemFields : [],
            indexPosition: this.props.indexPosition ? this.props.indexPosition : 0,
            opSCount : 0,
            noCheck : '',
            pageLoadCount :0,
            errors: {
                serial_no  :  '',
                noCheck : '',
            }
        }
    }
    async componentDidMount ()
    {   
        
    }
    
    onCheckChange(e) {
        let _ItemsArray = this.state.itemSerialNoAll;
        
        let errors = this.state.errors; 
        errors['noCheck'] = "";            
        this.setState({errors: errors});
        let _noCheck = this.state.pageLoadCount;
        _ItemsArray = _ItemsArray.map((item) => {
            if (String(item.serial_id) == String(e.target.value))
            {
                console.log('e.target', e.target);
                item.isSelected = e.target.checked;
                item.productId = this.state.itemId;
                if(this.state.transactionId > 0){
                    //if(e.target.checked == true && item.isSelectedOld == false){
                    if(e.target.checked == true){
                        item.dataCheck = 0;
                        _noCheck =  _noCheck + 1 ;
                    }
                    else{
                        item.dataCheck = 1;
                        _noCheck =  _noCheck - 1 ;
                    }
                }else{
                    if(e.target.checked == true){
                        item.dataCheck = 0;
                        _noCheck =  _noCheck + 1 ;
                    }else{
                        item.dataCheck = 1;
                        _noCheck =  _noCheck - 1 ;
                    }
                }
            }
            return item;
        });
        this.setState({ itemSerialNoAll: _ItemsArray,pageLoadCount:_noCheck });
    }

    addSaveSerialData= (event) =>
    {   
        let errors = this.state.errors; 
        let _selectedItems = [];
        let _serialQty = 0
        let _ItemsArray = this.state.itemSerialNoAll;

        let _count = 0;
        let _countOldCheck = 0;
        _ItemsArray.forEach(item => {
            if(this.state.transactionId > 0){            
                if (item.isSelected == true && item.isSelectedOld == true)
                {
                    _countOldCheck++;
                    _count++;
                    _selectedItems.push(item);
                }

            }
            
            if (item.isSelected == true)
            {
                _count++;
                _selectedItems.push(item);
            }
            
        });
        if(_count >0){
            errors['noCheck'] = "";            
            this.setState({errors: errors});
            
            let _totalQuantity = this.state.pageLoadCount;//this.state.totalQuantity;
            let _totalNewFinalAmt = 0;
            let _totalNewFinalCount = 0;
            let itemPrice = parseFloat(this.state.itemPrice) * parseFloat(_count);
            let _totalAmountOld = parseFloat(this.state.itemPrice) * parseFloat(_count);   
            
            let _newCheckItemPrice = 0 ;
            if(this.state.transactionId > 0){   
                if(_count > _countOldCheck){
                    _totalNewFinalCount  = _count;// - _countOldCheck;
                    _newCheckItemPrice = this.state.itemPrice * _totalNewFinalCount;
                }
            } 
            if(this.state.transactionId >0){
                _totalAmountOld = parseFloat(this.state.totalAmountOld) + parseFloat(_newCheckItemPrice);
                _totalQuantity =  this.state.totalQuantity + _count;
            }  
            else{
                if(this.state.totalAmountOld > 0){
                    _totalAmountOld = parseFloat(this.state.totalAmountOld) + parseFloat(_totalAmountOld);
                   
                    //_totalAmountOld = this.state.totalAmountOld + _newCheckItemPrice;
                }
            }  
            
            if(this.state.totalAmountOld > 0 && this.state.transactionId == 0){
                _totalQuantity =  this.state.pageLoadCount + this.state.totalQuantity;
            }
            /*if(_totalQuantity > 1){
                _totalQuantity = _totalQuantity - 1;
            }*/
            console.log("==_selectedItems==",JSON.stringify(_selectedItems));
            console.log("==_ItemsArray==",JSON.stringify(_ItemsArray));
            let _allSerialSendRedturn = _ItemsArray;

            let _fieldData =  this.state.itemFields;
            let discountAmt = 0;   
            let totalAmount = 0;
            let tax = 0;
            let amountBeforeTaxWithoutDisc = 0;
            let disc = 0;
            let amountBeforeTax = 0 ;
            let subTotal = 0;    
            let _discountAmtSend = 0;
            let _taxAmtSend = 0;
            let _itemToalAmtSend = 0;
            let _costPrice = 0;
         
            _fieldData.map((itemData, i) => {
                if (i == this.state.indexPosition) {          
                    let qty = _count ? _count : 0;
                    let rateValue = parseFloat(itemData.rate);
                    let taxType = parseFloat(itemData.tax_type.ratio) ? parseFloat(itemData.tax_type.ratio) : 0.0;
                    let additionalCess = parseFloat(itemData.Additional_CESS) ? parseFloat(itemData.Additional_CESS) : 0;
                    let discount_in_per =  parseFloat(itemData.discount_in_per) ?  parseFloat(itemData.discount_in_per) : 0;
                    let discount_in_amount =  parseFloat(itemData.discount_in_amount) ?  parseFloat(itemData.discount_in_amount) : 0;
             
                    subTotal = rateValue * qty;
                    if (itemData.sale_pruchase_tax_type == 'With Tax' && taxType > 0)
                    {   
                        if(discount_in_per > 0){           
                            _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);  
                            discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                            amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                            disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                            tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
                            totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);
            
                            //Discount amont == disc;
                            disc = disc.toFixed(2);
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = disc;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                        else{
                            if(taxType > 0){
                                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                            }else{
                                _costPrice = subTotal;
                            }      
                            discountAmt = discount_in_amount;// * qty;;
                            amountBeforeTax = _costPrice - discountAmt;
                            disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                            tax = amountBeforeTax * taxType / 100;
                            totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                    }
        
                    else if (itemData.sale_pruchase_tax_type == 'With Tax' && (taxType == 0 || taxType == '0')) 
                    {              
                        if(discount_in_per > 0){               
                            _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                            discountAmt = (discount_in_per / 100) * subTotal;
                            amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                            disc = amountBeforeTaxWithoutDisc * discount_in_per / 100;
                            tax = (amountBeforeTaxWithoutDisc - disc) * taxType / 100;
                            totalAmount = (amountBeforeTaxWithoutDisc - disc) + tax + additionalCess;
            
                            //Discount amont == disc;
                            disc = disc.toFixed(2);
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = disc;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                        else{        
                            if(taxType > 0){
                                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                            }else{
                                _costPrice = subTotal;
                            }     
                            amountBeforeTax = _costPrice - discountAmt;
                            disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                            tax = amountBeforeTax * taxType / 100;
                            totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                    }                    
                    else {
                        if(discount_in_per > 0){
                            _costPrice = subTotal;           
                            discountAmt = (discount_in_per / 100) * subTotal;
                            amountBeforeTaxWithoutDisc = subTotal - discountAmt;
                            tax = (amountBeforeTaxWithoutDisc) * taxType / 100;
                            totalAmount = parseFloat(amountBeforeTaxWithoutDisc) + parseFloat(tax) + parseFloat(additionalCess);            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                        else{  
                            _costPrice = subTotal;             
                            discountAmt = discount_in_amount;// * qty;;
                            amountBeforeTax = subTotal - discountAmt;
                            tax = amountBeforeTax * taxType / 100;
                            //totalAmount = amountBeforeTax + tax + (qty * additionalCess);
                            totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                                
                    }
                }
            })
            this.props.callBack(_selectedItems,_count,itemPrice,_totalAmountOld,_totalQuantity,_allSerialSendRedturn,_discountAmtSend,_taxAmtSend,_itemToalAmtSend,this.state.indexPosition,_costPrice);
        }
        else{           
            errors['noCheck'] = "Please check at least one.";            
            this.setState({errors: errors}); 
        }
    }


    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        let _countMain = this.state.openingStock;
        if(this.state.openingStock == 0){
            _countMain = this.state.opSCount;
        }        
        if(this.state.openingStock > 0 && (this.state.opSCount > this.state.openingStock)){
            _countMain = this.state.opSCount;
        }
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        //console.log("==SR==itemSerialNoAll==",this.state.itemSerialNoAll);
        return(
            <React.Fragment>              
                <form method="post" id="form-login" className="webForm px-30">                  
                    
                    {this.state.itemSerialNoAll.length  >0 &&
                    <div>
                    {this.state.itemSerialNoAll.map((element, index) => (
                        
                    <div >
                        { element.isFreeQuantity == false &&
                            <div className="form-group row">
                                {this.state.SerialNoChk == 1 && 
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serial_no" name="serial_no" value={element.serial_no || ""} onChange={e => this.handleChange(index, e)}  placeholder={this.state.SerialNoTxt} readOnly/>
                                            <label htmlFor="login-username">{this.state.SerialNoTxt} </label>
                                        </div> 
                                    </div>  
                                    }
                                    {this.state.SerialNoAdditionalChk1 == 1 && 
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serialNoAdditionalValue1" name="serialNoAdditionalValue1" value={element.serialNoAdditionalValue1 || ""} onChange={e => this.handleChange(index, e)}  placeholder="Enter Value 1" readOnly/>
                                            <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel1}</label>
                                            
                                        </div> 
                                    </div>  
                                    }
                                    {this.state.SerialNoAdditionalChk2 == 1 && 
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serialNoAdditionalValue2" name="serialNoAdditionalValue2" value={element.serialNoAdditionalValue2 || ""} onChange={e => this.handleChange(index, e)} placeholder="Enter Value 2" readOnly/>
                                            <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel2}</label>
                                        </div> 
                                    </div>
                                    
                                    
                                    }
                                    {((this.state.itemSerialNoAll.length  >0 ) && (index >= 0) ) &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <div className="wrapperRadio">
                                                <input type="checkbox" name="checkData" value={element.serial_id} onChange={this.onCheckChange.bind(this)}  checked={element.isSelected ? element.isSelected : ''}/>
                                                <label htmlFor="option-1" className="option option-1">
                                                    <div className="dot"></div>
                                                    <span>Select</span>
                                                </label>                                
                                            </div>
                                        </div>   
                                    </div>                           
                                    }
                            </div>
                        }
                    </div>
                    ))}

                    <button type="button" className="btn btn-alt-primary" onClick={this.addSaveSerialData} ><i className="fa fa-check mr-5"></i> Save</button>
                    
                    <span className="iris_error_txt">{errors.noCheck.length > 0 && <span className='error'>{errors.noCheck}</span>}</span>
                    </div> 
                    }
                   
                </form>
            </React.Fragment>
        )    
    }
}