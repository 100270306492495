import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

import { MDBDataTableV5 } from 'mdbreact';
let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = 'IN';
let _UserSession = localStorage.getItem('vs_UserSession');
if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _company_id = _companySession.company_id;
    country_code = _companySession.country_code;
    tax_name = _companySession.tax_name;
    gstTaxDetails = _companySession.taxDetails;
}

let columns = [
    {
        label: 'Date',
        field: 'date',
        width: 100,
    },
    {
        label: 'Receipt No',
        field: 'receipt_no',
        width: 100,
    },
    {
        label: 'Amount',
        field: 'amount',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';

export default class QuickPaymentHistory extends Component{
    constructor(props){
        super(props);
        this.state = {
            historyData: (this.props.historyData) ? this.props.historyData : [],
            receivedAmount : (this.props.receivedAmount) ? this.props.receivedAmount : '0',
            buttonTitle:'Add New',
            isLoaded : true, 
            itemArray : [],
            errors: {
            }

        }
    }
     

    loadItemListing(){
        let dataList = [];
        let rows = [];
        if(this.state.isLoaded == true)
        {
            let _itemDetails = this.props.historyData;
           
            if(_itemDetails.length > 0){
                
                _itemDetails.map((value,index) => {
                    
                    rows.push({                    
                        date: value.invoice_date,
                        receipt_no: value.invoice_no+ ' (Pymt In)',
                        amount:value.received_amount,
                    });
                });
            }
        }
        
        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }       
        return dataList;
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        return(
            <React.Fragment>
                <h2>Received During {this.props.type} : {parseFloat(this.state.receivedAmount).toFixed(2)}</h2>

                <div className="block">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block">
                                <div className="block-content">
                                    <h2 className="block-title ml-10">Linked With : </h2>
                                    
                                </div>
                                <div className="block-content" id="tableData">
                                    <MDBDataTableV5 
                                    hover
                                    entriesOptions={[25, 50, 100]}
                                    entries={25}
                                    pagesAmount={4}
                                    span
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                    barReverse
                                    data={this.loadItemListing()}
                                    />
                                </div>
                            </div>
                                
                        </div>
                    </div>
                </div>
                
            </React.Fragment>
        )    
    }
}