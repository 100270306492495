import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewParty from "../businessParty/quickPartyAdd";
import QuickAddLinkOtherTransaction from "./quickAddLinkOtherTransaction";
import QuickPaymentInPayLinkHistory from "./quickPaymentInPayLinkHistory";
import QuickAddNewPrefix from "../userSale/quickNewPrefixAdd";

import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Link } from "react-router-dom";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import validator from "validator";

let maxNumber = 999999;
let lengthInvNo = 6;
let _invoiceNumber = Math.floor(Math.pow(10, lengthInvNo - 1) + Math.random() * (Math.pow(10, lengthInvNo) - Math.pow(10, lengthInvNo - 1) - 1));

let _colorVal = "";
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _settingType = ""; //'showall';
let _bankArray = [];
_bankArray.push(
  { bank_id: "0", bank_name: "", paymentType: "Cash" },
  { bank_id: "0", bank_name: "", paymentType: "Cheque" }
);

let _UserSession = localStorage.getItem("vs_UserSession");
let _businessSetting = {};

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  _company_id = _UserSession.company_id;
  _businessSetting = _UserSession.businessSetting;

  if (_businessSetting!=undefined || _businessSetting!=null) {
    if (_businessSetting.PrintOriginalDuplicate == 0) {
      _settingType = "";
    }
  }
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  _countryCode = _companySession.country_code;
  _countryName = _companySession.country_name;
  _taxRateArray = _companySession.taxDetails;

  
  let bankArrayData = _companySession.bankDetails ? _companySession.bankDetails : [];
  bankArrayData.map((bank, index) => {
    if (
      !(
        _bankArray.filter((item) => item.bank_name == bank.bank_name)
          .length > 0
      )
    ) {
      _bankArray.push(bank);
    }
  });
  bankArrayData.map((bank, index) => {
  });
}
/*For State Data*/

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class userSaleListPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _timeReg: "iti__hide",
      buttonTitle: "Add Sale",
      partyRemainBalance: 0,
      PayableColor: "",
      _partyReg: "iti__hide",
      party_id: "0",
      party_name: "",
      is_party: "",
      partyArrayFilter: [],
      partyArray: [],
      itemArray: [],
      itemArrayFilter: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      modalIsOpen_SP: false,
      modalIsOpen_SBANK: false,
      modalIsOpen_LINKPay: false,
      modalIsOpen_LINKPayHistory: false,
      modalIsOpen_ADDPREFIX: false,
      billing_name: "",
      invoiceTime: _businessSetting.Tran_AddTime > 0 ? moment().format("h:mm A") : moment().format("h:mm A"),

      _itemReg: "iti__hide",
      _itemRegIndex: "0",

      isLoaded: true,
      isMakePayment: false,
      isDateUpdate: false,
      toggleStateSelALL : false,

      receivedHide: "iti__hide",

      rowHtml: "",
      countryCode: _countryCode,
      countryName: _countryName,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      paymentType: "Cash",
      settingType: _settingType,
      invoiceNo: _invoiceNumber,
      invoiceDate: moment().format("MM/DD/YYYY"),
      countryState: "",
      paymentRefNo: "",
      description: "",
      descImage: "",
      imagePreviewUrl: "",

      bankId: "0",
      bankName: "",
      totalPayLinkedAMt : 0,
      dueAmount: 0,
      receivedAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: 0,
      viaAgainst: 0,
      prefixId: 0,
      prefixName: "",
      prefixArray: [],
      party_contact_no: "",
      prefixD: 1,
      additional_value_1: "",
      additional_value_2: "",
      additional_value_3: "",
      PaymentDiscountPayInAmount: 0,
      unUsedAmount: 0,
      otherTransationArray: [],
      otherTransationPayInArray: [],
      otherTranPayInHistoryArray: [],
      totalLinkHistoryAmt: 0,
      additional_value_date: moment().format("MM/DD/YYYY"),
      transactionType: "",
      partySelDisable : 1,
      unUsedNewAmount : 0,
      receivedAmtKey : false,
      SendByRecive : '',
      isSaveBtn : false,
      cheque_open_status : 0,
      totalTransCount : 0,
      errors: {
        invoiceNo: "",
        invoiceDate: "",
        party_id: "",
        party_name: "",
        party_contact_no: "",
      },
    };
  }

  async componentDidMount() {
    let _handleParam = this.props.location.search;
    let _transactionType = "";
    let transactionId = 0;
    _handleParam = _handleParam.split("?auth=");
    if (_handleParam.length > 1) {
      _handleParam = _handleParam[1].split("&");
      transactionId = _handleParam[0] ? _handleParam[0] : 0;
      if (transactionId > 0) {
        _isEditMode = true;
      }
      _transactionType = _handleParam[1].split("tType=");
      _transactionType = _transactionType[1];
    }

    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            //this.setState({unitArray:_unitArray,isLoaded:true});
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href =
                global.webUrl + "business/manage-sale-payment-in/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let transAmout = _transactionArray.totalSummary;
              let _prefixDataUpdate = _transactionArray.prefix;
              let customerData = _transactionArray.customer;

              let _imgPath = "";
              if (_transactionArray.descImage!="") {
                _imgPath =
                  global.LIVE_URL +
                  "uploadDirectory/transaction/" +
                  _transactionArray.descImage;
              }
              let _totalUnUsedAmt = transAmout.unUsedAmount;
              if(_transactionArray.PaymentDiscountPayInAmount > 0){
                _totalUnUsedAmt  = parseFloat(transAmout.unUsedAmount) + parseFloat(transAmout.PaymentDiscountPayInAmount);
              }
              let _viaAgainst = 0;
              if (_transactionArray.fromInvoiceNo > 0) {
                _viaAgainst = _transactionArray.fromInvoiceNo;
              } else if (_transactionArray.againstInvoiceNo > 0) {
                _viaAgainst = _transactionArray.againstInvoiceNo;
              }
              let _partySelDisableEdit = 1;
              if(_transactionArray.totalLinkHistoryAmt > 0 && _transactionArray.totalLinkHistoryAmt!=0){
                _partySelDisableEdit = 0;
              }
              let _additional_value_date =  _transactionArray.additional_value_date;
              if(_transactionArray.additional_value_date == ''){
                _additional_value_date = moment().format("MM/DD/YYYY");
              }
              this.setState({
                billing_name: customerData.billing_name
                  ? customerData.billing_name
                  : "",
                invoiceTime: _transactionArray.invoice_time,

                viaAgainst: _viaAgainst,
                isMakePayment: _transactionArray.isMakePayment,
                party_id: customerData.party_id,
                party_name: customerData.party_name,
                buttonTitle: "Edit Payment In",
                invoiceNo: _transactionArray.invoiceNo,
                bankId: _transactionArray.bankId,
                bankName: _transactionArray.bankName,
                invoiceDate: moment(_transactionArray.invoiceDate).format("MM/DD/YYYY"),
                businessId: _transactionArray.business_id,
                companyId: _transactionArray.company_id,
                receivedAmount: transAmout.receivedAmount,
                totalAmount: transAmout.totalAmount,
                PaymentDiscountPayInAmount: transAmout.PaymentDiscountPayInAmount,
                unUsedAmount: _totalUnUsedAmt,//transAmout.unUsedAmount,
                description: _transactionArray.description,
                paymentType: _transactionArray.paymentType,
                paymentRefNo: _transactionArray.paymentRefNo,
                isLoaded: true,
                transactionId: transactionId,
                descImage: _transactionArray.descImage,
                imagePreviewUrl: _imgPath,
                isDateUpdate: true,
                settingType: _transactionArray.settingType,
                party_contact_no: _transactionArray.party_contact_no,
                prefixId: _prefixDataUpdate.id,
                prefixName: _prefixDataUpdate.name,
                additional_value_1: _transactionArray.additional_value_1,
                additional_value_2: _transactionArray.additional_value_2,
                additional_value_3: _transactionArray.additional_value_3,
                additional_value_date: moment(_additional_value_date).format("MM/DD/YYYY"),
                otherTransationPayInArray: _transactionArray.otherTransationPayInArray,
                otherTransationArray: _transactionArray.otherTransationPayInArray,
                otherTranPayInHistoryArray: _transactionArray.otherTranPayInHistoryArray,
                totalLinkHistoryAmt: _transactionArray.totalLinkHistoryAmt,
                totalPayLinkedAMt : _transactionArray.totalLinkHistoryAmt,
                transactionType: _transactionType,
                partySelDisable : _partySelDisableEdit,
                SendByRecive : _transactionArray.SendByRecive,
                totalTransCount : _transactionArray.otherTransationPayInArray.length,
                isSaveBtn : true,
                cheque_open_status : _transactionArray.cheque_open_status,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
    this.getPartyListData();
    this.businessPrefixList();
  }

  businessPrefixList() {
    let _prefixArray = [];
    const requestData = JSON.stringify({
      company_id: _company_id,
      businessId: _businessId,
      type: "PAYMENT-IN",
    });
    PostData(global.businessWebPrefixList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true, prefixD: 1 });
        } else {
          if (responseJson.response) {
            _prefixArray = responseJson.response;
            _prefixArray = _prefixArray.filter(
              (itemData) => itemData.prefix!=null
            );
          }
          this.setState({
            prefixArray: _prefixArray,
            isLoaded: true,
            prefixD: 1,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }
  getPartyListData() {
    let _partyArray = [];
    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _partyBal = 0;
          let _payableColor = "";
          if (responseJson.response) {
            _partyArray = responseJson.response;

            if (this.state.party_id > 0) {
              _partyArray.map((party, index) => {
                if (party.party_id == this.state.party_id) {
                  _partyBal = party.totalOutCome;
                  _payableColor = party.color;
                }
              });
            }
          }
          this.setState({
            partyArrayFilter: _partyArray,
            partyArray: _partyArray,
            isLoaded: true,
            partyRemainBalance: _partyBal,
            PayableColor: _payableColor,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let _value = 0;
    switch (name) {
      case "invoiceNo":
        errors.invoiceNo =
          value.length < 0 ? "*Invoice No Must be 1 numbers" : "";

        if (validator.isInt(value)) {
          errors.invoiceNo = "";
        } else {
          errors.invoiceNo = "*Please enter only numeric value.";
        }
        this.setState({ invoiceNo: value });
        break;

      case "invoiceDate":
        errors.invoiceDate =
          value.length < 5 ? "* Invoice date must be selected" : "";
        this.setState({ invoiceDate: value });
        break;

      case "countryState":
        errors.countryState =
          value.length < 0 ? "* State must be selected" : "";
        this.setState({ countryState: value });
        break;

      case "party_contact_no":
        if (validator.isMobilePhone(value)) {
          errors.party_contact_no = "";
        } else {
          if(value.length == 0 || value == ''){
            errors.party_contact_no = "";
          }else{
            errors.party_contact_no = "*Please enter valid mobile number!";
          }
        }
        this.setState({ party_contact_no: value });
      break;

      case "paymentType":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        let _valType = "";

        if (this.state.transactionId > 0) {
          this.setState({ paymentRefNo: "" });
        }
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        this.setState({
          paymentType: _valType,
          bankId: _bankId,
          bankName: _bankName,
        });
        break;
      case "receivedAmount":
        /*let _remainAmount = 0;
                let _paidAmt = value;
                if(_paidAmt == ''){
                    _paidAmt = 0;
                }
                this.setState({receivedAmount:_paidAmt,dueAmount:_remainAmount});*/

        _value = value;
        if (_value == 0 || _value == "") {
          _value = 0;//'';
        }
        let totalAmtKK = 0;
        if (this.state.unUsedAmount == 0 || this.state.unUsedAmount == '0') {
            if(_value == ''){
              totalAmtKK = parseFloat(this.state.PaymentDiscountPayInAmount);
            }else{
              totalAmtKK = parseFloat(_value) + parseFloat(this.state.PaymentDiscountPayInAmount);
            }
            if(this.state.partySelDisable == 0 || this.state.partySelDisable == '0'){
              totalAmtKK = parseFloat(totalAmtKK) - parseFloat(this.state.totalPayLinkedAMt);
              this.setState({ receivedAmount: _value, unUsedNewAmount: totalAmtKK,receivedAmtKey : true  });
              
            }else{
              this.setState({ receivedAmount: _value, totalAmount: totalAmtKK,receivedAmtKey : true });
            }
        } else {
            totalAmtKK = parseFloat(_value) + parseFloat(this.state.PaymentDiscountPayInAmount);
            if(this.state.partySelDisable == 0 || this.state.partySelDisable == '0'){
              if(_value == ''){
                totalAmtKK = parseFloat(this.state.totalPayLinkedAMt);
              }else{
                totalAmtKK = parseFloat(totalAmtKK) - parseFloat(this.state.totalPayLinkedAMt);
              }
              this.setState({ receivedAmount: _value, unUsedNewAmount: totalAmtKK,receivedAmtKey : true  });
              
            }
            else{
              if(_value == ''){
                totalAmtKK = parseFloat(this.state.PaymentDiscountPayInAmount);
              }
              this.setState({ receivedAmount: _value, totalAmount: totalAmtKK,receivedAmtKey : true});
            }
        }
        break;
      case "paymentRefNo":
        errors.paymentRefNo =
          value.length < 1 ? "* Payment  must be selected" : "";
        this.setState({ paymentRefNo: value });
        break;
      case "description":
        this.setState({ description: value });
        break;

      case "billing_name":
        this.setState({ billing_name: value });
        break;
      case "invoiceTime":
        this.setState({ invoiceTime: value });
        break;

      case "settingType":
        this.setState({ settingType: value });
        break;

      case "selPrefix":
        if (value == "ADDPREFIX") {
          this.openModalHandler("modalAdjustADDPREFIX");
        }
        let _prefixId = 0;
        let _prefixName = "";
        if (value > 0) {
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _prefixId = optionElement.getAttribute("data-prefixId");
          _prefixName = optionElement.getAttribute("data-prefixName");
        }
        this.setState({ prefixId: _prefixId, prefixName: _prefixName });
        break;

      case "PaymentDiscountPayInAmount":
        _value = value;
        if (_value == 0 || _value == "") {
          _value = '';
        }
        let recvAmt = this.state.receivedAmount;
        if(this.state.receivedAmount == ''){
          recvAmt = 0;
        }
        let totalAmt = parseFloat(_value) + parseFloat(recvAmt);
        let totalUnusedAmt = parseFloat(totalAmt) - parseFloat(this.state.totalPayLinkedAMt);
        if(_value == ''){
          totalAmt =  parseFloat(recvAmt);
          totalUnusedAmt  = parseFloat(this.state.totalPayLinkedAMt);
        }
        if(this.state.receivedAmount == 0){
          totalUnusedAmt = _value;
        }
        this.setState({ PaymentDiscountPayInAmount: _value,totalAmount: totalAmt,unUsedAmount :  totalUnusedAmt,unUsedNewAmount: totalUnusedAmt});
      break;
      case "unUsedAmount":
        this.setState({ unUsedAmount: value });
        break;
      case "totalAmount":
        this.setState({ totalAmount: value });
        break;

      case "additional_value_date":
        this.setState({ additional_value_date: value });
        break;
      case "additional_value_1":
        this.setState({ additional_value_1: value });
        break;
      case "additional_value_2":
        this.setState({ additional_value_2: value });
        break;
      case "additional_value_3":
        this.setState({ additional_value_3: value });
        break;

      default:
        break;
    }
  };

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: true });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    } 
    else if (e == "modalAddLinkPayment") {
      if (this.state.transactionId == 0) {
        this.setState({ modalIsOpen_LINKPay: true });
      }
      else if (this.state.transactionId > 0 && this.state.otherTranPayInHistoryArray.length > 0) {
        this.setState({
          modalIsOpen_LINKPay: true,
          otherTransationArray: this.state.otherTransationPayInArray,
        });
      }
      else if (this.state.transactionId > 0 && this.state.otherTranPayInHistoryArray.length == 0) {
        
        this.setState({
          modalIsOpen_LINKPay: true,
          otherTransationArray: this.state.otherTransationPayInArray,
          //unUsedAmount : this.state.receivedAmount,
        });
      }
    }
     else if (e == "modalAddLinkPaymentHistory") {
      if (this.state.transactionId > 0) {
        this.setState({
          modalIsOpen_LINKPayHistory: true,
          otherTranPayInHistoryArray: this.state.otherTranPayInHistoryArray,
        });
      }
      if (this.state.transactionId == 0) {
        this.setState({
          modalIsOpen_LINKPayHistory: true,
          otherTranPayInHistoryArray: this.state.otherTranPayInHistoryArray,
        });
      }
    } 
    else if (e == "modalAdjustADDPREFIX") {
      this.setState({ modalIsOpen_ADDPREFIX: true, prefixD: 0 });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: false });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    }
    else if (e == "modalAddLinkPayment") {
      if (this.state.transactionId === 0 && this.state.totalPayLinkedAMt === 0) {        
        let _otherTransationArray = this.state.otherTransationArray;
        _otherTransationArray = _otherTransationArray.map((value, itemIndex) => {
          return({
              ...value,
              link_amount : 0,
              total_amount_show: value.old_balance_amount,
              balance_amount: value.old_balance_amount,
              isSelected : false,
          })
      });
        this.setState({ modalIsOpen_LINKPay: false ,otherTransationArray : _otherTransationArray});
      }
      else
      {
        this.setState({ modalIsOpen_LINKPay: false });
      }
    } 
    
    else if (e == "modalAddLinkPaymentHistory") {
      this.setState({ modalIsOpen_LINKPayHistory: false });
    } 
    else if (e == "modalAdjustADDPREFIX") {
      this.setState({
        modalIsOpen_ADDPREFIX: false,
        _infoPrefixId: 0,
        _infoTitlePre: "",
        prefixId: 0,
        prefixName: "",
        prefixD: 1,
      });
    }
  };
  openModalHandlerLink(e) {
    /*console.log(this.state.transactionId+'==>>=='+this.state.totalAmount+'==>=='+this.state.receivedAmount + "==>>=="+this.state.toggleStateSelALL);
    
    if(this.state.transactionId === 0){
      
      this.selectPartiess.bind(this.state.party_name,this.state.party_id,this.state.partyRemainBalance,this.state.party_contact_no,this.state.PayableColor);
      this.setState({
        modalIsOpen_LINKPay: true,otherTransationArray:[],unUsedAmount:0
      });
    }else{
      this.setState({
        modalIsOpen_LINKPay: true,
        otherTransationArray: this.state.otherTransationArray,
        unUsedAmount: parseFloat(this.state.receivedAmount) + parseFloat(this.state.PaymentDiscountPayInAmount),
      });
    }*/

    this.setState({
      modalIsOpen_LINKPay: true,
      otherTransationArray: this.state.otherTransationArray,
      //unUsedAmount: parseFloat(this.state.unUsedAmount) + parseFloat(this.state.PaymentDiscountPayInAmount),
    });
  }
  /*End Modal open close*/

  /* Add Party Code */
  selectPartiess(name, e, balance, party_contact_no, _color) {
    let errors = this.state.errors;
    errors.party_name = "";
    let _otherTransationArray = [];
    let _totalTransCount = 0; 
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 365));
    const requestData = JSON.stringify({
      company_id: _company_id,
      business_id: _businessId,
      party_id: e,
      startDate: priorDate,//moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
      type: "ALL",
    });
    PostData(global.userPartyPaymentLinkTransList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({});

          this.setState({
            _partyReg: "iti__hide",
            party_name: name,
            party_id: e,
            is_party: "yes",
            partyRemainBalance: balance,
            party_contact_no: party_contact_no,
            PayableColor: _color,
            otherTransationPayInArray: [],
            otherTransationArray: [],
            receivedAmount: 0,
            totalAmount: 0,
            PaymentDiscountPayInAmount: 0,
            unUsedAmount: 0,
            isLoaded: true,
            prefixD: 1,
          });
        } else {
          if (responseJson.response) {
            _otherTransationArray = responseJson.response;      
            _totalTransCount = responseJson.totalCount; 
          }
          
          if(this.state.transactionId > 0 && this.state.totalLinkHistoryAmt > 0){
              this.setState({
                _partyReg: "iti__hide",
                party_name: name,
                party_id: e,
                is_party: "yes",
                partyRemainBalance: balance,
                party_contact_no: party_contact_no,
                PayableColor: _color,
                otherTransationPayInArray: [],
                otherTransationArray: _otherTransationArray,
                receivedAmount: 0,
                totalAmount: 0,
                PaymentDiscountPayInAmount: 0,
                unUsedAmount: 0,
                totalTransCount : _totalTransCount,
              });
          }
          else if(this.state.transactionId == 0 && this.state.totalLinkHistoryAmt == 0){
              this.setState({
                _partyReg: "iti__hide",
                party_name: name,
                party_id: e,
                is_party: "yes",
                partyRemainBalance: balance,
                party_contact_no: party_contact_no,
                PayableColor: _color,
                otherTransationPayInArray: [],
                otherTransationArray: _otherTransationArray,
                receivedAmount: 0,
                totalAmount: 0,
                PaymentDiscountPayInAmount: 0,
                unUsedAmount: 0,
                totalTransCount : _totalTransCount,
              });
          }
          else if(this.state.transactionId > 0 && this.state.totalLinkHistoryAmt == 0){
            this.setState({
              _partyReg: "iti__hide",
              party_name: name,
              party_id: e,
              is_party: "yes",
              partyRemainBalance: balance,
              party_contact_no: party_contact_no,
              PayableColor: _color,
              otherTransationPayInArray: [],
              otherTransationArray: _otherTransationArray,
              totalTransCount : _totalTransCount,
            });
          }
        }
    })
    .catch((error) => this.setState({ error, isLoaded: false }));
  }
  
  getReadyPartyList() {
    let data = [];
    if (this.state.isLoaded == true && this.state.isMakePayment == false) {
      let _partyArray = this.state.partyArrayFilter;
      if (_partyArray.length > 0) {
        data = this.state.partyArrayFilter.map((party, i) => {
          let _balance = party.totalOutCome;
          if (_balance < 0) {
            _colorVal = "red";
          } else if (_balance == 0 || _balance == "0") {
            _colorVal = "black";
          } else {
            _colorVal = "green";
          }
          return (
            <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.selectPartiess.bind(
                this,
                party.party_name,
                party.party_id,
                _balance,
                party.party_contact_no,
                party.color
              )}
            >
              <span className="iti__party-name">{party.party_name}</span>
              <span className="iti__party-balance" style={{ color: _colorVal }}>
                {numberFormat(Math.abs(_balance))}
              </span>
            </li>
          );
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAdjustNewParty")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Party
            </span>
          </li>
        );
      }
    }
    return data;
  }

  onMouseLeaveHandler(key) {
    if (key == "I") {
      this.setState({ _partyReg: "iti__hide" });
    }
  }

  manageShowPartyFilter(key) {
    if (key == "I") {
      this.setState({ _partyReg: "" });
    }
  }

  onPartyFilterName = (e) => {
    const data = this.state.partyArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({
      partyArrayFilter: filtered,
      party_name: keyword,
      is_party: "No",
    });
    this.getReadyPartyList();
  };
  /* End Party Code */

  /* Image Code */
  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }
  /* End Here*/

  /* Click Submit Button */
  clickToAddSale = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.party_name == "") {
      _isError = true;
      errors["party_name"] = "*Please Enter Party Name";
      this.setState({ errors: errors });
    }
    if (this.state.invoiceNo == "") {
      _isError = true;
      errors["invoiceNo"] = "*Please Enter Receipt No.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.transactionId > 0) {
        this.editSaleTransactionMethodCall();
      } else {
        this.addSaleTransactionMethodCall();
      }
    }
  };

  addSaleTransactionMethodCall() {
    let _prefix = JSON.stringify({
      name: this.state.prefixName,
      id: this.state.prefixId,
      isActive: "1",
    });
    let _totalAmtSend = this.state.totalAmount;
    let _totalRecvAmtSend = this.state.receivedAmount;
    if(this.state.totalPayLinkedAMt > 0 && (this.state.receivedAmount == 0 || this.state.receivedAmount == '0')){
      _totalAmtSend = this.state.totalPayLinkedAMt;
      _totalRecvAmtSend =  this.state.totalPayLinkedAMt;
    }

    let _totalSummary = JSON.stringify({
      subtotal: _totalAmtSend,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalQty: this.state.totalQuantity,
      totalDiscount: this.state.totalDiscount,
      totalAmount: _totalAmtSend,
      receivedAmount: _totalRecvAmtSend,
      dueAmount: this.state.dueAmount,
      PaymentDiscountPayInAmount: this.state.PaymentDiscountPayInAmount,
      unUsedAmount: this.state.unUsedAmount,
    });

    let _customer = JSON.stringify({
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_opening_balance: 0,
      billing_name: this.state.billing_name,
    });
    var saleData = new FormData();
    saleData.append("business_id", this.state.businessId);
    saleData.append("user_id", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("type", "PAYMENT-IN");
    saleData.append("prefix", _prefix);
    saleData.append("invoiceNo", this.state.invoiceNo);
    saleData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );

    saleData.append("invoiceTime", this.state.invoiceTime);
    saleData.append("created_from", 'WEB');
    saleData.append("totalSummary", _totalSummary);
    saleData.append("customer", _customer);
    //saleData.append('items', this.state.fields);
    saleData.append("paymentType", this.state.paymentType);
    saleData.append("paymentRefNo", this.state.paymentRefNo);
    saleData.append("selectedCountry", this.state.countryName);
    saleData.append("countryState", this.state.countryState);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("bankId", this.state.bankId);
    saleData.append("bankName", this.state.bankName);
    saleData.append("isMakePayment", this.state.isMakePayment);
    saleData.append("settingType", this.state.settingType);
    saleData.append("additional_value_1", this.state.additional_value_1);
    saleData.append("additional_value_2", this.state.additional_value_2);
    saleData.append("additional_value_3", this.state.additional_value_3);
    saleData.append("additional_value_date",moment(this.state.additional_value_date).format("MMMM D, YYYY"));
    saleData.append("otherTransationPayInArray",JSON.stringify(this.state.otherTransationPayInArray));
    
    new Promise((resolve, reject) => {
      fetch(global.createSalePaymentInTransaction, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              //window.location.href = global.webUrl+'business/manage-sale-payment-in/';
              window.location.href =
                global.webUrl +
                "business/download-pdf/?auth=" +
                res.transaction_Id;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  editSaleTransactionMethodCall() {
    let _prefix = JSON.stringify({
      name: this.state.prefixName,
      id: this.state.prefixId,
      isActive: "1",
    });

    if(this.state.cheque_open_status === 1){
      sendNotification(
        "Error Message 😓",
        "This transaction can not be edited because cheque associated with the transaction is already   closed. Re-open the cheque to edit this transaction.",
        "warning"
      );
      return;
    }
    
    let _totalSummary = JSON.stringify({
      subtotal: this.state.totalAmount,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalQty: this.state.totalQuantity,
      totalDiscount: this.state.totalDiscount,
      totalAmount: this.state.totalAmount,
      receivedAmount: this.state.receivedAmount,
      dueAmount: this.state.dueAmount,
      PaymentDiscountPayInAmount: this.state.PaymentDiscountPayInAmount,
      unUsedAmount: this.state.unUsedAmount,
    });

    let _customer = JSON.stringify({
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_opening_balance: 0,
      billing_name: this.state.billing_name,
    });
    var saleData = new FormData();
    saleData.append("transaction_id", this.state.transactionId);
    saleData.append("created_from", 'WEB');
    saleData.append("business_id", this.state.businessId);
    saleData.append("user_id", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("type", "PAYMENT-IN");
    saleData.append("prefix", _prefix);
    saleData.append("invoiceNo", this.state.invoiceNo);
    saleData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );

    saleData.append("invoiceTime", this.state.invoiceTime);

    saleData.append("totalSummary", _totalSummary);
    saleData.append("customer", _customer);
    //saleData.append('items', this.state.fields);
    saleData.append("paymentType", this.state.paymentType);
    saleData.append("paymentRefNo", this.state.paymentRefNo);
    saleData.append("selectedCountry", this.state.countryName);
    saleData.append("countryState", this.state.countryState);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("bankId", this.state.bankId);
    saleData.append("bankName", this.state.bankName);
    saleData.append("settingType", this.state.settingType);
    saleData.append("additional_value_1", this.state.additional_value_1);
    saleData.append("additional_value_2", this.state.additional_value_2);
    saleData.append("additional_value_3", this.state.additional_value_3);
    saleData.append("additional_value_date",moment(this.state.additional_value_date).format("MMMM D, YYYY"));
    saleData.append("otherTransationPayInArray",JSON.stringify(this.state.otherTransationPayInArray));
    saleData.append("totalPayLinkedAMt",JSON.stringify(this.state.totalPayLinkedAMt));
    
    //console.log("==saleData==",(saleData));return false;
    new Promise((resolve, reject) => {
      fetch(global.editSalePaymentInTransaction, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              if (this.state.transactionType == "ITEM") {
                window.location.href = global.webUrl + "business/manage-items/";
              } else if (this.state.transactionType == "PARTY") {
                window.location.href = global.webUrl + "business/manage-party/";
              } else if (this.state.transactionType == "SERVICE") {
                window.location.href =
                  global.webUrl + "business/manage-service/";
              } else if (this.state.transactionType == "CASHINHAND") {
                window.location.href =
                  global.webUrl + "business/manage-cash-in-hand/";
              }
              else if (this.state.transactionType == "Dashboard") {
                window.location.href = global.webUrl + "business/dashboard/";
              }
              else if (this.state.transactionType == "Bank") {
                window.location.href = global.webUrl + "business/manage-account-list/";
              }
              else if (this.state.transactionType == "Report") {
                window.location.href = global.webUrl + "business/reports/";
              }
               else {
                window.location.href =
                  global.webUrl + "business/manage-sale-payment-in/";
              }
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }
  /* End Submit button */

  handleCallBackDate = (start) => {
    this.setState({ invoiceDate: start.format("MMMM D, YYYY") });
  };

  isAdditionalDateRefresh() {
    let _additional_value_date = this.state.additional_value_date;
    if(this.state.additional_value_date == ''){
      _additional_value_date = moment().format("MM/DD/YYYY");
    }
    return (
      <DateRangePicker
        initialSettings={{
          singleDatePicker: true,
          startDate: _additional_value_date,
        }}
        onCallback={this.handleCallBackAddDate}
      >
        <input
          type="text"
          id="additional_value_date"
          name="additional_value_date"
          className="form-control"
          value={this.state.additional_value_date}
          readOnly
        />
      </DateRangePicker>
    );
  }
  handleCallBackAddDate = (start) => {
    this.setState({ additional_value_date: start.format("MMMM D, YYYY") });
  };

  settleAmountType() {
    let _payData = [];

    //_payData.push(<option value="NewBank">Add New Bank</option>);
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          _payData.push(
            <option
              value={_valOption}
              selected={_selBank}
              data-bankName={_disOption}
              data-bankId={_valOption}
            >
              {_disOption}
            </option>
          );

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }

    return _payData;
  }

  isDateRefresh() {
    let _invoiceDate = this.state.invoiceDate;
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" readOnly dataSend="1111" value={this.state.invoiceDate}/>
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" readOnly />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" readOnly />
        </DateRangePicker>
      );
    }
  }

  /* Invoice Time Slot */
  onMouseLeaveHandler_Time(key) {
    if (key == "I") {
      this.setState({ _timeReg: "iti__hide" });
    }
  }

  onTimeFilterName = (e) => {
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    this.setState({ invoiceTime: keyword });
    this.getTimeSlotList();
  };

  manageShowTimeFilter(key) {
    if (key == "I") {
      this.setState({ _timeReg: "" });
    }
  }

  selectInvoiceTime(e) {
    this.setState({
      _timeReg: "iti__hide",
      invoiceTime: e,
    });
  }

  getTimeSlotList() {
    let i = 1;
    let menuItems = [];
    while (i <= 24) {
      let amPmCaption = i < 12 ? "AM" : "PM";
      let timeValue = i <= 12 ? i : i - 12;

      let _timeInvoice = `${timeValue}:00 ${amPmCaption}`;
      menuItems.push(
        <li
          key={i}
          className="iti__party iti__standard"
          id="iti-item-in"
          onClick={this.selectInvoiceTime.bind(this, _timeInvoice)}
        >
          <span className="iti__party-name">{_timeInvoice}</span>
        </li>
      );
      i++;
    }
    return menuItems;
  }
  /* Invoice Time Slot End */

  render() {
    
    let _readonlyInputPaidRec = false;
    if(parseFloat(this.state.totalPayLinkedAMt) > 0){
      _readonlyInputPaidRec = true;
    }

    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideRows = "iti__hide";
    let _readonlyInput = false;
    let _readonlyCss = "";
    if (this.state.fields.length > 0) {
      _hideRows = "";
      _readonlyInput = true;
      _readonlyCss = "disabled";
    }

    let _disableTrans = false;
    if (this.state.viaAgainst > 0) {
      _disableTrans = true;
    }
    if (this.state.otherTransationPayInArray.length > 0) {
      _disableTrans = true;
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    if (this.state.transactionId > 0 && this.state.partySelDisable === 0 ) {
      _readonlyInput = "readOnly";
      _readonlyCss = "disabled";
    }
    if (this.state.transactionId > 0 && this.state.totalLinkHistoryAmt <= 0 ) {
      _readonlyInput = "";
    }
    if((this.state.transactionId == 0 ) && (this.state.partySelDisable == 0 || this.state.partySelDisable == '0')){
      _readonlyInput = "readOnly";
      _readonlyCss = "disabled";
    }

    const optionsData = [
      { value: "showall", label: "Show All" },
      { value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Triplicate", label: "Triplicate" },
    ];

    let _colorBal = "text-normal";
    if (this.state.partyRemainBalance < 0) {
      _colorBal = "text-danger";
    } else if (this.state.partyRemainBalance > 0) {
      _colorBal = "text-success";
    } else {
      _colorBal = "text-normal";
    }
    return (
      <React.Fragment>
        <div className="content"  id='LinkPaymentD'>
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Add / Edit Payment-In
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <Link className="breadcrumb-item" to={"/business/dashboard/"}>
              Dashboard
            </Link>
            <Link
              className="breadcrumb-item"
              to={"/business/manage-sale-payment-in/"}
            >
              Manage Payment In List
            </Link>
            <span className="breadcrumb-item active">
              Add / Edit Payment-In
            </span>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-6">
                        <div
                          className="form-material open"
                          onMouseLeave={this.onMouseLeaveHandler.bind(
                            this,
                            "I"
                          )}
                        >
                          {this.state.transactionId == 0 &&
                              <input
                              readOnly={_readonlyInput}
                              type="text"
                              className="form-control search-box"
                              onChange={this.onPartyFilterName}
                              onClick={this.manageShowPartyFilter.bind(this, "I")}
                              onKeyDown={this.manageShowPartyFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Search Party Name Here..."
                              autoComplete="off" 
                              name="party_name"
                              id="party_name"
                              value={this.state.party_name}
                            />
                          }
                          {(this.state.transactionId > 0 && this.state.totalLinkHistoryAmt == 0) &&
                              <input
                              type="text"
                              className="form-control search-box"
                              onChange={this.onPartyFilterName}
                              onClick={this.manageShowPartyFilter.bind(this, "I")}
                              onKeyDown={this.manageShowPartyFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Search Party Name Here..."
                              autoComplete="off" 
                              name="party_name"
                              id="party_name"
                              value={this.state.party_name}
                              dataS="111"
                            />
                          }
                          {(this.state.transactionId > 0 && this.state.totalLinkHistoryAmt > 0) && 
                              <input
                              readOnly={_readonlyInput}
                              type="text"
                              className="form-control search-box"
                              onChange={this.onPartyFilterName}
                              onClick={this.manageShowPartyFilter.bind(this, "I")}
                              onKeyDown={this.manageShowPartyFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Search Party Name Here..."
                              autoComplete="off" 
                              name="party_name"
                              id="party_name"
                              value={this.state.party_name}
                              dataS="222"
                            />
                          }
                          
                          <label htmlFor="login-username">
                            Party Name <span className="text-danger">*</span>
                          </label>

                          {(this.state.partySelDisable == 1) && (
                            <ul
                              className={
                                this.state._partyReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getReadyPartyList()}
                            </ul>
                          )}
                          <div
                            id="party_name-error"
                            className="animated fadeInDown"
                          >
                            {errors.party_name.length > 0 && (
                              <span className="error">{errors.party_name}</span>
                            )}
                          </div>

                          <div className={_colorBal}>
                            {this.state.party_name!="" &&
                            this.state.is_party!="No"
                              ? "Bal : " +
                                numberFormat(
                                  Math.abs(this.state.partyRemainBalance)
                                )
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="text"
                            className="form-control search-box"
                            placeholder="Phone Number"
                            autoComplete="off"
                            name="party_contact_no"
                            id="party_contact_no"
                            value={this.state.party_contact_no}
                            onChange={this.handleChange}
                            readOnly={_readonlyInput}
                            maxLength="13"
                          />
                          <label htmlFor="login-username">Phone Number</label>
                          <span className="iris_error_txt">
                            {errors.party_contact_no.length > 0 && (
                              <span className="error">
                                {errors.party_contact_no}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      {_businessSetting.Tran_BillingNameParty == 1 && (
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              className="form-control search-box"
                              placeholder="Billing Name Here..."
                              autoComplete="off"
                              name="billing_name"
                              id="billing_name"
                              value={this.state.billing_name}
                              onChange={this.handleChange}
                            />
                            <label htmlFor="login-username">Billing Name</label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="" id="formSideRight">
                      {_businessSetting.InvoiceBillNo == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Receipt No. <span className="text-danger">*</span>
                          </label>
                          <div
                            className="col-lg-3 form-material"
                            id="paddingOff"
                          >
                            <select
                              className="form-control "
                              id="selPrefix"
                              name="selPrefix"
                              required="required"
                              onChange={this.handleChange}
                            >
                              {this.state.prefixD == 1 && (
                                <option value="0" selected="selected">
                                  {" "}
                                  None
                                </option>
                              )}
                              {this.state.prefixArray.map(
                                (prefixData, index) => {
                                  let _selPrefix = "";
                                  if (this.state.transactionId == 0) {
                                    if (
                                      prefixData.prefix == this.state.prefixName
                                    ) {
                                      _selPrefix = "selected";
                                    }
                                  } else {
                                    if (
                                      this.state.prefixId ==
                                      prefixData.prefix_id
                                    ) {
                                      _selPrefix = "selected";
                                    }
                                  }
                                  return (
                                    <option
                                      key={index}
                                      value={prefixData.prefix_id}
                                      selected={_selPrefix}
                                      data-prefixName={prefixData.prefix}
                                      data-prefixId={prefixData.prefix_id}
                                    >
                                      {prefixData.prefix}
                                    </option>
                                  );
                                }
                              )}
                              <option value="ADDPREFIX"> Add New Prefix</option>
                            </select>
                          </div>
                          <div
                            className="col-lg-4 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="invoiceNo"
                              name="invoiceNo"
                              required="required"
                              onChange={this.handleChange}
                              placeholder="Enter a Receipt No."
                              autoComplete="off"
                              value={this.state.invoiceNo}
                            />
                            <div
                              id="invoiceNo-error"
                              className="animated fadeInDown"
                            >
                              {errors.invoiceNo.length > 0 && (
                                <span className="error">
                                  {errors.invoiceNo}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Date<span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          {this.isDateRefresh()}
                        </div>
                      </div>
                      {_businessSetting.Tran_AddTime == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Time
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                            onMouseLeave={this.onMouseLeaveHandler_Time.bind(
                              this,
                              "I"
                            )}
                          >
                            <input
                              readOnly="readOnly"
                              type="text"
                              className="form-control search-box"
                              onChange={this.onTimeFilterName}
                              onClick={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              onKeyDown={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Search Time Slot Here..."
                              autoComplete="off"
                              name="invoiceTime"
                              id="invoiceTime"
                              value={this.state.invoiceTime}
                            />
                            <ul
                              className={
                                this.state._timeReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getTimeSlotList()}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="form-material open" id="paddingOff">
                          <select
                            className="col-8 form-control"
                            defaultValue={
                              this.state.bankId > 0
                                ? this.state.bankId
                                : this.state.paymentType
                            }
                            id="paymentType"
                            name="paymentType"
                            onChange={this.handleChange}
                          >
                            {this.settleAmountType()}
                          </select>
                        </div>
                        {this.state.paymentType!="Cash" && (
                          <div className="form-material open">
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                required="required"
                                id="paymentRefNo"
                                name="paymentRefNo"
                                value={this.state.paymentRefNo}
                                onChange={this.handleChange}
                                placeholder="Enter Reference No.."
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        )}

                        <div className="form-material open">
                          <div className="col-12 form-material" id="paddingOff">
                            <input
                              type="text"
                              className="form-control"
                              required="required"
                              id="description"
                              name="description"
                              onChange={this.handleChange}
                              placeholder="Enter a description"
                              autoComplete="off"
                              value={this.state.description}
                            />
                          </div>
                        </div>
                        <div className="form-material open">
                          <div className="col-8 form-material" id="paddingOff">
                            <input
                              type="file"
                              accept="image/*"
                              style={{ width: "60%", display: "inline-block" }}
                              autoComplete="false"
                              className="form-control"
                              id="descImage"
                              name="descImage"
                              onChange={this.onChangeFileUploadHandler}
                            />
                            {this.previewImagesListing()}
                          </div>
                        </div>
                        {/*                                                 
                          {_businessSetting.PrintOriginalDuplicate == 1 &&
                              
                              <div className="form-material open">           
                              <label style={{textAlign: 'right'}} className="col-form-label" htmlFor="totalAmount">Original / Duplicate Setting</label> 
                              <div className="col-8 form-material" id="paddingOff">                         
                                      <select className="col-8 form-control"  id="settingType" name="settingType" onChange={this.handleChange}>
                                      {
                                          optionsData.map(({ value, label },index) => {
                                              let _selSet = '';
                                              if(this.state.settingType == value)
                                              {_selSet = 'selected';}
                                              return(            
                                              <option key={index} value={value}  selected={_selSet}>{label}</option>
                                              )
                                          })
                                      }
                                      </select>          
                                  </div>
                              </div>
                          } */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    {_businessSetting.PaymentDiscount == 1 && (
                      <div className="col-md-12" id="formSideRight">
                        <div className="">
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-6 col-form-label"
                              htmlFor="receivedAmount"
                            >
                              Discount Amount
                            </label>
                            <div
                              className="col-lg-6 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="number"
                                className="form-control"
                                id="PaymentDiscountPayInAmount"
                                name="PaymentDiscountPayInAmount"
                                placeholder=""
                                value={this.state.PaymentDiscountPayInAmount}
                                onChange={this.handleChange}
                                autoComplete="off"
                                onWheel={() => document.activeElement.blur()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-md-12" id="formSideRight">
                      <div className="">
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-6 col-form-label"
                            htmlFor="receivedAmount"
                          >
                            Received Amount 
                          </label>
                          {(this.state.transactionId > 0 && this.state.SendByRecive === 'SALE') &&
                            <div className="col-lg-6 form-material" id="paddingOff">
                              <input
                              readOnly="readyOnly"
                              type="number"
                              className="form-control"
                              id="receivedAmount"
                              name="receivedAmount"
                              placeholder=""
                              value={this.state.receivedAmount}
                              onChange={this.handleChange}
                              autoComplete="off"
                              dataCheck="999"
                              onWheel={() => document.activeElement.blur()}
                            />
                            </div>
                          }
                          {(this.state.receivedAmtKey == false && this.state.SendByRecive == '') &&
                          <div
                            className="col-lg-6 form-material"
                            id="paddingOff"
                          >
                            {/* {this.state.toggleStateSelALL == true && this.state.totalPayLinkedAMt >0 &&
                              <input
                                type="number"
                                // readOnly={_disableTrans}
                                className="form-control"
                                id="receivedAmount"
                                name="receivedAmount"
                                placeholder=""
                                value={this.state.receivedAmount >0 ? this.state.receivedAmount : this.state.totalPayLinkedAMt}
                                onChange={this.handleChange}
                                autoComplete="off"
                                dataCheck="111"
                                onWheel={() => document.activeElement.blur()}
                              />
                            }
                            {this.state.toggleStateSelALL == false && */}
                            {this.state.receivedAmount > 0 &&
                                <input
                                  type="number"
                                  className="form-control"
                                  id="receivedAmount"
                                  name="receivedAmount"
                                  readOnly={_readonlyInputPaidRec}
                                  placeholder=""
                                  value={this.state.receivedAmount}
                                  onChange={this.handleChange}
                                  autoComplete="off"
                                  dataCheck="111"
                                  onWheel={() => document.activeElement.blur()}
                                />
                            }
                            {(this.state.receivedAmount  == 0 || this.state.receivedAmount  == '0') &&
                                <input
                                  type="number"
                                  className="form-control"
                                  id="receivedAmount"
                                  name="receivedAmount"
                                  placeholder=""
                                  value={this.state.totalPayLinkedAMt > 0 ? this.state.totalPayLinkedAMt : this.state.receivedAmount}
                                  onChange={this.handleChange}
                                  autoComplete="off"
                                  dataCheck="222"
                                  onWheel={() => document.activeElement.blur()}
                                />
                            }
                            
                          {/* } */}
                          </div>
                          }
                          {(this.state.receivedAmtKey == true && this.state.SendByRecive == '') &&
                          <div
                            className="col-lg-6 form-material"
                            id="paddingOff"
                          >
                              <input
                                type="number"
                                className="form-control"
                                id="receivedAmount"
                                name="receivedAmount"
                                placeholder=""
                                value={this.state.receivedAmount}
                                onChange={this.handleChange}
                                autoComplete="off"
                                dataCheck="333"
                                onWheel={() => document.activeElement.blur()}
                              />
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                    {/* {this.state.partySelDisable+'==>>=='} */}
                    {(this.state.totalPayLinkedAMt == 0 && this.state.transactionId == 0) &&
                    <div className="col-md-12" id="formSideRight">
                      <div className="">
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-6 col-form-label"
                            htmlFor="receivedAmount"
                          >
                            Total Amount
                          </label>
                          <div
                            className="col-lg-6 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="number"
                              readOnly="readOnly"
                              className="form-control disabled"
                              id="totalAmount"
                              name="totalAmount"
                              placeholder=""
                              value={this.state.totalAmount}
                              onChange={this.handleChange}
                              autoComplete="off"
                              onWheel={() => document.activeElement.blur()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    {/*
                      {(this.state.totalPayLinkedAMt > 0 && this.state.transactionId > 0 ) &&
                        <div className="col-md-12" id="formSideRight">
                          <div className="">
                            <div className="form-group row">
                              <label
                                style={{ textAlign: "right" }}
                                className="col-lg-6 col-form-label"
                                htmlFor="receivedAmount"
                              >
                                Total Amount
                              </label>
                              <div
                                className="col-lg-6 form-material"
                                id="paddingOff"
                              >
                                <input
                                  type="number"
                                  readOnly="readOnly"
                                  className="form-control disabled"
                                  id="totalAmount"
                                  name="totalAmount"
                                  placeholder=""
                                  value={this.state.totalAmount}
                                  onChange={this.handleChange}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    */}
                    {(this.state.transactionId > 0  && this.state.totalLinkHistoryAmt == 0) &&
                    <div className="col-md-12" id="formSideRight">
                      <div className="">
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-6 col-form-label"
                            htmlFor="receivedAmount"
                          >
                            Total Amount
                          </label>
                          <div
                            className="col-lg-6 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="number"
                              readOnly="readOnly"
                              className="form-control disabled"
                              id="totalAmount"
                              name="totalAmount"
                              placeholder=""
                              value={this.state.totalAmount}
                              onChange={this.handleChange}
                              autoComplete="off"
                              onWheel={() => document.activeElement.blur()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    
                    {/* {(this.state.partySelDisable == 0 && this.state.totalPayLinkedAMt >= 0)&& */}
                    {this.state.transactionId >= 0 && // issue no 1038
                      <div className="col-md-12" id="formSideRight">
                        <div className="">
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-6 col-form-label"
                              htmlFor="receivedAmount"
                            >
                              Unused Amount
                            </label>
                            <div
                              className="col-lg-6 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="number"
                                className="form-control"
                                id="unUsedAmount"
                                name="unUsedAmount"
                                placeholder=""
                                value={this.state.unUsedAmount > 0 ? this.state.unUsedAmount : this.state.unUsedAmount}
                                // value={this.state.unUsedNewAmount!=0 ? this.state.unUsedNewAmount : this.state.unUsedAmount}
                                onChange={this.handleChange}
                                autoComplete="off"
                                readOnly
                                onWheel={() => document.activeElement.blur()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {/* {(this.state.transactionId > 0 && this.state.totalLinkHistoryAmt > 0)&&
                      <div className="col-md-12" id="formSideRight">
                        <div className="">
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-6 col-form-label"
                              htmlFor="receivedAmount"
                            >
                              Unused Amount
                            </label>
                            <div
                              className="col-lg-6 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="number"
                                className="form-control"
                                id="unUsedAmount"
                                name="unUsedAmount"
                                placeholder=""
                                value={this.state.unUsedNewAmount!=0 ? this.state.unUsedNewAmount : this.state.unUsedAmount}
                                onChange={this.handleChange}
                                autoComplete="off"
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    } */}
                    {/* } */}
                    {_businessSetting.TransactionAdditionalFieldPaymentIn >
                      0 && (
                      <div id="additionFields">
                        {_businessSetting.additional_field_check1 > 0 && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-6 col-form-label"
                              htmlFor="addF1"
                            >
                              {_businessSetting.additional_field_name1}
                            </label>
                            <div
                              className="col-lg-6 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="additional_value_1"
                                name="additional_value_1"
                                placeholder=""
                                value={this.state.additional_value_1}
                                autoComplete="off"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        )}

                        {_businessSetting.additional_field_check2 > 0 && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-6 col-form-label"
                              htmlFor="addF2"
                            >
                              {_businessSetting.additional_field_name2}
                            </label>
                            <div
                              className="col-lg-6 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="additional_value_2"
                                name="additional_value_2"
                                placeholder=""
                                value={this.state.additional_value_2}
                                autoComplete="off"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        )}

                        {_businessSetting.additional_field_check3 > 0 && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-6 col-form-label"
                              htmlFor="addF3"
                            >
                              {_businessSetting.additional_field_name3}
                            </label>
                            <div
                              className="col-lg-6 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="additional_value_3"
                                name="additional_value_3"
                                placeholder=""
                                value={this.state.additional_value_3}
                                autoComplete="off"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        )}

                        {_businessSetting.additional_field_date_field_check >
                          0 && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-6 col-form-label"
                              htmlFor="addFDate"
                            >
                              {
                                _businessSetting.additional_field_date_field_name
                              }
                            </label>
                            <div
                              className="col-lg-6 form-material"
                              id="paddingOff"
                            >
                              {this.isAdditionalDateRefresh()}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row justify-content-center text-left">
                  <div className="col-lg-9">
                    {_businessSetting.PaymentLink == 1 && (
                      <span>
                        {this.state.party_id > 0 &&
                          this.state.otherTransationPayInArray.length == 0 &&
                          this.state.otherTransationArray.length > 0 && (
                            <a
                              href="javascript:void(0)"
                              onClick={this.openModalHandlerLink.bind(
                                this,
                                "modalAddLinkPayment"
                              )}
                              className="btn btn-alt-primary mb-10 mx-5"
                              datatype="F"
                            >
                              Link Payment 
                              <i className="fa fa-question-circle"></i>
                            </a>
                          )}
                        {this.state.party_id > 0 &&
                          this.state.otherTransationPayInArray.length > 0 &&
                          this.state.otherTransationArray.length > 0 && (
                            <a
                              href="javascript:void(0)"
                              onClick={this.openModalHandler.bind(
                                this,
                                "modalAddLinkPayment"
                              )}
                              className="btn btn-alt-primary mb-10 mx-5"
                              datatype="S"
                            >
                              {" "}
                              Link Payment
                              <i className="fa fa-question-circle"></i>
                            </a>
                          )}
                      </span>
                    )}
                    {_businessSetting.PaymentLink == 1 && (
                      <span>
                        {this.state.party_id > 0 &&
                          this.state.otherTranPayInHistoryArray.length > 0 && (
                            <a
                              href="javascript:void(0)"
                              onClick={this.openModalHandler.bind(
                                this,
                                "modalAddLinkPaymentHistory"
                              )}
                              className="btn btn-alt-primary mb-10 mx-5"
                              datatype="F"
                            >
                              Payment History <i className="fa fa-history"></i>
                            </a>
                          )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row justify-content-center text-right">
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      onClick={this.clickToAddSale}
                      disabled={isDisabled}
                      className="btn btn-alt-primary mb-10 mx-5"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Edit Party Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SP}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewParty"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Party</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewParty"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewParty
                      isRedirectTo={"business/add-sale-payment-in/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewParty"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Party Details*/}

          {/* Modal Add Bank Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SBANK}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewBank"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Bank</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewBank"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewBankDetail
                      isRedirectTo={"business/add-sale/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewBank"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Bank Details*/}

          {/* Modal Add Link Other transaction Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_LINKPay}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAddLinkPayment"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add Link Payment To Txns</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAddLinkPayment"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    {this.state.transactionId == 0 &&
                        <QuickAddLinkOtherTransaction
                        otherTransationArray={this.state.otherTransationArray}
                        otherTransationPayInArray={this.state.otherTransationPayInArray ? this.state.otherTransationPayInArray : []}
                        receivedAmountSend={this.state.receivedAmount}
                        PaymentDiscountPayInAmount={
                          this.state.PaymentDiscountPayInAmount
                        }
                        totalAmount={this.state.totalAmount}
                        partyId={this.state.party_id}
                        transactionId={this.state.transactionId}
                        toggleStateSelALL={this.state.toggleStateSelALL}
                        unUsedAmount={this.state.unUsedAmount >0 ? this.state.unUsedAmount : this.state.totalAmount}
                        isSaveBtn ={this.state.isSaveBtn}
                        totalTransCount ={this.state.totalTransCount}
                        callBack={(
                          returnPayInData,
                          _receivedAmount,
                          _otherTranPayInHistoryArray,
                          _unUsedAmt,
                          _totalAmount,
                          _toggleStateSelALL,
                          totalPayLinkedAMt,
                          isSaveBtn
                        ) => {
                          let _partySelDisable = 1;
                          if(returnPayInData.length > 0){
                            _partySelDisable = 0;
                            _totalAmount = 0;
                          }
                          this.setState({
                            otherTransationPayInArray: returnPayInData,
                            otherTransationArray: returnPayInData,
                            modalIsOpen_LINKPay: false,
                            receivedAmount: _receivedAmount,
                            otherTranPayInHistoryArray: _otherTranPayInHistoryArray,
                            totalLinkHistoryAmt: _totalAmount,
                            unUsedAmount: _unUsedAmt ? _unUsedAmt : 0,
                            totalAmount: _totalAmount > 0 ? _totalAmount : 0,
                            toggleStateSelALL: _toggleStateSelALL ? _toggleStateSelALL : false,
                            partySelDisable: _partySelDisable,
                            totalPayLinkedAMt: totalPayLinkedAMt,
                            isSaveBtn : isSaveBtn
                          });
                        }}
                      />
                    }

                    {this.state.transactionId > 0 && 
                        <QuickAddLinkOtherTransaction
                        otherTransationArray={this.state.otherTransationArray}
                        otherTransationPayInArray={this.state.otherTransationPayInArray ? this.state.otherTransationPayInArray : []}
                        receivedAmountSend={this.state.receivedAmount}
                        PaymentDiscountPayInAmount={
                          this.state.PaymentDiscountPayInAmount
                        }
                        totalAmount={this.state.totalAmount}
                        partyId={this.state.party_id}
                        transactionId={this.state.transactionId}
                        toggleStateSelALL={this.state.toggleStateSelALL}
                        unUsedAmount={this.state.unUsedAmount >0 ? this.state.unUsedAmount : 0}
                        isSaveBtn={this.state.isSaveBtn}
                        totalTransCount ={this.state.totalTransCount}
                        callBack={(
                          returnPayInData,
                          _receivedAmount,
                          _otherTranPayInHistoryArray,
                          _unUsedAmt,
                          _totalAmount,
                          _toggleStateSelALL,
                          totalPayLinkedAMt,
                          isSaveBtn
                        ) => {
                          let _partySelDisable = 1;
                          if(returnPayInData.length > 0){
                            _partySelDisable = 0;
                            _totalAmount = 0;
                          }
                          this.setState({
                            otherTransationPayInArray: returnPayInData,
                          //otherTransationArray: returnPayInData,
                            modalIsOpen_LINKPay: false,
                            receivedAmount: _receivedAmount,
                            otherTranPayInHistoryArray:
                              _otherTranPayInHistoryArray,
                            totalLinkHistoryAmt: _totalAmount,
                            unUsedAmount: _unUsedAmt ? _unUsedAmt : 0,
                            totalAmount: _totalAmount ? _totalAmount : 0,
                            toggleStateSelALL: _toggleStateSelALL ? _toggleStateSelALL : false,
                            partySelDisable: _partySelDisable,
                            totalPayLinkedAMt: totalPayLinkedAMt,
                            isSaveBtn: isSaveBtn
                          });
                        }}
                      />
                    }
                    
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddLinkPayment')}>Close</button>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Link Other transaction Details*/}

          {/* Modal Pay Link Histpory Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_LINKPayHistory}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAddLinkPaymentHistory"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Payment History</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAddLinkPaymentHistory"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickPaymentInPayLinkHistory
                      otherTranPayInHistoryArray={
                        this.state.otherTranPayInHistoryArray
                      }
                      totalLinkHistoryAmt={this.state.totalLinkHistoryAmt}
                      totalTransCount={this.state.otherTranPayInHistoryArray.length}
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-alt-secondary"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddLinkPaymentHistory"
                      )}
                    >
                      Close
                    </button>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Pay Link History Details*/}

          {/* Modal Add New Prefix Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_ADDPREFIX}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustADDPREFIX"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Prefix</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustADDPREFIX"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewPrefix
                      callBack={(newPrefixId, newPrefixName) => {
                        this.setState({
                          prefixId: newPrefixId,
                          prefixName: newPrefixName,
                          modalIsOpen_ADDPREFIX: false,
                        });

                        this.businessPrefixList();
                      }}
                      transactionType="PAYMENT-IN"
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustADDPREFIX"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add New Prefix Details*/}
        </div>
      </React.Fragment>
    );
  }
}
