
const protocol = window.location.protocol;
const origin = window.location.origin;
const hostname = window.location.hostname;

let API_URL = origin+'/';

global.webUrl = API_URL;
global.PATH_FOLDER = "/";

if(hostname == 'localhost'){
  API_URL = protocol + "//www.developer.vyavsay.in/";
}

global.API_ADMIN_URL = API_URL + "web/adminApi/";
global.API_BUSINESS_URL = API_URL + "web/businessApi/";
global.API_USER_URL = API_URL + "web/userApi/";
global.API_WEBSITE_URL = API_URL + "/web/webApi/";
global.API_URL = API_URL + "web/";
global.LIVE_URL = API_URL;

global.FLAG_URL = API_URL + "uploadDirectory/flags/flags-small/";
global.BACK_URL = "";
