export const columns = {
    Sale_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Invoice No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Invoice No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Type',
            field: 'transaction_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Type',
            },
        },
        {
            label: 'Payment Status',
            field: 'sale_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Pay Type',
            },
        },
        {
            label: 'Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Amount',
            },
        },
        {
            label: 'Balance Due',
            field: 'balance',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Due',
            },
        },
        {
            label: 'Action',
            field: 'actionExtra',
            width: 100,
        }
    ],

    PaymentIN_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Receipt No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Receipt No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Received Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Received Amount',
            },
        },
        {
            label: 'Balance Amount',
            field: 'balance_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Amount',
            },
        },
        {
            label: 'Action',
            field: 'action',
            width: 100,
        }
    ],
    
    PaymentINLink_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Receipt No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Receipt No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Payment Status',
            field: 'pay_status',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Pay Type',
            },
        },
        {
            label: 'Received Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Received Amount',
            },
        },
        {
            label: 'Balance Amount',
            field: 'balance_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Amount',
            },
        },
        {
            label: 'Action',
            field: 'action',
            width: 100,
        }
    ],
    SaleEstimate_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Ref No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Ref No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Amount',
            },
        },
        {
            label: 'Balance Due',
            field: 'balance',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Due',
            },
        },
        {
            label: 'Status',
            field: 'status',
            width: 150
        },
        {
            label: 'Action',
            field: 'action',
            width: 100,
        },
        {
            label: '',
            field: 'extraAction',
            width: 100,
        }
    ],
    SaleDelvChallan_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Challan No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Challan No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Due Date',
            field: 'due_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Due Date',
            },
        },
        {
            label: 'Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Amount',
            },
        },
        {
            label: 'Status',
            field: 'status',
            width: 150
        },
        {
            label: 'Action',
            field: 'action',
            width: 100,
        }
        ,
        {
            label: '',
            field: 'extraAction',
            width: 100,
        }
    ],
    SaleOrder_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Due Date',
            field: 'due_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Due Date',
            },
        },
        {
            label: 'Order No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Order No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Amount',
            },
        },
        {
            label: 'Balance Due',
            field: 'balance',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Due',
            },
        },
        {
            label: 'Status',
            field: 'status',
            width: 150
        },
        {
            label: 'Action',
            field: 'action',
            width: 100,
        }
        ,
        {
            label: '',
            field: 'extraAction',
            width: 100,
        }
    ],
    SaleReturn_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Ref No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Ref No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Amount',
            },
        },
        {
            label: 'Received Amount',
            field: 'received_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Received Amount',
            },
        },
        {
            label: 'Balance Amount',
            field: 'balance_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Amount',
            },
        },
        {
            label: 'Status',
            field: 'pay_status',
            width: 150
        },
        {
            label: 'Action',
            field: 'action',
            width: 100,
        }
    ],
    Purchase_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Invoice No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Invoice No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Type',
            field: 'sale_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Type',
            },
        },
        {
            label: 'Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Amount',
            },
        },
        {
            label: 'Balance Due',
            field: 'balance',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Due',
            },
        },
        {
            label: 'Action',
            field: 'actionExtra',
            width: 100,
        }
    ],
    PurchaseOrder_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Due Date',
            field: 'due_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Due Date',
            },
        },
        {
            label: 'Order No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Order No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Amount',
            },
        },
        {
            label: 'Balance Due',
            field: 'balance',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Due',
            },
        },
        {
            label: 'Status',
            field: 'status',
            width: 150
        },
        {
            label: 'Action',
            field: 'action',
            width: 100,
        }
        ,
        {
            label: '',
            field: 'extraAction',
            width: 100,
        }
    ],
    
    PurchasePaymentOut_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Receipt No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Receipt No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Total Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Total Amount',
            },
        },
        {
            label: 'Balance Amount',
            field: 'balance_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Amount',
            },
        },
        {
            label: 'Action',
            field: 'action',
            width: 100,
        }
    ],
    
    PurchasePaymentOutLink_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Receipt No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Receipt No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Payment Status',
            field: 'pay_status',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Pay Type',
            },
        },
        {
            label: 'Total Amount',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Total Amount',
            },
        },
        {
            label: 'Balance Amount',
            field: 'balance_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Amount',
            },
        },
        {
            label: 'Action',
            field: 'action',
            width: 100,
        }
    ],
    PurchaseReturn_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Date',
            },
        },
        {
            label: 'Ref No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Ref No.',
            },
        },
        {
            label: 'Party Name',
            field: 'party_name',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Party Name',
            },
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Total',
            field: 'total_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Amount',
            },
        },
        {
            label: 'Balance Amount',
            field: 'balance_amount',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Balance Amount',
            },
        },
        {
            label: 'Action',
            field: 'action',
            width: 100,
        }
    ],
    Expense_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 50,
        },
        {
            label: 'Exp No.',
            field: 'invoice_no',
            width: 150,
        },
        {
            label: 'Party',
            field: 'party_name',
            width: 100,
        },
        {
            label: 'Payment Type',
            field: 'payment_type',
            width: 100,
        },
        {
            label: 'Amount',
            field: 'total_amount',
            width: 100,
        },
        {
            label: 'Balance',
            field: 'balance_amount',
            width: 100,
        },
        {
            label: '',
            field: 'actionextra',
            width: 100,
        }
    ],
    AllTrans_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Receipt No.',
            field: 'invoice_no',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Receipt No.',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 50,
        },
        {
            label: 'Party',
            field: 'party_name',
            width: 100,
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Payment Status',
            field: 'pay_status',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Pay Type',
            },
        },
        {
            label: 'Amount',
            field: 'total_amount',
            width: 100,
        },
        {
            label: 'Balance',
            field: 'balance_amount',
            width: 100,
        },
        {
            label: 'Action',
            field: 'actionextra',
            width: 100,
        }/*,
        {
            label: '',
            field: 'actionextra',
            width: 100,
        }*/
    ],
    AllTrans_PRECV_PPAID_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 50,
        },
        {
            label: 'Party',
            field: 'party_name',
            width: 100,
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Amount',
            field: 'total_amount',
            width: 100,
        },
        {
            label: 'Balance',
            field: 'balance_amount',
            width: 100,
        }/*,
        {
            label: '',
            field: 'actionextra',
            width: 100,
        }*/
    ],
    PartyToParty_Columns : [
        {
            label: '#',
            field: 'srNo',
            width: 20,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': '#',
            },
        },
        {
            label: 'Date',
            field: 'invoice_date',
            width: 50,
        },
        {
            label: 'Party',
            field: 'party_name',
            width: 100,
        },
        {
            label: 'Type',
            field: 'payment_type',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Type',
            },
        },
        {
            label: 'Total Amount',
            field: 'total_amount',
            width: 100,
        },
        {
            label: 'Balance',
            field: 'balance_amount',
            width: 100,
        },
        {
            label: '',
            field: 'actionextra',
            width: 100,
        }
    ]
}
