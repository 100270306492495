import { el } from "date-fns/locale";
import React, { useState, useEffect } from "react";

const SelectNormalQuntity = ({ callBack, data, isFreeQuantity, isNewQty }) => {

    const [normalRemaingQuantity, setNormalRemaingQuantity] = useState(0);
    const [normalQuantity, setNormalQuantity] = useState(0);

    useEffect(() => {
        console.log('data', JSON.stringify(data));
        console.log('isNewQty', isNewQty);
        setNormalRemaingQuantity(data.isNewQty == 'NEW' ? 0 : data.normal_remaining_quantity);
        if (isFreeQuantity) {
            setNormalQuantity(data.free_quantity);
        }
    }, []);

    const handleChangeNormalQty = (e) => {
        let value = e.target.value;
        value = value * 1;
        if (/^[0-9]*$/.test(value)) {
            setNormalQuantity(value);
        }
    }

    const saveNormalQty = () => {
        let remaingQty = data.normal_remaining_quantity;
        if (isNewQty == 'NEW') {
            if(isFreeQuantity){
                // Valid quantity
            }else if (parseInt(normalQuantity) <= 0) {
                alert('Quantity can not be less than or equal to 0.');
                return;
            } else if (parseInt(normalQuantity) > 0) {
                // Valid quantity
            } else {
                alert('Please enter valid quantity.');
                return;
            }
        } else {
            if (isFreeQuantity) {
                if (parseInt(normalQuantity) > remaingQty) {
                    alert('Quantity can not be greater than remaining quantity.');
                    return;
                } else if (parseInt(normalQuantity) < 0) {
                    alert('Quantity can not be less than 0.');
                    return;
                }
            } else {
                if (parseInt(normalQuantity) > remaingQty) {
                    alert('Quantity can not be greater than remaining quantity.');
                    return;
                } else if (parseInt(normalQuantity) <= 0) {
                    alert('Quantity can not be less than or equal to 0.');
                    return;
                } else if (parseInt(normalQuantity) > 0 && parseInt(normalQuantity) <= remaingQty) {
                    // Valid quantity
                } else {
                    alert('Please enter valid quantity.');
                    return;
                }
            }
        }
        
        let updatedData = {};
        if (isFreeQuantity) {
            updatedData = {
                ...data,
                free_quantity: normalQuantity ? parseInt(normalQuantity) : 0,
                trackingType: 'Normal',
                isNewQty: isNewQty
            }
        } else {
            updatedData = {
                ...data,
                quantity: parseInt(normalQuantity),
                trackingType: 'Normal',
                isNewQty: isNewQty
            }
        }

        console.log('updatedData', JSON.stringify(updatedData));
        callBack(updatedData, isFreeQuantity);

    }

    return (
        <div>

            <div className="form-group row">
                <span>
                    Available Quantity Is : {normalRemaingQuantity > 0 ? normalRemaingQuantity : 0}
                </span>
            </div>

            <div className="form-group row">
                <div className="col-6">
                    <div className="form-material open">
                        <input
                            type="text"
                            className="form-control"
                            id="serial_no"
                            name="without_sr_quantity"
                            value={normalQuantity}
                            onChange={(e) => handleChangeNormalQty(e)}
                            placeholder="Enter Quantity"
                            maxLength="3"
                        />
                        <label htmlFor="login-username">Enter Quantity </label>
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-12">
                    <button
                        type="submit"
                        className="btn btn-alt-primary"
                        onClick={() => saveNormalQty()}
                    >
                        <i className="fa fa-check mr-5"></i> Save
                    </button>
                </div>
            </div>

        </div>
    )
}
export default SelectNormalQuntity