import React from 'react';
import '../userController/SuccessDialog.css'; 

const SuccessDialog = ({ onClose, title, message }) => {
    return (
        <div className="dialog-overlay">
      <div className="dialog success-dialog">
        <div className="dialog-header">
          <h3>{title}</h3>
        </div>
        <div className="dialog-body">
          <div className="tick-container">
            <img 
              src="https://i.gifer.com/7efs.gif"
              alt="Success" 
              className="tick-gif" 
            />
          </div>
          <p>{message}</p>
        </div>
        <div className="dialog-footer">
          <button onClick={() => onClose()}>Ok</button>
        </div>
      </div>
    </div>
    );
};

export default SuccessDialog;