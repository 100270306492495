import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { da, tr } from "date-fns/locale";

const ViewBatchQuntity = ({ callBack, data, businessSetting }) => {

    const [itemBatch, setItemBatch] = useState({});
    const [batchData, setBatchData] = useState([]);
    const [changeQty, setChangeQty] = useState(false);
    const handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        console.log('data.itemBatchNo', JSON.stringify(data));

        setItemBatch(data);
        let itemBatchNo = data.selectedData;
        itemBatchNo = itemBatchNo.map((data) => ({
            ...data,
            opening_stock: data.opening_stock + parseInt(data.free_quantity) + parseInt(data.used_stock),
        }))
        setBatchData(itemBatchNo);


    }, []);

    const getInStock = (element) => {
        let freeQuantity = element.free_quantity ? parseInt(element.free_quantity) : 0;
        let usedQuantity = element.used_stock ? parseInt(element.used_stock) : 0;
        let openingStock = element.opening_stock;
        let stock = openingStock - (freeQuantity + usedQuantity);
        return stock;
    }


    const saveBatchData = (batch) => {
        batch.free_quantity = batch.free_quantity ? batch.free_quantity : 0;
        const usedQty = batch.used_stock;
        const freeQty = batch.free_quantity;
        const inStock = getInStock(batch);

        if (usedQty <= 0) {
            alert('Please enter at least one quantity.');
        } else if (inStock < 0) {
            alert('Sum of quantity and free quantity can not be greater than opening stock.');
        } else {
            let _itemBatchNo = itemBatch.itemBatchNo
            let remaingBatchData = _itemBatchNo.filter((data) => data.batch_id !== batch.batch_id);
            let selectedBatch = {
                ...batch,
                opening_stock: inStock,
            }
            let selectedData = [selectedBatch];
            let updatedData = {
                ...itemBatch,
                itemBatchNo: remaingBatchData,
                selectedData: selectedData,
                quantity: parseInt(usedQty),
                free_quantity: parseInt(freeQty),
                trackingType: 'Batch',
                isNewQty: selectedData.isNewItem ? 'NEW' : 'OLD'
            }
            console.log('updatedData', JSON.stringify(updatedData));

            callBack(updatedData);
        }
    }
    const handleCallBackExpireDate = (date, index) => {
        let _date = moment(date).format("MM/DD/YYYY");
        let _batchData = [...batchData];
        _batchData[index].exp_date = _date;
        setBatchData(_batchData);
    }
    const viewExpireDate = (expireDate, index) => {
        const slectedDate = moment(expireDate).toDate();
        return (
            <DatePicker
                selected={slectedDate}
                className="form-control search-box"
                onChange={date => handleCallBackExpireDate(date, index)}
            />
        );
    }
    const handleCallBackMfgDate = (date, index) => {
        let _date = moment(date).format("MM/DD/YYYY");
        let _batchData = [...batchData];
        _batchData[index].mfg_date = _date;
        setBatchData(_batchData);
    }
    const viewMfgDate = (mfgDate, index) => {
        const slectedDate = moment(mfgDate).toDate();
        return (
            <DatePicker
                selected={slectedDate}
                className="form-control search-box"
                onChange={date => handleCallBackMfgDate(date, index)}
            />
        );
    }

    let clearBottomBorder = { border: "none", outline: "none", background: "transparent", maxWidth: 100 };
    return (
        <div className="webForm px-30" style={{ height: 300, overflowY: 'auto', marginBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
                {batchData.map((element, index) => (
                    <div key={index}>
                        <div className="form-group row">
                            {businessSetting.BatchNoChk == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            readOnly={!(changeQty && element.isNewItem)}
                                            style={(changeQty && element.isNewItem) ? { maxWidth: 100 } : clearBottomBorder}
                                            value={element.batch_no}
                                            placeholder={businessSetting.BatchNoTxt}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                let _batchData = [...batchData];
                                                _batchData[index].batch_no = e.target.value;
                                                setBatchData(_batchData);
                                            }}
                                        />
                                        <label htmlFor="login-username">{businessSetting.BatchNoTxt}</label>
                                    </div>
                                </div>
                            }

                            {businessSetting.ExpDateChk == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        {
                                            (changeQty && element.isNewItem) ?
                                                viewExpireDate(element.exp_date, index) :
                                                <input
                                                    type="text"
                                                    readOnly
                                                    style={clearBottomBorder}
                                                    value={moment(element.exp_date).format('MM/DD/YYYY')}
                                                    placeholder="Enter Value 2"
                                                    autoComplete="off" />
                                        }
                                        <label htmlFor="login-username">{businessSetting.ExpDateTxt}</label>
                                    </div>
                                </div>
                            }

                            {businessSetting.MfgDateChk == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        {
                                            (changeQty && element.isNewItem) ?
                                                viewMfgDate(element.mfg_date, index) :
                                                <input
                                                    type="text"
                                                    readOnly
                                                    style={clearBottomBorder}
                                                    value={moment(element.mfg_date).format('MM/DD/YYYY')}
                                                    placeholder="Enter Value 2"
                                                    autoComplete="off" />
                                        }
                                        <label htmlFor="login-username">{businessSetting.MfgDateTxt}</label>
                                    </div>
                                </div>
                            }

                            {businessSetting.ModelNoChk == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            readOnly={!(changeQty && element.isNewItem)}
                                            style={(changeQty && element.isNewItem) ? { maxWidth: 100 } : clearBottomBorder}
                                            value={element.model_no ? element.model_no : 'NA'}
                                            placeholder={businessSetting.ModelNoTxt}
                                            maxLength="30"
                                            autoComplete="off"
                                            onChange={(e) => {
                                                let _batchData = [...batchData];
                                                _batchData[index].model_no = e.target.value;
                                                setBatchData(_batchData);
                                            }} />
                                        <label htmlFor="login-username">{businessSetting.ModelNoTxt}</label>
                                    </div>
                                </div>
                            }

                            {businessSetting.SizeChk == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            maxLength={3}
                                            readOnly={!(changeQty && element.isNewItem)}
                                            style={(changeQty && element.isNewItem) ? { maxWidth: 100 } : clearBottomBorder}
                                            value={element.size ? element.size : 0}
                                            onKeyPress={(e) => handleKeyPress(e)}
                                            placeholder={businessSetting.SizeTxt}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                value = value * 1;
                                                let _batchData = [...batchData];
                                                _batchData[index].size = value;
                                                setBatchData(_batchData);
                                            }}
                                        />
                                        <label htmlFor="login-username">{businessSetting.SizeTxt}</label>
                                    </div>
                                </div>
                            }

                            {businessSetting.BatchNoAdditionalChk1 == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            readOnly={!(changeQty && element.isNewItem)}
                                            style={(changeQty && element.isNewItem) ? { maxWidth: 100 } : clearBottomBorder}
                                            value={element.batchNoAdditionalValue1 ? element.batchNoAdditionalValue1 : 'NA'}
                                            placeholder="Enter Value 1"
                                            autoComplete="off"
                                            onChange={(e) => {
                                                let _batchData = [...batchData];
                                                _batchData[index].batchNoAdditionalValue1 = e.target.value;
                                                setBatchData(_batchData);
                                            }} />
                                        <label htmlFor="login-username">{businessSetting.BatchNoAdditionalLabel1}</label>

                                    </div>
                                </div>
                            }
                            {businessSetting.BatchNoAdditionalChk2 == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            readOnly={!(changeQty && element.isNewItem)}
                                            style={(changeQty && element.isNewItem) ? { maxWidth: 100 } : clearBottomBorder}
                                            value={element.batchNoAdditionalValue2 ? element.batchNoAdditionalValue2 : 'NA'}
                                            placeholder="Enter Value 2"
                                            autoComplete="off"
                                            onChange={(e) => {
                                                let _batchData = [...batchData];
                                                _batchData[index].batchNoAdditionalValue2 = e.target.value;
                                                setBatchData(_batchData);
                                            }} />
                                        <label htmlFor="login-username">{businessSetting.BatchNoAdditionalLabel2}</label>
                                    </div>
                                </div>
                            }
                            <div className="col-2">
                                <div className="form-material open">
                                    <input
                                        type="text"
                                        style={clearBottomBorder}
                                        value={element.opening_stock}
                                        placeholder="Enter Value 2"
                                        readOnly
                                        autoComplete="off" />
                                    <label htmlFor="login-username">Opening Stock</label>
                                </div>
                            </div>
                            {
                                //!element.isNewItem &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={clearBottomBorder}
                                            value={getInStock(element)}
                                            placeholder="Enter Value 2"
                                            readOnly
                                            autoComplete="off" />
                                        <label htmlFor="login-username">In Stock</label>
                                    </div>
                                </div>
                            }

                            {businessSetting.FreeItemQuantity == 1 &&
                                <div className="col-2">
                                    <div className="form-material open">
                                        <input
                                            readOnly={!changeQty}
                                            type="text"
                                            maxLength={3}
                                            style={changeQty ? { maxWidth: 100 } : clearBottomBorder}
                                            onKeyPress={(e) => handleKeyPress(e)}
                                            value={element.free_quantity}
                                            placeholder="Free Quantity"
                                            onChange={e => {
                                                let value = e.target.value ? parseInt(e.target.value) : 0;
                                                value = value * 1;
                                                let updatedData = [...batchData];
                                                updatedData[index].free_quantity = value;
                                                if (element.isNewItem) {
                                                    batchData[index].opening_stock = parseInt(batchData[index].used_stock) + value;
                                                }
                                                setBatchData(updatedData);
                                            }}
                                            autoComplete="off" />
                                        <label htmlFor="login-username">Free Quantity</label>
                                    </div>
                                </div>
                            }
                            <div className="col-2">
                                <div className="form-material open">
                                    <input
                                        readOnly={!changeQty}
                                        type="text"
                                        maxLength={3}
                                        style={changeQty ? { maxWidth: 100 } : clearBottomBorder}
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        value={element.used_stock}
                                        placeholder="Quantity"
                                        onChange={e => {
                                            let value = e.target.value ? parseInt(e.target.value) : 0;
                                            value = value * 1;
                                            let updatedData = [...batchData];
                                            updatedData[index].used_stock = value;
                                            if (element.isNewItem) {
                                                batchData[index].opening_stock = parseInt(batchData[index].free_quantity) + value;
                                            }
                                            setBatchData(updatedData);
                                        }}
                                        autoComplete="off" />
                                    <label htmlFor="login-username">Quantity
                                        <span className="text-danger"> * </span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-2" >
                                <div className="form-material open">
                                    <div className="wrapperRadio">
                                        <button
                                            type="submit"
                                            onClick={() => {
                                                if (changeQty) {
                                                    saveBatchData(element);
                                                } else {
                                                    setChangeQty(!changeQty);
                                                }

                                            }}
                                            className="btn btn-alt-info mb-10 mx-5">
                                            {!changeQty ? 'Change Quantity' : 'Save'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                }


            </div>
        </div>
    )
}
export default ViewBatchQuntity