import React, { Component } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { PostData } from "../../service/postData";
import { numberFormat } from "../../config/numberFormat";
import { sendNotification } from "../../_notificationSettings/notificationPanel";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { PaginationControl } from "react-bootstrap-pagination-control";

let _UserSession = localStorage.getItem("vs_UserSession");
let _signingId = "0";
let _businessId = "0";
let _companyId = "0";
let _tStockValueM = 0;
let _businessSetting = {};

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  _businessSetting = _UserSession.businessSetting;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _companyId = _companySession.company_id;
}
let _t = "";
if (_businessSetting.Tran_AddTime == 1) {
  _t = " & Time";
}
let _loader = "glb-ldr-prt active";
let transactionColumns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    sort: "asc",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Date" + _t,
    field: "invoice_date",
    width: 150,
    sort: "asc",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Date",
    },
  },
  {
    label: "Invoice No.",
    field: "invoice_no",
    width: 150,
    sort: "asc",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Invoice No.",
    },
  },
  {
    label: "Party Name",
    field: "party_name",
    width: 150,
    sort: "asc",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Party Name",
    },
  },
  {
    label: "Type",
    field: "type",
    width: 150,
    sort: "asc",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Party Type",
    },
  },
  {
    label: "Amount",
    field: "total_amount",
    width: 150,
    sort: "asc",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Amount",
    },
  },
  {
    label: "Balance Due",
    field: "balance",
    width: 150,
    sort: "asc",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Balance Due",
    },
  },
  {
    label: "Action",
    field: "actionExtra",
    width: 100,
    sort: "asc",
  },
];

let partyColumns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Name",
    field: "name",
    width: 50,
  },
  {
    label: "Opening Balance",
    field: "openingBalance",
    width: 150,
  },
  {
    label: "Date",
    field: "date",
    width: 100,
  },
  {
    label: "Amount",
    field: "amount",
    width: 100,
  },
];

let itemColumns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Name",
    field: "itemName",
    width: 50,
  },
  {
    label: "Sale Price",
    field: "salePrice",
    width: 150,
  },
  {
    label: "Purchase Price",
    field: "purchasePrice",
    width: 100,
  },
  {
    label: "In Stock",
    field: "inStock",
    width: 100,
  },
  {
    label: "Available Qty",
    field: "availableStock",
    width: 100,
  },
  {
    label: "Reserved Qty",
    field: "reserveStock",
    width: 100,
  },
];
export default class DashboardPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      listTitle: "Parties",
      itemArray: [],
      partyArray: [],
      transactionArray: [],

      _tStockValue: 0,
      partyCount: 0,
      itemCount: 0,
      saleCount: 0,
      purchaseCount: 0,
      totalGiveAmount: 0,
      totalGetAmount: 0,
      totalSaleAmount: 0,
      totalPurchaseAmount: 0,
      totalCashInHand: 0,
      totalBankBalance: 0,
      totalSaleOrderCount: 0,
      totalSaleOrderAmt: 0,
      totalPurchaseOrderCount: 0,
      totalPurchaseOrderAmt: 0,
      totalEstimateCount: 0,
      totalEstimateAmt: 0,
      totalDChallanCount: 0,
      totalDChallanAmt: 0,
      totalChequeCount: 0,
      totalReciveChequeCount: 0,
      totalReciveChequeAmount: 0,
      totalPaidChequeCount: 0,
      totalPaidChequeAmount: 0,
      totalExpenseAmt: 0,
      totalLoanAccountAmt: 0,
      totalStockValue: 0,
      totalOtherIncomeAmt: 0,
      totalExpiringStock: 0,
      transactionListArrayData: [],
      pageCount: 10,
      page: 1,
      totalCount: 0,
      pageShow: 0,
      setPage: 1,
      searchInvNo: "",
      searchPartyName: "",
      searchItemName: "",
      itemPage: 1,
      itemPageCount: 10,
      itemTotalCount: 0,
      itemPageShow: 0,
      itemSetPage: 1,
      itemListArrayData: [],

      partyPage: 1,
      partyPageCount: 10,
      partyTotalCount: 0,
      partyPageShow: 0,
      partySetPage: 1,
      //partyListArrayData : [],
    };
  }

  componentDidMount() {
    this.dashboadCountList();
    this.partyListData(this.state.partyPage, this.state.searchPartyName,this.state.partyPageCount);
    this.transactionListData(this.state.page, this.state.searchInvNo,this.state.pageCount);
    this.ItemListingNew(this.state.itemPage, this.state.searchItemName,this.state.itemPageCount);
  }

  dashboadCountList() {
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
    });

    PostData(global.userBusinessDashboardCountList, requestData, "POST").then(
      (result) => {
        let responseJson = result;
        let _transactionAmtCountData = "";
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          _transactionAmtCountData = responseJson.amountList[0];
          this.setState({
            isLoaded: true,
            partyCount: responseJson.countList[0].partyCount,
            purchaseCount: responseJson.countList[0].purchaseCount,
            saleCount: responseJson.countList[0].saleCount,
            itemCount: responseJson.countList[0].itemCount,
            totalGiveAmount: _transactionAmtCountData.totalGiveAmount
              ? _transactionAmtCountData.totalGiveAmount
              : 0,
            totalGetAmount: _transactionAmtCountData.totalGetAmount
              ? _transactionAmtCountData.totalGetAmount
              : 0,
            totalSaleAmount: _transactionAmtCountData.totalSaleAmount
              ? _transactionAmtCountData.totalSaleAmount
              : 0,
            totalPurchaseAmount: _transactionAmtCountData.totalPurchaseAmount
              ? _transactionAmtCountData.totalPurchaseAmount
              : 0,
            totalCashInHand: _transactionAmtCountData.totalCashInHand
              ? _transactionAmtCountData.totalCashInHand
              : 0,
            totalBankBalance: _transactionAmtCountData.totalBankBalance
              ? _transactionAmtCountData.totalBankBalance
              : 0,
            totalSaleOrderCount: _transactionAmtCountData.totalSaleOrderCount
              ? _transactionAmtCountData.totalSaleOrderCount
              : 0,
            totalSaleOrderAmt: _transactionAmtCountData.totalSaleOrderAmt
              ? _transactionAmtCountData.totalSaleOrderAmt
              : 0,
            totalPurchaseOrderCount:
              _transactionAmtCountData.totalPurchaseOrderCount
                ? _transactionAmtCountData.totalPurchaseOrderCount
                : 0,
            totalPurchaseOrderAmt:
              _transactionAmtCountData.totalPurchaseOrderAmt
                ? _transactionAmtCountData.totalPurchaseOrderAmt
                : 0,
            totalEstimateCount: _transactionAmtCountData.totalEstimateCount
              ? _transactionAmtCountData.totalEstimateCount
              : 0,
            totalEstimateAmt: _transactionAmtCountData.totalEstimateAmt
              ? _transactionAmtCountData.totalEstimateAmt
              : 0,
            totalDChallanCount: _transactionAmtCountData.totalDChallanCount
              ? _transactionAmtCountData.totalDChallanCount
              : 0,
            totalDChallanAmt: _transactionAmtCountData.totalDChallanAmt
              ? _transactionAmtCountData.totalDChallanAmt
              : 0,
            totalChequeCount: _transactionAmtCountData.totalChequeCount
              ? _transactionAmtCountData.totalChequeCount
              : 0,
            totalReciveChequeCount:
              _transactionAmtCountData.totalReciveChequeCount
                ? _transactionAmtCountData.totalReciveChequeCount
                : 0,
            totalReciveChequeAmount:
              _transactionAmtCountData.totalReciveChequeAmount
                ? _transactionAmtCountData.totalReciveChequeAmount
                : 0,
            totalPaidChequeCount: _transactionAmtCountData.totalPaidChequeCount
              ? _transactionAmtCountData.totalPaidChequeCount
              : 0,
            totalPaidChequeAmount:
              _transactionAmtCountData.totalPaidChequeAmount
                ? _transactionAmtCountData.totalPaidChequeAmount
                : 0,
            totalExpenseAmt: _transactionAmtCountData.totalExpenseAmt
              ? _transactionAmtCountData.totalExpenseAmt
              : 0,
            totalLoanAccountAmt: _transactionAmtCountData.totalLoanAccountAmt
              ? _transactionAmtCountData.totalLoanAccountAmt
              : 0,
            totalStockValue: _transactionAmtCountData.totalStockValue
              ? _transactionAmtCountData.totalStockValue
              : 0,
            totalOtherIncomeAmt: _transactionAmtCountData.totalOtherIncomeAmt
              ? _transactionAmtCountData.totalOtherIncomeAmt
              : 0,
            totalExpiringStock: _transactionAmtCountData.totalExpiringStock
              ? _transactionAmtCountData.totalExpiringStock
              : 0,
          });
        }
      }
    );
  }
  getPartySearch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let _valueSend = value;
    this.setState({ searchPartyName: _valueSend });
    if (_valueSend.length >= 3 || _valueSend == "") {
      this.partyListData(this.state.page, _valueSend,this.state.partyPageCount);
    }
  };
  partyListData(_page, searchInvSend,partyPageCount) {
    let _partyListArrayData = [];
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      partyPageCount: partyPageCount,
      partyPage: _page,
      searchPartyName: searchInvSend,
    });
    PostData(global.userBusinessDashboardPartyList, requestData, "POST").then(
      (result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true, transactionListArrayData: [] });
        } else {
          if (responseJson.response) {
            _partyListArrayData = responseJson.response;
            this.setState({
              isLoaded: true,
              partyArray: _partyListArrayData,
              partyTotalCount: responseJson.partyTotalCount,
              partyPageShow: responseJson.partyPageShow,
            });
          }
        }
      }
    );
  }

  getInvoiceSearch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let _valueSend = value;
    this.setState({ searchInvNo: _valueSend });
    if (_valueSend.length >= 3 || _valueSend == "") {
      this.transactionListData(this.state.page, _valueSend,this.state.pageCount);
    }
  };

  transactionListData(_page, searchInvSend,pageCount) {
    let _transactionListArrayData = [];
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      pageCount: pageCount,
      search_inv_no: searchInvSend,
      page: _page,
    });
    PostData(
      global.userBusinessDashboardTransactionList,
      requestData,
      "POST"
    ).then((result) => {
      let responseJson = result;
      if (responseJson.success == false) {
        this.setState({ isLoaded: true, transactionListArrayData: [], totalCount: 0 });
      } else {
        if (responseJson.response) {
          _transactionListArrayData = responseJson.response;
          this.setState({
            isLoaded: true,
            transactionListArrayData: _transactionListArrayData,
            totalCount: responseJson.totalCount,
            pageShow: responseJson.pageShow,
          });
        }
      }
    });
  }

  loadTransactionListingNew() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transactionDetails = this.state.transactionListArrayData;
      if (_transactionDetails.length > 0) {
        let _ii = 0;

        // if (this.state.setPage > 1) {
        //   _ii = (this.state.setPage - 1) * this.state.pageCount;
        // }
        _transactionDetails.map((value, index) => {
          _ii++;

          let _trans_duplicate_normal = value.trans_duplicate_normal ? value.trans_duplicate_normal : '';
          let _type = "";
          let _editUrl = "";
          let _saleDuplicate = "";
          let _purchaseDuplicate = "";
          let _saleOrderDuplicate = "";
          let _purchaseOrderDuplicate = "";

          let _typeSend = "&tType=Dashboard";
          if (value.type == "SALE") {
            _type = <span className={`badge badge-success`}>SALE</span>;
            _editUrl = "/business/add-sale/?auth=" + value.id + _typeSend;
            let editDuplicate =
              "/business/add-sale-duplicate/?auth=" + value.id+'&tType=DUPLICATE';
            _saleDuplicate = (
              <Link className="dropdown-item" to={editDuplicate}>
                Duplicate
              </Link>
            );
          } 
          else if (value.type == "SALE ORDER") {
            _type = (
              <span className={`badge badge-primaryLight`}>SALE ORDER</span>
            );
            _editUrl = "/business/add-sale-order/?auth=" + value.id + _typeSend;
            let editDuplicate =
              "/business/add-sale-duplicate/?auth=" + value.id+'&tType=ORDER';
            _saleDuplicate = (
              <Link className="dropdown-item" to={editDuplicate}>
                Convert To Sale
              </Link>
            );
            let editSODuplicate =
              "/business/add-sale-duplicate-order/?auth=" + value.id;
            _saleOrderDuplicate = (
              <Link className="dropdown-item" to={editSODuplicate}>
                Duplicate
              </Link>
            );
          } 
          else if (value.type == "ESTIMATE") {
            _type = (
              <span className={`badge badge-primaryLight`}>ESTIMATE</span>
            );
            //_editUrl = '';
            _editUrl =
              "business/add-sale-estimate/?auth=" + value.id + _typeSend;

            let editDuplicate =
              "/business/add-sale-duplicate/?auth=" + value.id;
            _saleDuplicate = (
              <Link className="dropdown-item" to={editDuplicate}>
                Duplicate
              </Link>
            );
          } 
          else if (value.type == "CREDIT NOTE") {
            _type = (
              <span className={`badge badge-primaryLight`}>CREDIT NOTE</span>
            );
            _editUrl =
              "/business/add-sale-credit-note/?auth=" +
              value.id +
              "&tType=CREDIT NOTE";
          } 
          else if (value.type == "PAYMENT-IN") {
            _type = (
              <span className={`badge badge-successDark`}>PAYMENT-IN</span>
            );
            _editUrl =
              "/business/add-sale-payment-in/?auth=" + value.id + _typeSend;
          } 
          else if (value.type == "DELIVERY CHALLAN") {
            _type = (
              <span className={`badge badge-primaryLight`}>
                {" "}
                DELIVERY CHALLAN
              </span>
            );
            _editUrl =
              "/business/add-sale-delivery-challan/?auth=" +
              value.id +
              _typeSend;
          } 
          else if (value.type == "PURCHASE") {
            _type = <span className={`badge badge-success`}>PURCHASE</span>;
            _editUrl = "/business/add-purchase/?auth=" + value.id + _typeSend;
            let editDuplicateP =
              "/business/manage-purchase-to-duplicate/?auth=" + value.id+'&tType=DUPLICATE';
            _purchaseDuplicate = (
              <Link className="dropdown-item" to={editDuplicateP}>
                Duplicate
              </Link>
            );
          } 
          else if (value.type == "EXPENSE") {
            _type = <span className={`badge badge-secondary`}>EXPENSE</span>;
            _editUrl = "/business/add-expense/?auth=" + value.id + _typeSend;
          } 
          else if (value.type == "PURCHASE ORDER") {
            _type = (
              <span className={`badge badge-secondary`}>PURCHASE ORDER</span>
            );
            _editUrl =
              "/business/add-purchase-order/?auth=" + value.id + _typeSend;
            let editDuplicateP =
              "/business/manage-purchase-to-duplicate/?auth=" + value.id+'&tType=ORDER';
            _purchaseDuplicate = (
              <Link className="dropdown-item" to={editDuplicateP}>
                Conver To Purchase
              </Link>
            );

            let editSODuplicate =
              "/business/manage-purchase-order-duplicate/?auth=" + value.id;
            _purchaseOrderDuplicate = (
              <Link className="dropdown-item" to={editSODuplicate}>
                Duplicate
              </Link>
            );
          }
          else if (value.type == "DEBIT NOTE") {
            _type = <span className={`badge badge-secondary`}>DEBIT NOTE</span>;
            _editUrl =
              "/business/add-purchase-debit-note/?auth=" + value.id + _typeSend;
          }
          else if (value.type == "PAYMENT-OUT") {
            _type = (
              <span className={`badge badge-secondary`}>PAYMENT-OUT</span>
            );
            _editUrl =
              "/business/add-purchase-payment-out/?auth=" +
              value.id +
              _typeSend;
          }
          else if ( value.type == "OTHER INCOME" ||value.type == "Other Income") {
            _type = <span className={`badge badge-success`}>OTHER INCOME</span>;
            _editUrl = "/business/create-other-income/?auth=" + value.id;
          }
          else if (value.type == "Party To Party") {
            _type = (
              <span className={`badge badge-success`}>Party To Party</span>
            );
            _editUrl = "/business/party-to-party-transfer/?auth=" + value.id;
          }

          if(_trans_duplicate_normal == 'Duplicate'){
            _saleDuplicate = '';
            _purchaseDuplicate = '';
            _saleOrderDuplicate = '';
            _purchaseOrderDuplicate = '';
        }

          let _actionExtra = (
            <div className="btn-group" role="group">
              <button
                type="button"
                id="btnGroupVerticalDrop3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="btnGroupVerticalDrop1"
              >
                {_trans_duplicate_normal == 'Normal' &&
                  <Link className="dropdown-item" to={_editUrl}>
                    View/Edit Details
                  </Link>
                }
                {_saleDuplicate}
                {_purchaseDuplicate}
                {_saleOrderDuplicate}
                {_purchaseOrderDuplicate}
                <Link
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToDelete.bind(this, value.id, "Sale")}
                >
                  Delete
                </Link>
                <Link
                  className="dropdown-item"
                  to={"/business/download-pdf/?auth=" + value.id}
                  target="_blank"
                >
                  Download PDF
                </Link>
                <Link
                  className="dropdown-item"
                  to={"/business/download-pdf/?auth=" + value.id}
                  target="_blank"
                >
                  Preview
                </Link>
                <Link
                  className="dropdown-item"
                  to={"/business/download-pdf/?auth=" + value.id}
                  target="_blank"
                >
                  Print
                </Link>
              </div>
            </div>
          );
          if (value.type == "OTHER INCOME" || value.type == "Other Income") {
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <Link className="dropdown-item" to={_editUrl}>
                    View/Edit Details
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={"/business/download-oi-pdf/?auth=" + value.id}
                    target="_blank"
                  >
                    Download PDF
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={"/business/download-oi-pdf/?auth=" + value.id}
                    target="_blank"
                  >
                    Preview
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={"/business/download-oi-pdf/?auth=" + value.id}
                    target="_blank"
                  >
                    Print
                  </Link>
                </div>
              </div>
            );
          } else if (value.type == "Party To Party") {
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <Link className="dropdown-item" to={_editUrl}>
                    View/Edit Details
                  </Link>
                </div>
              </div>
            );
          }
          let showDateTime = value.invoice_date;
          if (_businessSetting.Tran_AddTime == 1) {
            showDateTime = value.invoice_date + " " + value.invoice_time;
          }
          let _pageAK = this.state.setPage;
          
          let _showDupL = '';
          if(_trans_duplicate_normal == 'Duplicate'){
              _showDupL = ' (Duplicate)';
          }
          let _totalAmt = value.total_amount;
          if (value.type === "PURCHASE" && parseFloat(value.payable_amount) >0) {
            _totalAmt = value.payable_amount;
          }

          rows.push({
            //srNo: _ii, //value.srNo,//_ii,//            
            srNo: ((_pageAK - 1) * this.state.pageCount) + _ii,
            type: _type,
            invoice_date: showDateTime,
            invoice_no: value.invoice_no + _showDupL,
            party_name: value.party_name,
            payment_type: value.payment_paid,
            total_amount: numberFormat(_totalAmt),
            balance: numberFormat(value.balance),
            actionExtra: _actionExtra,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: transactionColumns,
        rows: rows,
      };
    }
    return dataList;
  }

  getItemSearch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let _valueSend = value;
    this.setState({ searchItemName: _valueSend });
    if (_valueSend.length >= 3 || _valueSend == "") {
      this.ItemListingNew(this.state.page, _valueSend,this.state.itemPageCount);
    }
  };

  ItemListingNew(_page, searchInvSend,itemPageCount) {
    let _itemListArrayData = [];
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      itemPageCount: itemPageCount,
      itemPage: _page,
      searchItemName: searchInvSend,
    });
    PostData(global.userBusinessDashboardItemList, requestData, "POST").then(
      (result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true, itemListArrayData: [] });
        } else {
          if (responseJson.response) {
            _itemListArrayData = responseJson.response;

            _itemListArrayData.map((value, index) => {
              _tStockValueM = _tStockValueM + value.totalStockPrice;
            });
            this.setState({
              isLoaded: true,
              itemListArrayData: _itemListArrayData,
              itemTotalCount: responseJson.itemTotalCount,
              itemPageShow: responseJson.itemPageShow,
              _tStockValue: _tStockValueM,
            });
          }
        }
      }
    );
  }

  loadItemListingNew() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _itemDetails = this.state.itemListArrayData;
      if (_itemDetails.length > 0) {
        _itemDetails.map((value, index) => {
          let _originalStock = (
            <span className="text-success">{value.totalStock}</span>
          );
          if (value.color == "Red") {
            _originalStock = (
              <span className="text-danger">{value.totalStock}</span>
            );
          }
          rows.push({
            srNo: value.srNo,
            itemName: value.name,
            salePrice: numberFormat(value.sale_price),
            purchasePrice: numberFormat(value.purchase_price),
            inStock: _originalStock,
            availableStock: value.availableQty,
            reserveStock: value.reservedQty,
          });
        });
      }
      //this.setState({ _tStockValue: _StockValue });
    }

    if (rows) {
      dataList = {
        columns: itemColumns,
        rows: rows,
      };
    }
    return dataList;
  }

  loadPartyListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _partyDetails = this.state.partyArray;
      if (_partyDetails.length > 0) {
        _partyDetails.map((value, index) => {
          let _originalAmt  = "";
          if(value.totalOutCome == 0 || value.totalOutCome == null){
            _originalAmt = (
              <span >
                {numberFormat(0)} (Balance Clear)               
              </span>
            );
          }else{
            _originalAmt = (
              <span className="text-success">
                {numberFormat(Math.abs(value.totalOutCome))}
                <br />
                {value.txtBalance}
              </span>
            );
            if (value.color == "Red") {
              _originalAmt = (
                <span className="text-danger">
                  {numberFormat(Math.abs(value.totalOutCome))}
                  <br />
                  {value.txtBalance}
                </span>
              );
            }
            if (value.color == "Black") {
              _originalAmt = (
                <span className="text">
                  {numberFormat(Math.abs(value.totalOutCome))}
                  <br />
                  {value.txtBalance}
                </span>
              );
            }
          }
          
          rows.push({
            srNo: value.srNo,
            name: value.party_name,
            openingBalance: numberFormat(Math.abs(value.party_opening_balance)),
            date: value.added_on,
            amount: _originalAmt,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: partyColumns,
        rows: rows,
      };
    }
    return dataList;
  }

  manageTabList(value) {
    this.setState({ listTitle: value });
    //this.buttonAddShow();
  }

  clickToDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getSaleListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  buttonAddShow() {
    let _returnButton = "";
    if (this.state.listTitle == "Parties") {
      _returnButton = (
        <div>
          <Link
            to={"/business/add-party/"}
            className="btn btn-sm btn-outline-secondary"
          >
            <span>
              <i className="si si-plus"></i> Add New Party
            </span>
          </Link>
        </div>
      );
    } else if (this.state.listTitle == "Items") {
      /*else if(this.state.listTitle == 'Transactions'){
            _returnButton = <div><a href={"/business/sale-invoice/"} className="btn btn-sm btn-outline-secondary"><span><i className="si si-plus"></i> Add New Transaction</span></Link></div>
        }*/
      _returnButton = (
        <div>
          <Link
            to={"/business/add-item/"}
            className="btn btn-sm btn-outline-secondary"
          >
            <span>
              <i className="si si-plus"></i> Add New Item
            </span>
          </Link>
        </div>
      );
    }
    return _returnButton;
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _callListing = this.loadPartyListing();
    if (this.state.listTitle == "Transactions") {
      _callListing = this.loadTransactionListingNew();
    } else if (this.state.listTitle == "Items") {
      _callListing = this.loadItemListingNew();
    }

    return (
      <React.Fragment>
        <div className="content">
          <div className="row" data-toggle="appear">
            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-party/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="si si-emoticon-smile fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    <span>{this.state.partyCount}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Parties
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/sale-invoice/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="si si-basket fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    Sale Invoice <span>{this.state.saleCount}</span>
                  </div>
                  <div className="font-size font-w600">
                    Sale Amount{" "}
                    <span>{numberFormat(this.state.totalSaleAmount)}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Sales
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/purchase-invoice/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="si si-basket fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    Purchase Bills <span>{this.state.purchaseCount}</span>
                  </div>
                  <div className="font-size font-w600">
                    Purchase Amount{" "}
                    <span>{numberFormat(this.state.totalPurchaseAmount)}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Purchase
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-items/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    <span>{this.state.itemCount}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Items
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-party/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block colorRed">
                    <i className="fa fa-arrow-up fa-3x text-body-bg-dark colorRed"></i>
                  </div>
                  <div className="font-size font-w600  colorRed">
                    <span>{numberFormat(this.state.totalGiveAmount)}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    You'll Give
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-party/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-arrow-down fa-3x text-body-bg-dark colorGreen"></i>
                  </div>
                  {
                    String(this.state.totalGetAmount).includes('-') ?
                        <div className="font-size font-w600 colorRed">
                          <span>{numberFormat(this.state.totalGetAmount)}</span>
                        </div>:
                        <div className="font-size font-w600 colorGreen">
                          <span>{numberFormat(this.state.totalGetAmount)}</span>
                        </div>
                  }

                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    You'll Get
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-cash-in-hand/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  {
                    String(this.state.totalCashInHand).includes('-') ?
                        <div className="font-size font-w600 colorRed">
                          <span>{numberFormat(this.state.totalCashInHand)}</span>
                        </div> :
                        <div className="font-size font-w600 colorGreen">
                          <span>{numberFormat(this.state.totalCashInHand)}</span>
                        </div>
                  }

                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Cash In Hand
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-items/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    {/* <span>{numberFormat(_tStockValueM)}</span> */}
                    <span>{numberFormat(this.state.totalStockValue)}</span>
                    
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Stock Value
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-account-list/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    <span>{numberFormat(this.state.totalBankBalance)}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Bank Balance
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-expense/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    <span>{numberFormat(this.state.totalExpenseAmt)}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Expense
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-loan-accounts/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600 colorRed">
                    <span>{numberFormat(this.state.totalLoanAccountAmt)}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Loan Account
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/other-income/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  {
                    String(this.state.totalOtherIncomeAmt).includes('-') ?
                        <div className="font-size font-w600 colorRed">
                          <span>{numberFormat(this.state.totalOtherIncomeAmt)}</span>
                        </div>:
                        <div className="font-size font-w600 colorGreen">
                          <span>{numberFormat(this.state.totalOtherIncomeAmt)}</span>
                        </div>
                  }

                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Other Income
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/reports/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    <span>{this.state.totalExpiringStock}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Expiring Stock
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-sale-order/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    Open Orders <span>{this.state.totalSaleOrderCount}</span>
                  </div>
                  <div className="font-size font-w600">
                    Order Amount{" "}
                    <span>{numberFormat(this.state.totalSaleOrderAmt)}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Sale Order
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-purchase-order/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    Open Orders{" "}
                    <span>{this.state.totalPurchaseOrderCount}</span>
                  </div>
                  <div className="font-size font-w600">
                    Order Amount{" "}
                    <span>
                      {numberFormat(this.state.totalPurchaseOrderAmt)}
                    </span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Purchase Order
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-sale-estimate/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    Open Estimate <span>{this.state.totalEstimateCount}</span>
                  </div>
                  <div className="font-size font-w600">
                    Estimate Amount{" "}
                    <span>{numberFormat(this.state.totalEstimateAmt)}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Estimate/Quotations
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-sale-delivery-challan/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    Open Challan <span>{this.state.totalDChallanCount}</span>
                  </div>
                  <div className="font-size font-w600">
                    Challan Amount{" "}
                    <span>{numberFormat(this.state.totalDChallanAmt)}</span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Delivery Challan
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-xl-4">
              <Link
                className="block block-link-shadow text-right"
                to={"/business/manage-cheque-list/"}
              >
                <div className="block-content block-content-full clearfix">
                  <div className="float-left mt-10 d-none d-sm-block">
                    <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                  </div>
                  <div className="font-size font-w600">
                    Received Cheques(
                    <span style={{ color: "red" }}>
                      {this.state.totalReciveChequeCount}
                    </span>
                    )
                    <span>
                      {" "}
                      {numberFormat(this.state.totalReciveChequeAmount)}
                    </span>
                  </div>
                  <div className="font-size font-w600">
                    Paid Cheques(
                    <span style={{ color: "red" }}>
                      {this.state.totalPaidChequeCount}
                    </span>
                    ){" "}
                    <span>
                      {numberFormat(this.state.totalPaidChequeAmount)}
                    </span>
                  </div>
                  <div className="font-size-sm font-w600 text-uppercase text-muted">
                    Open Cheques {this.state.totalChequeCount}
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="block">
                <ul
                  className="nav nav-tabs nav-tabs-block"
                  data-toggle="tabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <Link
                      className={
                        this.state.listTitle == "Parties"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Parties")}
                    >
                      <i className="si si-emoticon-smile mr-5 text-primary"></i>{" "}
                      Parties
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.state.listTitle == "Transactions"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Transactions")}
                    >
                      <i className="si si-basket text-info"></i> Transactions
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.state.listTitle == "Items"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Items")}
                    >
                      <i className="si si-check text-success"></i> Items
                    </Link>
                  </li>
                </ul>
                <div className="block-content tab-content">
                  <div
                    className="tab-pane active"
                    id="btabs-static-home"
                    role="tabpanel"
                  >
                    <div className="block-header">
                      <h3 className="block-title">
                        # {this.state.listTitle} List
                      </h3>

                      <div className="block-options">
                        {this.buttonAddShow()}
                      </div>
                    </div>
                    <div className="block-header">
                      {this.state.listTitle == "Parties" && (
                        <input
                          type="text"
                          className="form-control col-6"
                          style={{ display: "block" }}
                          placeholder="Search Party Name (type at least three characters)"
                          onChange={this.getPartySearch}
                        />
                      )}
                      {this.state.listTitle == "Transactions" && (
                        <input
                          type="text"
                          className="form-control col-6"
                          style={{ display: "inline-block" }}
                          placeholder="Search Invoice Number (type at least three characters)"
                          onChange={this.getInvoiceSearch}
                        />
                      )}
                      {this.state.listTitle == "Items" && (
                        <input
                          type="text"
                          className="form-control col-6"
                          style={{ display: "inline-block" }}
                          placeholder="Search Item Name (type at least three characters)"
                          onChange={this.getItemSearch}
                        />
                      )}
                    </div>

                    <div className="block-content">
                      <MDBDataTableV5
                        //hover
                        //entriesOptions={[1, 5, 10]}
                        // entries={25}
                        // pagesAmount={4}
                        // span
                        // pagingTop
                        // searchTop={false}
                        // searchBottom={false}
                        // pagingBottom={false}
                        // pagingFull={false}
                        // paginationLeft={false}
                        // paging={false}
                        // // barReverse
                        // data={_callListing}
                        hover
                        span
                        displayEntries={false}
                        entries={100}
                        pagingTop={false}
                        searchTop={false}
                        searchBottom={false}
                        info={false}
                        barReverse
                        data={_callListing}
                      />
                    </div>
                    <div className="block-content">
                      {this.state.listTitle == "Parties" && (
                        <div>                          
                            <div style={{ float: "right", marginTop: "-40px" }}>
                              <div className="d-flex align-items-center justify-content-end">
                                  <div className="d-flex align-items-center">
                                  <div role="status" aria-live="polite"> Rows per page:</div>
                                  </div>
                                  <div className="d-flex align-items-center" >
                                  <select className=" d-flex align-items-center custom-select custom-select-sm form-control form-control-sm"
                                          onChange={(event) => {
                                              event.preventDefault();
                                              const { name, value } = event.target;
                                              this.setState(prevState => ({
                                                      partyPageCount : value,
                                                      partySetPage : 1,
                                                  }),
                                                  () => {
                                                      this.partyListData(1,this.state.searchPartyName,
                                                          value
                                                      );
                                                  });
                                          }}>
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                  </select>
                                  </div>
                                  <div className="d-flex align-items-center">
                                  <div role="status" aria-live="polite"> Total: {this.state.partyTotalCount}</div>
                                  </div>
                              </div>
                            </div>
                            <div style={{ float: "left", marginTop: "-45px" }}>
                                <PaginationControl
                                    between={5}
                                  total={this.state.partyTotalCount}
                                  limit={this.state.partyPageCount}
                                  changePage={(page) => {
                                    this.setState({ partySetPage: page });
                                    this.partyListData(
                                      page,
                                      this.state.searchPartyName,this.state.partyPageCount
                                    );
                                  }} 
                                  ellipsis={1}
                                    last={true}
                                  activeClass={this.state.partySetPage}
                                  page={this.state.partySetPage}
                                />
                            </div>
                        </div>
                      )}
                      {this.state.listTitle == "Transactions" && (
                        <div> 
                          <div style={{ float: "right", marginTop: "-40px" }}>
                              <div className="d-flex align-items-center justify-content-end">
                                  <div className="d-flex align-items-center">
                                  <div role="status" aria-live="polite"> Rows per page:</div>
                                  </div>
                                  <div className="d-flex align-items-center" >
                                  <select className=" d-flex align-items-center custom-select custom-select-sm form-control form-control-sm"
                                          onChange={(event) => {
                                              event.preventDefault();
                                              const { name, value } = event.target;
                                              this.setState(prevState => ({
                                                      pageCount : value,
                                                      setPage : 1,
                                                  }),
                                                  () => {
                                                      this.transactionListData(1,this.state.searchInvNo,
                                                          value
                                                      );
                                                  });
                                          }}>
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                  </select>
                                  </div>
                                  <div className="d-flex align-items-center">
                                  <div role="status" aria-live="polite"> Total: {this.state.totalCount}</div>
                                  </div>
                              </div>
                          </div>
                          <div style={{ float: "left", marginTop: "-45px" }}>
                            {
                              this.state.transactionListArrayData.length > 0 &&
                              <PaginationControl
                                  between={5}
                                  total={this.state.totalCount}
                                  limit={this.state.pageCount}
                                  changePage={(page) => {
                                    this.setState({ setPage: page });
                                    this.transactionListData(
                                        page,
                                        this.state.searchInvNo,this.state.pageCount
                                    );
                                  }}
                                  ellipsis={1}
                                  last={true}
                                  activeClass={this.state.setPage}
                                  page={this.state.setPage}
                              />
                            }

                          </div>
                        </div>
                      )}
                      {this.state.listTitle == "Items" && (
                        <div>   
                          <div style={{ float: "right", marginTop: "-40px" }}>
                              <div className="d-flex align-items-center justify-content-end">
                                  <div className="d-flex align-items-center">
                                  <div role="status" aria-live="polite"> Rows per page:</div>
                                  </div>
                                  <div className="d-flex align-items-center" >
                                  <select className=" d-flex align-items-center custom-select custom-select-sm form-control form-control-sm"
                                          onChange={(event) => {
                                              event.preventDefault();
                                              const { name, value } = event.target;
                                              this.setState(prevState => ({
                                                itemPageCount : value,
                                                      itemSetPage : 1,
                                                  }),
                                                  () => {
                                                      this.ItemListingNew(1,this.state.searchItemName,
                                                          value
                                                      );
                                                  });
                                          }}>
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                  </select>
                                  </div>
                                  <div className="d-flex align-items-center">
                                  <div role="status" aria-live="polite"> Total: {this.state.itemTotalCount}</div>
                                  </div>
                              </div>
                          </div>
                          <div style={{ float: "left", marginTop: "-45px" }}>
                            <PaginationControl
                                between={5}
                              total={this.state.itemTotalCount}
                              limit={this.state.itemPageCount}
                              changePage={(page) => {
                                this.setState({ itemSetPage: page });
                                this.ItemListingNew(
                                  page,
                                  this.state.searchItemName,this.state.itemPageCount
                                );
                              }} 
                              ellipsis={1}
                                last={true}
                              activeClass={this.state.itemSetPage}
                              page={this.state.itemSetPage}
                            />
                            
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
