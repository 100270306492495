import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import QuickAddNewOICategory from "./quickAddOtherIncomeCategory";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import * as XLSX from "xlsx"; 
import ButtonPdf from "../htmlPdfCreation";
let _signingId = "0"; 
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = ""; 
let _activeCategoryS = "";
let _Tran_BillingNameParty = "1";
let _countryCode = "IN";
let _businessSetting = {};
let columns = [];
let _loader = "glb-ldr-prt active";

export default class manageOtherIncomeListPanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessSetting = _UserSession.businessSetting;
      _Tran_BillingNameParty = _businessSetting.Tran_BillingNameParty;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      country_code = _companySession.country_code;
      tax_name = _companySession.tax_name;
    }
    let _t = "";
    if (_businessSetting.Tran_AddTime == 1) {
      _t = " & Time";
    }
    if (_Tran_BillingNameParty == 1) {
      columns = [
        {
          label: "#",
          field: "srNo",
          width: 20,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "#",
          },
        },
        {
          label: "Category Name",
          field: "cat_name",
          width: 50,
        },
        {
          label: "Billing Name",
          field: "billing_name",
          width: 150,
        },
        {
          label: "Total Amount",
          field: "total_amount",
          width: 100,
        },
        {
          label: "Date" + _t,
          field: "date",
          width: 100,
        },
        {
          label: "Action",
          field: "actionextra",
          width: 100,
        },
      ];
    } else {
      columns = [
        {
          label: "#",
          field: "srNo",
          width: 20,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "#",
          },
        },
        {
          label: "Category Name",
          field: "cat_name",
          width: 50,
        },
        {
          label: "Total Amount",
          field: "total_amount",
          width: 100,
        },
        {
          label: "Date" + _t,
          field: "date",
          width: 100,
        },
        {
          label: "Action",
          field: "actionextra",
          width: 100,
        },
      ];
    }

    this.state = {
      buttonTitle: "Add New",
      tit:'Add',
      isLoaded: true,
      modalIsOpenAddNewOICat: false,
      catDateWiseTotal : "",
      company_id: _company_id,
      businessId: _businessId,
      userId: _signingId,
      OICategoryArrayTransaction: [],
      otherIncomeCategoryArrayFilter: [],
      otherIncomeCategoryArray: [],
      catDataLength: 0,
      activeCategory: "",
      activeCategoryBalance: "",
      importExcelFile: "",
      activeEditloanAccountId: 0,
      activeOICatId: 0,
      activeLoanTrasactionId: 0,

      activeMinDate: moment().format("MM/DD/YYYY"),
      activeIsTransaction: false,
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
      dateLabel: "",
      errors: {},
    };
  }

  async componentDidMount() {
    this.getOICategoryListData();
    //this.getBankTrasactionListData();
  }

  getCategoryOITransactionListData(id, cat) {
    let _transactionArray = [];
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
      category_id: id,
      cat: cat,
      isDateFilter: true,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
    });

    PostData(global.otherIncomeTransactionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        
        if (responseJson.success == false) {
          this.setState({OICategoryArrayTransaction: _transactionArray,isLoaded: true, catDateWiseTotal: result.current_total});
        } else {
          if (responseJson.response) {
            _transactionArray = responseJson.response;
          }
            this.setState({OICategoryArrayTransaction: _transactionArray,isLoaded: true, catDateWiseTotal: result.current_total,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getOICategoryListData() {
    let _otherIncomeCategoryArray = [];

    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });

    PostData(global.userOIItemCatList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            otherIncomeCategoryArray: _otherIncomeCategoryArray,
            isLoaded: true,
            catDataLength: 0,
            otherIncomeCategoryArrayFilter: _otherIncomeCategoryArray,
          });
        } else {
          if (responseJson.response) {
            _otherIncomeCategoryArray = responseJson.response;
          }
          this.setState({
            otherIncomeCategoryArray: _otherIncomeCategoryArray,
            isLoaded: true,
            catDataLength: _otherIncomeCategoryArray.length,
            activeCategory: _otherIncomeCategoryArray[0].category,
            activeCategoryBalance: _otherIncomeCategoryArray[0].current_balance,
            otherIncomeCategoryArrayFilter: _otherIncomeCategoryArray,
            activeOICatId: _otherIncomeCategoryArray[0].id,
          });
          this.getCategoryOITransactionListData(
            _otherIncomeCategoryArray[0].id,
            _otherIncomeCategoryArray[0].category
          );
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadCategoryListing() {
    let dataList = [];
    let rows = [];

    if (this.state.isLoaded == true) {
      let _bankDetails = this.state.otherIncomeCategoryArrayFilter;
      if (_bankDetails.length > 0) {
        _bankDetails.map((value, index) => {
          let _activeClass = "simpleRow"; //deactiveRow
          if (this.state.activeOICatId == value.id) {
            _activeClass = "activeRow";
          }
          let _deletebtn = (
            <a
              className="dropdown-item"
              onClick={this.clickToDeleteCategory.bind(this, value.id)}
              style={{ margin: "0px" }}
            >
              Delete
            </a>
          );

          if (value.isTransaction == true) {
            _deletebtn = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.clickToDeleteNotifiyParty.bind(this, value.id)}
                style={{ margin: "0px" }}
              >
                Delete
              </a>
            );
          }
          dataList.push(
            <tr
              key={index}
              className={_activeClass + " tableRow"}
              onClick={this.clickToActiveRow.bind(
                this,
                value.id,
                value.category,
                value.current_balance
              )}
            >
              <td>{value.category}</td>
              <td>{numberFormat(value.current_balance)}</td>
              <th className="text-center" scope="row">
                <div className="btn-group show" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right min-width-200"
                    aria-labelledby="toolbarDrop"
                    style={{ margin: "0px" }}
                  >
                    <a
                      className="dropdown-item"
                      onClick={this.openModalEditAccountHandler.bind(
                        this,
                        "modalAddOICategory",
                        value.id
                      )}
                      style={{ margin: "0px" }}
                    >
                      Edit
                    </a>
                    {_deletebtn}
                  </div>
                </div>
              </th>
            </tr>
          );
        });
      }
    }
    return dataList;
  }

  handleCallBackDate = (start, end) => {
    this.setState({
      startDate: start.format("MMMM D, YYYY"),
      endDate: end.format("MMMM D, YYYY"),
    });
    this.getCategoryOITransactionListData(this.state.activeOICatId);
  };
  clickToActiveRow(id, txt, openingBalance) {
    this.setState({
      activeCategory: txt,
      activeOICatId: id,
      activeCategoryBalance: openingBalance,
    });
    localStorage.setItem("_activeCategoryS", txt);
    if (id > 0) {
      this.getCategoryOITransactionListData(id);
    }
    _activeCategoryS = localStorage.getItem("_activeCategoryS");
  }

  clickToDeleteNotifiyParty() {
    confirmAlert({
      title: "Alert",
      message:
        "This Other Income category can not be deleted as it already has income transactions. Please delete all other income transactions before deleting the income category.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }
  clickToDeleteCategory(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              catId: value,
            });
            PostData(global.userOIItemCategoryDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getOICategoryListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToDeleteTransactionNotify(value) {
    confirmAlert({
      title: "Transaction can't be deleted",
      message: "Cheque against this transaction has been closed, please reopen it to delete this transaction.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }
  clickToEditTransactionNotify(value) {
    confirmAlert({
      title: "Transaction can't be edited",
      message: "Cheque against this transaction has been closed, please reopen it to edit this transaction.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }

  clickToDeleteTransaction(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const requestData = JSON.stringify({
              transaction_id: value,
            });
            PostData(
              global.userOIItemTransactionDelete,
              requestData,
              "POST"
            ).then((result) => {
              let responseJson = result;
              if (responseJson.success == false) {
                sendNotification(
                  "Error Message 😓",
                  responseJson.message,
                  "danger"
                );
                return;
              } else {
                sendNotification(
                  "Success Message 👍",
                  responseJson.message,
                  "success"
                );
                this.getOICategoryListData();
                return;
              }
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  loadTrasactionListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.OICategoryArrayTransaction;
      if (_transDetails.length > 0) {
        let _i = 0;
        _transDetails.map((value, index) => {
          _i++;
          let _delB = "";
          let _editB = "";
          if (value.cheque_status == "1" && value.cheque_status == 1) {
            _delB = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                data-id={value.id}
                onClick={this.clickToDeleteTransactionNotify.bind(
                  this,
                  value.id
                )}
              >
                Delete
              </a>
            );
            _editB = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                data-id={value.id}
                onClick={this.clickToEditTransactionNotify.bind(this, value.id)}
              >
                View/Edit Details
              </a>
            );
          } else {
            _delB = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                data-id={value.id}
                onClick={this.clickToDeleteTransaction.bind(this, value.id)}
              >
                Delete
              </a>
            );
            _editB = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl +
                  "business/create-other-income/?auth=" +
                  value.id
                }
              >
                View/Edit Details
              </a>
            );
          }
          let _actionExtra = (
            <div className="btn-group" role="group">
              <button
                type="button"
                id="btnGroupVerticalDrop3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="btnGroupVerticalDrop1"
              >
                {_editB}
                <a
                  className="dropdown-item"
                  href={
                    global.webUrl +
                    "business/create-other-income-cuplicate/?auth=" +
                    value.id
                  }
                >
                  Duplicate
                </a>
                {_delB}

                <a
                  className="dropdown-item"
                  href={
                    global.webUrl + "business/download-oi-pdf/?auth=" + value.id
                  }
                  target="_blank"
                >
                  Download PDF
                </a>
                <a
                  className="dropdown-item"
                  href={
                    global.webUrl + "business/download-oi-pdf/?auth=" + value.id
                  }
                  target="_blank"
                >
                  Preview
                </a>
                <a
                  className="dropdown-item"
                  href={
                    global.webUrl + "business/download-oi-pdf/?auth=" + value.id
                  }
                  target="_blank"
                >
                  Print
                </a>
              </div>
            </div>
          );
          let showDateTime = value.invoice_date;
          if (_businessSetting.Tran_AddTime == 1) {
            showDateTime = value.invoice_date + " " + value.invoice_time;
          }
          rows.push({
            srNo: _i,
            cat_name: this.state.activeCategory,
            billing_name: value.billing_name,
            date: showDateTime,
            total_amount: numberFormat(value.total_amount),
            actionextra: _actionExtra,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }
 
  /*For Modal open close*/
  openModalEditAccountHandler(popId, catId) {
    if (popId == "modalAddOICategory") {
      this.setState({ modalIsOpenAddNewOICat: true, catId: catId, tit:'Edit' });
    }
  }

  openModalHandler(e) {
    if (e == "modalAddOICategory") {
      this.setState({ modalIsOpenAddNewOICat: true, catId: 0, tit:'Add' });
    }
  }

  closeModalHandler = (e) => {
    if (e == "modalAddOICategory") {
      this.setState({ modalIsOpenAddNewOICat: false });
    }
  };
  /*End Modal open close*/

  /* Loan Filter Code */
  onPartyFilterName = (e) => {
    const data = this.state.otherIncomeCategoryArray;
    let _name = e.currentTarget.value;

    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ otherIncomeCategoryArrayFilter: filtered });

    this.loadCategoryListing();
  };
  /* End Loan Filter Code */

  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.getOICategoryListData();
  };
  /* End Here */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideCss = "hide";
    if (this.state.otherIncomeCategoryArrayFilter.length > 0) {
      _hideCss = "";
    }

    if (this.state.activeCategoryBalance == 0) {
      //_hideCss = 'hide';
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Other Income List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">
              Manage Other Income List
            </span>
          </nav>

          <div className="row">
            <div className="col-md-3">
              <div className="block">
                <div className="block pull-r-l">
                  <div className="block-content">
                    <div className="mb-10">
                      <button
                        type="button"
                        data-toggle="layout"
                        data-action="header_search_on"
                        className="btn btn-sm btn-circle btn-outline-secondary mr-5 mb-5"
                      >
                        <i className="fa fa-search"></i>
                      </button>

                      <div
                        className="btn-group pull-right"
                        role="group"
                        aria-label="Button group with nested dropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.openModalHandler.bind(
                            this,
                            "modalAddOICategory"
                          )}
                        >
                          <i className="fa fa-plus mr-5"></i>{" "}
                          <b>Add Category</b>
                        </button>
                      </div>
                    </div>
                    <div id="page-header-search" className="overlay-header">
                      <div className="content-header content-header-fullrow">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-toggle="layout"
                              data-action="header_search_off"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            onChange={this.onPartyFilterName}
                            className="form-control"
                            placeholder="Search here.."
                            id="page-header-search-input"
                            name="page-header-search-input"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ borderBottom: "1px solid #e4e7ed" }}></div>
                  <div
                    className="block-content"
                    data-toggle="slimscroll"
                    data-height="600px"
                    data-color="#cccccc"
                    data-opacity="1"
                    data-always-visible="true"
                  >
                    <table className="table table-striped table-vcenter">
                      <thead id="sideTable">
                        <tr>
                          <th>Category</th>
                          <th style={{ width: "50px" }}>Amount&nbsp;</th>
                          <th style={{ width: "50px" }}>&nbsp;&nbsp;Action</th>
                        </tr>
                      </thead>
                      <tbody id="sideTable">{this.loadCategoryListing()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="block">
                <div className="block-content">
                  <div className="row items-push">
                    {this.state.catDataLength > 0 && (
                      <div className="col-lg-12">
                        <div>
                          <p className="mb-10" style={{ fontSize: "15px" }}>
                            <span>Name : {this.state.activeCategory}</span>
                          </p>
                        </div>
                        <div>
                          <p className="mb-10" style={{ fontSize: "15px" }}>
                            <span>
                              Amount :{" "}
                              {numberFormat(this.state.catDateWiseTotal)}
                            </span>

                            <div
                              className="btn-group pull-right"
                              role="group"
                              aria-label="Button group with nested dropdown"
                            >
                              <a
                                type="button"
                                className="btn btn-secondary"
                                href={
                                  global.webUrl +
                                  "business/create-other-income/"
                                }
                              >
                                <i className="fa fa-plus mr-5"></i>{" "}
                                <b>Add Other Income</b>
                              </a>
                            </div>
                          </p>
                        </div>
                      </div>
                    )}
                    {this.state.catDataLength == 0 && (
                      <div className="col-lg-12">
                        <div>
                          <p className="mb-10" style={{ fontSize: "15px" }}>
                            <h5>
                              Add any additional income from other sources
                              outside business & know your real profit
                            </h5>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Button group with nested dropdown"
                              style={{ marginLeft: "27%" }}
                            >
                              <a
                                type="button"
                                className="btn btn-secondary"
                                href={
                                  global.webUrl +
                                  "business/create-other-income/"
                                }
                              >
                                <i className="fa fa-plus mr-5"></i>{" "}
                                <b>Add Your First Other Income</b>
                              </a>
                            </div>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="block">
                {this.state.catDataLength > 0 && (
                  <div className="col-sm-12">
                    <h5 style={{ paddingTop: "15px" }}>Transactions : </h5>
                    <span>Filter : </span>
                    <DateRangePicker
                      initialSettings={{
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                        locale: {
                          format: "MMMM D, YYYY",
                        },
                        ranges: {
                          Today: [moment().toDate(), moment().toDate()],
                          Yesterday: [
                            moment().subtract(1, "days").toDate(),
                            moment().subtract(1, "days").toDate(),
                          ],
                          "This Week": [
                            moment().startOf("week").format("MMM DD, YYYY"),
                            moment().endOf("week").format("MMM DD, YYYY"),
                          ],
                          "This Month": [
                            moment().startOf("month").toDate(),
                            moment().endOf("month").toDate(),
                          ],
                          "Last Month": [
                            moment()
                              .subtract(1, "month")
                              .startOf("month")
                              .toDate(),
                            moment()
                              .subtract(1, "month")
                              .endOf("month")
                              .toDate(),
                          ],
                          "This Quarter": [
                            moment().startOf("month").toDate(),
                            moment().add(2, "month").endOf("month").toDate(),
                          ],
                          "This Financial Year": [
                            moment()
                              .month("April")
                              .startOf("month")
                              .format("MMM DD, YYYY"),
                            moment()
                              .add(1, "year")
                              .month("March")
                              .endOf("month")
                              .format("MMM DD, YYYY"),
                          ],
                        },
                      }}
                      onCallback={this.handleCallBackDate}
                    >
                      <input
                        type="text"
                        className="form-control col-4"
                        style={{ display: "inline-block" }}
                      />
                    </DateRangePicker>
                  </div>
                )}
                {this.state.catDataLength > 0 && (
                  <div className="block-content">
                    <MDBDataTableV5
                      hover
                      entriesOptions={[25, 50, 100]}
                      entries={25}
                      pagesAmount={4}
                      span
                      pagingTop
                      searchTop
                      searchBottom={false}
                      barReverse
                      data={this.loadTrasactionListing()}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Edit Other Income Category Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpenAddNewOICat}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddOICategory"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">{this.state.tit} Income Category</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddOICategory"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewOICategory
                    callBackCategory={(category_name, category_id) => {
                      this.setState({
                        category_name: category_name,
                        category_name: category_name,
                        category_id: category_id,
                        modalIsOpenAddNewOICat: false,
                        handleItemSalePurchasePrice: "",
                        handleAddDisable: "Disabled",
                      });
                    }}
                    category_name={this.state.category_name}
                    catId={this.state.catId}
                    isRedirectTo={"business/other-income/"}
                    callBack={this.getUpdateResponse}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAddOICategory"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Other Income Category Details*/}
      </React.Fragment>
    );
  }
}
