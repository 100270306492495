import React, { useEffect, useState, useContext } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { Link } from "react-router-dom";
import "../../../index.css";
import { numberFormat } from "../../../config/numberFormat";
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import { PostData } from "../../../service/postData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { el } from "date-fns/locale";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import QuickAdjustAddNewTerms from "../userExpense/quickAddTerms";
import QuickAddNewPrefix from "../userSale/quickNewPrefixAdd";
import SaleAddItemTable from "../userSale/SaleAddItemTable";
import { Loader } from "@progress/kendo-react-indicators";
import { set, sub } from "date-fns";
import SuccessDialog from "../SuccessDialog";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { useLocation, useHistory } from 'react-router-dom';


const ManageSaleCreate = (props) => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const auth = queryParams.get('auth');
  const transactionType = queryParams.get('tType') ? queryParams.get('tType') : '';
  const isConvert = transactionType == 'ORDER' || transactionType == 'DUPLICATE' ||
    transactionType == 'CHALLAN' || transactionType == 'ESTIMATE';
  const [businessSetting, setBusinessSetting] = useState({});
  const [partyId, setPartyId] = useState(0);
  const [partyName, setPartyName] = useState('');
  const [partyContactNo, setPartyContactNo] = useState('');
  const [partyRemainBalance, setPartyRemainBalance] = useState(0);
  const [billingName, setBillingName] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [poDate, setPoDate] = useState(moment().format("MM/DD/YYYY"));
  const [EWayBillNo, setEWayBillNo] = useState('');
  const [paymentTermsData, setPaymentTermsData] = useState([]);
  const [dueOnDate, setDueOnDate] = useState(moment().format("MM/DD/YYYY"));
  const [termsId, setTermsId] = useState(0);
  const [prefixId, setPrefixId] = useState(1);
  const [prefixName, setPrefixName] = useState('');
  const [prefixArray, setPrefixArray] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState(Math.floor(Math.pow(10, 5) + Math.random() * (Math.pow(10, 6) - Math.pow(10, 5) - 1)));
  const [invoiceDate, setInvoiceDate] = useState(moment().format("MM/DD/YYYY"));
  const [invoiceTime, setInvoiceTime] = useState(moment().format("hh:mm A"));
  const [stateArray, setStateArray] = useState([]);
  const [stateName, setStateName] = useState('');
  const [bankId, setBankId] = useState(-2);
  const [bankName, setBankName] = useState('');
  const [paymentType, setPaymentType] = useState('Cash');
  const [paymentRefNo, setPaymentRefNo] = useState('');
  const [description, setDescription] = useState('');
  const [descImage, setDescImage] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [transactionId, setTransactionId] = useState(0);
  const [toggleState, setToggleState] = useState(true);
  const [printType, setPrintType] = useState('showall');
  const [totalTransDiscountPer, setTotalTransDiscountPer] = useState(0);
  const [totalTransDiscountAmount, setTotalTransDiscountAmount] = useState(0);
  const [taxRateArray, setTaxRateArray] = useState([]);
  const [transactionWiseTaxId, setTransactionWiseTaxId] = useState(0);
  const [totalTransTaxAmount, setTotalTransTaxAmount] = useState(0);
  const [totalTransTaxRatio, setTotalTransTaxRatio] = useState(0);
  const [transAdditionalCharge1, setTransAdditionalCharge1] = useState(0);
  const [transAdditionalCharge2, setTransAdditionalCharge2] = useState(0);
  const [transAdditionalCharge3, setTransAdditionalCharge3] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [itemSubTotalAmount, setItemSubTotalAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [additionalValue1, setAdditionalValue1] = useState('');
  const [additionalValue2, setAdditionalValue2] = useState('');
  const [additionalValue3, setAdditionalValue3] = useState('');
  const [additionalValue4, setAdditionalValue4] = useState(moment().format("MM/DD/YYYY"));
  const [transportDetailField1, setTransportDetailField1] = useState('');
  const [transportDetailField2, setTransportDetailField2] = useState('');
  const [transportDetailField3, setTransportDetailField3] = useState('');
  const [transportDetailField4, setTransportDetailField4] = useState('');
  const [transportDetailField5, setTransportDetailField5] = useState('');
  const [transportDetailField6, setTransportDetailField6] = useState('');
  const [partyItemArray, setPartyItemArray] = useState([]);
  const [partyArray, setPartyArray] = useState([]);
  const [bankArray, setBankArray] = useState([]);
  const [companyId, setCompanyId] = useState(0);
  const [businessId, setBusinessId] = useState(0);
  const [signingId, setSigningId] = useState(0);
  const [countryName, setCountryName] = useState('');
  const [showPartyViewList, setShowPartyViewList] = useState(false);
  const [isDialogOpen_Time, setIsDialogOpen_Time] = useState(false);
  const [isDialogOpen_AddTerms, setIsDialogOpen_AddTerms] = useState(false);
  const [isDialogOpen_AddPrefix, setIsDialogOpen_AddPrefix] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [printTypeList, setprintTypeList] = useState([]);
  const [selectedItemArray, setSelectedItemArray] = useState([]);
  const [isReturn, setIsReturn] = useState(false);
  const [isMakePayment, setIsMakePayment] = useState(false);
  const [totalLinkHistoryAmt, setTotalLinkHistoryAmt] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    let dummyData = [
      { value: "showall", label: "Show All" },
      { value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Triplicate", label: "Triplicate" },
    ]
    setprintTypeList(dummyData);
  }, []);

  useEffect(() => {
    let _UserSession = localStorage.getItem("vs_UserSession");

    if (_UserSession != undefined || _UserSession != null) {
      if (_UserSession.loginType != "Admin") {
        _UserSession = JSON.parse(_UserSession);
        let _signingId = _UserSession.loginId;
        let _businessId = _UserSession.tokenKey;
        let _company_id = _UserSession.company_id;
        let _businessSetting = _UserSession.businessSetting;

        setSigningId(_signingId);
        setCompanyId(_company_id);
        setBusinessId(_businessId);
        setBusinessSetting(_businessSetting);

        if (_businessSetting != undefined || _businessSetting != null) {
          if (_businessSetting.PrintOriginalDuplicate == 0) {
            setPrintType('');
          }
        }
      }
    }
    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession != undefined || _companySession != null) {
      _companySession = JSON.parse(_companySession);
      let _businessId = _companySession.business_id;
      let _company_id = _companySession.company_id;
      let _countryCode = _companySession.country_code;
      let _countryName = _companySession.country_name;
      let _taxRateArray = _companySession.taxDetails;
      let _company_Logo = _companySession.company_logo;


      setCompanyId(_company_id);
      setBusinessId(_businessId);
      setCountryName(_countryName);
      _taxRateArray.push({ id: 0, name: 'None', ratio: 0 });
      setTaxRateArray(_taxRateArray);

      getPartyListData(_company_id);
      getPaymentTerms(_company_id, _businessId);
      getPrefixList(_company_id, _businessId);
      getStateListData(_countryCode);
      getBankListData(_company_id);
      getTransactionDetails(_company_id);
    }


  }, []);

  const getTransactionDetails = (company_id) => {
    if (auth) {
      setTransactionId(auth);
      setIsLoading(true);
      setIsEditMode(true);
      const requestData = JSON.stringify({
        transaction_id: auth,
      });
      PostData(global.userSaleTransactionDetails, requestData, "POST")
        .then((result) => {
          setIsLoading(false);
          let responseJson = result;
          if (responseJson.success) {
            let response = responseJson.response[0];
            console.log('response', JSON.stringify(response));
            let totalSummary = response.totalSummary;
            let prefix = response.prefix;
            let items = response.items;
            let customer = response.customer;

            setPartyId(customer.party_id);
            setPartyName(customer.party_name);
            setPartyContactNo(customer.party_contact_no);
            setBillingName(customer.billing_name);
            setBillingAddress(customer.party_billing_address);
            setPartyRemainBalance(customer.partyBalance);
            setToggleState(response.isToggle);
            setPoNumber(response.po_number);
            setPoDate(response.po_date ? response.po_date : moment().format("MM/DD/YYYY"));
            setDueOnDate(response.dueOnDate ? response.dueOnDate : moment().format("MM/DD/YYYY"));
            setTermsId(response.dueTerms);
            setEWayBillNo(response.E_Way_Bill_No);
            setPrefixId(prefix.id);
            setPrefixName(prefix.name);
            !isConvert && setInvoiceNo(response.invoiceNo);
            setInvoiceDate(response.invoiceDate);
            setInvoiceTime(response.invoice_time);
            setStateName(response.countryState);
            setTotalTransDiscountPer(response.totalTransDiscountPer);
            setTotalTransDiscountAmount(response.totalTransDiscountAmount);
            setTransactionWiseTaxId(response.transactionWiseTaxId);
            setTransAdditionalCharge1(response.TransAdditionalCharge1);
            setTransAdditionalCharge2(response.TransAdditionalCharge2);
            setTransAdditionalCharge3(response.TransAdditionalCharge3);
            setReceivedAmount(parseFloat(totalSummary.paidAmount) + totalSummary.receivedAmount);
            setDueAmount(totalSummary.dueAmount);
            setAdditionalValue1(response.additional_value_1);
            setAdditionalValue2(response.additional_value_2);
            setAdditionalValue3(response.additional_value_3);
            setAdditionalValue4(response.additional_value_date);
            setTransportDetailField1(response.TransportDetailField1);
            setTransportDetailField2(response.TransportDetailField2);
            setTransportDetailField3(response.TransportDetailField3);
            setTransportDetailField4(response.TransportDetailField4);
            setTransportDetailField5(response.TransportDetailField5);
            setTransportDetailField6(response.TransportDetailField6);
            setBankId(response.bankId);
            setBankName(response.bankName);
            setPaymentType(response.paymentType);
            setPaymentRefNo(response.paymentRefNo);
            setDescription(response.description);
            setImagePreviewUrl(response.descImage ? (response.urlImg + response.descImage) : '');
            setPrintType(response.settingType);
            setIsReturn(response.isReturn);
            setIsMakePayment(response.isMakePayment);
            setTotalLinkHistoryAmt(response.totalLinkHistoryAmt);
            setSelectedItemArray(items);

            getPartyWiseItem(customer.party_id, company_id);
            getBankListData(company_id);
            if (response.isReturn) {
              alert('You cannot update this transaction because it is already a partial or full return, if you want to edit it, delete the return of that transaction.');
            } else if (response.isMakePayment) {
              alert('The payment link for this sale has already been processed,  i.e the payment has been received. Therefore, we cannot make any changes to the sale transaction. If you need to make any changes, please ensure that you delete the entry of the linked transaction first.');
            }

          } else {
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href = global.webUrl + "business/sale-invoice/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
            //this.setState({unitArray:_unitArray,isLoaded:true});
          }
        })
        .catch((error) => setIsLoading(false));

    }
  }

  const getPartyListData = (_company_id) => {

    let _url_GetData = global.userPartyList + "?company_id=" + _company_id;
    setIsLoading(true);
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {

        } else {
          let _partyBal = 0;
          if (responseJson.response) {
            let _partyArray = responseJson.response;
            setPartyArray(_partyArray);
            _partyArray.map((party, index) => {
              if (party.party_id == partyId) {
                _partyBal = party.totalOutCome;
              }
            });
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  const getPartyWiseItem = (partyId, companyId) => {
    setIsLoading(true);
    const requestData = JSON.stringify({
      company_id: companyId,
      partyId: partyId,
    });
    PostData(global.userItemSalePurPartyList, requestData, "POST")
      .then((result) => {
        let responseJson = result;

        if (responseJson.success == false) {
          setPartyItemArray([]);
        } else {
          if (responseJson.response) {
            let _itemsArray = responseJson.response;
            _itemsArray.map((field, index) => {
              _itemsArray[index].isFilter = false;
            });
            _itemsArray = _itemsArray.map((item) => {
              return ({
                ...item,
                rate: item.sale_price,
                quantity: 0,
                free_quantity: 0,
                trans_count: 0,
                discount_in_per: 0,
                discount_in_amount: 0,
                total_amount: 0,
                Additional_CESS: parseFloat(item.Additional_CESS),
                selectedData: [],
                itemBatchNo: item.itemBatchNo.filter((data) => data.opening_stock > 0)
              })
            });
            setPartyItemArray(_itemsArray);

          }
        }
        setIsLoading(false);
      })
      .catch((error) => { setIsLoading(false); });
  }

  const getPaymentTerms = (companyId, businessId) => {
    let dummyData = [{ "id": -1, "terms_name": "Add Terms", "terms_days": 0, "terms_default_status": 0 },
    { "id": 0, "terms_name": "Custom", "terms_days": 0, "terms_default_status": 0 }];
    const requestDueDateData = JSON.stringify({
      businessId: businessId,
      company_id: companyId,
    });

    PostData(global.businessDueDatePaymentTermList, requestDueDateData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          setPaymentTermsData([...dummyData]);
        } else {
          let _mainResponse = responseJson.response;
          setPaymentTermsData([...dummyData, ..._mainResponse]);
          _mainResponse.map((item, index) => {
            if (item.terms_default_status == 1) {
              let _terms_days = item.terms_days;

              let dueDateOnload = moment().add(_terms_days, "days").format("MM/DD/YYYY");
              setDueOnDate(dueDateOnload);
              setTermsId(item.id);

            }
          });
        }
      })
      .catch((error) => {

      });
  }
  const getPrefixList = (_company_id, _businessId) => {
    let dummyData = [{ "isSelected": false, "prefix_id": -1, "prefix": "Add Prefix", "prefix_type": "SALE" },
    { "isSelected": false, "prefix_id": 0, "prefix": "None", "prefix_type": "SALE" }];
    const requestData = JSON.stringify({
      company_id: _company_id,
      businessId: _businessId,
      type: "SALE",
    });
    PostData(global.businessWebPrefixList, requestData, "POST")
      .then(async (result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          setPrefixArray([...dummyData]);
        } else {
          if (responseJson.response) {
            let _prefixArray = responseJson.response;
            _prefixArray = _prefixArray.filter(
              (itemData) => itemData.prefix != null
            );
            let _prefixSelData = _prefixArray.filter((item) => item.isSelected == true)
            setPrefixArray([...dummyData, ..._prefixArray])
            setPrefixId(_prefixSelData[0].prefix_id);
            setPrefixName(_prefixSelData[0].prefix);

          }
        }
      })
      .catch((error) => { });
  }
  const getStateListData = (countryCode) => {
    let _url_GetData =
      global.adminStateList + "?countryCode=" + countryCode;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
        } else {
          if (responseJson.response) {
            let _stateArray = responseJson.response;
            setStateArray(_stateArray);
          }
        }
      })
      .catch((error) => { });
  }
  const getBankListData = (company_id) => {
    let dummyData = [{ id: "-1", bank_name: "Add Bank", paymentType: "" },
    { id: "-2", bank_name: "Cash", paymentType: "Cash" },
    { id: "-3", bank_name: "Cheque", paymentType: "Cheque" }]
    let _url_GetData = global.userBankDetailsList + "?company_id=" + company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          setBankArray([...dummyData]);
        } else {
          if (responseJson.response) {
            let _bankDetailArray = responseJson.response;
            setBankArray([...dummyData, ..._bankDetailArray]);
          }
        }
      })
      .catch((error) => { });
  }

  const onToggleChange = () => {
    let isToggleState = !toggleState;
    let _totalAmount = totalAmount;
    if (isToggleState) {
      setReceivedAmount(_totalAmount);
      setDueAmount(0);
    } else {
      setReceivedAmount(0);
      setDueAmount(_totalAmount);
    }
    setToggleState(isToggleState);
  }
  const getColorBal = () => {
    let _colorBal = "text-normal";
    if (partyRemainBalance < 0) {
      _colorBal = "text-danger";
    } else if (partyRemainBalance > 0) {
      _colorBal = "text-success";
    } else {
      _colorBal = "text-normal";
    }
    return _colorBal;
  }
  const onChangePartyName = (value) => {
    setShowPartyViewList(true);
  }
  const manageShowPartyFilter = () => {
    setShowPartyViewList(true);
  }


  const viewPartyList = () => {

    let data = [];
    let _colorVal = '';
    data = partyArray.map((party, i) => {
      let _balance = party.totalOutCome;
      if (_balance < 0) {
        _colorVal = "red";
      } else if (_balance == 0 || _balance == "0") {
        _colorVal = "black";
      } else {
        _colorVal = "green";
      }
      return (
        <li
          key={i}
          className="iti__party iti__standard"
          id="iti-item-in"
          onClick={() => selectPartiess(party)}
        >
          <span className="iti__party-name">{party.party_name}</span>
          <span className="iti__party-balance" style={{ color: _colorVal }}>
            {numberFormat(Math.abs(_balance))}
          </span>
        </li>
      );
    });
    return data;
  }
  const selectPartiess = (party) => {
    setPartyId(party.party_id);
    setPartyName(party.party_name);
    setPartyRemainBalance(party.totalOutCome);
    setPartyContactNo(party.party_contact_no);
    setBillingName(party.billing_name);
    setBillingAddress(party.party_billing_address);
    setShowPartyViewList(false);
    getPartyWiseItem(party.party_id, companyId);
  }
  const handleCallBackPoDate = (date) => {
    setPoDate(moment(date).format("MM/DD/YYYY"));
  };
  const viewPoDate = () => {
    const slectedDate = moment(poDate).toDate();
    return (
      <DatePicker
        selected={slectedDate}
        disabled={isConvert}
        className="form-control search-box"
        onChange={date => handleCallBackPoDate(date)}
      />
    );
  }
  const selectPaymentTerms = (e) => {
    let value = e.target.value;
    if (value == -1) {
      setIsDialogOpen_AddTerms(true);
    } else {
      let patmentData = paymentTermsData.filter((data) => data.id == value)[0];
      let _invDate = moment(invoiceDate).format("MM/DD/YYYY");
      let newDueDate = moment(_invDate).add(patmentData.terms_days, "days").format("MM/DD/YYYY");
      setDueOnDate(newDueDate);
      setTermsId(value);
    }
  }

  const selectPrefix = (e) => {
    let value = e.target.value;
    if (value == -1) {
      setIsDialogOpen_AddPrefix(true);
    } else {
      let prefixData = prefixArray.filter((data) => data.prefix_id == value)[0];
      setPrefixId(prefixData.prefix_id);
      setPrefixName(prefixData.prefix);
    }
  }
  const handleCallBackDueTermsDate = (date) => {
    setDueOnDate(moment(date).format("MM/DD/YYYY"));
    setTermsId(0);
  };
  const viewDueOnDate = () => {
    const slectedDate = moment(dueOnDate).toDate();
    return (
      <DatePicker
        selected={slectedDate}
        disabled={isConvert}
        className="form-control search-box"
        onChange={date => handleCallBackDueTermsDate(date)}
      />
    );
  }
  const handleCallBackInvoiceDate = (date) => {
    date = moment(date).format("MM/DD/YYYY")
    setInvoiceDate(date);
    if (termsId > 0) {
      let patmentData = paymentTermsData.filter((data) => data.id == termsId)[0];
      let newDueDate = moment(date).add(patmentData.terms_days, "days").format("MM/DD/YYYY");
      setDueOnDate(newDueDate);
    }
  };
  const viewInvoiceDate = () => {
    const slectedDate = moment(invoiceDate).toDate();
    return (
      <DatePicker
        selected={slectedDate}
        disabled={isConvert}
        className="form-control search-box"
        onChange={date => handleCallBackInvoiceDate(date)}
      />
    );
  }
  const handleCallBackAdditionalValue4Date = (date) => {
    setAdditionalValue4(moment(date).format("MM/DD/YYYY"));
  };
  const viewAdditionalDate = () => {
    const slectedDate = moment(additionalValue4).toDate();
    return (
      <DatePicker
        selected={slectedDate}
        disabled={isConvert}
        className="form-control search-box"
        onChange={date => handleCallBackAdditionalValue4Date(date)}
      />
    );
  }
  const onMouseLeaveHandlerTime = () => {

  }
  const onMouseLeaveHandler = () => {

  }
  const manageShowTimeFilter = () => {

  }
  const generateTimeSlots = (interval = 1) => {
    const slots = [];
    const start = new Date(); // Start from current time
    start.setSeconds(0, 0); // Round down to the nearest hour

    for (let i = 0; i < 24 * 60 / interval; i++) {
      start.setMinutes(start.getMinutes() + interval);
      //slots.push(formatTime(new Date(start)));
      slots.push(moment(start).format("hh:mm A"));
    }

    return slots;
  };
  const availableTimeSlots = generateTimeSlots();
  const getTimeSlotList = () => {
    return availableTimeSlots.map((time, index) => (
      <div
        key={index}
        className="dropdown-item"
        onClick={() => {
          setIsDialogOpen_Time(false);
          setInvoiceTime(time);
        }}
      >
        {time}
      </div>
    ))
  }

  const selectBank = (e) => {
    let value = e.target.value;
    if (value == -1) {
      //setIsDialogOpen_AddPrefix(true);
    } else {
      let bankData = bankArray.filter((data) => data.id == value)[0];
      setBankId(value);
      setBankName(bankData.bank_name ? bankData.bank_name : bankData.display_name);
      setPaymentType(bankData.paymentType);
    }
  }
  const onChangeFileUploadHandler = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setDescImage(file);
      setImagePreviewUrl(reader.result);

    };
    reader.readAsDataURL(file);
  }
  const previewImagesListing = () => {
    let dataList = [];
    let _imageDetails = imagePreviewUrl;
    if (_imageDetails != "") {
      dataList.push(
        <img
          style={{ width: "35px" }}
          className="preview"
          src={_imageDetails}
          alt={"image-"}
          key="13213"
        />
      );
    }
    return dataList;
  }
  const roundToDecimalPlaces = (value) => {
    value = parseFloat(value);
    value = isNaN(value) ? 0 : parseFloat(value.toFixed(2));
    return value;
  };

  const onCallbackAddTerms = (data) => {
    setIsDialogOpen_AddTerms(false);
    getPaymentTerms(companyId, businessId);
  }
  const onCallbackAddPrefix = (id, name) => {
    setIsDialogOpen_AddPrefix(false);
    setPrefixId(id);
    setPrefixName(name);
    getPrefixList(companyId, businessId);
  }

  const onChangeDiscountPercentage = (value) => {
    value = value * 1;
    setTotalTransDiscountPer(value);
    let _subTotalAmount = subTotalAmount;
    let _discountAmount = (value / 100) * _subTotalAmount;
    setTotalTransDiscountAmount(_discountAmount);
    _subTotalAmount = _subTotalAmount - _discountAmount;
    let _taxRate = taxRateArray.filter((data) => data.id == transactionWiseTaxId)[0];
    let _taxAmount = (_taxRate.ratio / 100) * _subTotalAmount;
    _taxAmount = roundToDecimalPlaces(_taxAmount);
    setTotalTransTaxAmount(_taxAmount);
    let _totalAmount = _subTotalAmount;
    _totalAmount = _totalAmount + _taxAmount + transAdditionalCharge1 + transAdditionalCharge2 + transAdditionalCharge3;
    setTotalAmount(roundToDecimalPlaces(_totalAmount));
    _totalAmount = _totalAmount - receivedAmount;
    setDueAmount(roundToDecimalPlaces(_totalAmount));
  }
  const onChangeDiscountAmount = (value) => {
    value = value * 1;
    setTotalTransDiscountAmount(value);
    let _subTotalAmount = subTotalAmount - value;
    let _discountPer = (value / _subTotalAmount) * 100;
    _discountPer = roundToDecimalPlaces(_discountPer);
    setTotalTransDiscountPer(_discountPer);

    let _taxRate = taxRateArray.filter((data) => data.id == transactionWiseTaxId)[0];
    let _taxAmount = (_taxRate.ratio / 100) * _subTotalAmount;
    _taxAmount = roundToDecimalPlaces(_taxAmount);
    setTotalTransTaxAmount(_taxAmount);
    let _totalAmount = _subTotalAmount;
    _totalAmount = _totalAmount + _taxAmount + transAdditionalCharge1 + transAdditionalCharge2 + transAdditionalCharge3;
    setTotalAmount(_totalAmount);
    _totalAmount = _totalAmount - receivedAmount;
    setDueAmount(_totalAmount);
  }
  const onChangeTaxRate = (value) => {
    value = value * 1;
    setTransactionWiseTaxId(value);
    let _subTotalAmount = subTotalAmount;
    let _taxRate = taxRateArray.filter((data) => data.id == value)[0];
    let _taxAmount = (_taxRate.ratio / 100) * _subTotalAmount;
    _taxAmount = roundToDecimalPlaces(_taxAmount);
    setTotalTransTaxRatio(_taxRate.ratio);
    setTotalTransTaxAmount(_taxAmount);
    let _totalAmount = _subTotalAmount;
    _totalAmount = (_totalAmount - totalTransDiscountAmount) + (_taxAmount + transAdditionalCharge1 + transAdditionalCharge2 + transAdditionalCharge3);
    setTotalAmount(roundToDecimalPlaces(_totalAmount));
    _totalAmount = _totalAmount - receivedAmount;
    setDueAmount(roundToDecimalPlaces(_totalAmount));
  }
  const onChangeAdditionalCharge1 = (value) => {
    value = value * 1;
    setTransAdditionalCharge1(value);
    let _totalAmount = subTotalAmount;
    _totalAmount = _totalAmount - totalTransDiscountAmount + totalTransTaxAmount + value + transAdditionalCharge2 + transAdditionalCharge3;
    setTotalAmount(_totalAmount);
    _totalAmount = _totalAmount - receivedAmount;
    setDueAmount(_totalAmount);
  }
  const onChangeAdditionalCharge2 = (value) => {
    value = value * 1;
    setTransAdditionalCharge2(value);
    let _totalAmount = subTotalAmount;
    _totalAmount = _totalAmount - totalTransDiscountAmount + totalTransTaxAmount + transAdditionalCharge1 + value + transAdditionalCharge3;
    setTotalAmount(_totalAmount);
    _totalAmount = _totalAmount - receivedAmount;
    setDueAmount(_totalAmount);
  }
  const onChangeAdditionalCharge3 = (value) => {
    value = value * 1;
    setTransAdditionalCharge3(value);
    let _totalAmount = subTotalAmount;
    _totalAmount = _totalAmount - totalTransDiscountAmount + totalTransTaxAmount + transAdditionalCharge1 + transAdditionalCharge2 + value;
    setTotalAmount(_totalAmount);
    _totalAmount = _totalAmount - receivedAmount;
    setDueAmount(_totalAmount);
  }
  const onChangeReceivedAmount = (value) => {
    value = value * 1;
    setReceivedAmount(value);
    let _totalAmount = totalAmount - value;
    setDueAmount(_totalAmount);
  }
  const callBack = async (data, _totalAmount, totalQuantity, itemSubTotalAmount) => {
    setSubTotalAmount(_totalAmount);
    setItemSubTotalAmount(itemSubTotalAmount);
    setSelectedItemArray(data);
    let _discountAmount = (totalTransDiscountPer / 100) * _totalAmount;
    _discountAmount = roundToDecimalPlaces(_discountAmount);
    setTotalTransDiscountAmount(_discountAmount);
    let _taxRate = taxRateArray.filter((data) => data.id == transactionWiseTaxId)[0];
    let _taxAmount = (_taxRate.ratio / 100) * _totalAmount;
    _taxAmount = roundToDecimalPlaces(_taxAmount);
    setTotalTransTaxRatio(_taxRate.ratio);
    setTotalTransTaxAmount(_taxAmount);
    let totalAmount = (_totalAmount - _discountAmount) + (_taxAmount + transAdditionalCharge1 + transAdditionalCharge2 + transAdditionalCharge3);
    setTotalAmount(roundToDecimalPlaces(totalAmount));
    let _receivedAmount = toggleState ? (isEditMode ? roundToDecimalPlaces(totalAmount) : receivedAmount) : receivedAmount;
    setReceivedAmount(_receivedAmount);
    totalAmount = totalAmount - _receivedAmount;
    setDueAmount(isMakePayment ? dueAmount : roundToDecimalPlaces(totalAmount));
    setTotalQuantity(totalQuantity);
  }


  const clickToAddSale = () => {
    if (isReturn) {
      alert('You cannot update this transaction because it is already a partial or full return, if you want to edit it, delete the return of that transaction.');
    } else if (isMakePayment) {
      alert('The payment link for this sale has already been processed,  i.e the payment has been received. Therefore, we cannot make any changes to the sale transaction. If you need to make any changes, please ensure that you delete the entry of the linked transaction first.');
    } else if (selectedItemArray.length <= 0) {
      alert('Please select at least one item.');
    } else if (selectedItemArray.filter((data) => parseInt(data.rate) == 0).length > 0) {
      alert('Price/Unit must be greater than 0.');
    } else if (dueAmount < 0) {
      alert('Due amount cannot be less than 0.');
    } else if (bankId == 0) {
      alert('Please select payment type like (Cash, Cheque or any bank.)');
    } else {
      setIsLoading(true);
      let customer = {
        party_id: partyId,
        party_name: partyName,
        party_contact_no: partyContactNo,
        party_opening_balance: partyRemainBalance,
        billing_name: billingName,
      };
      let prefix = {
        name: prefixName,
        id: prefixId,
        isActive: "1",
      };
      let totalSummary = {
        subtotal: subTotalAmount,
        gstRatio: totalTransTaxRatio,
        gstAmount: totalTransTaxAmount,
        totalQty: totalQuantity,
        totalDiscount: totalTransDiscountAmount,
        totalAmount: totalAmount,
        receivedAmount: toggleState ? totalAmount : receivedAmount,
        dueAmount: toggleState ? 0 : dueAmount,
      };
      let transactionWiseAmount = {
        transactionWiseTaxId: transactionWiseTaxId,
        totalTransTaxAmount: totalTransTaxAmount,
        totalTransDiscountPer: totalTransDiscountPer,
        totalTransDiscountAmount: totalTransDiscountAmount,
      };
      let transactionAdditionalCharge = {
        TransAdditionalCharge1: transAdditionalCharge1,
        TransAdditionalCharge2: transAdditionalCharge2,
        TransAdditionalCharge3: transAdditionalCharge3,
      };
      let transacTransportationDetails = {
        TransportDetailField1: transportDetailField1,
        TransportDetailField2: transportDetailField2,
        TransportDetailField3: transportDetailField3,
        TransportDetailField4: transportDetailField4,
        TransportDetailField5: transportDetailField5,
        TransportDetailField6: transportDetailField6,
      };
      let saleData = new FormData();
      saleData.append("transaction_id", transactionId);
      saleData.append("business_id", businessId);
      saleData.append("user_id", signingId);
      saleData.append("company_id", companyId);
      saleData.append("isSaleCash", toggleState);
      saleData.append("customer", JSON.stringify(customer));
      saleData.append("billing_address", billingAddress);
      saleData.append("poNumber", poNumber);
      saleData.append("poDate", poDate);
      saleData.append("dueOnDate", dueOnDate);
      saleData.append("dueTerms", termsId);
      saleData.append("E_Way_Bill_No", EWayBillNo);
      saleData.append("prefix", JSON.stringify(prefix));
      saleData.append("invoiceNo", invoiceNo);
      saleData.append("invoiceDate", invoiceDate);
      saleData.append("invoiceTime", invoiceTime);
      saleData.append("selectedCountry", countryName);
      saleData.append("countryState", stateName);
      saleData.append("items", JSON.stringify(selectedItemArray));
      saleData.append("totalSummary", JSON.stringify(totalSummary));
      saleData.append("transactionWiseAmount", JSON.stringify(transactionWiseAmount));
      saleData.append("transactionAdditionalCharge", JSON.stringify(transactionAdditionalCharge));
      saleData.append("additional_value_1", additionalValue1);
      saleData.append("additional_value_2", additionalValue2);
      saleData.append("additional_value_3", additionalValue3);
      saleData.append("additional_value_date", additionalValue4);
      saleData.append("bankId", bankId);
      saleData.append("bankName", bankName);
      saleData.append("paymentType", paymentType);
      saleData.append("paymentRefNo", paymentRefNo);
      saleData.append("description", description);
      saleData.append("descImage", descImage);
      saleData.append("settingType", printType);
      saleData.append("transactionTransportationDetails", JSON.stringify(transacTransportationDetails));

      if (isEditMode) {
        saleData.append("isMakePayment", isMakePayment);
        saleData.append("totalLinkHistoryAmt", totalLinkHistoryAmt);
      }
      if (isConvert) {
        saleData.append("sendUrlType", transactionType);
      }

      const formObject = {};
      saleData.forEach((value, key) => {
        if (key == 'customer' || key == 'prefix' || key == 'items' || key == 'totalSummary' || key == 'transactionWiseAmount' || key == 'transactionAdditionalCharge' | key == 'transactionTransportationDetails') {
          value = JSON.parse(value);
        }
        formObject[key] = value;
      });
      console.log('formObject', JSON.stringify(formObject));
      //setIsLoading(false); return;
      let url = isConvert ? global.createSaleDuplicate : (isEditMode ? global.updateSaleTransaction : global.createSaleTransaction);
      console.log('url', url);
      fetch(url, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          setIsLoading(false);
          if (res.success == true) {
            setTransactionId(res.transaction_Id);
            setShowSuccessDialog(true);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
          }
          setMessage(res.message);
        })
        .catch((error) => {
          console.log('error', error);
          setIsLoading(false);
        });

    }
  }

  return (
    <div className="">
      <div className="content">
        <div className="content-header">
          <div className="header-section">
            {/* <h2 className="content-heading">Manage Business</h2> */}
            <h1 className="content-heading">
              <i className="gi gi-direction"></i>Add / Edit Sale Invoice
              <br />
            </h1>
          </div>
        </div>
        <nav className="breadcrumb push">
          <Link className="breadcrumb-item" to={"/business/dashboard/"}>
            Dashboard
          </Link>
          <Link className="breadcrumb-item" to={"/business/sale-invoice/"}>
            Manage Sale Invoice List
          </Link>
          <span className="breadcrumb-item active">
            Add / Edit Sale Invoice
          </span>
          <Link
            className="breadcrumb-item"
            to={"/business/add-sale/"}
            target="_blank"
          >
            <i className="fa fa-plus"> (Add More)</i>
          </Link>
        </nav>
        <div className="block">
          <div className="row">
            <div className="col-md-6">
              <div className="block block-transparent">
                <div className="block-content" id="formSideLeft">
                  <div className="form-group row">
                    <div className="col-6">
                      <div
                        className="form-material open"
                        onMouseLeave={() => onMouseLeaveHandler()}
                      >
                        <input
                          disabled={isEditMode}
                          type="text"
                          className="form-control search-box"
                          onChange={(e) => onChangePartyName(e)}
                          onClick={() => manageShowPartyFilter()}
                          placeholder="Search Party Name Here..."
                          autoComplete="off"
                          name="party_name"
                          id="party_name"
                          value={partyName}
                        />
                        <label htmlFor="login-username">
                          Party Name <span className="text-danger">*</span>
                        </label>
                        {
                          showPartyViewList &&
                          <ul
                            className={" iti__party-list iti__party-list--dropup"
                            }
                            id="country-listbox"
                            aria-expanded="false"
                            role="listbox"
                            aria-activedescendant="iti-item-ad"
                          >
                            {viewPartyList()}
                          </ul>
                        }
                        <div className={getColorBal() + ' '}>
                          {partyName != ""
                            ? "Bal : " +
                            numberFormat(
                              Math.abs(partyRemainBalance)
                            )
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        transactionId > 0 ? "hide" : "col-6"
                      }
                    >
                      <div>
                        <ToggleSwitch
                          txtLeft="Credit"
                          txtRight="Cash"
                          small
                          id="newsletter"
                          checked={toggleState}
                          disabled={isConvert}
                          onChange={() => onToggleChange()}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control search-box"
                          placeholder="Phone Number"
                          autoComplete="off"
                          value={partyContactNo}
                          disabled={isConvert}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[0-9]*$/.test(value)) {
                              setPartyContactNo(value);
                            }
                          }}
                          maxLength="13"
                        />
                        <label htmlFor="login-username">Phone Number</label>

                      </div>
                    </div>
                    {businessSetting.Tran_BillingNameParty == 1 && (
                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="text"
                            className="form-control search-box"
                            placeholder="Billing Name Here..."
                            autoComplete="off"
                            value={billingName}
                            disabled={isConvert}
                            onChange={(e) => {
                              const value = e.target.value;
                              setBillingName(value);
                            }}
                          />
                          <label htmlFor="login-username">Billing Name</label>
                        </div>
                      </div>
                    )}
                    {businessSetting.Tran_BillingNameParty == 1 && (
                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="text"
                            className="form-control search-box"
                            placeholder="Billing Address Here..."
                            autoComplete="off"
                            value={billingAddress}
                            disabled={isConvert}
                            onChange={(e) => {
                              const value = e.target.value;
                              setBillingAddress(value);
                            }}
                          />
                          <label htmlFor="login-username">Billing Address</label>
                        </div>
                      </div>
                    )}
                    {businessSetting.Tran_CustomerPO == 1 && (
                      <div className="col-6">
                        <div className="form-material open">
                          {viewPoDate()}
                          <label htmlFor="login-username">PO Date</label>
                        </div>
                      </div>
                    )}
                    {businessSetting.Tran_CustomerPO == 1 && (
                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="text"
                            className="form-control search-box"
                            placeholder="PO Number"
                            autoComplete="off"
                            maxLength="20"
                            onKeyPress={(e) => handleKeyPress(e)}
                            value={poNumber}
                            disabled={isConvert}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value)) {
                                setPoNumber(value);
                              }
                            }}
                          />
                          <label htmlFor="login-username">PO Number</label>
                        </div>
                      </div>
                    )}

                    {businessSetting.EWayBillNo == 1 && (
                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="text"
                            className="form-control search-box"
                            placeholder="E Way Bill No"
                            autoComplete="off"
                            value={EWayBillNo}
                            disabled={isConvert}
                            onChange={(e) => {
                              const value = e.target.value;
                              setEWayBillNo(value);
                            }}
                          />
                          <label htmlFor="login-username">
                            E Way Bill No
                          </label>
                        </div>
                      </div>
                    )}
                    {businessSetting.DueDatesPaymentTerms == 1 &&
                      toggleState == false && (
                        <div className="col-6">
                          <div className="form-material open">

                            <select
                              className="form-control "
                              required="required"
                              onChange={(e) => selectPaymentTerms(e)}
                              value={termsId}
                              disabled={isConvert}
                            >
                              {paymentTermsData.map(
                                (item, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={item.id}
                                    >
                                      {item.terms_name}
                                    </option>
                                  );
                                }
                              )}
                            </select>


                            <label htmlFor="login-username">Pmt. Terms</label>
                          </div>
                        </div>
                      )}

                    {businessSetting.DueDatesPaymentTerms == 1 &&
                      !toggleState && (
                        <div className="col-6">
                          {businessSetting.DueDatesPaymentTerms == 1 &&
                            <div className="form-material open">
                              {viewDueOnDate()}
                              <label htmlFor="login-username">Due On</label>
                            </div>
                          }
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="block block-transparent">
                <div className="block-content">
                  <div className="" id="formSideRight">
                    {businessSetting.InvoiceBillNo == 1 && (
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Invoice No. <span className="text-danger">*</span>
                        </label>
                        <div
                          className="col-lg-3 form-material"
                          id="paddingOff"
                        >

                          <select
                            className="form-control "
                            required="required"
                            onChange={(e) => selectPrefix(e)}
                            value={prefixId}
                            disabled={isConvert}
                          >

                            {prefixArray.map(
                              (prefixData, index) => {

                                return (
                                  <option
                                    key={index}
                                    value={prefixData.prefix_id}
                                  >
                                    {prefixData.prefix}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div
                          className="col-lg-4 form-material"
                          id="paddingOff"
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="invoiceNo"
                            name="invoiceNo"
                            required="required"
                            maxLength="20"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value)) {
                                setInvoiceNo(value);
                              }
                            }}
                            placeholder="Enter a Invoice No."
                            autoComplete="off"
                            value={invoiceNo}
                          />

                        </div>
                      </div>
                    )}

                    <div className="form-group row">
                      <label
                        style={{ textAlign: "right" }}
                        className="col-lg-4 col-form-label"
                        htmlFor="val-username"
                      >
                        Invoice Date <span className="text-danger">*</span>
                      </label>

                      <div className="col-lg-8 form-material" id="paddingOff">
                        {viewInvoiceDate()}
                      </div>
                    </div>
                    {businessSetting.Tran_AddTime == 1 && (
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Time
                        </label>
                        <div
                          className="col-lg-8 form-material"
                          id="paddingOff"
                          onMouseLeave={() => onMouseLeaveHandlerTime()}
                        >
                          <input
                            readOnly="readOnly"
                            type="text"
                            className="form-control search-box"
                            onClick={() => setIsDialogOpen_Time(true)}
                            placeholder="Please Select Time"
                            autoComplete="off"
                            name="invoiceTime"
                            id="invoiceTime"
                            value={invoiceTime}
                            disabled={isConvert}
                          />
                          {
                            isDialogOpen_Time &&
                            <ul
                              className={" iti__party-list iti__party-list--dropup"}
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {getTimeSlotList()}
                            </ul>
                          }
                        </div>
                      </div>
                    )}
                    {businessSetting.SateOfSupply == 1 && (
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          State of Supply
                        </label>
                        <div
                          className="col-lg-8 form-material"
                          id="paddingOff"
                        >
                          <select
                            className="form-control "
                            id="countryState"
                            name="countryState"
                            value={stateName}
                            disabled={isConvert}
                            onChange={(e) => setStateName(e.target.value)}
                          >
                            <option value=""> None</option>
                            {stateArray.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  value={item.name}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    )}

                    <div className="form-material open"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* table page */}
          {
            partyItemArray.length > 0 &&
            <SaleAddItemTable
              isConvert={isConvert}
              isEditMode={isEditMode}
              partyItemArray={partyItemArray}
              selectedItemArray={selectedItemArray}
              callBack={(data, totalAmount, totalQuantity, itemSubTotalAmount) => { callBack(data, totalAmount, totalQuantity, itemSubTotalAmount) }}
            />
          }
          {/* table page */}
          {
            (partyItemArray.length > 0 && selectedItemArray.length > 0) &&
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="form-material open" id="paddingOff">
                          <select
                            className="col-8 form-control"
                            value={bankId}
                            onChange={(e) => selectBank(e)}
                            disabled={isConvert}
                          >
                            {
                              bankArray.map((bankD, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={bankD.id}
                                  >
                                    {bankD.bank_name ? bankD.bank_name : bankD.display_name}
                                  </option>
                                )
                              })
                            }
                          </select>
                        </div>
                        {paymentType != "Cash" && (
                          <div className="form-material open">
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                value={paymentRefNo}
                                required="required"
                                onChange={(e) => setPaymentRefNo(e.target.value)}
                                placeholder="Enter Reference No.."
                                autoComplete="off"
                                maxLength={30}
                                disabled={isConvert}
                              />
                            </div>
                          </div>
                        )}
                        <div className="form-material open">
                          <div className="col-12 form-material" id="paddingOff">
                            <input
                              type="text"
                              className="form-control"
                              required="required"
                              onChange={(e) => setDescription(e.target.value)}
                              placeholder="Enter a description"
                              autoComplete="off"
                              value={description}
                              disabled={isConvert}
                            />
                          </div>
                        </div>
                        <div className="form-material open">
                          <div className="col-8 form-material" id="paddingOff">
                            <input
                              type="file"
                              accept="image/*"
                              style={{ width: "60%", display: "inline-block" }}
                              autoComplete="false"
                              className="form-control"
                              id="descImage"
                              name="descImage"
                                disabled={isConvert}
                              onChange={(e) => onChangeFileUploadHandler(e)}
                            />
                            {previewImagesListing()}
                          </div>
                        </div>

                        {businessSetting.PrintOriginalDuplicate == 1 && (
                          <div className="form-material open">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-form-label"
                              htmlFor="totalAmount"
                            >
                              Original / Duplicate Setting
                            </label>
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <select
                                className="col-8 form-control"
                                onChange={(e) => setPrintType(e.target.value)}
                                value={printType}
                                disabled={isConvert}
                              >
                                {printTypeList.map(({ value, label }, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={value}
                                    >
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div id="taxDisData">
                      <div className="col-md-12" id="formSideRight">
                        <div className="row">
                          <label
                            style={{
                              textAlign: "right",
                              margin: "25px 0 0 0",
                            }}
                            className="col-lg-4 col-form-label"
                          >
                            Sub Total Amount
                          </label>
                          <div
                            className="col-lg-4 form-material"
                            style={{
                              padding: "0 !important",
                              margin: "0 !important",
                            }}
                          >
                            <input
                              type="text"
                              className={"form-control"}
                              autoComplete="off"
                              value={itemSubTotalAmount}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      {businessSetting.TransactionWiseDiscount == 1 && (
                        <div className="col-md-12" id="formSideRight">
                          <div className="form-group row">
                            <label
                              style={{
                                textAlign: "right",
                                margin: "25px 0 0 0",
                              }}
                              className="col-lg-4 col-form-label"
                            >
                              Discount %
                            </label>
                            <div
                              className="col-lg-4 form-material"
                              id=""
                              style={{
                                padding: "0 !important",
                                margin: "0 !important",
                              }}
                            >
                              <input
                                type="text"
                                id="totalTransDiscountPer"
                                name="totalTransDiscountPer"
                                onChange={(e) => onChangeDiscountPercentage(e.target.value)}
                                placeholder="In %"
                                className={"form-control"}
                                onKeyPress={(e) => handleKeyPress(e)}
                                autoComplete="off"
                                value={totalTransDiscountPer}
                                disabled={isConvert}
                              />
                            </div>
                            <div
                              className="col-lg-4 form-material"
                              id=""
                              style={{
                                padding: "0 !important",
                                margin: "0 !important",
                              }}
                            >
                              <input
                                type="text"
                                id="totalTransDiscountAmount"
                                name="totalTransDiscountAmount"
                                onChange={(e) => onChangeDiscountAmount(e.target.value)}
                                placeholder=""
                                className="form-control"
                                onKeyPress={(e) => handleKeyPress(e)}
                                value={totalTransDiscountAmount}
                                autoComplete="off"
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                                disabled={isConvert}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {businessSetting.TransactionWiseTax == 1 && (
                        <div className="col-md-12" id="formSideRight">
                          <div className="form-group row">
                            <label
                              style={{
                                textAlign: "right",
                                margin: "0 0 0 0",
                              }}
                              className="col-lg-4 col-form-label"
                              htmlFor="totalAmount"
                            >
                              Tax{" "}
                            </label>
                            <div
                              className="col-lg-4 form-material"
                              id="paddingOff"
                              style={{
                                padding: "0 !important",
                                margin: "0 0 !important",
                              }}
                            >
                              <select
                                style={{ width: "85px" }}
                                className="form-control"
                                onChange={(e) => { onChangeTaxRate(e.target.value) }}
                                value={transactionWiseTaxId}
                                disabled={isConvert}
                              >
                                {taxRateArray.map((tax, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={tax.id} >
                                      {tax.name + (tax.id == 0 ? '' : "@" + tax.ratio + "%")}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div
                              className="col-lg-4 form-material"
                              id="paddingOff"
                              style={{
                                padding: "0 !important",
                                margin: "0 !important",
                              }}
                            >
                              <input
                                readOnly="readOnly"
                                type="text"
                                id="totalTransTaxAmount"
                                name="totalTransTaxAmount"
                                placeholder=""
                                className={"form-control "}
                                autoComplete="off"
                                value={totalTransTaxAmount}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {/*Additional Charges */}
                      {businessSetting.TransactionAdditionalChargeEnable ==
                        1 && (
                          <div id="taxDisData">
                            {businessSetting.TransactionAdditionalCharge1Enable ==
                              1 && (
                                <div className="col-md-12" id="formSideRight">
                                  <div className="form-group row">
                                    <label
                                      style={{ textAlign: "right" }}
                                      className="col-lg-4 col-form-label"
                                      htmlFor="TransAdditionalCharge1"
                                    >
                                      {
                                        businessSetting.TransactionAdditionalCharge1Name
                                      }
                                    </label>
                                    <div
                                      className="col-lg-8 form-material"
                                      id="paddingOff"
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        onChange={(e) => onChangeAdditionalCharge1(e.target.value)}
                                        placeholder=""
                                        value={transAdditionalCharge1}
                                        autoComplete="off"
                                        disabled={isConvert}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                            {businessSetting.TransactionAdditionalCharge2Enable ==
                              1 && (
                                <div className="col-md-12" id="formSideRight">
                                  <div className="form-group row">
                                    <label
                                      style={{ textAlign: "right" }}
                                      className="col-lg-4 col-form-label"
                                      htmlFor="TransAdditionalCharge2"
                                    >
                                      {
                                        businessSetting.TransactionAdditionalCharge2Name
                                      }
                                    </label>
                                    <div
                                      className="col-lg-8 form-material"
                                      id="paddingOff"
                                    >
                                      <input
                                        type="text"
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        onChange={(e) => onChangeAdditionalCharge2(e.target.value)}
                                        placeholder=""
                                        className="form-control"
                                        value={transAdditionalCharge2}
                                        autoComplete="off"
                                        style={{
                                          padding: "0 !important",
                                          margin: "0 !important",
                                        }}
                                        disabled={isConvert}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                            {businessSetting.TransactionAdditionalCharge3Enable ==
                              1 && (
                                <div className="col-md-12" id="formSideRight">
                                  <div className="form-group row">
                                    <label
                                      style={{ textAlign: "right" }}
                                      className="col-lg-4 col-form-label"
                                      htmlFor="TransAdditionalCharge3"
                                    >
                                      {
                                        businessSetting.TransactionAdditionalCharge3Name
                                      }
                                    </label>
                                    <div
                                      className="col-lg-8 form-material"
                                      id="paddingOff"
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        onChange={(e) => onChangeAdditionalCharge3(e.target.value)}
                                        placeholder=""
                                        value={transAdditionalCharge3}
                                        autoComplete="off"
                                        disabled={isConvert}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      {/*End Additional Charges */}
                    </div>

                    <div className="col-md-12" id="formSideRight">
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="totalAmount"
                        >
                          Total Amount
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="form-control"
                            placeholder=""
                            value={totalAmount}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      {!toggleState &&
                        <div >
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="receivedAmount"
                            >
                              Received Amount
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >

                              <input
                                type="text"
                                className="form-control"
                                onKeyPress={(e) => handleKeyPress(e)}
                                placeholder=""
                                value={receivedAmount}
                                onChange={(e) => onChangeReceivedAmount(e.target.value)}
                                autoComplete="off"
                                onWheel={() => document.activeElement.blur()}
                                disabled={isConvert}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="dueAmount"
                            >
                              Balance Due
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                readOnly="readOnly"
                                className="form-control"
                                id="dueAmount"
                                name="dueAmount"
                                placeholder=""
                                value={dueAmount}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      }

                      <div id="additionFields">
                        {(businessSetting.additional_field_check1 == 1) && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="addF1"
                            >
                              {businessSetting.additional_field_name1}
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="additional_value_1"
                                name="additional_value_1"
                                placeholder=""
                                value={additionalValue1}
                                autoComplete="off"
                                disabled={isConvert}
                                onChange={(e) => setAdditionalValue1(e.target.value)}
                              />
                            </div>
                          </div>
                        )}

                        {(businessSetting.additional_field_check2 == 1 ||
                          businessSetting.additional_field_check2 ===
                          true) && (
                            <div className="form-group row">
                              <label
                                style={{ textAlign: "right" }}
                                className="col-lg-4 col-form-label"
                                htmlFor="addF2"
                              >
                                {businessSetting.additional_field_name2}
                              </label>
                              <div
                                className="col-lg-8 form-material"
                                id="paddingOff"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="additional_value_2"
                                  name="additional_value_2"
                                  placeholder=""
                                  value={additionalValue2}
                                  autoComplete="off"
                                disabled={isConvert}
                                  onChange={(e) => setAdditionalValue2(e.target.value)}
                                />
                              </div>
                            </div>
                          )}

                        {(businessSetting.additional_field_check3 == 1 ||
                          businessSetting.additional_field_check3 ===
                          true) && (
                            <div className="form-group row">
                              <label
                                style={{ textAlign: "right" }}
                                className="col-lg-4 col-form-label"
                                htmlFor="addF3"
                              >
                                {businessSetting.additional_field_name3}
                              </label>
                              <div
                                className="col-lg-8 form-material"
                                id="paddingOff"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="additional_value_3"
                                  name="additional_value_3"
                                  placeholder=""
                                  value={additionalValue3}
                                  autoComplete="off"
                                disabled={isConvert}
                                  onChange={(e) => setAdditionalValue3(e.target.value)}
                                />
                              </div>
                            </div>
                          )}
                        {(businessSetting.additional_field_date_field_check ===
                          1 ||
                          businessSetting.additional_field_date_field_check ===
                          true) && (
                            <div className="form-group row">
                              <label
                                style={{ textAlign: "right" }}
                                className="col-lg-4 col-form-label"
                                htmlFor="addFDate"
                              >
                                {
                                  businessSetting.additional_field_date_field_name
                                }
                              </label>
                              <div
                                className="col-lg-8 form-material"
                                id="paddingOff"
                              >
                                {viewAdditionalDate()}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {businessSetting.TransportationDetailsField1Enable == 1 && (
                // Transportation Detail Here
                // <QuickTransportationDetails />
                <div className="col-md-12">
                  <div className="block block-transparent">
                    <div className="block-content">
                      <h5 className="content-heading">
                        Transportation Details
                      </h5>
                      <div className="block">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="block block-transparent">
                              <div className="block-content" id="formSideLeft">
                                <div className="form-group row">
                                  {businessSetting.TransportationDetailsField1Enable ==
                                    1 && (
                                      <div className="col-4">
                                        <div className="form-material open">
                                          <input
                                            type="text"
                                            className="form-control search-box"
                                            placeholder={
                                              businessSetting.TransportationDetailsField1Name
                                            }
                                            autoComplete="off"
                                            name="TransportDetailField1"
                                            id="TransportDetailField1"
                                            value={transportDetailField1}
                                            disabled={isConvert}
                                            onChange={(e) => setTransportDetailField1(e.target.value)}
                                          />
                                          <label htmlFor="login-username">
                                            {
                                              businessSetting.TransportationDetailsField1Name
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  {businessSetting.TransportationDetailsField2Enable ==
                                    1 && (
                                      <div className="col-4">
                                        <div className="form-material open">
                                          <input
                                            type="text"
                                            className="form-control search-box"
                                            placeholder={
                                              businessSetting.TransportationDetailsField2Name
                                            }
                                            autoComplete="off"
                                            name="TransportDetailField2"
                                            id="TransportDetailField2"
                                            value={
                                              transportDetailField2
                                            }
                                            disabled={isConvert}
                                            onChange={(e) => setTransportDetailField2(e.target.value)}
                                          />
                                          <label htmlFor="login-username">
                                            {
                                              businessSetting.TransportationDetailsField2Name
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  {businessSetting.TransportationDetailsField3Enable ==
                                    1 && (
                                      <div className="col-4">
                                        <div className="form-material open">
                                          <input
                                            type="text"
                                            className="form-control search-box"
                                            placeholder={
                                              businessSetting.TransportationDetailsField3Name
                                            }
                                            autoComplete="off"
                                            name="TransportDetailField3"
                                            id="TransportDetailField3"
                                            value={
                                              transportDetailField3
                                            }
                                            disabled={isConvert}
                                            onChange={(e) => setTransportDetailField3(e.target.value)}
                                          />
                                          <label htmlFor="login-username">
                                            {
                                              businessSetting.TransportationDetailsField3Name
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  {businessSetting.TransportationDetailsField4Enable ==
                                    1 && (
                                      <div className="col-4">
                                        <div className="form-material open">
                                          <input
                                            type="text"
                                            className="form-control search-box"
                                            placeholder={
                                              businessSetting.TransportationDetailsField4Name
                                            }
                                            autoComplete="off"
                                            name="TransportDetailField4"
                                            id="TransportDetailField4"
                                            value={
                                              transportDetailField4
                                            }
                                            disabled={isConvert}
                                            onChange={(e) => setTransportDetailField4(e.target.value)}
                                          />
                                          <label htmlFor="login-username">
                                            {
                                              businessSetting.TransportationDetailsField4Name
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  {businessSetting.TransportationDetailsField5Enable ==
                                    1 && (
                                      <div className="col-4">
                                        <div className="form-material open">
                                          <input
                                            type="text"
                                            className="form-control search-box"
                                            placeholder={
                                              businessSetting.TransportationDetailsField5Name
                                            }
                                            autoComplete="off"
                                            name="TransportDetailField5"
                                            id="TransportDetailField5"
                                            value={
                                              transportDetailField5
                                            }
                                            disabled={isConvert}
                                            onChange={(e) => setTransportDetailField5(e.target.value)}
                                          />
                                          <label htmlFor="login-username">
                                            {
                                              businessSetting.TransportationDetailsField5Name
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  {businessSetting.TransportationDetailsField6Enable ==
                                    1 && (
                                      <div className="col-4">
                                        <div className="form-material open">
                                          <input
                                            type="text"
                                            className="form-control search-box"
                                            placeholder={
                                              businessSetting.TransportationDetailsField6Name
                                            }
                                            autoComplete="off"
                                            name="TransportDetailField6"
                                            id="TransportDetailField6"
                                            value={
                                              transportDetailField6
                                            }
                                            disabled={isConvert}
                                            onChange={(e) => setTransportDetailField6(e.target.value)}
                                          />
                                          <label htmlFor="login-username">
                                            {
                                              businessSetting.TransportationDetailsField6Name
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <div className="row justify-content-center text-right">
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      onClick={() => clickToAddSale()}
                      className="btn btn-alt-primary mb-10 mx-5"
                    >
                      Save{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
      {isDialogOpen_AddTerms && (
        <Dialog title={'Due Dates and Payment terms'} onClose={() => setIsDialogOpen_AddTerms(false)}>
          <QuickAdjustAddNewTerms
            callBack={(data) => onCallbackAddTerms(data)}
            callBackTerms={() => {
              setIsDialogOpen_AddTerms(false);
            }}
          />
        </Dialog>
      )}
      {
        isDialogOpen_AddPrefix && (
          <Dialog width={500} title={'Add New Prefix'} onClose={() => setIsDialogOpen_AddPrefix(false)}>
            <QuickAddNewPrefix
              callBack={(newPrefixId, newPrefixName) => onCallbackAddPrefix(newPrefixId, newPrefixName)}
              transactionType="SALE"
            />

          </Dialog>
        )
      }

      {
        isLoading && (
          <Dialog >
            <Loader type="converging-spinner" />
          </Dialog>
        )
      }
      {
        showSuccessDialog &&
        <SuccessDialog
          onClose={() => {
            setShowSuccessDialog(false);

            if (transactionType) {
              history.goBack();
            } else {
              window.location.href = global.webUrl + "business/download-pdf/?auth=" + transactionId;
            }
          }}
          title={'Success'}
          message={message}
        />
      }

    </div>
  );
}
export default ManageSaleCreate