import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import validator from "validator";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Modal from "react-modal";
import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTypeData = [];
let _bankArray = [];

let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
  gstTypeData = _companySession.taxType;

  let bankArrayData = _companySession.bankDetails
    ? _companySession.bankDetails
    : [];
  bankArrayData.map((bank, index) => {
    _bankArray.push(bank);
  });

}

let _bankId1 = 0;
let _bankName1 = "";

if (_bankArray.length > 0) {
  _bankId1 = _bankArray[0].bank_id;
  _bankName1 = _bankArray[0].bank_name;
}
let _loader = "glb-ldr-prt active";
let _isEditMode = false;
export default class quickBankTransferPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-loan-accounts/",
      buttonTitle: "",
      hideShow: "",
      isLoaded: true,
      company_id: _company_id,
      businessId: _businessId,
      userId: _signingId,
      activeBankId: this.props.activeBankId,
      popType: this.props.popType,

      imagePreviewUrl: [],
      imgCollection: [],
      imgCollectionOld: [],
      transfer_date: moment().format("MMMM D, YYYY"),
      amount: "",
      deposit_to: "Cash",
      withdraw_from: "Cash",
      description: "",
      depositType: "",
      accountTrasactionId: this.props.accountTrasactionId ? this.props.accountTrasactionId : 0,
      bankDataSend : this.props.bankDataSend ? this.props.bankDataSend : [],

      bankId_W: _bankId1, //'0',
      bankName_W: _bankName1,
      bankId_D: _bankId1,
      bankName_D: _bankName1,
      bankId_AC: _bankId1,
      bankName_Ac: _bankName1,
      _bankIdAC: _bankId1,

      adjust_type: "Increase",
      defaultBankId_W: "0",
      defaultBankId_D: "0",
      errors: {
        amount: "",
        deposit_to: "",
        withdraw_from: "",
        description: "",
      },
    };
    _isEditMode = this.props.accountTrasactionId > 0 ? true : false;
  }

  async componentDidMount() {

    let accountTrasactionId = this.props.accountTrasactionId;

    if (accountTrasactionId > 0) {
      const requestData = JSON.stringify({
        transferId: accountTrasactionId,
      });

      PostData(global.userBankTransferDetailsList, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _isEditMode = true;
              let _transArray = responseJson.response[0];
              this.setState({
                buttonTitle: "Edit",
                bank_transction_id: _transArray.bank_transction_id,
                user_id: _transArray.user_id,
                business_id: _transArray.business_id,
                company_id: _transArray.company_id,
                transaction_type: _transArray.transaction_type,
                deposit_to: _transArray.deposit_to,
                withdraw_from: _transArray.withdraw_from,
                bankId_W: _transArray.bank_id_w,
                bankName_W: _transArray.bank_name_w,
                bankId_D: _transArray.bank_id_d,
                bankName_D: _transArray.bank_name_d,
                transfer_date: moment(_transArray.transfer_date).format(
                  "MMMM D, YYYY"
                ),
                amount: _transArray.amount,
                description: _transArray.description,
                adjust_type: _transArray.adjust_type,
                bankId_AC: _transArray.adjust_bank_id,
                bankName_Ac: _transArray.adjust_bank_name,
                via: _transArray.via,
                defaultBankId_W: _transArray.bank_id_w,
                defaultBankId_D: _transArray.bank_id_d,
                imagePreviewUrl: _transArray.attach_image,
                depositType: _transArray.transaction_type,
                accountTrasactionId: this.props.accountTrasactionId,
                isLoaded: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    } else {
      _isEditMode = false;
      if (this.props.popType == "CTB") {
        this.setState({ depositType: "Deposit" });
      } else if (this.props.popType == "BTC") {
        this.setState({ depositType: "Withdraw" });
      }
    }
  }

  handleCallBackDate = (start) => {
    this.setState({ transfer_date: start.format("MMMM D, YYYY") });
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "amount":
        if (validator.isInt(value)) {
          errors.amount = "";
        } else {
          errors.amount = "*Amount should be numeric!";
        }
        this.setState({ amount: value });
        break;

      case "deposit_to":
        let _bankId = 0;
        let _bankName = "";
        let _wValue = "Cash";
        if (this.state.popType == "CTB") {
          if (value > 0) {
            _wValue = "Bank";
            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index];

            _bankId = optionElement.getAttribute("data-bankFromId");
            _bankName = optionElement.getAttribute("data-bankFromName");
            errors["deposit_to"] = "";
            this.setState({ errors: errors });
          }
          if (value == "NewBank") {
            this.openModalHandler("modalAdjustNewBank");
          }
        } else if (this.state.popType == "BTC") {
          _wValue = "Cash";

        } else if (this.state.popType == "BTB") {
          if (value > 0) {
            _wValue = "Bank";
            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index];

            _bankId = optionElement.getAttribute("data-bankFromId");
            _bankName = optionElement.getAttribute("data-bankFromName");
          }
          if (value == "NewBank") {
            this.openModalHandler("modalAdjustNewBank");
          }
        } else if (this.state.popType == "ADB") {
          _wValue = ""; // no need to define
        }

        if (
          this.state.bankId_W > 0 &&
          this.state.bankId_D > 0 &&
          this.state.popType == "BTB"
        ) {
          if (_bankId == this.state.bankId_W) {
            errors.deposit_to = "*Bank account should not be same!";
          } else {
            errors.deposit_to = "";
            errors.withdraw_from = "";
          }
        }

        this.setState({
          deposit_to: _wValue,
          bankId_D: _bankId,
          bankName_D: _bankName,
        });

        break;

      case "withdraw_from":
        let _bankIdW = 0;
        let _bankNameW = "";
        let _wValueW = "Cash";
        if (this.state.popType == "CTB") {
          _wValueW = "Cash";
        } else if (this.state.popType == "BTC") {
          if (value > 0) {
            _wValueW = "Bank";
            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index];

            _bankIdW = optionElement.getAttribute("data-bankFromIdW");
            _bankNameW = optionElement.getAttribute("data-bankNameW");
            errors["withdraw_from"] = "";
            this.setState({ errors: errors });
          }
          if (value == "NewBank") {
            _wValueW = "Bank";
            _bankIdW = _bankArray[0].bank_id;
            _bankNameW = _bankArray[0].bank_name;
            this.openModalHandler("modalAdjustNewBank");
          }
        } else if (this.state.popType == "BTB") {
          if (value > 0) {
            _wValueW = "Bank";
            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index];

            _bankIdW = optionElement.getAttribute("data-bankFromIdW");
            _bankNameW = optionElement.getAttribute("data-bankNameW");
          }
          if (value == "NewBank") {
            this.openModalHandler("modalAdjustNewBank");
          }
        } else if (this.state.popType == "ADB") {
          _wValueW = ""; // no need to define
        }

        if (
          this.state.bankId_W > 0 &&
          this.state.bankId_D > 0 &&
          this.state.popType == "BTB"
        ) {
          if (_bankIdW == this.state.bankId_D) {
            errors.withdraw_from = "*Bank account should not be same!";
          } else {
            errors.withdraw_from = "";
            errors.deposit_to = "";
          }
        }

        this.setState({
          withdraw_from: _wValueW,
          bankId_W: _bankIdW,
          bankName_W: _bankNameW,
        });
        break;

      case "account_name":
        let _bankIdAC = 0;
        let _bankNameAC = "";
        let _wValueAC = "CASH";

        if (this.state.popType == "ADB") {
          if (value > 0) {
            _wValueAC = "Bank";
            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index];

            _bankIdAC = optionElement.getAttribute("data-bankADBId");
            _bankNameAC = optionElement.getAttribute("data-bankFromNameAC");
          }else{
            _bankIdAC = optionElement.getAttribute("data-bankADBId");
            _bankNameAC = optionElement.getAttribute("data-bankFromNameAC");

          }

          if (value == "NewBank") {
            this.openModalHandler("modalAdjustNewBank");
          }
        }
        this.setState({
          account_name: _wValueAC,
          bankId_AC: _bankIdAC,
          bankName_AC: _bankNameAC,
        });
        break;

      case "description":
        this.setState({ description: value });
        break;

      case "adjust_type":
        this.setState({ adjust_type: value });
        break;
      default:
        break;
    }
  };
  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    }
  };
  /*End Modal open close*/

  clickToAddBankDetails = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    //alert('aaa');return false;
    //alert(this.state.withdraw_from  + '==k=='+this.state.deposit_to );return false;

    if (this.state.received_in == "") {
      _isError = true;
      errors["received_in"] = "*Please Select Loan Received In";
      this.setState({ errors: errors });
    }

    if (this.state.amount == "") {
      _isError = true;
      errors["amount"] = "*Please Enter amount.";
      this.setState({ errors: errors });
    }
    if (
      (this.state.bankId_D == "0" || this.state.bankId_D == 0) &&
      this.state.depositType == "Deposit" &&
      this.state.deposit_to!="Cash"
    ) {
      _isError = true;
      errors["deposit_to"] = "*Please select any bank.";
      this.setState({ errors: errors });
    }
    if (
      (this.state.bankId_W == "0" || this.state.bankId_W == 0) &&
      this.state.depositType == "Withdraw" &&
      this.state.withdraw_from!="Cash"
    ) {
      _isError = true;
      errors["withdraw_from"] = "*Please select any bank.";
      this.setState({ errors: errors });
    }
    if (
      (this.state.bankId_D == "0" || this.state.bankId_D == 0) &&
      this.state.deposit_to!="Cash"
    ) {
      _isError = true;
      errors["deposit_to"] = "*Please select any bank.";
      this.setState({ errors: errors });
    }
    if (
      (this.state.bankId_W == "0" || this.state.bankId_W == 0) &&
      this.state.withdraw_from!="Cash"
    ) {
      _isError = true;
      errors["withdraw_from"] = "*Please select any bank.";
      this.setState({ errors: errors });
    }
    if (
      this.state.bankId_W > 0 &&
      this.state.bankId_D > 0 &&
      this.state.popType == "BTB"
    ) {
      if (this.state.bankId_W == this.state.bankId_D) {
        _isError = true;
        errors["withdraw_from"] = "*Bank account should not be same!";
        errors["deposit_to"] = "*Bank account should not be same!";
        this.setState({ errors: errors });
      }
    }

    if (_isError == false) {
      if (this.props.accountTrasactionId > 0) {
        this.userEditMoneyTransfer();
      } else {
        this.addBankTrasacDMethodCall();
      }
    }
  };

  onRadioChange = (e) => {
    let errors = this.state.errors;
    let _typeRadio = "BTC";
    errors["withdraw_from"] = "";
    errors["deposit_to"] = "";
    if (e.target.value == "Deposit") {
      _typeRadio = "CTB";
    }
    this.setState({
      depositType: e.target.value,
      popType: _typeRadio,
    });
    this.setState({ errors: errors });
  };
  settleAmountType() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }
          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankName={_disOption}
                data-bankId={_valOption}
              >
                {_disOption}
              </option>
            );
          }
        });
        _payData.push(<option value="NewBank">Add New Bank </option>);
      } else {
        _payData.push(<option value="">Select Bank </option>);
        _payData.push(<option value="NewBank">Add New Bank</option>);
      }
    }
    return _payData;
  }

  settleAmountType_WAk() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId_W) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }
          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankNameW={_disOption}
                data-bankFromIdW={_valOption}
              >
                {_disOption}
              </option>
            );
          }
        });
        _payData.push(<option value="NewBank">Add New Bank </option>);
      } else {
        _payData.push(<option value="0">Select Bank </option>);
        _payData.push(<option value="NewBank">Add New Bank</option>);
      }
    }
    return _payData;
  }
  settleAmountTypeDeposit() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (_valOption == this.state.bankId_D) {
            _selBank = "selected";
          }
          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankFromName={_disOption}
                data-bankFromId={_valOption}
              >
                {_disOption}
              </option>
            );
          }


          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              //_payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
        _payData.push(<option value="NewBank">Add New Bank </option>);
      } else {
        _payData.push(<option value="0">Select Bank </option>);
        _payData.push(<option value="NewBank">Add New Bank</option>);
        //return(<option value="NewBank">Add New Bank</option>)
      }
    }

    return _payData;
  }

  settleAmountTypeADB() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId_AC) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankName={_disOption}
                data-bankADBId={_valOption}
              >
                {_disOption}
              </option>
            );
          }
        });
        _payData.push(<option value="NewBank">Add New Bank</option>);
      } else {
        _payData.push(<option value="0">Select Bank </option>);
        _payData.push(<option value="NewBank">Add New Bank</option>);
      }
    }

    return _payData;
  }

  addBankTrasacDMethodCall() {
    let transaction_type = "";
    if (this.state.popType == "CTB") {
      transaction_type = "Deposit";
      if(this.state.bankId_D==0){
        alert('Please select correct Deposit To Bank');
        return false;
      }
    } else if (this.state.popType == "BTC") {
      transaction_type = "Withdraw";
      if(this.state.bankId_W == 0){
        alert('Please select correct Withdraw From Bank');
        return false;
      }
    }
    
    var saleData = new FormData();
    saleData.append("businessId", this.state.businessId);
    saleData.append("userId", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("accountId", this.state.activeBankId);
    saleData.append("transaction_type", transaction_type);
    saleData.append("transfer_date", this.state.transfer_date);
    saleData.append("via", this.state.popType);
    saleData.append("deposit_to", this.state.deposit_to);
    saleData.append("withdraw_from", this.state.withdraw_from);
    saleData.append("amount", this.state.amount);
    saleData.append("bankId_W", this.state.bankId_W);
    saleData.append("bankName_W", this.state.bankName_W);
    saleData.append("bankName_D", this.state.bankName_D);
    saleData.append("bankId_D", this.state.bankId_D);
    saleData.append("account_name", this.state.account_name);
    saleData.append("bankId_AC", this.state.bankId_AC); 
    saleData.append("bankName_AC", this.state.bankName_Ac);
    saleData.append("adjust_type", this.state.adjust_type);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    //console.log(this.state.bankName_Ac+'==>=='+this.state.account_name); return false;
    new Promise((resolve, reject) => {
      fetch(global.userAddMoneyTransfer, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              this.props.callBack("hello");
              //window.location.href = global.webUrl+'business/sale-invoice/';
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  userEditMoneyTransfer() {
    let transaction_type = "";
    if (this.state.popType == "CTB") {
      transaction_type = "Deposit";
    } else if (this.state.popType == "BTC") {
      transaction_type = "Withdraw";
    }
    var saleData = new FormData();
    saleData.append("bank_transction_id", this.props.accountTrasactionId);
    saleData.append("businessId", this.state.businessId);
    saleData.append("userId", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("accountId", this.state.activeBankId);
    saleData.append("transaction_type", transaction_type);
    saleData.append("transfer_date", this.state.transfer_date);
    saleData.append("via", this.state.popType);
    saleData.append("deposit_to", this.state.deposit_to);
    saleData.append("withdraw_from", this.state.withdraw_from);
    saleData.append("amount", this.state.amount);
    saleData.append("bankId_W", this.state.bankId_W);
    saleData.append("bankName_W", this.state.bankName_W);
    saleData.append("bankName_D", this.state.bankName_D);
    saleData.append("bankId_D", this.state.bankId_D);
    saleData.append("account_name", this.state.account_name);
    saleData.append("bankId_AC", this.state.bankId_AC);
    saleData.append("bankName_AC", this.state.bankName_Ac);
    saleData.append("adjust_type", this.state.adjust_type);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);

    //alert(this.state.transfer_date)

    new Promise((resolve, reject) => {
      fetch(global.userEditMoneyTransfer, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              this.props.callBack("hello");
              //window.location.href = global.webUrl+'business/sale-invoice/';
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }

  isWithdrawDepositRadioType() {
    if (this.state.popType == "CTB") {
      return (
        <div className="col-6">
          <div className="form-material open">
            <input
              type="radio"
              required="required"
              id="depositTypee"
              name="depositType"
              value="Deposit"
              checked={this.state.depositType == "Deposit" ? "checked" : ""}
              defaultChecked
              onChange={this.onRadioChange}
            />
            &nbsp;&nbsp;Deposit&nbsp;&nbsp;
            <input
              type="radio"
              required="required"
              id="withdrawType"
              name="depositType"
              value="Withdraw"
              checked={this.state.depositType == "Withdraw" ? "checked" : ""}
              onChange={this.onRadioChange}
            />
            &nbsp;&nbsp;Withdraw&nbsp;&nbsp;
          </div>
        </div>
      );
    } else if (this.state.popType == "BTC") {
      return (
        <div className="col-6">
          <div className="form-material open">
            <input
              type="radio"
              required="required"
              id="depositTypee"
              name="depositType"
              value="Deposit"
              checked={this.state.depositType == "Deposit" ? "checked" : ""}
              onChange={this.onRadioChange}
              defaultChecked
            />
            &nbsp;&nbsp;Deposit&nbsp;&nbsp;
            <input
              type="radio"
              required="required"
              id="withdrawType"
              name="depositType"
              value="Withdraw"
              checked={this.state.depositType == "Withdraw" ? "checked" : ""}
              onChange={this.onRadioChange}
            />
            &nbsp;&nbsp;Withdraw&nbsp;&nbsp;
          </div>
        </div>
      );
    }
  }

  isWithdrawDepositType() {
    const { errors } = this.state;

    if (this.state.popType == "CTB") {
      // && this.state.depositType == 'Deposit'
      return (
        <div className="form-group row">
          <div className="col-6">
            <div className="form-material open">
              <select
                className="col-8 form-control"
                value={
                  this.state.bankId_D > 0
                    ? this.state.bankId_D
                    : this.state.deposit_to
                }
                id="deposit_to"
                name="deposit_to"
                onChange={this.handleChange}
              >
                {this.settleAmountTypeDeposit()}
              </select>
              <label htmlFor="login-username">Deposit To</label>
              <span className="iris_error_txt">
                {errors.deposit_to.length > 0 && (
                  <span className="error">{errors.deposit_to}</span>
                )}
              </span>
            </div>
          </div>
          <div className="col-6">
            <div className="form-material open">
              <input
                type="text"
                required="required"
                className="form-control"
                id="withdraw_from"
                name="withdraw_from"
                value="Cash"
                placeholder="Enter withdraw from"
                onChange={this.handleChange}
              />
              <label htmlFor="login-username">Withdraw From </label>
              <span className="iris_error_txt">
                {errors.withdraw_from.length > 0 && (
                  <span className="error">{errors.withdraw_from}</span>
                )}
              </span>
            </div>
          </div>
        </div>
      );
    } else if (this.state.popType == "BTC") {
      return (
        <div className="form-group row">
          <div className="col-6">
            <div className="form-material open">
              <input
                type="text"
                required="required"
                className="form-control"
                id="deposit_to"
                name="deposit_to"
                value="Cash"
                placeholder="Enter Amount"
                onChange={this.handleChange}
              />
              <label htmlFor="login-username">Deposit To</label>
              <span className="iris_error_txt">
                {errors.deposit_to.length > 0 && (
                  <span className="error">{errors.deposit_to}</span>
                )}
              </span>
            </div>
          </div>
          <div className="col-6">
            <div className="form-material open">
              <select
                  value={
                    this.state.bankId_W > 0
                        ? this.state.bankId_W
                        : this.state.withdraw_from
                  }
                name="withdraw_from"
                id="withdraw_from"
                className="form-control"
                onChange={this.handleChange}
              >
                {this.settleAmountType_WAk()}
              </select>
              <label htmlFor="login-username">Withdraw From </label>
              <span className="iris_error_txt">
                {errors.withdraw_from.length > 0 && (
                  <span className="error">{errors.withdraw_from}</span>
                )}
              </span>
            </div>
          </div>
        </div>
      );
    } else if (this.state.popType == "BTB") {
      return (
        <div className="form-group row">
          <div className="col-6">
            <div className="form-material open">
              <select
                className="col-8 form-control"
                defaultValue={
                  this.state.bankId > 0
                    ? this.state.bankId
                    : this.state.deposit_to
                }
                id="deposit_to"
                name="deposit_to"
                onChange={this.handleChange}
              >
                {this.settleAmountTypeDeposit()}
              </select>
              <label htmlFor="login-username">Deposit To</label>
              <span className="iris_error_txt">
                {errors.deposit_to.length > 0 && (
                  <span className="error">{errors.deposit_to}</span>
                )}
              </span>
            </div>
          </div>
          <div className="col-6">
            <div className="form-material open">
              <select
                className="col-8 form-control"
                defaultValue={
                  this.state.defaultBankId_W > 0
                    ? this.state.defaultBankId_W
                    : this.state.withdraw_from
                }
                id="withdraw_from"
                name="withdraw_from"
                onChange={this.handleChange}
              >
                {this.settleAmountType_WAk()}
              </select>
              <label htmlFor="login-username">Withdraw From </label>
              <span className="iris_error_txt">
                {errors.withdraw_from.length > 0 && (
                  <span className="error">{errors.withdraw_from}</span>
                )}
              </span>
            </div>
          </div>
        </div>
      );
    } else if (this.state.popType == "ADB") {
      let incSel = "";
      let redSel = "";
      if (this.state.adjust_type == "Increase") {
        incSel = "selected";
        redSel = "";
      } else {
        incSel = "";
        redSel = "selected";
      }
      return (
        <div className="form-group row">
          <div className="col-6">
            <div className="form-material open">
              <select
                name="adjust_type"
                id="adjust_type"
                className="form-control"
                onChange={this.handleChange}
              >
                <option value="Increase" selected={incSel}>
                  Increase Balance
                </option>
                <option value="Reduce" selected={redSel}>
                  Reduce Balance
                </option>
              </select>
              <label htmlFor="login-username">Type </label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-material open">
              <select
                className="col-8 form-control"
                defaultValue={
                  this.state.bankId > 0
                    ? this.state.bankId
                    : this.state.deposit_to
                }
                id="account_name"
                name="account_name"
                onChange={this.handleChange}
              >
                {this.settleAmountTypeADB()}
              </select>
              <label htmlFor="login-username">Account Name</label>
            </div>
          </div>
        </div>
      );
    }
  }

  isDateRefresh = () => {
    let _invoiceDate = moment(this.state.transfer_date).format('MM/DD/YYYY');

    console.log('_invoiceDate', _invoiceDate)
    console.log('this.state.accountTrasactionId', this.state.accountTrasactionId)

    if (_isEditMode == true && this.state.accountTrasactionId > 0) {
      console.log('_invoiceDate>>>>>', _invoiceDate)
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate:_invoiceDate,
          }}
          onApply={(event, picker) => {
            this.setState({ transfer_date: picker.startDate.format("MMMM D, YYYY") });
          }}
          //onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" value={_invoiceDate} />
        </DateRangePicker>
      );
    } else if (
      this.state.isLoaded == true &&
      _isEditMode == false &&
      this.state.accountTrasactionId == 0
    ) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: moment().format("MM/DD/YYYY"),
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    }
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            {this.isWithdrawDepositRadioType()}
            <div className="col-6">
              <div className="form-material open">
                {this.isDateRefresh()}
                <label htmlFor="login-username">Transfer Date</label>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          {this.isWithdrawDepositType()}
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="amount"
                  name="amount"
                  value={this.state.amount}
                  placeholder="Enter Amount"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Enter Amount <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.amount.length > 0 && (
                    <span className="error">{errors.amount}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                <textarea
                  name="description"
                  id="description"
                  placeholder="Enter Description"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.description}
                  rows="1"
                ></textarea>
                <label htmlFor="login-username">
                  Enter Description (Optional)
                </label>
              </div>
            </div>
          </div>

          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <label htmlFor="login-username">Attach Image (Optional)</label>
              <div className="form-material open">
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.onChangeFileUploadHandler}
                  name="item_image"
                />
              </div>
              <div>
                {this.previewImagesListing()}
                {/* {this.previewOldImagesListing()} */}
              </div>
            </div>
          </div>

          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddBankDetails}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> Save
              </button>
            </div>
          </div>
        </form>

        {/* Modal Add Bank Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SBANK}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewBank"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add New Bank</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewBank"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewBankDetail
                    isRedirectTo={"business/manage-account-list/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewBank"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Bank Details*/}
      </React.Fragment>
    );
  }
}
