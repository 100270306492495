import React, { Component } from 'react';
//import {PostData} from '../service/postData';
import '../App.css';
import { Route, Switch } from 'react-router'
import routes from '../routes/routes';
//import { ToastContainer } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";

//----Include Fragment Pages-----
import HeaderSection from '../fragment_folder/header';
import MenuSection from '../fragment_folder/menusBusiness';
import TopHeaderPage from '../fragment_folder/topBusinessHeader';
import FooterPage from '../fragment_folder/footer';
//----End Include Fragment Pages-----

// ----- All Business / Company Pages Here...
import _business_DashboardPanel from '../views_folder/businessController/dashboard';
import _business_profilePanel from '../views_folder/businessController/profile/profile';

import _business_BusinessUserListPanel from '../views_folder/businessController/businessUser/manageBusinessUser';

import _business_CompanyListPanel from '../views_folder/businessController/company/companyList';
import _business_CompanyAddPanel from '../views_folder/businessController/company/companyAdd';

import _business_CommonSettingPanel from '../views_folder/businessController/commonSetting/commonSetting';
// -----End Business / Company Pages Here...

// ----- All Items / Services / Party Pages Here...
import _user_AddParty from '../views_folder/userController/businessParty/manageAddParty';
import _user_Party from '../views_folder/userController/businessParty/party';

import _user_saleInvoice from '../views_folder/userController/userSale/manageSaleList';
//import _user_createSale from '../views_folder/userController/userSale/manageCreateSale';
import _user_createSale from '../views_folder/userController/userSale/ManageSaleCreate';
import _user_createItem from '../views_folder/userController/userItem/manageAddItem';
import _user_itemLists from '../views_folder/userController/userItem/itemList';
import _user_serviceList from '../views_folder/userController/userItem/manageAllService';
import _user_createService from '../views_folder/userController/userItem/manageAddService';

import _user_unitList from '../views_folder/userController/unit/unit';
// -----End Items / Services / Party Pages Here...

// ----- All Sale Transaction Pages Here...
import _user_saleEstimateList from '../views_folder/userController/userSale/manageSaleEstimateList';
import _user_createSaleEstimate from '../views_folder/userController/userSale/manageCreateSaleEstimate';
import _user_createSaleOrder from '../views_folder/userController/userSale/manageCreateSaleOrder';
import _user_saleOrderList from '../views_folder/userController/userSale/manageSaleOrderList';
import _user_createSaleDelvChallan from '../views_folder/userController/userSale/manageCreateDeliveryChallan';
import _user_saleDelvChallanList from '../views_folder/userController/userSale/manageDeliveryChallanList';

import _user_createSalePaymentIn from '../views_folder/userController/userSale/manageCreatePaymentIn'
import _user_salePaymentInList from '../views_folder/userController/userSale/manageSalePaymentInList';

import _user_saleReturnList from '../views_folder/userController/userSale/manageSaleReturnList';
import _user_createSaleReturnCreditNote from '../views_folder/userController/userSale/manageCreateReturnCreditNote';

import _user_createSaleReceivePaymentIn from '../views_folder/userController/userSale/manageCreateReceivePaymentIn';
import _user_createSaleMakePaymentOut from '../views_folder/userController/userSale/manageCreateMakePaymentOut';
import _user_createSaleDuplicate from '../views_folder/userController/userSale/manageCreateDuplicateSale';
//import _user_SaleToReturnCredit from '../views_folder/userController/userSale/manageCreateSaleToReturnCredit';

import _user_SaleDuplicateCredit from '../views_folder/userController/userSale/manageCreateDuplicateCreditNote';
import _user_saleOrderDuplicate_Add from '../views_folder/userController/userSale/manageCreateDuplicateSaleOrder';
// -----End All Sale Transaction Pages Here...

// ----- All Purchase Transaction Pages Here...
import _user_purchaseInvoice from '../views_folder/userController/userPurchase/managePurchaseList';
import _user_createPurchase from '../views_folder/userController/userPurchase/manageCreatePurchase';
import _user_createPurchase2 from '../views_folder/userController/userPurchase/manageCreatePurchaseNew';
import _user_createSale2 from '../views_folder/userController/userSale/manageCreateSaleNew';


import _user_createPurchasePaymentout from '../views_folder/userController/userPurchase/manageCreatePaymentOut'
import _user_purchasePaymentOutList from '../views_folder/userController/userPurchase/managePurchasePaymentOutList';

import _user_purchaseReturnList from '../views_folder/userController/userPurchase/managePurchaseReturnList';
import _user_createPurchaseReturnDebitNote from '../views_folder/userController/userPurchase/manageCreateReturnPurchase';

import _user_createPurchaseOrder from '../views_folder/userController/userPurchase/manageCreatePurchaseOrder';
import _user_purchaseOrderList from '../views_folder/userController/userPurchase/managePurchaseOrderList';

import _user_purchaseMakePayment from '../views_folder/userController/userPurchase/manageCreatePurchaseToPaymentOut';
//import _user_purchaseToReturn from '../views_folder/userController/userPurchase/manageCreatePurchaseToReturn';
import _user_purchaseToDuplicate from '../views_folder/userController/userPurchase/manageCreatePurchaseToDuplicate';
import _user_purchaseMakePaymentIn from '../views_folder/userController/userPurchase/manageCreatePurchaseToPaymentIn';

import _user_purchaseToDuplicateReturn from '../views_folder/userController/userPurchase/manageCreatePurchaseToDuplicateReturn';

import _user_purchaseOrderDuplicate from '../views_folder/userController/userPurchase/manageCreatePurchaseDuplicateOrder';

import _user_expense from '../views_folder/userController/userExpense/expenseList';
import _user_addExpense from '../views_folder/userController/userExpense/addExpense';
import _user_addExpenseDuplicate from '../views_folder/userController/userExpense/manageCreateExpenseToDuplicate';
import _user_addExpensePayment from '../views_folder/userController/userExpense/manageCreateExpensePayment';

import _user_accountList from '../views_folder/userController/cashBank/manageBankList';
import _user_chequeList from '../views_folder/userController/chequeBank/chequeList';
import _user_cashInHandList from '../views_folder/userController/cashBank/manageCashINHand';
import _user_loacAccountList from '../views_folder/userController/cashBank/manageLoanAccountList';
import _user_loacAccountStatementList from '../views_folder/userController/cashBank/manageViewLoanStatementList';

// -----End All Purchase Transaction Pages Here...

import _user_htmlPdfCreation from '../views_folder/userController/htmlPdfCreation';
import _user_downloadPDFFile from '../views_folder/userController/dwonloadPDFFile';
import _user_returnChallanPDFFile from '../views_folder/userController/ReturnAndChallanPDF';
import _user_downloadOIPDFFile from '../views_folder/userController/otherIncomePdfCreation';
import _user_smsListData from '../views_folder/userController/smsSetting/smsSettingList';

import _user_partyToPartyTransfer from '../views_folder/userController/businessParty/partyToPartyTransfer';

// -- All Reports Page
import _user_reports from '../views_folder/userController/reports/reports';
import _user_allPrefixList from '../views_folder/businessController/commonSetting/childFolder/allPrefixListData';

import _user_otherIncomeTransList from '../views_folder/userController/otherIncome/otherIncomeList';
import _user_otherIncomeCreateTrans from '../views_folder/userController/otherIncome/quickAddOtherIncome';
import _user_otherDuplicateIncomeCreate from '../views_folder/userController/otherIncome/quickAddDuplicateOtherIncome';
import _user_otherIncomeList from '../views_folder/userController/otherIncome/manageOtherIncomeList';
import _user_utilitiyImportItemList from '../views_folder/userController/businessUtilities/manageImportItemList';
import _user_utilitiyImportPartyList from '../views_folder/userController/businessUtilities/managePartyList';
import _user_utilitiyExportItemList from '../views_folder/userController/businessUtilities/manageExportItemList';
import _user_utilitiyBulkUpdateItemList from '../views_folder/userController/businessUtilities/manageBulkUpdateItem';

import _user_utilitiyBulkUpdateServiceList from '../views_folder/userController/businessUtilities/manageBulkUpdateServices';


import _user_utilitiyGenerateBarcode from '../views_folder/userController/businessUtilities/manageGenerateBarcode';

export default class PrivatePanel extends Component{
  render(){
    return(
        <div id="page-wrapper">
            <div id="page-container" className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed">
            <aside id="side-overlay">
                <HeaderSection pagekey="business"/>
            </aside>  
            <MenuSection pageId={global.BACK_URL} />
            <TopHeaderPage />
            <main id="main-container">
                <div>

                    <Switch>
                        <Route exact={true} path={routes._business_DashboardPanel} component={_business_DashboardPanel} />
                        <Route exact={true} path={routes._business_profilePanel} component={_business_profilePanel} />

                        <Route exact={true} path={routes._business_BusinessUserListPanel} component={_business_BusinessUserListPanel} />

                        <Route exact={true} path={routes._business_CompanyListPanel} component={_business_CompanyListPanel} />
                        <Route exact={true} path={routes._business_CompanyAddPanel} component={_business_CompanyAddPanel} />
                        {/* <Route exact={true} path="/business/manage-business/" component={BusinessListPanel} />
                        <Route exact={true} path="/business/add-business/" component={businessAddPanel} />
                         */}
                        {/* <Route exact={true} path={routes.categoryPanel} component={CategoryPanel} /> */}
                        
                        <Route exact={true} path={routes._business_BusinessCommonSettingPanel} component={_business_CommonSettingPanel} />
                        
                        {/* Party / Items / Services Pages Route */}
                        <Route exact={true} path={routes._user_PartyPanel} component={_user_Party} />
                        <Route exact={true} path={routes._user_AddParty} component={_user_AddParty} />
                        <Route exact={true} path={routes._user_saleInvoice} component={_user_saleInvoice} />
                        <Route exact={true} path={routes._user_saleInvoice_Add} component={_user_createSale} />
                        <Route exact={true} path={routes._user_Item_Add} component={_user_createItem} />
                        <Route exact={true} path={routes._user_itemList} component={_user_itemLists} />
                        <Route exact={true} path={routes._user_ServiceList} component={_user_serviceList} />
                        <Route exact={true} path={routes._user_Service_Add} component={_user_createService} />

                        <Route exact={true} path={routes._user_unitList} component={_user_unitList} />

                        {/* Sale Transaction Pages Route */}                        
                        <Route exact={true} path={routes._user_saleEstimateList} component={_user_saleEstimateList} />
                        <Route exact={true} path={routes._user_saleEdtimate_Add} component={_user_createSaleEstimate} />
                         
                        <Route exact={true} path={routes._user_saleOrderList} component={_user_saleOrderList} />
                        <Route exact={true} path={routes._user_saleOrder_Add} component={_user_createSaleOrder} />
                        <Route exact={true} path={routes._user_saleDelvChallanList} component={_user_saleDelvChallanList} />
                        <Route exact={true} path={routes._user_saleDelvChallan_Add} component={_user_createSaleDelvChallan} />
                        
                        <Route exact={true} path={routes._user_salePaymentInList} component={_user_salePaymentInList} />
                        <Route exact={true} path={routes._user_salePaymentIn_Add} component={_user_createSalePaymentIn} />

                        <Route exact={true} path={routes._user_saleReturnList} component={_user_saleReturnList} />
                        <Route exact={true} path={routes._user_saleRetCreditNote_Add} component={_user_createSaleReturnCreditNote} />

                        <Route exact={true} path={routes._user_saleReceivePaymentIn_Add} component={_user_createSaleReceivePaymentIn} />
                        <Route exact={true} path={routes._user_saleMakePaymentOut_Add} component={_user_createSaleMakePaymentOut} />
                        
                        {/* <Route exact={true} path={routes._user_saleToReturnCreditNote_Add} component={_user_SaleToReturnCredit} /> */}
                        <Route exact={true} path={routes._user_saleDuplicate_Add} component={_user_createSaleDuplicate} />

                        <Route exact={true} path={routes._user_saleReturnDuplicate} component={_user_SaleDuplicateCredit} />
                        
                        <Route exact={true} path={routes._user_saleOrderDuplicate_Add} component={_user_saleOrderDuplicate_Add} />
                        
                        {/* Purchase Transaction Pages Route */}
                        <Route exact={true} path={routes._user_purchaseInvoice} component={_user_purchaseInvoice} />
                        <Route exact={true} path={routes._user_purchaseInvoice_Add} component={_user_createPurchase} />
                        <Route exact={true} path={routes._user_purchaseInvoice_Add2} component={_user_createPurchase2} />
                        <Route exact={true} path={routes._user_saleInvoice_Add2} component={_user_createSale2} />
                        <Route exact={true} path={routes._user_purchasePaymentOut_Add} component={_user_createPurchasePaymentout} />
                        <Route exact={true} path={routes._user_purchasePaymentOutList} component={_user_purchasePaymentOutList} />
                        <Route exact={true} path={routes._user_purchaseReturnList} component={_user_purchaseReturnList} />
                        <Route exact={true} path={routes._user_purchaseRetDebitNote_Add} component={_user_createPurchaseReturnDebitNote} />
                        <Route exact={true} path={routes._user_purchaseOrder_Add} component={_user_createPurchaseOrder} />
                        <Route exact={true} path={routes._user_purchaseOrderList} component={_user_purchaseOrderList} />

                        <Route exact={true} path={routes._user_purchaseMakePayment} component={_user_purchaseMakePayment} />
                        {/* <Route exact={true} path={routes._user_purchaseToReturn} component={_user_purchaseToReturn} /> */}
                        <Route exact={true} path={routes._user_purchaseToDuplicate} component={_user_purchaseToDuplicate} />
                        <Route exact={true} path={routes._user_purchaseMakePaymentIn} component={_user_purchaseMakePaymentIn} />
                        
                        <Route exact={true} path={routes._user_purchaseToDuplicateReturn} component={_user_purchaseToDuplicateReturn} />
                        <Route exact={true} path={routes._user_purchaseOrderDuplicate} component={_user_purchaseOrderDuplicate} />

                        <Route exact={true} path={routes._user_expense} component={_user_expense} />
                        <Route exact={true} path={routes._user_addExpense} component={_user_addExpense} />
                        <Route exact={true} path={routes._user_addExpenseDuplicate} component={_user_addExpenseDuplicate} />
                        <Route exact={true} path={routes._user_addExpensePayment} component={_user_addExpensePayment} />

                        <Route exact={true} path={routes._user_accountList} component={_user_accountList} />
                        <Route exact={true} path={routes._user_chequeList} component={_user_chequeList} />
                        <Route exact={true} path={routes._user_cashInHandList} component={_user_cashInHandList} />
                        <Route exact={true} path={routes._user_loacAccountList} component={_user_loacAccountList} />
                        <Route exact={true} path={routes._user_loacAccountStatementList} component={_user_loacAccountStatementList} />
                        
                        {/* PDF Pages Route */}
                        <Route exact={true} path="/business/manage-pdf/" component={_user_htmlPdfCreation} />
                        <Route exact={true} path="/business/download-pdf/" component={_user_downloadPDFFile} />
                        <Route exact={true} path="/business/return-challan-pdf/" component={_user_returnChallanPDFFile} />
                        <Route exact={true} path="/business/download-oi-pdf/" component={_user_downloadOIPDFFile} />
                        
                        
                        <Route exact={true} path={routes._business_smsAddListData} component={_user_smsListData} />

                        <Route exact={true} path={routes._user_partyToPartyTransfer} component={_user_partyToPartyTransfer} />

                        <Route exact={true} path={routes._user_reports} component={_user_reports} />
                        <Route exact={true} path={routes._user_allPrefixList} component={_user_allPrefixList} />
                        
                        <Route exact={true} path={routes._user_otherIncomeTransList} component={_user_otherIncomeTransList} />
                        <Route exact={true} path={routes._user_otherIncomeCreateTrans} component={_user_otherIncomeCreateTrans} />
                        <Route exact={true} path={routes._user_otherIncomeList} component={_user_otherIncomeList} />
                        <Route exact={true} path={routes._user_otherDuplicateIncomeCreate} component={_user_otherDuplicateIncomeCreate} />
                        <Route exact={true} path={routes._user_utilitiyImportItemList} component={_user_utilitiyImportItemList} />
                        <Route exact={true} path={routes._user_utilitiyImportPartyList} component={_user_utilitiyImportPartyList} />
                        <Route exact={true} path={routes._user_utilitiyExportItemList} component={_user_utilitiyExportItemList} />
                        <Route exact={true} path={routes._user_utilitiyBulkUpdateItemList} component={_user_utilitiyBulkUpdateItemList} />
                        <Route exact={true} path={routes._user_utilitiyBulkUpdateServiceList} component={_user_utilitiyBulkUpdateServiceList} />
                        <Route exact={true} path={routes._user_utilitiyGenerateBarcode} component={_user_utilitiyGenerateBarcode} />
                        

                        
                    </Switch>
                </div>
            </main>
            <FooterPage />
            </div>
        </div>
    )
  }
}