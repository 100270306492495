import React, { useState, useEffect } from "react";
import { Checkbox } from '@progress/kendo-react-inputs';

const ViewSerialQuntity = ({ callBack, data, isFreeQuantity, businessSetting }) => {

    let [remaingSerialData, setRemaingSerialData] = useState([]);
    let [serialData, setSerialData] = useState([]);

    useEffect(() => {
        setSerialData(data.selectedData);
        setRemaingSerialData(data.itemSerialNo);

    }, []);


    const onCheckedFree = (value, index) => {
        let updatedData = [...remaingSerialData];
        updatedData[index].isFreeQuantity = !updatedData[index].isFreeQuantity;
        if(updatedData[index].isFreeQuantity){
            updatedData[index].isSelected =  false;
        }else{
            //updatedData[index].isSelected =  true;
        }
        setRemaingSerialData(updatedData);
    }
    const onCheckedSelect = (value, index) => {
        let updatedData = [...remaingSerialData];
        updatedData[index].isSelected = !updatedData[index].isSelected;
        if(updatedData[index].isSelected){
            updatedData[index].isFreeQuantity =  false;
        }else{
            //updatedData[index].isFreeQuantity =  true;
        }
        setRemaingSerialData(updatedData);
    }

    const onCheckedDelete = (value, index) => {
        let updatedData = [...serialData];
        updatedData[index].isDeleted = !updatedData[index].isDeleted;
        updatedData[index].isSelected = !updatedData[index].isSelected;
        setSerialData(updatedData);
    }
    const saveSerialData = () => {
        let _remaingSerial = remaingSerialData.map((data) => ({
            ...data,
            isSelected: data.isFreeQuantity ? true : data.isSelected
        }));
        let _selectedData = [...serialData, ..._remaingSerial];
        if (_selectedData.filter((element) => element.isSelected).length == 0) {
            alert('This item must contain at least one serial number. If you do not require it, please delete the item.');
            return;
        } else {
            
            let selectedData = _selectedData.filter((element) => element.isSelected);
            
            let _remaingSerialData = _selectedData.filter((element) => !element.isSelected);
            _remaingSerialData = _remaingSerialData.map((data) => ({
                ...data,
                isFreeQuantity: data.isDeleted ? false : data.isFreeQuantity
            }));
            
            _remaingSerialData = _remaingSerialData.map(({ isDeleted, ...rest }) => rest);

            let selectedFreeData = selectedData.filter((element) => element.isFreeQuantity);
            let selectedNotFreeData = selectedData.filter((element) => !element.isFreeQuantity);
            
            if(selectedNotFreeData == 0){
                alert('Please un select at least one non-free serial number.');
                return;
            }
            
            let updatedData = {};
            updatedData = {
                ...data,
                itemSerialNo: _remaingSerialData,
                selectedData: selectedData,
                quantity: selectedNotFreeData.length,
                free_quantity: selectedFreeData.length,
                trackingType: 'Serial',
                isNewQty: false
            }
            callBack(updatedData, isFreeQuantity);
        }
    }
    let clearBottomBorder = { border: "none", outline: "none", background: "transparent" };
    return (
        <div className="webForm px-30" style={{maxHeight: 600, overflowY: 'auto', marginBottom: 20}}>
        <div style={{ marginBottom: '20px' }}>
            
            {
                serialData.map((element, index) => {
                    return (
                        <div className="form-material row" key={index} style={{border: '2px solid #ccc', marginBottom: 5, paddingTop: 0, padding: 15}}>
                            <div>
                                    <span htmlFor="login-username" 
                                        style={{
                                            position: 'absolute',
                                            left: '0px',
                                            top: '0px',
                                            padding: '5px',
                                            borderBottomRightRadius: 10,
                                            backgroundColor: 'thistle'
                                        }}>{index+1}</span>
                                </div>
                            {businessSetting.SerialNoChk == 1 &&
                                <div className="col-3">
                                    <div className="form-material open" >
                                        <input
                                            type="text"
                                            style={clearBottomBorder}
                                            value={element.serial_no}
                                            placeholder={businessSetting.SerialNoTxt}
                                            readOnly
                                        />
                                        <label htmlFor="login-username">{businessSetting.SerialNoTxt} </label>
                                        
                                    </div>
                                </div>
                            }
                            {businessSetting.SerialNoAdditionalChk1 == 1 &&
                                <div className="col-3">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={clearBottomBorder}
                                            value={element.serialNoAdditionalValue1}
                                            placeholder={businessSetting.SerialNoAdditionalLabel1}
                                            readOnly
                                        />
                                        <label htmlFor="login-username">{businessSetting.SerialNoAdditionalLabel1}</label>

                                    </div>
                                </div>
                            }
                            {businessSetting.SerialNoAdditionalChk2 == 1 &&
                                <div className="col-3">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={clearBottomBorder}
                                            value={element.serialNoAdditionalValue2}
                                            placeholder={businessSetting.SerialNoAdditionalLabel2}
                                            readOnly
                                        />
                                        <label htmlFor="login-username">{businessSetting.SerialNoAdditionalLabel2}</label>
                                    </div>
                                </div>


                            }
                             <div>
                                    {
                                        element.isFreeQuantity &&
                                        <span htmlFor="login-username" 
                                        style={{
                                            position: 'absolute',
                                            right: '0px',
                                            top: '0px',
                                            padding: '5px',
                                            backgroundColor: 'thistle'
                                        }}>FREE</span>
                                    }
                                </div>
                            <div className="col-2">
                                <div className="form-material open">
                                    <Checkbox
                                        label={'Delete'}
                                        checked={element.isDeleted}
                                        onChange={e => onCheckedDelete(e.target.value, index)}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {
                remaingSerialData.length > 0 && 
                <div>
                    <div
                        style={{
                        width: '100%',        // Full width of its parent
                        height: '1px',        // Line thickness
                        backgroundColor: '#e2e2e2', // Line color (black in this case)
                        margin: '20px 0',      // Optional: Add some margin around the line
                        }}
                    ></div>
                    <div style={{marginTop: 10, marginBottom: 0}}>
                        <h5> Remaining Serial </h5>
                    </div>
                </div>
            }

            {
                remaingSerialData.map((element, index) => {
                    return (
                        <div className="form-material row" key={index} style={{border: '2px solid #ccc', marginBottom: 5, paddingTop: 0, padding: 15}}>
                            {businessSetting.SerialNoChk == 1 &&
                                <div className="col-3">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={clearBottomBorder}
                                            value={element.serial_no}
                                            placeholder={businessSetting.SerialNoTxt}
                                            readOnly
                                        />
                                        <label htmlFor="login-username">{businessSetting.SerialNoTxt} </label>
                                    </div>
                                </div>
                            }
                            {businessSetting.SerialNoAdditionalChk1 == 1 &&
                                <div className="col-3">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={clearBottomBorder}
                                            value={element.serialNoAdditionalValue1}
                                            placeholder={businessSetting.SerialNoAdditionalLabel1}
                                            readOnly
                                        />
                                        <label htmlFor="login-username">{businessSetting.SerialNoAdditionalLabel1}</label>

                                    </div>
                                </div>
                            }
                            {businessSetting.SerialNoAdditionalChk2 == 1 &&
                                <div className="col-3">
                                    <div className="form-material open">
                                        <input
                                            type="text"
                                            style={clearBottomBorder}
                                            value={element.serialNoAdditionalValue2}
                                            placeholder={businessSetting.SerialNoAdditionalLabel2}
                                            readOnly
                                        />
                                        <label htmlFor="login-username">{businessSetting.SerialNoAdditionalLabel2}</label>
                                    </div>
                                </div>


                            }
                            <div className="col-2" >
                                <div className="form-material open">
                                    <Checkbox
                                        label={'Select'}
                                        checked={element.isSelected}
                                        onChange={e => onCheckedSelect(e.target.value, index)}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </div>
        <div className="row" >
                    <div className="col-12">
                        <button className="btn btn-primary" onClick={saveSerialData}>Save</button>
                    </div>
                </div>
        </div>

    )
}
export default ViewSerialQuntity