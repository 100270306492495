import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import validator from "validator";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewParty from "../businessParty/quickPartyAdd";
import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import QuickAddNewPrefix from "../userSale/quickNewPrefixAdd";
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import { Link } from "react-router-dom";
import QuickTransactionSaleItemSection from "./quickTransactionSaleItemSection";
import QuickAdjustAddNewTerms from "../userExpense/quickAddTerms";
//import QuickLowStockItems from '../userSale/quickLowStockItems';
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _loader = "glb-ldr-prt active";
let _isEditMode = false;
let maxNumber = 999999;
let lengthInvNo = 6;
let _invoiceNumber = Math.floor(Math.pow(10, lengthInvNo - 1) + Math.random() * (Math.pow(10, lengthInvNo) - Math.pow(10, lengthInvNo - 1) - 1));


let _colorVal = "";
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _company_Logo = "";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _settingType = "showall";
let _bankArray = [];
let _businessSetting = {};
_bankArray.push(
  { bank_id: "0", bank_name: "", paymentType: "Cash" },
  { bank_id: "0", bank_name: "", paymentType: "Cheque" }
);

export default class manageCreateSale extends Component {
  constructor(props) {
    super(props);
    let _UserSession = localStorage.getItem("vs_UserSession");

    if (_UserSession!=undefined || _UserSession!=null) {
      if (_UserSession.loginType!="Admin") {
        _UserSession = JSON.parse(_UserSession);
        _signingId = _UserSession.loginId;
        _businessId = _UserSession.tokenKey;
        _company_id = _UserSession.company_id;
        _businessSetting = _UserSession.businessSetting;
        if (_businessSetting!=undefined || _businessSetting!=null) {
          if (_businessSetting.PrintOriginalDuplicate == 0) {
            _settingType = null;
          }
        }
      }
    }
    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;
      _company_Logo = _companySession.company_logo;
      let bankArrayData = _companySession.bankDetails ? _companySession.bankDetails : [];
      bankArrayData.map((bank, index) => {
        if (
          !(
            _bankArray.filter((item) => item.bank_name == bank.bank_name)
              .length > 0
          )
        ) {
          _bankArray.push(bank);
        }
      });
      bankArrayData.map((bank, index) => {
      });
    }
    /*For State Data*/
    this.state = {
      buttonTitle: "Add Sale",
      toggleState: _businessSetting.CashSaleToggle > 0 ? true : false,
      //toggleState : (_businessSetting.CashSaleToggle > 0) ? false : true,
      isMakePayment: false,
      isItemCount: 0,
      _partyReg: "iti__hide",
      _timeReg: "iti__hide",

      poDate: moment().format("MM/DD/YYYY"),
      dueOnDate: moment().format("MM/DD/YYYY"),
      dueTerms: "",
      dueDays: 0,

      poNumber: "",
      E_Way_Bill_No: "",
      billing_name: "",
      billing_address : "",
      invoiceTime: _businessSetting.Tran_AddTime > 0 ? moment().format("h:mm A") : moment().format("h:mm A"),

      partyRemainBalance: 0,
      party_id: 0,
      party_name: "",
      is_party: "",
      partyArrayFilter: [],
      partyArray: [],
      stateArray: [],
      paymentTermsData: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],

      modalIsOpen_SP: false,
      modalIsOpen_SBANK: false,
      modalIsOpen_ADDPREFIX: false,

      selectTypeTxt: "Normal",
      normalQtyField: 0,
      popupShow: 0,
      itemPosition: -1,
      selectType: 0, // 0 = not 1=both 2=both but data empty
      normal_quantityAdded: 0,
      batchSendType: "New",
      btnShowSerialNormalBatch: 0, //0 =notserial 1=serial 2=batch

      _itemReg: "iti__hide",
      _itemRegIndex: "0",

      isLoaded: true,
      isDateUpdate: false,

      modalIsOpen_LowStock: false,
      isChecked: false,
      lowStokItemName: "",

      receivedHide: "iti__hide",

      rowHtml: "",
      countryCode: _countryCode,
      countryName: _countryName,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      paymentType: "Cash",

      settingType: _settingType,
      invoiceNo: _invoiceNumber,
      rate_tax: "With Tax",
      invoiceDate: moment().format("MM/DD/YYYY"),
      countryState: "",

      paymentRefNo: "",
      description: "",
      descImage: "",
      imagePreviewUrl: [],

      bankId: "0",
      bankName: "",

      dueAmount: 0,
      receivedAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      sendFreeQty: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: 0,
      totalAmountOld: 0,
      totalAmountNew: 0,
      totalTransDiscountAmount: 0.0,
      totalTransTaxAmount: 0.0,
      transactionWiseTaxId: 0,
      EnableItem: _businessSetting.EnableItem,
      DisplayPurchasePrice: _businessSetting.DisplayPurchasePrice,
      FreeItemQuantity: _businessSetting.FreeItemQuantity,
      TransCount: _businessSetting.Count,
      TransAdditionalCharge1: 0,
      TransAdditionalCharge2: 0,
      TransAdditionalCharge3: 0,
      TransportDetailField1: "",
      TransportDetailField2: "",
      TransportDetailField3: "",
      TransportDetailField4: "",
      TransportDetailField5: "",
      TransportDetailField6: "",
      batchItemEnable: 0,
      serialItemEnable: 0,
      normal_remaining_quantity: 0,
      OldChnageQuantity: 0,
      OldUsedStockQty: 0,
      popuptype: "",
      popupVal: [],

      prefixId: 0,
      prefixName: "",
      prefixArray: [],
      handleAddDisable: "",
      handleItemSalePurchasePrice: "",
      party_contact_no: "",
      prefixD: 1,
      additional_value_1: "",
      additional_value_2: "",
      additional_value_3: "",
      additional_value_date: moment().format("MM/DD/YYYY"),
      pageLoadTerms: 0,
      dateCalendarChange: 0,
      transactionType: "",
      totalLinkHistoryAmt: 0,
      modalIsOpen_AddTerm: false,
      transactionWiseTaxIdPer : 0,
      isReturn : false,
      cheque_open_status : 0,
      errors: {
        invoiceNo: "",
        invoiceDate: "",
        party_id: "",
        party_name: "",
        E_Way_Bill_No: "",
        party_contact_no: "",
      },
    };
    this.handleChecked = this.handleChecked.bind(this);
  }

  async componentDidMount() {
    let _terms_days = 0;
    let _handleParam = this.props.location.search;
    let _transactionType = "";
    let transactionId = 0;
    _handleParam = _handleParam.split("?auth=");
    if (_handleParam.length > 1) {
      _handleParam = _handleParam[1].split("&");
      transactionId = _handleParam[0] ? _handleParam[0] : 0;
      if (transactionId > 0) {
        _isEditMode = true;
      }
      _transactionType = _handleParam[1].split("tType=");
      _transactionType = _transactionType[1];
    }
    

    /*Due Dates terms setting data */
    let _selTermsId = 0;
    const requestDueDateData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });

    PostData(global.businessDueDatePaymentTermList, requestDueDateData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
          }
          let _mainResponse = responseJson.response;
          this.setState({
            isLoaded: true,
            paymentTermsData: responseJson.response,
          });
          _mainResponse.map((item, index) => {
            if (item.terms_default_status == 1) {
              _selTermsId = item.id;
              _terms_days = item.terms_days;
              
              let dueDateOnload = moment().add(_terms_days, "days").format("MM/DD/YYYY");
              this.setState({ dueDays: _terms_days, dueOnDate: dueDateOnload });
              this.state.dueDays = _terms_days;
            }
          });
        }
    })
      .catch((error) => this.setState({ error, isLoaded: false }));
    /* End Due Dates terms setting data */

    //this.getSettingSetData(_businessId);
    this.setState({
      invoiceDate: moment().format("MM/DD/YYYY"),
      poDate: moment().format("MMMM D, YYYY"),
      dueTerms: _selTermsId,
    });

    //this.getBankListData();
    this.getStateListData();
    //this.getItemListData(_company_id);

    /*For master Units*/
    let _urlDataUnits =
      global.userUnitList +
      "?businessId=" +
      _businessId +
      "&company_id=" +
      this.state.company_id;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    /*For master Units*/

    this.setState({ unitArray: _unitArray, isLoaded: true });

    /*Set Setting Data */
    let _prefixSetArray = [];
    let _SalePrefixName = "";
    let _SalePrefixID = "";
    const requestData = JSON.stringify({
      businessId: _businessId,
    });
    PostData(global.businessPrefixSetData, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _prefixSetArray = responseJson.response;
            _SalePrefixName = _prefixSetArray[0].SalePrefixName;
            _SalePrefixID = _prefixSetArray[0].SalePrefixId;
          }
          this.setState({
            prefixName: _SalePrefixName,
            prefixId: _SalePrefixID,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
    /*Set Setting Data */

    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href = global.webUrl + "business/sale-invoice/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
            //this.setState({unitArray:_unitArray,isLoaded:true});
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];

              let transAmout = _transactionArray.totalSummary;
              let _prefixDataUpdate = _transactionArray.prefix;
              let itemsData = _transactionArray.items;
              let customerData = _transactionArray.customer;
              let _batchItemEnable = 0;
              let _serialItemEnable = 0;
              itemsData.map((_itemsBSData, index) => {
                if (
                  _itemsBSData.itemBatchNoAllData &&
                  _itemsBSData.itemBatchNoAllData.length > 0
                ) {
                  _batchItemEnable = 1;
                  //_serialItemEnable = 1;
                }
                if (
                  _itemsBSData.itemSerialNoAllData &&
                  _itemsBSData.itemSerialNoAllData.length > 0
                ) {
                  _serialItemEnable = 1;
                  //_batchItemEnable = 1;
                }
              });

              let _imgPath = "";
              if (_transactionArray.descImage!="") {
                _imgPath =
                  global.LIVE_URL +
                  "uploadDirectory/transaction/" +
                  _transactionArray.descImage;
              }
              let _paymentRefNo = _transactionArray.paymentRefNo;
              if (
                _transactionArray.paymentRefNo == "null" ||
                _transactionArray.paymentRefNo == null
              ) {
                _paymentRefNo = "";
              }
              let _isSaleCash = false;
              if(parseFloat(_transactionArray.isToggle) ==  1){
                _isSaleCash = true;
              }
              
              
              let _additional_value_date =  _transactionArray.additional_value_date;
              if(_transactionArray.additional_value_date == ''){
                _additional_value_date = moment().format("MM/DD/YYYY");
              }
              let _dateCalendarChange = 0
              if(_transactionArray.dueTerms == 0){
                _dateCalendarChange = 1;
              }
              this.setState({
                _partyReg: "iti__hide",
                billing_name: customerData.billing_name ? customerData.billing_name : "",
                billing_address :  _transactionArray.billingAddress,
                toggleState: _isSaleCash,
                invoiceTime: _transactionArray.invoice_time,
                poNumber: _transactionArray.po_number,
                E_Way_Bill_No: _transactionArray.E_Way_Bill_No,
                poDate: moment(_transactionArray.po_date).format("MM/DD/YYYY"),
                isItemCount: _transactionArray.isItemNumber,
                isMakePayment: _transactionArray.isMakePayment,
                party_id: customerData.party_id,
                party_name: customerData.party_name,
                bankId: _transactionArray.bankId,
                bankName: _transactionArray.bankName,
                prefixId: _prefixDataUpdate.id,
                prefixName: _prefixDataUpdate.name,
                unitArray: _unitArray,
                buttonTitle: "Edit Sale",
                invoiceNo: _transactionArray.invoiceNo,
                invoiceDate: moment(_transactionArray.invoiceDate).format("MM/DD/YYYY"),
                state: _transactionArray.countryState,
                countryState: _transactionArray.countryState,
                dueTerms: _transactionArray.dueTerms,
                dueOnDate: moment(_transactionArray.dueOnDate).format("MM/DD/YYYY"),
                dateCalendarChange :  _dateCalendarChange,
                businessId: _transactionArray.business_id,
                companyId: _transactionArray.company_id,
                //:parseFloat(transAmout.totalAmount)+parseFloat(transAmout.gstAmount),
                totalAmount: parseFloat(transAmout.totalAmount),
                totalAmountOld: transAmout.totalAmount,
                receivedAmount: transAmout.receivedAmount,
                subtotal: transAmout.subtotal,
                gstRatio: transAmout.gstRatio,
                gstAmount: transAmout.gstAmount,
                totalQuantity: transAmout.totalQty,
                totalDiscount: transAmout.totalDiscount,
                dueAmount: transAmout.dueAmount,
                description: _transactionArray.description,
                paymentType: _transactionArray.paymentType,
                paymentRefNo: _paymentRefNo,
                fields: itemsData,
                isLoaded: true,
                transactionId: transactionId,
                descImage: _transactionArray.descImage,
                imagePreviewUrl: _imgPath,
                isDateUpdate: true,
                EnableItem: _transactionArray.EnableItem,
                DisplayPurchasePrice: _transactionArray.DisplayPurchasePrice,
                //FreeItemQuantity :_transactionArray.FreeItemQuantity,
                //TransCount :_transactionArray.Count,
                settingType: _transactionArray.settingType,
                transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                totalTransDiscountAmount: _transactionArray.totalTransDiscountAmount,
                TransAdditionalCharge1: _transactionArray.TransAdditionalCharge1,
                TransAdditionalCharge2: _transactionArray.TransAdditionalCharge2,
                TransAdditionalCharge3: _transactionArray.TransAdditionalCharge3,
                TransportDetailField1: _transactionArray.TransportDetailField1,
                TransportDetailField2: _transactionArray.TransportDetailField2,
                TransportDetailField3: _transactionArray.TransportDetailField3,
                TransportDetailField4: _transactionArray.TransportDetailField4,
                TransportDetailField5: _transactionArray.TransportDetailField5,
                TransportDetailField6: _transactionArray.TransportDetailField6,
                batchItemEnable: _batchItemEnable,
                serialItemEnable: _serialItemEnable,
                totalAmountNew: transAmout.totalAmount,
                party_contact_no: _transactionArray.party_contact_no,
                additional_value_1: _transactionArray.additional_value_1,
                additional_value_2: _transactionArray.additional_value_2,
                additional_value_3: _transactionArray.additional_value_3,
                additional_value_date: moment(_additional_value_date).format("MM/DD/YYYY"),
                transactionType: _transactionType,
                totalLinkHistoryAmt: _transactionArray.totalLinkHistoryAmt,
                pageLoadTerms: 1,
                isReturn : _transactionArray.isReturn,
                cheque_open_status : _transactionArray.cheque_open_status,
                
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }

    this.getPartyListData();
    this.businessPrefixList();

  }

  getStateListData() {
    let _stateArray = [];

    let _url_GetData =
      global.adminStateList + "?countryCode=" + this.state.countryCode;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _stateArray = responseJson.response;
          }
          this.setState({ stateArray: _stateArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  businessPrefixList() {
    let _prefixArray = [];
    const requestData = JSON.stringify({
      company_id: _company_id,
      businessId: _businessId,
      type: "SALE",
    });
    PostData(global.businessWebPrefixList, requestData, "POST")
      .then(async(result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true, prefixD: 1 });
        } else {
          if (responseJson.response) {
            _prefixArray = responseJson.response;
            
            _prefixArray = _prefixArray.filter(
              (itemData) => itemData.prefix!=null
            );
          }

          let _prefixSelData =  _prefixArray.filter((item) => item.isSelected == true)
          
          await this.setState({prefixArray: _prefixArray})
          this.setState({
            prefixArray: _prefixArray,
            isLoaded: true,
            prefixD: 1,
            prefixName: _prefixSelData[0].prefix ? _prefixSelData[0].prefix : '',
            prefixId: _prefixSelData[0].prefix_id >0 ? _prefixSelData[0].prefix_id : 0,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getPartyListData() {
    let _partyArray = [];
    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _partyBal = 0;
          if (responseJson.response) {
            _partyArray = responseJson.response;

            if (this.state.party_id > 0) {
              _partyArray.map((party, index) => {
                if (party.party_id == this.state.party_id) {
                  _partyBal = party.totalOutCome;
                }
              });
            }
          }
          this.setState({
            partyArrayFilter: _partyArray,
            partyArray: _partyArray,
            isLoaded: true,
            partyRemainBalance: _partyBal,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getTotalAmount(value, _type) {
    let _mainSubTotalAmt = 0;
    let _totalOtherCharges = 0;
    this.state.fields.map((data) => {
      _mainSubTotalAmt += parseFloat(data.total_amount) + parseFloat(data.tax_value) - parseFloat(data.discount_in_amount);
    });
    let _totalTaxAmount = _mainSubTotalAmt;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1
      ? this.state.TransAdditionalCharge1
      : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2
      ? this.state.TransAdditionalCharge2
      : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3
      ? this.state.TransAdditionalCharge3
      : 0;

    let _totalTransTaxAmount = this.state.totalTransTaxAmount
      ? this.state.totalTransTaxAmount
      : 0;

    let _totalTranDisAmount = this.state.totalTransDiscountAmount
      ? this.state.totalTransDiscountAmount
      : 0;

    if (_type == "totalTransDiscountPer")
    {
      _totalTranDisAmount = (parseFloat(_totalTaxAmount)) * ((parseFloat(value)) / parseFloat(100));
    }
    if (_type == "totalTransDiscountAmount")
    {
      _totalTranDisAmount = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    if (_type == "taxTypeTrans") {
      _totalTransTaxAmount = parseFloat(value);
    }

    let _totalAmount = parseFloat(_totalTaxAmount) - parseFloat(_totalTranDisAmount);

    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3) + parseFloat(_totalTransTaxAmount);

    _totalAmount = parseFloat(_totalAmount) + parseFloat(_totalOtherCharges);

    return _totalAmount;
  }

  getTotalDueAmount(_totalAmount) {
    let _dueAmountAK = _totalAmount;
    if (this.state.receivedAmount > 0) {
      _dueAmountAK =
        parseFloat(_totalAmount) - parseFloat(this.state.receivedAmount);
    }
    if (_dueAmountAK < 0) {
      _dueAmountAK = 0;
    }
    return _dueAmountAK;
  }

  //Party Wise Item Rate Ak
  getItemListPartyData(company_id, partyId) {
    let _itemsArray = [];
    const requestData = JSON.stringify({
      company_id: company_id,
      partyId: partyId,
    });
    PostData(global.userItemSalePurPartyList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _itemsArray = responseJson.response;
            _itemsArray.map((field, index) => {
              _itemsArray[index].isFilter = false;
            });
          }
          this.setState({
            itemArrayFilter: _itemsArray,
            itemArray: _itemsArray,
            isLoaded: true,
            totalQuantity: 0,
            gstAmount: 0,
            totalAmount: 0,
            totalAmountNew: 0,
            totalDiscount: 0,
            serialItemEnable: 0,
            batchItemEnable: 0,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target; 
    let errors = this.state.errors;
    
    switch (name) {
      case "invoiceNo":
        errors.invoiceNo =
          value.length < 0 ? "*Invoice No Must be 4 numbers" : "";
        if (validator.isInt(value)) {
          errors.invoiceNo = "";
        } else {
          errors.invoiceNo = "*Please enter only numeric value.";
        }
        this.setState({ invoiceNo: value });
        break;

      /*case "invoiceDate":
        errors.invoiceDate =
          value.length < 5 ? "* Invoice date must be selected" : "";
        this.setState({ invoiceDate: value });
        
        break;*/

      case "countryState":
        errors.countryState =
          value.length < 0 ? "* State must be selected" : "";
        this.setState({ countryState: value });
        break;

      case "dueTerms":
        
      if (value == "ADDTERMS") {
        this.openModalHandler("modalAdjustAddNewTerms");
      }
      else {
        let mainVal = value;
        mainVal = mainVal.split(",");
        errors.dueTerms = value.length < 0 ? "* Due terms must be selected" : "";
        let _invDate = moment(this.state.invoiceDate).format("MM/DD/YYYY");
        let newDueDate = moment(_invDate).add(mainVal[1], "days").format("MM/DD/YYYY");
        this.setState({
          dueOnDate: newDueDate,
          startDate: newDueDate,
          pageLoadTerms: 1,
          dateCalendarChange: 0,
          dueDays : mainVal[1],
          dueTerms: mainVal[0] 
        });
        this.isDueOnDateDateRefreshOnChange(newDueDate);
      }
      break;

      case "party_contact_no":
        if (validator.isMobilePhone(value)) {
          errors.party_contact_no = "";
        } else {
          if(value.length == 0 || value == ''){
            errors.party_contact_no = "";
          }else{
            errors.party_contact_no = "*Please enter valid mobile number!";
          }
        }
        this.setState({ party_contact_no: value });
      break;

      case "paymentType":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        let _valType = "";

        if (this.state.transactionId > 0) {
          this.setState({ paymentRefNo: "" });
        }
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        
        this.setState({
          paymentType: _valType,
          bankId: _bankId,
          bankName: _bankName,
        });
        break;

      case "receivedAmount":
        let _remainAmount = 0;
        let _paidAmt = value;
        if (_paidAmt == "") {
          _paidAmt = 0;
        }
        if (value >= 0) {
          _remainAmount =
            parseFloat(this.state.totalAmount) - parseFloat(_paidAmt);
        }
        this.setState({
          receivedAmount: _paidAmt,
          dueAmount: _remainAmount.toFixed(2),
        });
        break;
      case "paymentRefNo":
        errors.paymentRefNo =
          value.length < 1 ? "* Payment must be selected" : "";
        this.setState({ paymentRefNo: value });
        break;
      case "description":
        this.setState({ description: value });
        break;

      case "billing_name":
        this.setState({ billing_name: value });
        break;
      case "billing_address":
        this.setState({ billing_address: value });
        break;
        
      case "poNumber":
        this.setState({ poNumber: value });
        break;
      case "E_Way_Bill_No":
        if (validator.isInt(value)) {
          errors.E_Way_Bill_No = "";
        } else {
          errors.E_Way_Bill_No = "*Please enter only numeric value.";
        }
        this.setState({ E_Way_Bill_No: value });
        break;

      case "poDate":
        this.setState({ poDate: value });
        break;
      case "rate_tax":
        this.setState({ rate_tax: value });
        //console.log(value+'===');
        break;

      case "totalAmount":
        let _balanaceDue = this.state.dueAmount;
        if (this.state.fields.length == 0) {
          //_balanaceDue = value;
          _balanaceDue =
            parseFloat(value) - parseFloat(this.state.receivedAmount);
        }
        this.setState({
          totalAmount: value,
          dueAmount: _balanaceDue,
          totalAmountOld: value,
        });
        //console.log(this.state.totalAmount+'==>>=='+value);
        break;

      
      case "totalTransDiscountPer":
              
      if (!validator.isInt(value)) {    
        let _mainSubTotalAmt = 0;
        this.state.fields.map((data) => {
          _mainSubTotalAmt += parseFloat(data.total_amount);
        });
        this.setState({            
          totalAmount: _mainSubTotalAmt,
          totalTransDiscountAmount: 0,
          totalTransDiscountPer: 0,
          dueAmount: _mainSubTotalAmt,
          transactionWiseTaxId  : 0,
        });        
        //alert("Charges can not be less than zero or character");
        return;
      }else{  
        let _valueSend = value;
        if (value > 100) {
          alert("Discount cannot be more than 100%");
        } 
        else {
          if (_valueSend == "" || _valueSend == 0) {
            _valueSend = 0;
          }
          let _mainSubTotalAmt = 0;
          this.state.fields.map((data) => {
            _mainSubTotalAmt += parseFloat(data.total_amount);
          });
          let _finalTotalAmt = _mainSubTotalAmt;
          let _payableAmount = 0;

          let _totalTranDiscPerAmount = (parseFloat(_mainSubTotalAmt) * parseFloat(_valueSend)) / parseFloat(100);

          let _afterDisTotAmt = (parseFloat(_finalTotalAmt) - parseFloat(_totalTranDiscPerAmount)).toFixed(2);
          
          let _percGstAmt = 0;
          if(this.state.transactionWiseTaxIdPer > 0){
            _percGstAmt = (parseFloat(_afterDisTotAmt) * parseFloat(this.state.transactionWiseTaxIdPer)) / parseFloat(100);
            _percGstAmt = _percGstAmt.toFixed(2)
          }
          _finalTotalAmt = parseFloat(_afterDisTotAmt) + parseFloat(_percGstAmt); 

          let _totalAdditionalCharges = parseFloat(this.state.TransAdditionalCharge1) + parseFloat(this.state.TransAdditionalCharge2) + parseFloat(this.state.TransAdditionalCharge3); 
          
          _finalTotalAmt = parseFloat(_finalTotalAmt) + parseFloat(_totalAdditionalCharges);  
          _payableAmount = _finalTotalAmt;   
          
          let _receivedAmount = this.state.receivedAmount;
          if(value == 100 && this.state.transactionId >0){
            _receivedAmount = 0;
          }
          if(_finalTotalAmt <= 0 && this.state.transactionId >0){
            _receivedAmount = 0;
          }

          this.setState({
            totalAmount: _finalTotalAmt.toFixed(2),
            totalTransDiscountAmount: _totalTranDiscPerAmount.toFixed(2),
            totalTransDiscountPer: _valueSend,
            dueAmount: _payableAmount.toFixed(2),
            totalTransTaxAmount: _percGstAmt,
            receivedAmount: _receivedAmount,
            transactionWiseTaxId  : 0,
          });

          if (_valueSend <= 0 || _valueSend == "") {
              this.setState({
                totalAmount: _finalTotalAmt,
                totalTransDiscountAmount: 0,
                totalTransDiscountPer: 0,
                dueAmount: _finalTotalAmt,
                receivedAmount: _receivedAmount,
                transactionWiseTaxId  : 0,
              });
            }
          }
        }
      break;
      
      case "totalTransDiscountAmount":
        if (!validator.isInt(value)) {  
          
          /*let _mainSubTotalAmt = 0;
          this.state.fields.map((data) => {
            _mainSubTotalAmt += parseFloat(data.total_amount);
          });
          this.setState({            
            totalAmount: _mainSubTotalAmt,
            totalTransDiscountAmount: 0,
            totalTransDiscountPer: 0,
          });               
          alert("Charges can not be less than zero or character");
          return;*/
          let _value = value;
          let _mainSubTotalAmt = 0;
          this.state.fields.map((data) => {
            _mainSubTotalAmt += parseFloat(data.total_amount);
          });
          if (parseFloat(_value) > parseFloat(_mainSubTotalAmt)) {
            alert("Discount amount cannot be more than total amount.");
          }
            else {
            if (_value == "" || _value == undefined || _value == null) {
              _value = 0;
            }
              
            let _finalTotalAmt = _mainSubTotalAmt;
            let _payableAmount = 0;

            let _totalTranDiscPerAmount = parseFloat(_value * 100) / parseFloat(_mainSubTotalAmt);
            
            let _afterDisTotAmt = (parseFloat(_finalTotalAmt) - parseFloat(_value)).toFixed(2);
            let _percGstAmt = 0;
            if(this.state.transactionWiseTaxIdPer > 0){
              _percGstAmt = (parseFloat(_afterDisTotAmt) * parseFloat(this.state.transactionWiseTaxIdPer)) / parseFloat(100);
              _percGstAmt = _percGstAmt.toFixed(2)
            }
            
            _finalTotalAmt = parseFloat(_afterDisTotAmt) + parseFloat(_percGstAmt); 

            let _totalAdditionalCharges = parseFloat(this.state.TransAdditionalCharge1) + parseFloat(this.state.TransAdditionalCharge2) + parseFloat(this.state.TransAdditionalCharge3); 

            _finalTotalAmt = parseFloat(_finalTotalAmt) + parseFloat(_totalAdditionalCharges);
            _payableAmount = _finalTotalAmt.toFixed(2);
            

            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              totalTransDiscountPer: _totalTranDiscPerAmount.toFixed(2),
              totalTransDiscountAmount: value,
              dueAmount: _payableAmount,
              totalTransTaxAmount: _percGstAmt,
              transactionWiseTaxId  : 0,
            });

            if (_value <= 0 || _value == "") {
              this.setState({
                totalAmount: _finalTotalAmt,
                totalTransDiscountAmount: _value,
                totalTransDiscountPer: 0,
                transactionWiseTaxId  : 0,
              });
            }
          }
        }else{  
          
          let _value = value;
          let _mainSubTotalAmt = 0;
          this.state.fields.map((data) => {
            _mainSubTotalAmt += parseFloat(data.total_amount);
          });
          if (parseFloat(_value) > parseFloat(_mainSubTotalAmt)) {
            alert("Discount amount cannot be more than total amount.");
          }
            else {
            if (_value == "" || _value == undefined || _value == null) {
              _value = 0;
            }
              
            let _finalTotalAmt = _mainSubTotalAmt;
            let _payableAmount = 0;

            let _totalTranDiscPerAmount = parseFloat(_value * 100) / parseFloat(_mainSubTotalAmt);
            
            let _afterDisTotAmt = (parseFloat(_finalTotalAmt) - parseFloat(_value)).toFixed(2);
            let _percGstAmt = 0;
            if(this.state.transactionWiseTaxIdPer > 0){
              _percGstAmt = (parseFloat(_afterDisTotAmt) * parseFloat(this.state.transactionWiseTaxIdPer)) / parseFloat(100);
              _percGstAmt = _percGstAmt.toFixed(2)
            }
            
            _finalTotalAmt = parseFloat(_afterDisTotAmt) + parseFloat(_percGstAmt); 

            let _totalAdditionalCharges = parseFloat(this.state.TransAdditionalCharge1) + parseFloat(this.state.TransAdditionalCharge2) + parseFloat(this.state.TransAdditionalCharge3); 

            _finalTotalAmt = parseFloat(_finalTotalAmt) + parseFloat(_totalAdditionalCharges);
            _payableAmount = _finalTotalAmt.toFixed(2);
            

            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              totalTransDiscountPer: _totalTranDiscPerAmount.toFixed(2),
              totalTransDiscountAmount: value,
              dueAmount: _payableAmount,
              totalTransTaxAmount: _percGstAmt,
              transactionWiseTaxId  : 0,
            });

            if (_value <= 0 || _value == "") {
              this.setState({
                totalAmount: _finalTotalAmt,
                totalTransDiscountAmount: _value,
                totalTransDiscountPer: 0,
                transactionWiseTaxId  : 0,
              });
            }
          }
        }  
        
      break;         
      
      case "TransAdditionalCharge1":
        
        if (!validator.isInt(value)) { 
          let _finalTotalAmt_1 = this.getTotalAmountAdditionCharge(0, "TransAdditionalCharge1");
          let _finalDueAmt_1 = this.getTotalRemainAmountAdditionCharge(0, "TransAdditionalCharge1");
          this.setState({            
            totalAmount: _finalTotalAmt_1.toFixed(2),
            TransAdditionalCharge1: 0,
            dueAmount: _finalDueAmt_1.toFixed(2),
          });         
          //alert("Charges can not be less than zero or character");
          //return;
        }else{            
          if (value < 0) {
            alert("Charges can not be less than zero");
          }
          else{ 
            let _val1 = value;
            if (_val1 == "" || _val1 == undefined || _val1 == null) {
              _val1 = 0;
            }
            let _finalTotalAmt = this.getTotalAmountAdditionCharge(_val1, "TransAdditionalCharge1");
            let _finalDueAmt = this.getTotalRemainAmountAdditionCharge(_val1, "TransAdditionalCharge1");

            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              TransAdditionalCharge1: value,
              dueAmount: _finalDueAmt.toFixed(2),
            });

            if (_val1 <= 0 || _val1 == "") {
              this.setState({
                TransAdditionalCharge1: _val1,
              });
            }
          }
        }

        break;

      case "TransAdditionalCharge2":
          if (!validator.isInt(value)) {              
            let _finalTotalAmt_1 = this.getTotalAmountAdditionCharge(0, "TransAdditionalCharge2");
            let _finalDueAmt_1 = this.getTotalRemainAmountAdditionCharge(0, "TransAdditionalCharge2");
            this.setState({            
              totalAmount: _finalTotalAmt_1.toFixed(2),
              TransAdditionalCharge2: 0,
              dueAmount: _finalDueAmt_1.toFixed(2),
            });              
            //alert("Charges can not be less than zero or character");
            return;
          }else{            
            if (value < 0) {
              alert("Charges can not be less than zero");
            }
            else{              
              let _val2 = value;        
              if (_val2 == "" || _val2 == undefined || _val2 == null) {
                _val2 = 0;
              }
              
              let _finalTotalAmt = this.getTotalAmountAdditionCharge(_val2, "TransAdditionalCharge2");
              let _finalDueAmt = this.getTotalRemainAmountAdditionCharge(_val2, "TransAdditionalCharge2");
    
              this.setState({
                totalAmount: _finalTotalAmt.toFixed(2),
                TransAdditionalCharge2: value,
                dueAmount: _finalDueAmt.toFixed(2),
              });
              
              
              if (_val2 <= 0 || _val2 == "") {
                this.setState({
                  TransAdditionalCharge2: _val2,
                });
              }
            }
          }
          
        break;

      case "TransAdditionalCharge3":        
        if (!validator.isInt(value)) {             
          let _finalTotalAmt_1 = this.getTotalAmountAdditionCharge(0, "TransAdditionalCharge3");
          let _finalDueAmt_1 = this.getTotalRemainAmountAdditionCharge(0, "TransAdditionalCharge3");
          this.setState({            
            totalAmount: _finalTotalAmt_1.toFixed(2),
            TransAdditionalCharge3: 0,
            dueAmount: _finalDueAmt_1.toFixed(2),
          });           
          //alert("Charges can not be less than zero or character");
          return;
        }else{            
          if (value < 0) {
            alert("Charges can not be less than zero");
          }
          else{ 
            let _val1 = value;
            if (_val1 == "" || _val1 == undefined || _val1 == null) {
              _val1 = 0;
            }
            let _finalTotalAmt = this.getTotalAmountAdditionCharge(_val1, "TransAdditionalCharge3");
            let _finalDueAmt = this.getTotalRemainAmountAdditionCharge(_val1, "TransAdditionalCharge3");
            
            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              TransAdditionalCharge3: _val1,
              dueAmount: _finalDueAmt.toFixed(2),
            });
            
            if (_val1 <= 0 || _val1 == "") {
              this.setState({
                TransAdditionalCharge1: _val1,
              });
            }
          }
        }
        break;
    
      case "settingType":
        this.setState({ settingType: value });
        break;

      case "TransportDetailField1":
        this.setState({ TransportDetailField1: value });
        break;

      case "TransportDetailField2":
        this.setState({ TransportDetailField2: value });
        break;

      case "TransportDetailField3":
        this.setState({ TransportDetailField3: value });
        break;

      case "TransportDetailField4":
        this.setState({ TransportDetailField4: value });
        break;

      case "TransportDetailField5":
        this.setState({ TransportDetailField5: value });
        break;

      case "TransportDetailField6":
        this.setState({ TransportDetailField6: value });
        break;

      case "selPrefix":
        if (value == "ADDPREFIX") {
          this.openModalHandler("modalAdjustADDPREFIX");
        }
        let _prefixId = 0;
        let _prefixName = "";
        if (value > 0) {
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _prefixId = optionElement.getAttribute("data-prefixId");
          _prefixName = optionElement.getAttribute("data-prefixName");
        }
        this.setState({ prefixId: _prefixId, prefixName: _prefixName });
        break;

      case "additional_value_date":
        this.setState({ additional_value_date: value });
        break;
      case "additional_value_1":
        this.setState({ additional_value_1: value });
        break;
      case "additional_value_2":
        this.setState({ additional_value_2: value });
        break;
      case "additional_value_3":
        this.setState({ additional_value_3: value });
        break;

      default:
        break;
    }
  };

  getTotalAmountAdditionCharge (value, _type){
    let _totalOtherCharges = 0;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1 ? this.state.TransAdditionalCharge1 : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2 ? this.state.TransAdditionalCharge2 : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3 ? this.state.TransAdditionalCharge3 : 0;
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    let _totalAmount = this.state.totalAmountOld;
    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3);
    _totalAmount = parseFloat(_totalAmount) + parseFloat(_totalOtherCharges);
    return _totalAmount;
  }

  getTotalRemainAmountAdditionCharge (value, _type){
    let _totalOtherCharges = 0;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1 ? this.state.TransAdditionalCharge1 : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2 ? this.state.TransAdditionalCharge2 : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3 ? this.state.TransAdditionalCharge3 : 0;
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    let _totalDuePayAmount = this.state.totalAmountOld;
    
    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3);

    _totalDuePayAmount = parseFloat(_totalDuePayAmount) + parseFloat(_totalOtherCharges);
    
    if(parseFloat(this.state.receivedAmount) > 0) {
      _totalDuePayAmount = parseFloat(_totalDuePayAmount) - parseFloat(this.state.receivedAmount);
    }
    return _totalDuePayAmount;
  }	
  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: true });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    } else if (e == "modalLowStockItem") {
      this.setState({ modalIsOpen_LowStock: true });
    } else if (e == "modalAdjustADDPREFIX") {
      this.setState({ modalIsOpen_ADDPREFIX: true, prefixD: 0 });
    }else if (e == "modalAdjustAddNewTerms") {
      this.setState({ modalIsOpen_AddTerm: true });
    }
  }

  closeModalHandler = (e) => {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: false });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    } else if (e == "modalAdjustADDPREFIX") {
      this.setState({
        modalIsOpen_ADDPREFIX: false,
        _infoPrefixId: 0,
        _infoTitlePre: "",
        prefixId: 0,
        prefixName: "",
        prefixD: 1,
      });
    }else if (e == "modalAdjustAddNewTerms") {
      this.setState({ modalIsOpen_AddTerm: false,dueTerms : '01' });
    }
  };

  /*End Modal open close*/

  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.setState({
      modalIsOpen_AddTerm: false,
    });
    this.getPaymentTerms();
  };
  /* End Here */
  
  getPaymentTerms() {
    
    /*Due Dates terms setting data */
    let _selTermsId = 0;    
    let _terms_days = 0;
    const requestDueDateData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });
    this.setState({ dueTerms: '',dueDays: 0, });
    PostData(global.businessDueDatePaymentTermList, requestDueDateData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
          }
          let _mainResponse = responseJson.response;
          this.setState({
            isLoaded: true,
            paymentTermsData: responseJson.response,
          });
          _mainResponse.map((item, index) => {
            if (item.terms_default_status == 1) {
              _selTermsId = item.id;
              _terms_days = item.terms_days;
              
              let dueDateOnload = moment().add(_terms_days, "days").format("MM/DD/YYYY");              
              this.state.dueDays = _terms_days;
              this.setState({ dueOnDate: dueDateOnload,dueTerms: '',dueDays: _terms_days, });
            }
          });
        }
    })
      .catch((error) => this.setState({ error, isLoaded: false }));
    /* End Due Dates terms setting data */
  }

  /*Low Stock Data */
  closeModalHandlerLowStock = (e) => {
    if (e == "modalLowStockItem") {
      this.setState({ modalIsOpen_LowStock: false });
    }
  };
  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  SaveModalHandlerLowStock = (e) => {
    let _valueCheck = 0;
    if (this.state.isChecked == true) {
      _valueCheck = 1;
    }
    if (e == "modalLowStockItem") {
      const requestDataLowStock = JSON.stringify({
        settingValue: _valueCheck,
        settingKey: "lowStockStatus",
        businessId: _businessId,
        passcode: 0,
      });
      PostData(global.businessSettingUpdate, requestDataLowStock, "POST").then(
        (result) => {
          if (result.success == true) {
            let _UserSession = localStorage.getItem("vs_UserSession");
            let _updateLocal = _UserSession;
            let _businessSettingData = _updateLocal.businessSetting;
            _businessSettingData["lowStockStatus"] = _valueCheck;
            localStorage.removeItem("vs_UserSession");
            localStorage.setItem(
              "vs_UserSession",
              JSON.stringify(_updateLocal)
            );
            this.addSaleTransactionMethodCall();
          } else {
            sendNotification("Error Message 😓", result.message, "danger");
            return;
          }
        }
      );
      this.setState({ modalIsOpen_LowStock: false });
    }
  };
  /*End Low Stock Data */

  
  
  handleDynamicInputsTrans(event) {
    let dataVal = event.target.value;
    const finalTaxTrans = dataVal.split("@");
    let _percGstAmt = 0;
    let _mainSubTotalAmt = 0;
    this.state.fields.map((data) => {
      _mainSubTotalAmt += parseFloat(data.total_amount);
    }); 
    if (finalTaxTrans[1] >= 0) {      
      let _totalTaxAmount = _mainSubTotalAmt;//this.state.totalAmount;

      _percGstAmt =(parseFloat(_totalTaxAmount) * parseFloat(finalTaxTrans[1])) / parseFloat(100);
      _totalTaxAmount = parseFloat(_totalTaxAmount) + parseFloat(_percGstAmt); 

      //let _finalTotalAmt = _totalTaxAmount;
      let _totalTransDiscountAmount = this.state.totalTransDiscountAmount ? this.state.totalTransDiscountAmount : 0;
      let _finalTotalAmt = parseFloat(_totalTaxAmount) - parseFloat(_totalTransDiscountAmount);

      let _payableAmt = _finalTotalAmt.toFixed(2);

      this.setState({
        totalAmount: _finalTotalAmt.toFixed(2),
        dueAmount: _payableAmt,
        totalTransTaxAmount: _percGstAmt.toFixed(2),
        transactionWiseTaxId: finalTaxTrans[0],
        transactionWiseTaxIdPer: finalTaxTrans[1],
      });
    }
  }	

  /* Add Party Code */
  selectPartiess(name, e, balance, party_contact_no) {
    let errors = this.state.errors;
    errors.party_name = "";

    //Party Wise Item Rate Ak
    //this.setState({fields:[]});
    if (this.state.transactionId == 0) {
      if (this.state.party_id > 0) {
        //document.body.dispatchEvent("clearData");
        document.body.dispatchEvent(new CustomEvent("clearData"));
      }
      this.state.party_id = e;
      this.state.is_party = "yes";
      //this.getItemListPartyData(_company_id, e);
      this.setState({
        _partyReg: "iti__hide",
        party_name: name,
        party_id: e,
        partyRemainBalance: balance,
        party_contact_no: party_contact_no,
        fields: [],
      });
    } else {
      this.state.party_id = e;
      this.state.is_party = "yes";
      this.setState({
        _partyReg: "iti__hide",
        party_name: name,
        party_id: e,
        partyRemainBalance: balance,
        party_contact_no: party_contact_no,
        fields: [],
      });
    }
  }

  getReadyPartyList() {
    let data = [];
    if (this.state.isLoaded == true && this.state.isMakePayment == false) {
      let _partyArray = this.state.partyArrayFilter;
      if (_partyArray.length > 0) {
        data = this.state.partyArrayFilter.map((party, i) => {
          let _balance = party.totalOutCome;
          if (_balance < 0) {
            _colorVal = "red";
          } else if (_balance == 0 || _balance == "0") {
            _colorVal = "black";
          } else {
            _colorVal = "green";
          }
          return (
            <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.selectPartiess.bind(
                this,
                party.party_name,
                party.party_id,
                _balance,
                party.party_contact_no
              )}
            >
              <span className="iti__party-name">{party.party_name}</span>
              <span className="iti__party-balance" style={{ color: _colorVal }}>
                {numberFormat(Math.abs(_balance))}
              </span>
            </li>
          );
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAdjustNewParty")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Party
            </span>
          </li>
        );
      }
    }
    return data;
  }

  onMouseLeaveHandler(key) {
    if (key == "I") {
      this.setState({ _partyReg: "iti__hide" });
    }
  }

  manageShowPartyFilter(key) {
    if (key == "I") {
      this.setState({ _partyReg: "" }); //
    }
  }

  onPartyFilterName = (e) => {
    const data = this.state.partyArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({
      partyArrayFilter: filtered,
      party_name: keyword,
      is_party: "No",
    });

    this.getReadyPartyList();
  };
  /* End Party Code */

  /* Toggle Manage */
  onToggleChange = (checked) => {
    this.setState({ toggleState: checked });
    let _daySs = this.state.dueDays ? this.state.dueDays : 0;
    let dueDateOnload = moment().add(_daySs, "days").format("MM/DD/YYYY");
    this.setState({ dueOnDate: dueDateOnload });

    // True = Cash
    // False = Credit
  };
  /* Toggle Manage */

  /* Image Code */
  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }
  /* End Here*/

  /* Click Submit Button */
  clickToAddSale = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.party_name == "") {
      _isError = true;
      errors["party_name"] = "*Please Enter Party Name";
      this.setState({ errors: errors });
    }

    if (this.state.invoiceNo == "") {
      _isError = true;
      errors["invoiceNo"] = "*Please Enter Invoice No.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.transactionId > 0) {
        this.editSaleTransactionMethodCall();
      } else {
        /*Low Stock Data*/
        /*if (_businessSetting.ShowLowStockDialog == 1 && _businessSetting.lowStockStatus == 0){
                  if(this.state.lowStokItemName.length > 0){
                      this.openModalHandler('modalLowStockItem');
                      return false;
                  }else{
                      this.addSaleTransactionMethodCall();
                  }
                }
                /*End Low Stock Data*/
        /*else{
                this.addSaleTransactionMethodCall();
              }*/
        this.addSaleTransactionMethodCall();
      }
    }
  };

  addSaleTransactionMethodCall() {
    let _mainSubTotalAmt = 0; //Add by AK
    let _mainTotalAmt = this.state.totalAmount; //Add by AK
    let _itemPriceStatus = false;
    if (this.state.handleAddDisable.length > 2) {
      //alert("Cannot submit empty item row, please select item.");
      //return false;
      sendNotification(
        "Error Message 😓",
        "Cannot submit empty item row, please select item.",
        "warning"
      );
      return;
    }
    if (this.state.handleItemSalePurchasePrice.length > 2) {
      //alert("Item price can not be zero");
      //return false;
      sendNotification(
        "Error Message 😓",
        "Item price can not be zero.",
        "warning"
      );
      return;
    }

    if (this.state.dueAmount < 0) {
      sendNotification(
        "Error Message 😓",
        "Received amount on sale order can not be more than total order amount.",
        "warning"
      );
      return;
    }
    
    if (this.state.dueTerms == '01') {
      sendNotification(
        "warning Message 😓",
        "Please select any Pmt. Terms",
        "warning"
      );
      return;
    }
    
    if (parseInt(this.state.receivedAmount) < 0) {
      sendNotification(
        "warning Message 😓",
        "Receive amount can not be less than zero",
        "warning"
      );
      return;
    }
    let _prefix = JSON.stringify({
      name: this.state.prefixName,
      id: this.state.prefixId,
      isActive: "1",
    });
    if (_mainTotalAmt == 0) {
      _mainTotalAmt = this.state.totalAmount;
      _mainSubTotalAmt = this.state.totalAmount;
    } else {
      
      let _mainTotalAmount = 0;
      this.state.fields.map((data) => {
        _mainTotalAmount = parseFloat(_mainTotalAmount) + parseFloat(data.total_amount);  

        if(parseFloat(data.rate <= 0) || data.rate == 0 || data.rate == '0' || data.rate == ''){
          _itemPriceStatus = true;
        }
      }); //Add by AK      
      _mainTotalAmt = _mainTotalAmount;
      if (parseFloat(this.state.totalTransDiscountAmount) > 0 || parseFloat(this.state.totalTransTaxAmount) > 0)
      {
        _mainTotalAmt = parseFloat(this.state.totalAmount);
        _mainSubTotalAmt = parseFloat(this.state.totalAmountOld);
      }
    }
    if(_itemPriceStatus == true){
      sendNotification(
        "Error Message 😓",
        "Item price can not 0",
        "warning"
      );
      return;
    }
   
    let _totalSummary = JSON.stringify({
      subtotal: this.state.totalAmountOld, //this.state.totalAmount,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalQty: this.state.totalQuantity,
      totalDiscount: this.state.totalDiscount,
      totalAmount: _mainTotalAmt, //this.state.totalAmount,
      receivedAmount: this.state.receivedAmount,
      dueAmount: this.state.dueAmount,
    }); //Add by AK

   //console.log(this.state.totalDiscount+"-=_totalSummary==",_totalSummary);return false;
    let _customer = JSON.stringify({
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_opening_balance: 0,
      billing_name: this.state.billing_name,
    });

    let _transactionWiseAmount = JSON.stringify({
      transactionWiseTaxId: this.state.transactionWiseTaxId
        ? this.state.transactionWiseTaxId
        : 0,
      totalTransTaxAmount: this.state.totalTransTaxAmount
        ? this.state.totalTransTaxAmount
        : 0,
      totalTransDiscountPer: this.state.totalTransDiscountPer
        ? this.state.totalTransDiscountPer
        : 0,
      totalTransDiscountAmount: this.state.totalTransDiscountAmount
        ? this.state.totalTransDiscountAmount
        : 0,
    }); //Add by AK

    let _transactionAdditionalCharge = JSON.stringify({
      TransAdditionalCharge1: this.state.TransAdditionalCharge1
        ? this.state.TransAdditionalCharge1
        : 0,
      TransAdditionalCharge2: this.state.TransAdditionalCharge2
        ? this.state.TransAdditionalCharge2
        : 0,
      TransAdditionalCharge3: this.state.TransAdditionalCharge3
        ? this.state.TransAdditionalCharge3
        : 0,
    }); //Add by AK

    let _transacTransportationDetails = JSON.stringify({
      TransportDetailField1: this.state.TransportDetailField1
        ? this.state.TransportDetailField1
        : "",
      TransportDetailField2: this.state.TransportDetailField2
        ? this.state.TransportDetailField2
        : "",
      TransportDetailField3: this.state.TransportDetailField3
        ? this.state.TransportDetailField3
        : "",
      TransportDetailField4: this.state.TransportDetailField4
        ? this.state.TransportDetailField4
        : "",
      TransportDetailField5: this.state.TransportDetailField5
        ? this.state.TransportDetailField5
        : "",
      TransportDetailField6: this.state.TransportDetailField6
        ? this.state.TransportDetailField6
        : "",
    }); //Add by AK
    var saleData = new FormData();
    saleData.append("business_id", this.state.businessId);
    saleData.append("user_id", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("type", "SALE");
    saleData.append("created_from", "WEB");
    saleData.append("prefix", _prefix);
    saleData.append("invoiceNo", this.state.invoiceNo);
    saleData.append("billing_address", this.state.billing_address);
    saleData.append("invoiceDate",moment(this.state.invoiceDate).format("MMMM D, YYYY"));
    saleData.append("poNumber", this.state.poNumber);
    saleData.append("poDate", moment(this.state.poDate).format("MMMM D, YYYY"));
    saleData.append("dueOnDate",moment(this.state.dueOnDate).format("MMMM D, YYYY"));
    saleData.append("dueTerms", this.state.dueTerms);
    saleData.append("E_Way_Bill_No", this.state.E_Way_Bill_No);
    saleData.append("invoiceTime", this.state.invoiceTime);
    saleData.append("totalSummary", _totalSummary);
    saleData.append("transactionWiseAmount", _transactionWiseAmount);
    saleData.append("transactionAdditionalCharge",_transactionAdditionalCharge);
    saleData.append("transactionTransportationDetails",_transacTransportationDetails);
    saleData.append("customer", _customer);
    saleData.append("items", JSON.stringify(this.state.fields));
    saleData.append("paymentType", this.state.paymentType);
    saleData.append("paymentRefNo", this.state.paymentRefNo);
    saleData.append("selectedCountry", this.state.countryName);
    saleData.append("countryState", this.state.countryState);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("isSaleCash", this.state.toggleState);
    saleData.append("bankId", this.state.bankId);
    saleData.append("bankName", this.state.bankName);
    saleData.append("settingType", this.state.settingType);
    saleData.append("additional_value_1", this.state.additional_value_1);
    saleData.append("additional_value_2", this.state.additional_value_2);
    saleData.append("additional_value_3", this.state.additional_value_3);
    saleData.append("additional_value_date",moment(this.state.additional_value_date).format("MMMM D, YYYY"));

    //console.log("==>items-->==",(JSON.stringify(this.state.fields))); return false;
    new Promise((resolve, reject) => {
      fetch(global.API_USER_URL+'createSaleTransactionOld/', {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              //window.location.href = global.webUrl+'business/sale-invoice/';
              window.location.href =
                global.webUrl +
                "business/download-pdf/?auth=" +
                res.transaction_Id;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  editSaleTransactionMethodCall() {
    let _mainSubTotalAmt = 0; //Add by AK
    let _mainTotalAmt = this.state.totalAmount; //Add by AK
    let _itemPriceStatus = false;
    // if (this.state.dueAmount < 0) {
    //   sendNotification(
    //     "Error Message 😓",
    //     "Received amount on sale can not be more than total order amount.",
    //     "warning"
    //   );
    //   return;
    // }

    if (this.state.handleAddDisable.length > 2) {
      alert("Cannot submit empty item row, please select item.");
      return false;
    }
    if (this.state.handleItemSalePurchasePrice.length > 2) {
      alert("Item price can not be zero");
      return false;
    }

    if(this.state.isReturn ==  true){
      sendNotification(
        "warning Message 😓",
        "You cannot update this transaction because it is already a partial return, if you want to edit it, delete the return of that transaction.",
        "warning"
      );
      return;
    }

    if (this.state.dueTerms == '01') {
      sendNotification(
        "warning Message 😓",
        "Please select any Pmt. Terms",
        "warning"
      );
      return;
    }
    if (parseInt(this.state.receivedAmount) < 0) {
      sendNotification(
        "warning Message 😓",
        "Receive amount can not be less than zero",
        "warning"
      );
      return;
    }
    let _prefix = JSON.stringify({
      name: this.state.prefixName,
      id: this.state.prefixId,
      isActive: "1",
    });

    if (_mainTotalAmt == 0) {
      _mainTotalAmt = this.state.totalAmount;
      _mainSubTotalAmt = this.state.totalAmount;
    } else {
      
      let _mainTotalAmount = 0;
      this.state.fields.map((data) => {
        _mainTotalAmount = parseFloat(_mainTotalAmount) + parseFloat(data.total_amount);  

        if(parseFloat(data.rate <= 0) || data.rate == 0 || data.rate == '0' || data.rate == ''){
          _itemPriceStatus = true;
        }
      }); //Add by AK      
      _mainTotalAmt = _mainTotalAmount;
      if (parseFloat(this.state.totalTransDiscountAmount) > 0 || parseFloat(this.state.totalTransTaxAmount) > 0)
      {
        _mainTotalAmt = parseFloat(this.state.totalAmount);
        _mainSubTotalAmt = parseFloat(this.state.totalAmountOld);
      }
    }

    if(_itemPriceStatus == true){
      sendNotification(
        "Error Message 😓",
        "Item price can not 0",
        "warning"
      );
      return;
    }
    if(this.state.cheque_open_status === 1){
      sendNotification(
        "Error Message 😓",
        "This transaction can not be edited because cheque associated with the transaction is already   closed. Re-open the cheque to edit this transaction.",
        "warning"
      );
      return;
    }
    
    let _totalSummary = JSON.stringify({
      subtotal: this.state.totalAmountOld, //this.state.totalAmount,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalQty: this.state.totalQuantity,
      totalDiscount: this.state.totalDiscount,
      totalAmount: _mainTotalAmt, //this.state.totalAmount,
      receivedAmount: this.state.receivedAmount,
      dueAmount: this.state.dueAmount,
    }); //Add by AK
    let _customer = JSON.stringify({
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_opening_balance: 0,
      billing_name: this.state.billing_name,
    });

    let _transactionWiseAmount = JSON.stringify({
      transactionWiseTaxId: this.state.transactionWiseTaxId
        ? this.state.transactionWiseTaxId
        : 0,
      totalTransTaxAmount: this.state.totalTransTaxAmount
        ? this.state.totalTransTaxAmount
        : 0,
      totalTransDiscountPer: this.state.totalTransDiscountPer
        ? this.state.totalTransDiscountPer
        : 0,
      totalTransDiscountAmount: this.state.totalTransDiscountAmount
        ? this.state.totalTransDiscountAmount
        : 0,
    }); //Add by AK

    let _transactionAdditionalCharge = JSON.stringify({
      TransAdditionalCharge1: this.state.TransAdditionalCharge1
        ? this.state.TransAdditionalCharge1
        : 0,
      TransAdditionalCharge2: this.state.TransAdditionalCharge2
        ? this.state.TransAdditionalCharge2
        : 0,
      TransAdditionalCharge3: this.state.TransAdditionalCharge3
        ? this.state.TransAdditionalCharge3
        : 0,
    }); //Add by AK

    let _transacTransportationDetails = JSON.stringify({
      TransportDetailField1: this.state.TransportDetailField1
        ? this.state.TransportDetailField1
        : "",
      TransportDetailField2: this.state.TransportDetailField2
        ? this.state.TransportDetailField2
        : "",
      TransportDetailField3: this.state.TransportDetailField3
        ? this.state.TransportDetailField3
        : "",
      TransportDetailField4: this.state.TransportDetailField4
        ? this.state.TransportDetailField4
        : "",
      TransportDetailField5: this.state.TransportDetailField5
        ? this.state.TransportDetailField5
        : "",
      TransportDetailField6: this.state.TransportDetailField6
        ? this.state.TransportDetailField6
        : "",
    }); //Add by AK
    
    var saleData = new FormData();
    saleData.append("transaction_id", this.state.transactionId);
    saleData.append("business_id", this.state.businessId);
    saleData.append("user_id", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("type", "SALE");
    saleData.append("prefix", _prefix);
    saleData.append("billing_address", this.state.billing_address);
    saleData.append("invoiceNo", this.state.invoiceNo);
    saleData.append("invoiceDate",moment(this.state.invoiceDate).format("MMMM D, YYYY"));
    saleData.append("poNumber", this.state.poNumber);
    saleData.append("poDate", moment(this.state.poDate).format("MMMM D, YYYY"));
    saleData.append("dueOnDate",moment(this.state.dueOnDate).format("MMMM D, YYYY"));
    saleData.append("dueTerms", this.state.dueTerms);
    saleData.append("E_Way_Bill_No", this.state.E_Way_Bill_No);
    saleData.append("invoiceTime", this.state.invoiceTime);
    saleData.append("totalSummary", _totalSummary);
    saleData.append("transactionWiseAmount", _transactionWiseAmount);
    saleData.append("transactionAdditionalCharge", _transactionAdditionalCharge);
    saleData.append("transactionTransportationDetails",_transacTransportationDetails);
    saleData.append("customer", _customer);
    saleData.append("items", JSON.stringify(this.state.fields));
    saleData.append("paymentType", this.state.paymentType);
    saleData.append("paymentRefNo", this.state.paymentRefNo);
    saleData.append("selectedCountry", this.state.countryName);
    saleData.append("countryState", this.state.countryState);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("isSaleCash", this.state.toggleState);
    saleData.append("bankId", this.state.bankId);
    saleData.append("bankName", this.state.bankName);
    saleData.append("isMakePayment", this.state.isMakePayment);
    saleData.append("settingType", this.state.settingType);
    saleData.append("additional_value_1", this.state.additional_value_1);
    saleData.append("additional_value_2", this.state.additional_value_2);
    saleData.append("additional_value_3", this.state.additional_value_3);
    saleData.append("additional_value_date",moment(this.state.additional_value_date).format("MMMM D, YYYY"));
    saleData.append("totalLinkHistoryAmt", this.state.totalLinkHistoryAmt);
    //console.log("==>items-->==",(JSON.stringify(this.state.fields))); return false;
    new Promise((resolve, reject) => {
      fetch(global.editSaleTransaction, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              if (this.state.transactionType == "ITEM") {
                window.location.href = global.webUrl + "business/manage-items/";
              } else if (this.state.transactionType == "PARTY") {
                window.location.href = global.webUrl + "business/manage-party/";
              } else if (this.state.transactionType == "Dashboard") {
                window.location.href = global.webUrl + "business/dashboard/";
              } else if (this.state.transactionType == "SERVICE") {
                window.location.href =
                  global.webUrl + "business/manage-service/";
              } else if (this.state.transactionType == "CASHINHAND") {
                window.location.href =
                  global.webUrl + "business/manage-cash-in-hand/";
              } else if (this.state.transactionType == "Bank") {
                window.location.href =
                  global.webUrl + "business/manage-account-list/";
              } else if (this.state.transactionType == "Report") {
                window.location.href = global.webUrl + "business/reports/";
              } else {
                window.location.href = global.webUrl + "business/sale-invoice/";
              }
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }
  /* End Submit button */

  handleCallBackDate = (start) => {
    
    this.setState({ invoiceDate: start.format("MMMM D, YYYY") });    
    let changeInvD = moment(this.state.invoiceDate).format("MM/DD/YYYY")
    let dueDateChange = moment(changeInvD).add(this.state.dueDays, "days").format("MM/DD/YYYY");
    this.setState({
      dueOnDate: dueDateChange,
      startDate: dueDateChange,
      pageLoadTerms: 1,
      dateCalendarChange: 0,
    });
    this.isDueOnDateDateRefreshOnChange(dueDateChange);
    
  };

  settleAmountType() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        let _addNewBank = 0;
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankName={_disOption}
                data-bankId={_valOption}
              >
                {_disOption}
              </option>
            );
          }

          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _addNewBank = 1;
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }
    return _payData;
  }

  isDateRefresh() {
    let _invoiceDate = this.state.invoiceDate;

    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            dataSend="1111"
            value={_invoiceDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" readOnly value={_invoiceDate} />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" readOnly />
        </DateRangePicker>
      );
    }
  }

  isAdditionalDateRefresh() {
    let _additional_value_date = this.state.additional_value_date;

    return (
      <DateRangePicker
        initialSettings={{
          singleDatePicker: true,
          startDate: _additional_value_date,
        }}
        onCallback={this.handleCallBackAddDate}
      >
        <input
          type="text"
          id="additional_value_date"
          name="additional_value_date"
          className="form-control"
          value={this.state.additional_value_date}
          readOnly
        />
      </DateRangePicker>
    );
  }
  handleCallBackAddDate = (start) => {
    this.setState({ additional_value_date: start.format("MMMM D, YYYY") });
  };
  /* PO Date */
  handleCallBackPODate = (start) => {
    this.setState({ poDate: start.format("MMMM D, YYYY") });
  };

  isPODateRefresh() {
    let _poDate = moment().format("MM/DD/YYYY");
    if (
      this.state.poDate == "Invalid date" ||
      this.state.poDate == "" ||
      this.state.poDate == "null" ||
      this.state.poDate == null
    ) {
      _poDate = moment().format("MM/DD/YYYY");
    } else {
      _poDate = this.state.poDate;
    }
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _poDate,
          }}
          onCallback={this.handleCallBackPODate}
        >
          <input type="text" className="form-control" readOnly />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _poDate,
          }}
          onCallback={this.handleCallBackPODate}
        >
          <input type="text" className="form-control" readOnly />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _poDate,
          }}
          onCallback={this.handleCallBackPODate}
        >
          <input
            type="text"
            className="form-control"
            datach="else if"
            readOnly
          />
        </DateRangePicker>
      );
    }
  }
  /* PO Date End */

  /* Due date onDate */
  handleCallBackDueTermsDateDate = (start) => {
    let _dateCalendarChange = 1;
    if(this.state.transactionId >0)
    {
      _dateCalendarChange = 11;
    }
    
    this.setState({
      dueOnDate: start.format("MM/DD/YYYY"),
      dateCalendarChange: _dateCalendarChange,
      dueTerms: 0
    });
  };

  isDueOnDateDateRefresh(newDueDate) {
    let _dueOnDate = this.state.dueOnDate; //newDueDate;
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _dueOnDate,
          }}
          onCallback={this.handleCallBackDueTermsDateDate}
        >
          <input type="text" className="form-control" readOnly  />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _dueOnDate,
          }}
          onCallback={this.handleCallBackDueTermsDateDate}
        >
          <input type="text" className="form-control" readOnly  />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _dueOnDate,
          }}
          onCallback={this.handleCallBackDueTermsDateDate}
        >
          <input
            type="text"
            className="form-control"
            datach="else if"
            readOnly
          />
        </DateRangePicker>
      );
    }
  }
  isDueOnDateDateRefreshOnChange(newDueDate) {
    let _dueOnDate = this.state.dueOnDate; //newDueDate;
        
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _dueOnDate,
          }}
          onCallback={this.handleCallBackDueTermsDateDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={this.state.startDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _dueOnDate,
          }}
          onCallback={this.handleCallBackDueTermsDateDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={this.state.startDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _dueOnDate,
          }}
          onCallback={this.handleCallBackDueTermsDateDate}
        >
          <input
            type="text"
            className="form-control"
            datach="else if"
            readOnly
            value={this.state.startDate}
          />
        </DateRangePicker>
      );
    }
  }
  /* Due date on Date End */

  /* Invoice Time Slot */
  onMouseLeaveHandler_Time(key) {
    if (key == "I") {
      this.setState({ _timeReg: "iti__hide" });
    }
  }

  onTimeFilterName = (e) => {
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    this.setState({ invoiceTime: keyword });
    this.getTimeSlotList();
  };

  manageShowTimeFilter(key) {
    if (key == "I") {
      this.setState({ _timeReg: "" });
    }
  }

  selectInvoiceTime(e) {
    this.setState({
      _timeReg: "iti__hide",
      invoiceTime: e,
    });
  }

  formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };
  
  // Function to generate time slots with given interval (in minutes)
  generateTimeSlots = (interval = 1) => {
    const slots = [];
    const start = new Date(); // Start from current time
    start.setSeconds(0, 0); // Round down to the nearest hour
  
    // Generate time slots for the next 24 hours
    for (let i = 0; i < 24 * 60 / interval; i++) {
      start.setMinutes(start.getMinutes() + interval);
      slots.push(this.formatTime(new Date(start)));
    }
  
    return slots;
  };
  availableTimeSlots = this.generateTimeSlots(); 

  getTimeSlotList() {
    
    return this.availableTimeSlots.map((time, index) => (
      <div
        key={index}
        className="dropdown-item"
        onClick={this.selectInvoiceTime.bind(this, time)}
      >
        {time}
      </div>
    ))

  }
  /* Invoice Time Slot End */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideRows = "iti__hide";
    let _readonlyInput = false;
    let _readonlyCss = "disabled";
    if (this.state.fields.length > 0) {
      _hideRows = "";
      _readonlyInput = true;
      _readonlyCss = "disabled";
    } else {
      if (this.state.totalAmount > 0) {
        _hideRows = "";
        _readonlyInput = false;
        _readonlyCss = "disabled";
      }
    }

    if (this.state.toggleState == true && this.state.transactionId == 0) {
      _hideRows = "iti__hide";
    }
    if (this.state.paymentType == 'Cash' && this.state.transactionId > 0) {
      _hideRows = "iti__hide";
    }

    let _readonlyPaidInput = false;
    let _readonlyTotalInput = true;
    if (this.state.isMakePayment == true) {
      if (this.state.isItemCount > 0) {
        _readonlyTotalInput = true;
      }
      _readonlyInput = true;
      _readonlyPaidInput = true;
    }
    const optionsData = [
      { value: "showall", label: "Show All" },
      { value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Triplicate", label: "Triplicate" },
    ];

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    const otherStyle = {
      padding: "0 0 0 10px !important",
    };

    /*For Low Stock Enable */
    let _finalStockHtml = "";
    let _lowStokItemName = this.state.lowStokItemName;
    if (this.state.lowStokItemName.length == 1) {
      _finalStockHtml = [];
      _finalStockHtml.push(<li>{_lowStokItemName}</li>);
    }
    if (this.state.lowStokItemName.length > 1) {
      const products = this.state.lowStokItemName;
      _finalStockHtml = [];
      products.forEach((product) => {
        _finalStockHtml.push(<li>{product}</li>);
      });
    }

    /*End Low Stock Enable */

    // let _handleParamItemPage = this.props.location.search;
    // _handleParamItemPage = _handleParamItemPage.split('?auth=');
    // let _transId = _handleParamItemPage[1] ? _handleParamItemPage[1] : 0;
    let _transId = this.state.transactionId;

    let _colorBal = "text-normal";
    if (this.state.partyRemainBalance < 0) {
      _colorBal = "text-danger";
    } else if (this.state.partyRemainBalance > 0) {
      _colorBal = "text-success";
    } else {
      _colorBal = "text-normal";
    }

    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Add / Edit Sale Invoice
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <Link className="breadcrumb-item" to={"/business/dashboard/"}>
              Dashboard
            </Link>
            <Link className="breadcrumb-item" to={"/business/sale-invoice/"}>
              Manage Sale Invoice List
            </Link>
            <span className="breadcrumb-item active">
              Add / Edit Sale Invoice
            </span>
            <Link
              className="breadcrumb-item"
              to={"/business/add-sale/"}
              target="_blank"
            >
              <i className="fa fa-plus"> (Add More)</i>
            </Link>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-6">
                        <div
                          className="form-material open"
                          onMouseLeave={this.onMouseLeaveHandler.bind(
                            this,
                            "I"
                          )}
                        >
                          <input
                            readOnly={_readonlyInput}
                            type="text"
                            className="form-control search-box"
                            onChange={this.onPartyFilterName}
                            onClick={this.manageShowPartyFilter.bind(this, "I")}
                            onKeyDown={this.manageShowPartyFilter.bind(
                              this,
                              "I"
                            )}
                            placeholder="Search Party Name Here..."
                            autoComplete="off"
                            name="party_name"
                            id="party_name"
                            value={this.state.party_name}
                          />
                          <label htmlFor="login-username">
                            Party Name <span className="text-danger">*</span>
                          </label>
                          {this.state.fields.length == 0 && (
                            <ul
                              className={
                                this.state._partyReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getReadyPartyList()}
                            </ul>
                          )}
                          <div
                            id="party_name-error"
                            className="animated fadeInDown"
                          >
                            {errors.party_name.length > 0 && (
                              <span className="error">{errors.party_name}</span>
                            )}
                          </div>

                          <div className={_colorBal}>
                            {this.state.party_name!="" &&
                            this.state.is_party!="No"
                              ? "Bal : " +
                                numberFormat(
                                  Math.abs(this.state.partyRemainBalance)
                                )
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.transactionId > 0 ? "hide" : "col-6"
                        }
                      >
                        <div>
                          <ToggleSwitch
                            txtLeft="Credit"
                            txtRight="Cash"
                            small
                            id="newsletter"
                            checked={this.state.toggleState}
                            onChange={this.onToggleChange}
                          />
                          {/* <p>Newsletter: {String(this.state.toggleState)}</p> */}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="text"
                            className="form-control search-box"
                            placeholder="Phone Number"
                            autoComplete="off"
                            name="party_contact_no"
                            id="party_contact_no"
                            value={this.state.party_contact_no}
                            onChange={this.handleChange}
                            maxLength="13"
                          />
                          <label htmlFor="login-username">Phone Number</label>

                          <span className="iris_error_txt">
                            {errors.party_contact_no.length > 0 && (
                              <span className="error">
                                {errors.party_contact_no}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      {_businessSetting.Tran_BillingNameParty == 1 && (
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              className="form-control search-box"
                              placeholder="Billing Name Here..."
                              autoComplete="off"
                              name="billing_name"
                              id="billing_name"
                              value={this.state.billing_name}
                              onChange={this.handleChange}
                            />
                            <label htmlFor="login-username">Billing Name</label>
                          </div>
                        </div>
                      )}
                      {_businessSetting.Tran_BillingNameParty == 1 && (
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              className="form-control search-box"
                              placeholder="Billing Address Here..."
                              autoComplete="off"
                              name="billing_address"
                              id="billing_address"
                              value={this.state.billing_address}
                              onChange={this.handleChange}
                            />
                            <label htmlFor="login-username">Billing Address</label>
                          </div>
                        </div>
                      )}
                      {_businessSetting.Tran_CustomerPO == 1 && (
                        <div className="col-6">
                          <div className="form-material open">
                            {this.isPODateRefresh()}
                            <label htmlFor="login-username">PO Date</label>
                          </div>
                        </div>
                      )}
                      {_businessSetting.Tran_CustomerPO == 1 && (
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              className="form-control search-box"
                              placeholder="PO Number"
                              autoComplete="off"
                              name="poNumber"
                              id="poNumber"
                              value={this.state.poNumber}
                              onChange={this.handleChange}
                            />
                            <label htmlFor="login-username">PO Number</label>
                          </div>
                        </div>
                      )}

                      {_businessSetting.EWayBillNo == 1 && (
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              className="form-control search-box"
                              placeholder="E Way Bill No"
                              autoComplete="off"
                              name="E_Way_Bill_No"
                              id="E_Way_Bill_No"
                              value={this.state.E_Way_Bill_No}
                              onChange={this.handleChange}
                            />
                            <label htmlFor="login-username">
                              E Way Bill No
                            </label>
                            <div
                              id="E_Way_Bill_No-error"
                              className="animated fadeInDown"
                            >
                              {errors.E_Way_Bill_No.length > 0 && (
                                <span className="error">
                                  {errors.E_Way_Bill_No}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {_businessSetting.DueDatesPaymentTerms == 1 &&
                        this.state.toggleState == false && (
                          <div className="col-6">
                            <div className="form-material open">
                              
                              {this.state.dateCalendarChange == 11 &&
                                  <select
                                  className="form-control "
                                  id="dueTerms"
                                  name="dueTerms"
                                  required="required"
                                  onChange={this.handleChange}
                                >  
                                  <option key="0" value="0" selected="selected">
                                    Custom
                                  </option>
                                  {this.state.paymentTermsData.map(
                                    (item, index) => {
                                      let _selTerms = "";
                                      if (this.state.transactionId == 0 && item.terms_default_status == 1) {
                                        _selTerms = "selected";
                                      }
                                      if (this.state.transactionId > 0 && this.state.dueTerms == item.id
                                      )
                                      {
                                        _selTerms = "selected";
                                      }
                                      if (this.state.transactionId > 0 && this.state.dateCalendarChange == 11){
                                        _selTerms = "";
                                      }
                                      // if(this.state.state == item.name){_sel = 'selected';}
                                      return (
                                        <option
                                          key={index}
                                          value={item.id + "," + item.terms_days}
                                          selected={_selTerms}
                                        >
                                          {item.terms_name}
                                        </option>
                                      );
                                    }
                                  )}                                  
                                </select>
                                }
                                {this.state.dateCalendarChange!=11 &&
                                    <select
                                    className="form-control "
                                    id="dueTerms"
                                    name="dueTerms"
                                    required="required"
                                    onChange={this.handleChange}
                                  >                                  
                                    <option key="0" value="ADDTERMS">
                                      Add Terms
                                    </option>                                  
                                    {this.state.dateCalendarChange == 1 &&
                                      <option key="0" value="0" selected="selected">
                                        Custom
                                      </option>
                                    }
                                    
                                    {this.state.paymentTermsData.map(
                                      (item, index) => {
                                        let _selTerms = "";
                                        if (this.state.transactionId == 0 && item.terms_default_status == 1) {
                                          _selTerms = "selected";
                                        }
                                        
                                        if (this.state.transactionId > 0 && this.state.dueTerms == item.id
                                        )
                                        {
                                          _selTerms = "selected";
                                        }
                                        if (this.state.transactionId > 0 && this.state.dateCalendarChange == 1){
                                          _selTerms = "";
                                          
                                        }
                                        if (this.state.transactionId > 0 && this.state.dateCalendarChange == 11){
                                          _selTerms = "";
                                          
                                        }
                                        // if(this.state.state == item.name){_sel = 'selected';}
                                        return (
                                          <option
                                            key={index}
                                            value={item.id + "," + item.terms_days}
                                            selected={_selTerms}
                                          >
                                            {item.terms_name}
                                          </option>
                                        );
                                      }
                                    )}                                  
                                  </select>
                                }
                              
                              <label htmlFor="login-username">Pmt. Terms</label>
                            </div>
                          </div>
                        )}

                      {_businessSetting.DueDatesPaymentTerms == 1 &&
                        this.state.toggleState == false && (
                          <div className="col-6">
                            {_businessSetting.DueDatesPaymentTerms == 1 &&
                              this.state.pageLoadTerms == 0 && (
                                <div className="form-material open">
                                  {this.isDueOnDateDateRefresh(
                                    moment().format("MM/DD/YYYY")
                                  )}
                                  <label htmlFor="login-username">Due On</label>
                                </div>
                            )} 
                            
                            {_businessSetting.DueDatesPaymentTerms == 1 &&
                              this.state.pageLoadTerms == 1 && (
                                <div className="form-material open">
                                  {this.isDueOnDateDateRefreshOnChange(
                                    this.state.dueOnDate
                                  )}
                                  <label htmlFor="login-username">Due On</label>
                                </div>
                              )}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="" id="formSideRight">
                      {_businessSetting.InvoiceBillNo == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Invoice No. <span className="text-danger">*</span>
                          </label>
                          <div
                            className="col-lg-3 form-material"
                            id="paddingOff"
                          >
                            <select
                              className="form-control "
                              id="selPrefix"
                              name="selPrefix"
                              required="required"
                              onChange={this.handleChange}
                            >
                              {this.state.prefixD == 1 && (
                                <option value="0" selected="selected">
                                  {" "}
                                  None
                                </option>
                              )}
                              
                              {this.state.prefixArray.map(
                                (prefixData, index) => {
                                  
                                  let _selPrefix = "";
                                  if (this.state.transactionId == 0) {
                                    if (
                                      prefixData.prefix == this.state.prefixName
                                    ) {
                                      _selPrefix = "selected";
                                    }
                                  } else {
                                    if (
                                      this.state.prefixId ==
                                      prefixData.prefix_id
                                    ) {
                                      _selPrefix = "selected";
                                    }
                                  }
                                  return (
                                    <option
                                      key={index}
                                      value={prefixData.prefix_id}
                                      selected={_selPrefix}
                                      data-prefixName={prefixData.prefix}
                                      data-prefixId={prefixData.prefix_id}
                                    >
                                      {prefixData.prefix}
                                    </option>
                                  );
                                }
                              )}
                              <option value="ADDPREFIX"> Add New Prefix</option>
                            </select>
                          </div>
                          &nbsp;&nbsp;&nbsp;
                          <div
                            className="col-lg-4 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="invoiceNo"
                              name="invoiceNo"
                              required="required"
                              onChange={this.handleChange}
                              placeholder="Enter a Invoice No."
                              autoComplete="off"
                              value={this.state.invoiceNo}
                            />
                            <div
                              id="invoiceNo-error"
                              className="animated fadeInDown"
                            >
                              {errors.invoiceNo.length > 0 && (
                                <span className="error">
                                  {errors.invoiceNo}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Invoice Date <span className="text-danger">*</span>
                        </label>

                        <div className="col-lg-8 form-material" id="paddingOff">
                          {this.isDateRefresh()}
                        </div>
                      </div>
                      {_businessSetting.Tran_AddTime == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Time
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                            onMouseLeave={this.onMouseLeaveHandler_Time.bind(
                              this,
                              "I"
                            )}
                          >
                            <input
                              readOnly="readOnly"
                              type="text"
                              className="form-control search-box"
                              onChange={this.onTimeFilterName}
                              onClick={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              onKeyDown={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Please Select Time"
                              autoComplete="off"
                              name="invoiceTime"
                              id="invoiceTime"
                              value={this.state.invoiceTime}
                            />
                            <ul
                              className={
                                this.state._timeReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getTimeSlotList()}
                            </ul>
                          </div>
                        </div>
                      )}
                      {_businessSetting.SateOfSupply == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            State of Supply
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <select
                              className="form-control "
                              id="countryState"
                              name="countryState"
                              
                              onChange={this.handleChange}
                            >
                              <option value=""> None</option>
                              {this.state.stateArray.map((item, index) => {
                                let _sel = "";
                                if (this.state.state == item.name) {
                                  _sel = "selected";
                                }
                                return (
                                  <option
                                    key={index}
                                    value={item.name}
                                    selected={_sel}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      )}

                      <div className="form-material open"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Item Data Here by other pages */}
            {this.state.party_name!="" && this.state.party_id > 0 && (
              <QuickTransactionSaleItemSection
                party_id={this.state.party_id}
                fields={this.state.fields}
                transId={_transId}
                sendPageType="Normal"
                callBackItem={(
                  fieldArray,
                  _totalAmountOldSend,
                  _totalAmountNewSend,
                  _totalAmount,
                  _dueAmount,
                  _paidAM,
                  _totalDiscount,
                  _totalGstAmt,
                  _totalQuantity,
                  handleAddDisable,
                  handleItemSalePurchasePrice,
                  _transactionWiseTaxId,
                  _totalTransTaxAmount,
                  _totalTransDiscountPer,
                  _totalTransDiscountAmount
                ) => {
                  this.setState({
                    fields: fieldArray,
                    totalAmountOld: _totalAmountOldSend,
                    totalAmountNew: _totalAmountNewSend,
                    totalAmount: _totalAmount,
                    dueAmount: _dueAmount ? _dueAmount : _totalAmount,
                    receivedAmount: _paidAM,
                    gstAmount: _totalGstAmt,
                    totalDiscount: _totalDiscount,
                    totalQuantity: _totalQuantity,
                    handleAddDisable: handleAddDisable,
                    handleItemSalePurchasePrice: handleItemSalePurchasePrice,
                    transactionWiseTaxId: _transactionWiseTaxId
                      ? _transactionWiseTaxId
                      : 0,
                    totalTransTaxAmount: _totalTransTaxAmount
                      ? _totalTransTaxAmount
                      : 0,
                    totalTransDiscountPer: _totalTransDiscountPer
                      ? _totalTransDiscountPer
                      : 0,
                    totalTransDiscountAmount: _totalTransDiscountAmount
                      ? _totalTransDiscountAmount
                      : 0,
                      TransAdditionalCharge1: 0,
                      TransAdditionalCharge2: 0,
                      TransAdditionalCharge3: 0,
                  });
                  this.getPartyListData();
                }}
              />
            )}
            {/* Item Data Here by other pages */}

            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="form-material open" id="paddingOff">
                          <select
                            className="col-8 form-control"
                            defaultValue={
                              this.state.bankId > 0
                                ? this.state.bankId
                                : this.state.paymentType
                            }
                            id="paymentType"
                            name="paymentType"
                            onChange={this.handleChange}
                          >
                            {this.settleAmountType()}
                          </select>
                        </div>
                        {this.state.paymentType!="Cash" && (
                          <div className="form-material open">
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.paymentRefNo}
                                required="required"
                                id="paymentRefNo"
                                name="paymentRefNo"
                                onChange={this.handleChange}
                                placeholder="Enter Reference No.."
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        )}
                        <div className="form-material open">
                          <div className="col-12 form-material" id="paddingOff">
                            <input
                              type="text"
                              className="form-control"
                              required="required"
                              id="description"
                              name="description"
                              onChange={this.handleChange}
                              placeholder="Enter a description"
                              autoComplete="off"
                              value={this.state.description}
                            />
                          </div>
                        </div>
                        <div className="form-material open">
                          <div className="col-8 form-material" id="paddingOff">
                            <input
                              type="file"
                              accept="image/*"
                              style={{ width: "60%", display: "inline-block" }}
                              autoComplete="false"
                              className="form-control"
                              id="descImage"
                              name="descImage"
                              onChange={this.onChangeFileUploadHandler}
                            />
                            {this.previewImagesListing()}
                          </div>
                        </div>

                        {_businessSetting.PrintOriginalDuplicate == 1 && (
                          <div className="form-material open">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-form-label"
                              htmlFor="totalAmount"
                            >
                              Original / Duplicate Setting
                            </label>
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <select
                                className="col-8 form-control"
                                id="settingType"
                                name="settingType"
                                onChange={this.handleChange}
                              >
                                {optionsData.map(({ value, label }, index) => {
                                  let _selSet = "";
                                  if (this.state.settingType == value) {
                                    _selSet = "selected";
                                  }
                                  return (
                                    <option
                                      key={index}
                                      value={value}
                                      selected={_selSet}
                                    >
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    {this.state.fields.length > 0 && (
                      <div id="taxDisData">
                        {_businessSetting.TransactionWiseDiscount == 1 && (
                          <div className="col-md-12" id="formSideRight">
                            <div className="form-group row">
                              <label
                                style={{
                                  textAlign: "right",
                                  margin: "25px 0 0 0",
                                }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalAmount"
                              >
                                Discount %
                              </label>
                              <div
                                className="col-lg-4 form-material"
                                id=""
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransDiscountPer"
                                  name="totalTransDiscountPer"
                                  onChange={this.handleChange}
                                  placeholder="In %"
                                  className="form-control"
                                  autoComplete="off"
                                  value={this.state.totalTransDiscountPer}
                                />
                              </div>
                              <div
                                className="col-lg-4 form-material"
                                id=""
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransDiscountAmount"
                                  name="totalTransDiscountAmount"
                                  onChange={this.handleChange}
                                  placeholder=""
                                  className="form-control"
                                  value={this.state.totalTransDiscountAmount}
                                  autoComplete="off"
                                  style={{
                                    padding: "0 !important",
                                    margin: "0 !important",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {_businessSetting.TransactionWiseTax == 1 && (
                          <div className="col-md-12" id="formSideRight">
                            <div className="form-group row">
                              <label
                                style={{
                                  textAlign: "right",
                                  margin: "25px 0 0 0",
                                }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalAmount"
                              >
                                Tax{" "}
                              </label>
                              <div
                                className="col-lg-4 form-material"
                                id="paddingOff"
                                style={{
                                  padding: "0 !important",
                                  margin: "0 0 !important",
                                }}
                              >
                                <select
                                  style={{ width: "85px" }}
                                  className="form-control"
                                  id="taxTypeTrans"
                                  name="taxTypeTrans"
                                  onChange={this.handleDynamicInputsTrans.bind(
                                    this
                                  )}
                                >
                                  <option value="0@0" selected>
                                    {" "}
                                    None
                                  </option>
                                  {this.state.taxRateArray.map((tax, index) => {
                                    let _sel = "";
                                    if (
                                      this.state.transactionWiseTaxId == tax.id
                                    ) {
                                      _sel = "selected";
                                    }
                                    return (
                                      <option
                                        key={index}
                                        value={tax.id + "@" + tax.ratio}
                                        selected={_sel}
                                      >
                                        {tax.name + "@" + tax.ratio + "%"}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div
                                className="col-lg-4 form-material"
                                id="paddingOff"
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransTaxAmount"
                                  name="totalTransTaxAmount"
                                  placeholder=""
                                  className={"form-control " + _readonlyCss}
                                  autoComplete="off"
                                  value={this.state.totalTransTaxAmount}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {/*Additional Charges */}
                        {_businessSetting.TransactionAdditionalChargeEnable ==
                          1 && (
                          <div id="taxDisData">
                            {_businessSetting.TransactionAdditionalCharge1Enable ==
                              1 && (
                              <div className="col-md-12" id="formSideRight">
                                <div className="form-group row">
                                  <label
                                    style={{ textAlign: "right" }}
                                    className="col-lg-4 col-form-label"
                                    htmlFor="TransAdditionalCharge1"
                                  >
                                    {
                                      _businessSetting.TransactionAdditionalCharge1Name
                                    }
                                  </label>
                                  <div
                                    className="col-lg-8 form-material"
                                    id="paddingOff"
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="TransAdditionalCharge1"
                                      name="TransAdditionalCharge1"
                                      onChange={this.handleChange}
                                      placeholder=""
                                      value={this.state.TransAdditionalCharge1}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {_businessSetting.TransactionAdditionalCharge2Enable ==
                              1 && (
                              <div className="col-md-12" id="formSideRight">
                                <div className="form-group row">
                                  <label
                                    style={{ textAlign: "right" }}
                                    className="col-lg-4 col-form-label"
                                    htmlFor="TransAdditionalCharge2"
                                  >
                                    {
                                      _businessSetting.TransactionAdditionalCharge2Name
                                    }
                                  </label>
                                  <div
                                    className="col-lg-8 form-material"
                                    id="paddingOff"
                                  >
                                    {/* <input
                                      type="number"
                                      className="form-control"
                                      id="TransAdditionalCharge2"
                                      name="TransAdditionalCharge2"
                                      onChange={this.handleChange}
                                      placeholder=""
                                      value={this.state.TransAdditionalCharge2}
                                      autoComplete="off"
                                    /> */}
                                    <input
                                      type="text"
                                      id="TransAdditionalCharge2"
                                      name="TransAdditionalCharge2"
                                      onChange={this.handleChange}
                                      placeholder=""
                                      className="form-control"
                                      value={this.state.TransAdditionalCharge2}
                                      autoComplete="off"
                                      style={{
                                        padding: "0 !important",
                                        margin: "0 !important",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {_businessSetting.TransactionAdditionalCharge3Enable ==
                              1 && (
                              <div className="col-md-12" id="formSideRight">
                                <div className="form-group row">
                                  <label
                                    style={{ textAlign: "right" }}
                                    className="col-lg-4 col-form-label"
                                    htmlFor="TransAdditionalCharge3"
                                  >
                                    {
                                      _businessSetting.TransactionAdditionalCharge3Name
                                    }
                                  </label>
                                  <div
                                    className="col-lg-8 form-material"
                                    id="paddingOff"
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="TransAdditionalCharge3"
                                      name="TransAdditionalCharge3"
                                      onChange={this.handleChange}
                                      placeholder=""
                                      value={this.state.TransAdditionalCharge3}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {/*End Additional Charges */}
                      </div>
                    )}

                    <div className="col-md-12" id="formSideRight">
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="totalAmount"
                        >
                          Total Amount
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="form-control disabled"
                            id="totalAmount"
                            name="totalAmount"
                            onChange={this.handleChange}
                            placeholder=""
                            value={this.state.totalAmount}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      {this.state.toggleState == false &&
                        <div >
                        {/* className={_hideRows} */}
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="receivedAmount"
                            >
                              Received Amount
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="number"
                                readOnly={_readonlyPaidInput}
                                className="form-control"
                                id="receivedAmount"
                                name="receivedAmount"
                                placeholder=""
                                value={this.state.receivedAmount}
                                onChange={this.handleChange}
                                autoComplete="off"
                                onWheel={() => document.activeElement.blur()}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="dueAmount"
                            >
                              Balance Due
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                readOnly="readOnly"
                                className="form-control"
                                id="dueAmount"
                                name="dueAmount"
                                placeholder=""
                                value={
                                  this.state.dueAmount > 0
                                    ? this.state.dueAmount
                                    : 0
                                }
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      }
                     
                      {/* {_businessSetting.TransactionAdditionalFieldSale >0 && */}
                      <div id="additionFields">
                        {(_businessSetting.additional_field_check1 == 1 ||
                          _businessSetting.additional_field_check1 ===
                            true) && (_businessSetting.show_field_sale == true) && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="addF1"
                            >
                              {_businessSetting.additional_field_name1}
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="additional_value_1"
                                name="additional_value_1"
                                placeholder=""
                                value={this.state.additional_value_1}
                                autoComplete="off"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        )}

                        {(_businessSetting.additional_field_check2 == 1 ||
                          _businessSetting.additional_field_check2 ===
                            true) && (_businessSetting.show_field_sale == true) && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="addF2"
                            >
                              {_businessSetting.additional_field_name2}
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="additional_value_2"
                                name="additional_value_2"
                                placeholder=""
                                value={this.state.additional_value_2}
                                autoComplete="off"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        )}

                        {(_businessSetting.additional_field_check3 == 1 ||
                          _businessSetting.additional_field_check3 ===
                            true) && (_businessSetting.show_field_sale == true) && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="addF3"
                            >
                              {_businessSetting.additional_field_name3}
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="additional_value_3"
                                name="additional_value_3"
                                placeholder=""
                                value={this.state.additional_value_3}
                                autoComplete="off"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {(_businessSetting.additional_field_date_field_check ===
                          1 ||
                          _businessSetting.additional_field_date_field_check ===
                            true) && (_businessSetting.show_field_sale == true) && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="addFDate"
                            >
                              {
                                _businessSetting.additional_field_date_field_name
                              }
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              {this.isAdditionalDateRefresh()}
                            </div>
                          </div>
                        )}
                      </div>
                      {/* } */}
                    </div>
                  </div>
                </div>
              </div>

              {_businessSetting.TransportationDetailsField1Enable == 1 && (
                // Transportation Detail Here
                // <QuickTransportationDetails />
                <div className="col-md-12">
                  <div className="block block-transparent">
                    <div className="block-content">
                      <h5 className="content-heading">
                        Transportation Details
                      </h5>
                      <div className="block">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="block block-transparent">
                              <div className="block-content" id="formSideLeft">
                                <div className="form-group row">
                                  {_businessSetting.TransportationDetailsField1Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField1Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField1"
                                          id="TransportDetailField1"
                                          value={
                                            this.state.TransportDetailField1
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField1Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField2Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField2Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField2"
                                          id="TransportDetailField2"
                                          value={
                                            this.state.TransportDetailField2
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField2Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField3Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField3Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField3"
                                          id="TransportDetailField3"
                                          value={
                                            this.state.TransportDetailField3
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField3Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField4Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField4Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField4"
                                          id="TransportDetailField4"
                                          value={
                                            this.state.TransportDetailField4
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField4Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField5Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField5Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField5"
                                          id="TransportDetailField5"
                                          value={
                                            this.state.TransportDetailField5
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField5Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField6Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField6Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField6"
                                          id="TransportDetailField6"
                                          value={
                                            this.state.TransportDetailField6
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField6Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <div className="row justify-content-center text-right">
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      onClick={this.clickToAddSale}
                      disabled={isDisabled}
                      className="btn btn-alt-primary mb-10 mx-5"
                    >
                      Save{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Edit Party Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SP}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewParty"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Party</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewParty"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewParty isRedirectTo={"business/add-sale/"} />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewParty"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Party Details*/}

          {/* Modal Add Bank Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SBANK}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewBank"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Bank</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewBank"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewBankDetail
                      isRedirectTo={"business/add-sale/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewBank"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Bank Details*/}

          {/* Modal Low Stock Item Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_LowStock}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalLowStockItem"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">
                      <i
                        className="fa fa-warning"
                        style={{ color: "yellow" }}
                      ></i>
                      &nbsp;&nbsp;Low Stock
                    </h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandlerLowStock.bind(
                          this,
                          "modalLowStockItem"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <p>
                      Your stock for following item(s) is not suifficient to
                      fulfil this sale
                    </p>
                    <ul className="ml-50">
                      {this.state.lowStokItemName!="" && _finalStockHtml}
                    </ul>

                    <p>Do you really want to save this sale?</p>
                    <p>
                      {" "}
                      <input
                        type="checkbox"
                        value="1"
                        onChange={this.handleChecked}
                      />
                      &nbsp;&nbsp;Don't show this again
                    </p>
                    <button
                      type="button"
                      className="btn btn-alt-secondary"
                      onClick={this.closeModalHandlerLowStock.bind(
                        this,
                        "modalLowStockItem"
                      )}
                    >
                      No
                    </button>

                    <button
                      type="button"
                      className="btn btn-alt-primary  ml-20"
                      onClick={this.SaveModalHandlerLowStock.bind(
                        this,
                        "modalLowStockItem"
                      )}
                    >
                      Yes
                    </button>
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandlerLowStock.bind(
                      this,
                      "modalLowStockItem"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Low Stock Item Details*/}

          {/* Modal Add New Prefix Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_ADDPREFIX}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustADDPREFIX"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Prefix</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustADDPREFIX"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewPrefix
                      callBack={(newPrefixId, newPrefixName) => {
                        this.setState({
                          prefixId: newPrefixId,
                          prefixName: newPrefixName,
                          modalIsOpen_ADDPREFIX: false,
                        });

                        this.businessPrefixList();
                      }}
                      transactionType="SALE"
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustADDPREFIX"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add New Prefix Details*/}
          
          {/* Modal Add Terms Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_AddTerm}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustAddNewTerms"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Due Dates and Payment terms</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustAddNewTerms"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAdjustAddNewTerms
                      callBack={this.getUpdateResponse}
                      callBackTerms={() => {
                        this.setState({
                          modalIsOpen_AddTerm: false,
                        });
                      }}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustAddNewTerms"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Terms  Details*/}

        </div>
      </React.Fragment>
    );
  }
}
