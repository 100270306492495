import React, { Component, createContext, useEffect, useState } from 'react';

import './App.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter as Router, useLocation} from 'react-router-dom'
import { Redirect, Route, Switch} from 'react-router'
import routes from './routes/routes';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

//import { ToastContainer } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";

//----Define Pages via Order-----
import accountLoginPanel from './accountLogin_folder/accountLogin';
import accountRegisterPanel from './accountLogin_folder/accountRegister';
import PrivatePanel from './private/private';
import BusinessPanel from './private/customer';
import UserPanel from './private/user-NoUse';

// --- Define Website Page Here ---
import homePanel from './websiteFolder/home';
import aboutPanel from './websiteFolder/aboutUs';
import faqPanel from './websiteFolder/faq';
import partnerUsPanel from './websiteFolder/partnerUs';
import termsConditionPanel from './websiteFolder/termsCondition';
import pricePanel from './websiteFolder/price';
import blogPanel from './websiteFolder/blog';
import blogDetailsPanel from './websiteFolder/blogDetails';
import featuresPanel from './websiteFolder/features';
import privacyPolicyPanel from './websiteFolder/privacyPolicy';
import contactSupportPanel from './websiteFolder/contact';
import demoVideoPanel from './websiteFolder/demoVideo';


class App extends Component{
  render(){
    return(
      <div id="njDirectory">
          <Router>
            <div>
              <Switch>
                <Route exact path="/" component={homePanel} />
                <Route exact path="/about-us/" component={aboutPanel} />
                <Route exact path="/pricing/" component={pricePanel} />
                <Route exact path="/blogs/" component={blogPanel} />
                <Route exact path={["/blog/:blogId/:blogTxt"]} path="/blog/:blogId/:blogTxt" component={blogDetailsPanel} />

                <Route exact path="/privacy-policy/" component={privacyPolicyPanel} />
                <Route exact path="/contact/" component={contactSupportPanel} />
                <Route exact path="/demo-videos/" component={demoVideoPanel} />
                <Route exact path="/features/" component={featuresPanel} />
                <Route exact path="/faq/" component={faqPanel} />
                <Route exact path="/partner-with-us/" component={partnerUsPanel} />
                <Route exact path="/terms-condition/" component={termsConditionPanel} />


                <Route exact path={routes.accountLoginPanel} component={accountLoginPanel}/>
                <Route exact path={routes.accountRegisterPanel} component={accountRegisterPanel}/>
                <Route path={routes.privatePanel} component={PrivatePanel} />
                <Route path={routes.businessPanel} component={BusinessPanel} />
                <Route path={routes.userPanel} component={UserPanel} />
              </Switch>
            </div>
          </Router>
          <ReactNotification />
      </div>
    )
  }
}
export default App;