import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewParty from "../businessParty/quickPartyAdd";
//import QuickAddNewItem from '../userItem/quickSaleItemAdd';

import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import QuickTransactionItemSection from "./quickTransactionItemSection";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let maxNumber = 999999;
let _invoiceNumber = Math.floor(Math.random() * maxNumber + 1);
 
let maxNumberRet = 9999;
let _invoiceNoReturn = Math.floor(Math.random() * maxNumberRet + 1);
let _colorVal = "";
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _bankArray = [];
let _settingType = "showall";
_bankArray.push(
  { bank_id: "0", bank_name: "", paymentType: "Cash" },
  { bank_id: "0", bank_name: "", paymentType: "Cheque" }
);

let _businessSetting = {};
/*For State Data*/

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class userSaleListPanel extends Component {
  constructor(props) {
    super(props);
    let _UserSession = localStorage.getItem("vs_UserSession");

    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
      _businessSetting = _UserSession.businessSetting;

      if (_businessSetting!=undefined || _businessSetting!=null) {
        if (_businessSetting.PrintOriginalDuplicate == 0) {
          _settingType = null;
        }
      }
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;

      let bankArrayData = _companySession.bankDetails
        ? _companySession.bankDetails
        : [];
      bankArrayData.map((bank, index) => {
        if (
          !(
            _bankArray.filter((item) => item.bank_name == bank.bank_name)
              .length > 0
          )
        ) {
          _bankArray.push(bank);
        }
      });
    }
    this.state = {
      buttonTitle: "Add Purchase",
      _timeReg: "iti__hide",

      typeTrans: "DEBIT NOTE",
      partyRemainBalance: 0,
      _partyReg: "iti__hide",
      party_id: "0",
      party_name: "",
      partyArrayFilter: [],
      partyArray: [],
      stateArray: [],
      itemArray: [],
      itemArrayFilter: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      modalIsOpen_SP: false,
      modalIsOpen_SBANK: false,

      billing_name: "",
      invoiceTime: _businessSetting.Tran_AddTime > 0 ? moment().format("h:mm A") : moment().format("h:mm A"),

      _itemReg: "iti__hide",
      _itemRegIndex: "0",

      isLoaded: true,
      isDateUpdate: false,

      receivedHide: "iti__hide",

      rowHtml: "",
      countryCode: _countryCode,
      countryName: _countryName,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      paymentType: "Cash",
      settingType: _settingType,
      invoiceNo: _invoiceNumber,
      invoiceNoReturn: _invoiceNoReturn,
      rate_tax: "With Tax",
      invoiceDate: moment().format("MM/DD/YYYY"),
      invoiceDateReturn: moment().format("MM/DD/YYYY"),
      billingAddress: "",
      countryState: "",
      paymentRefNo: "",
      description: "",
      descImage: "",
      imagePreviewUrl: "",
      bankId: "0",
      bankName: "",

      dueAmount: 0,
      receivedAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: 0,
      totalAmountOld: 0,
      totalTransDiscountAmount: 0.0,
      totalTransTaxAmount: 0.0,
      transactionWiseTaxId: 0,
      EnableItem: _businessSetting.EnableItem,
      FreeItemQuantity: _businessSetting.FreeItemQuantity,
      TransCount: _businessSetting.Count,
      TransAdditionalCharge1: 0,
      TransAdditionalCharge2: 0,
      TransAdditionalCharge3: 0,
      TransportDetailField1: "",
      TransportDetailField2: "",
      TransportDetailField3: "",
      TransportDetailField4: "",
      TransportDetailField5: "",
      TransportDetailField6: "",
      handleAddDisable: "",
      handleItemSalePurchasePrice: "",
      party_contact_no: "",

      errors: {
        invoiceNo: "",
        invoiceNoReturn: "",
        invoiceDate: "",
        billingAddress: "",
        invoiceDateReturn: "",
        party_id: "",
        party_name: "",
        party_contact_no: "",
      },
    };
  }

  async componentDidMount() {
    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let transactionId = _handleParam[1] ? _handleParam[1] : 0;
    if (transactionId > 0) {
      _isEditMode = true;
    }

    this.getStateListData();
    //this.getItemListData(_company_id);

    /*For master Units*/
    let _urlDataUnits =
      global.userUnitList +
      "?businessId=" +
      _businessId +
      "&company_id=" +
      this.state.company_id;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    /*For master Units*/

    this.setState({ unitArray: _unitArray, isLoaded: true });

    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            //this.setState({unitArray:_unitArray,isLoaded:true});
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href = this.state.isRedirectUrl;
              return;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let transAmout = _transactionArray.totalSummary;
              let itemsData = _transactionArray.items;
              let customerData = _transactionArray.customer;

              let _imgPath = "";
              if (_transactionArray.descImage!="") {
                _imgPath =
                  global.LIVE_URL +
                  "uploadDirectory/transaction/" +
                  _transactionArray.descImage;
              }

              let rediretURL = global.webUrl + "business/purchase-invoice/";
              if (_transactionArray.type == "DEBIT NOTE") {
                rediretURL = global.webUrl + "business/manage-purchase-return/";
              } else if (_transactionArray.type == "PURCHASE") {
                rediretURL = global.webUrl + "business/purchase-invoice/";
              } else if (_transactionArray.type == "PURCHASE ORDER") {
                rediretURL = global.webUrl + "business/manage-purchase-order/";
              }

              this.setState({
                billing_name: customerData.billing_name
                  ? customerData.billing_name
                  : "",
                invoiceTime: _transactionArray.invoice_time,

                typeTrans: _transactionArray.type,
                isRedirectUrl: rediretURL,
                party_id: customerData.party_id,
                party_name: customerData.party_name,
                bankId: _transactionArray.bankId,
                bankName: _transactionArray.bankName,
                unitArray: _unitArray,
                buttonTitle: "Edit Purchase",
                invoiceNo: _transactionArray.invoiceNo,
                invoiceDate: moment(_transactionArray.invoiceDate).format(
                  "MM/DD/YYYY"
                ),
                state: _transactionArray.countryState,
                countryState: _transactionArray.countryState,
                businessId: _transactionArray.business_id,
                companyId: _transactionArray.company_id,
                totalAmount: transAmout.totalAmount,
                paidAmount: transAmout.paidAmount,
                receivedAmount: transAmout.receivedAmount,
                subtotal: transAmout.subtotal,
                gstRatio: transAmout.gstRatio,
                gstAmount: transAmout.gstAmount,
                totalQuantity: transAmout.totalQty,
                totalDiscount: transAmout.totalDiscount,
                dueAmount: transAmout.totalAmount,
                description: _transactionArray.description,
                paymentType: "Cash",
                paymentRefNo: _transactionArray.paymentRefNo,
                fields: itemsData,
                isLoaded: true,
                transactionId: transactionId,
                descImage: _transactionArray.descImage,
                imagePreviewUrl: _imgPath,
                isDateUpdate: true,
                EnableItem: _transactionArray.EnableItem,
                FreeItemQuantity: _transactionArray.FreeItemQuantity,
                TransCount: _transactionArray.Count,
                settingType: _transactionArray.settingType,
                totalAmountOld: transAmout.totalAmount,
                transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                totalTransDiscountAmount:
                  _transactionArray.totalTransDiscountAmount,
                TransAdditionalCharge1:
                  _transactionArray.TransAdditionalCharge1,
                TransAdditionalCharge2:
                  _transactionArray.TransAdditionalCharge2,
                TransAdditionalCharge3:
                  _transactionArray.TransAdditionalCharge3,
                TransportDetailField1: _transactionArray.TransportDetailField1,
                TransportDetailField2: _transactionArray.TransportDetailField2,
                TransportDetailField3: _transactionArray.TransportDetailField3,
                TransportDetailField4: _transactionArray.TransportDetailField4,
                TransportDetailField5: _transactionArray.TransportDetailField5,
                TransportDetailField6: _transactionArray.TransportDetailField6,
                party_contact_no: _transactionArray.party_contact_no,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
    this.getPartyListData();
  }

  getStateListData() {
    let _stateArray = [];

    let _url_GetData =
      global.adminStateList + "?countryCode=" + this.state.countryCode;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _stateArray = responseJson.response;
          }
          this.setState({ stateArray: _stateArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getPartyListData() {
    let _partyArray = [];
    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _partyBal = 0;
          if (responseJson.response) {
            _partyArray = responseJson.response;

            if (this.state.party_id > 0) {
              _partyArray.map((party, index) => {
                if (party.party_id == this.state.party_id) {
                  _partyBal = party.totalOutCome;
                }
              });
            }
          }
          this.setState({
            partyArrayFilter: _partyArray,
            partyArray: _partyArray,
            isLoaded: true,
            partyRemainBalance: _partyBal,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getTotalAmount(value, _type) {
    let _mainSubTotalAmt = 0;
    let _totalOtherCharges = 0;
    this.state.fields.map((data) => {
      _mainSubTotalAmt +=
        parseFloat(data.total_amount) +
        parseFloat(data.tax_value) -
        parseFloat(data.discount_in_amount);
    });
    let _totalTaxAmount = _mainSubTotalAmt;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1
      ? this.state.TransAdditionalCharge1
      : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2
      ? this.state.TransAdditionalCharge2
      : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3
      ? this.state.TransAdditionalCharge3
      : 0;

    let _totalTransTaxAmount = this.state.totalTransTaxAmount
      ? this.state.totalTransTaxAmount
      : 0;

    let _totalTranDisAmount = this.state.totalTransDiscountAmount
      ? this.state.totalTransDiscountAmount
      : 0;

    if (_type == "totalTransDiscountPer")
    {
      _totalTranDisAmount = (parseFloat(_totalTaxAmount)) * ((parseFloat(value)) / parseFloat(100));
    }
    if (_type == "totalTransDiscountAmount") 
    {
      _totalTranDisAmount = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    if (_type == "taxTypeTrans") {
      _totalTransTaxAmount = parseFloat(value);
    }
    let _totalAmount = parseFloat(_totalTaxAmount) - parseFloat(_totalTranDisAmount);

    _totalOtherCharges =
      parseFloat(_TransAdditionalCharge1) +
      parseFloat(_TransAdditionalCharge2) +
      parseFloat(_TransAdditionalCharge3) +
      parseFloat(_totalTransTaxAmount);

    _totalAmount = parseFloat(_totalAmount) + parseFloat(_totalOtherCharges);
    return _totalAmount;
  }

  getTotalDueAmount(_totalAmount) {
    let _dueAmountAK = _totalAmount;
    if (this.state.receivedAmount > 0) {
      _dueAmountAK = parseFloat(_totalAmount) - parseFloat(this.state.receivedAmount);
    }
    if (_dueAmountAK < 0) {
      _dueAmountAK = 0;
    }
    return _dueAmountAK;
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "invoiceNo":
        errors.invoiceNo = value.length < 1 ? "*Bill No Must be 1 numbers" : "";
        if (validator.isInt(value)) {
          errors.invoiceNo = "";
        } else {
          errors.invoiceNo = "*Please enter only numeric value.";
        }
        this.setState({ invoiceNo: value });
        break;

      case "invoiceNoReturn":
        errors.invoiceNoReturn =
          value.length < 1 ? "*Return No Must be 1 numbers" : "";
        this.setState({ invoiceNoReturn: value });
        break;

      case "invoiceDate":
        errors.invoiceDate =
          value.length < 5 ? "* Bill date must be selected" : "";
        this.setState({ invoiceDate: value });
        break;

      case "invoiceDateReturn":
        errors.invoiceDateReturn =
          value.length < 5 ? "* Due date must be selected" : "";
        this.setState({ invoiceDateReturn: value });
        break;

      case "countryState":
        errors.countryState =
          value.length < 0 ? "* State must be selected" : "";
        this.setState({ countryState: value });
        break;

      case "party_contact_no":
        if (validator.isMobilePhone(value)) {
          errors.party_contact_no = "";
        } else {
          if (value.length == 0 || value == "") {
            errors.party_contact_no = "";
          } else {
            errors.party_contact_no = "*Please enter valid mobile number!";
          }
        }
        this.setState({ party_contact_no: value });
        break;

      case "paymentType":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        //console.log(value);
        let _valType = "";

        if (this.state.transactionId > 0) {
          this.setState({ paymentRefNo: "" });
        }
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        //console.log(_bankId+'--'+_bankName+'---'+value+'----'+_valType);
        this.setState({
          paymentType: _valType,
          bankId: _bankId,
          bankName: _bankName,
        });
        break;
      case "receivedAmount":
        let _remainAmount = 0;
        let _paidAmt = value;
        if (_paidAmt == "") {
          _paidAmt = 0;
        }
        if (_paidAmt >= 0) {
          _remainAmount =
            parseFloat(this.state.totalAmount) - parseFloat(_paidAmt);
        }
        this.setState({
          receivedAmount: _paidAmt,
          dueAmount: _remainAmount.toFixed(2),
        });
        break;
      case "paymentRefNo":
        errors.paymentRefNo =
          value.length < 1 ? "* Payment  must be selected" : "";
        this.setState({ paymentRefNo: value });
        break;
      case "description":
        this.setState({ description: value });
        break;
      case "billingAddress":
        this.setState({ billingAddress: value });
        break;
      case "totalAmount":
        let _balanaceDue = this.state.dueAmount;
        if (this.state.fields.length == 0) {
          _balanaceDue = value;
        }

        this.setState({
          totalAmount: value,
          dueAmount: _balanaceDue,
          totalAmountOld: value,
        });
        break;

      case "billing_name":
        this.setState({ billing_name: value });
        break;
      case "invoiceTime":
        this.setState({ invoiceTime: value });
        break;

      case "settingType":
        this.setState({ settingType: value });
        break;

      case "totalTransDiscountPer":
        if (value > 100) {
          alert("Discount cannot be more than 100%");
        } else {
          let _totalTaxAmount = this.state.totalAmount;
          if (this.state.totalAmountOld > 0) {
            _totalTaxAmount = this.state.totalAmountOld;
          }
          let _finalTotalAmt = this.getTotalAmount(
            value,
            "totalTransDiscountPer"
          );
          let _finalDueAmt = this.getTotalDueAmount(_finalTotalAmt);
          let _mainSubTotalAmt = 0;

          this.state.fields.map((data) => {
            console.log(data.subtotal + "==Amt--", JSON.stringify(data));
            _mainSubTotalAmt +=
              parseFloat(data.total_amount) +
              parseFloat(data.tax_value) -
              parseFloat(data.discount_in_amount);
          });
          let _totalTranDisAmount =
            (parseFloat(_mainSubTotalAmt) * parseFloat(value)) /
            parseFloat(100);

          this.setState({
            totalAmount: _finalTotalAmt.toFixed(2),
            totalTransDiscountAmount: _totalTranDisAmount.toFixed(2),
            totalTransDiscountPer: value,
            dueAmount: _finalDueAmt.toFixed(2),
          });

          if (value <= 0 || value == "") {
            this.setState({
              totalAmount: _finalTotalAmt,
              totalTransDiscountAmount: 0,
              totalTransDiscountPer: 0,
              dueAmount: _finalDueAmt,
            });
          }
        }
        /*if(value > 100){
                    alert("Discount cannot be more than 100%");
                }
                else{
                    let _totalTaxAmount = this.state.totalAmount ;
                    if(this.state.totalAmountOld >0){
                        _totalTaxAmount = this.state.totalAmountOld ;
                    }
                    let _totalTranDisAmount = (parseFloat(_totalTaxAmount)) * parseFloat(value) / parseFloat(100);   

                    let _totalAmount = parseFloat(_totalTaxAmount) - parseFloat(_totalTranDisAmount);
                    
                    this.setState({totalAmount:_totalAmount.toFixed(2),totalTransDiscountAmount:_totalTranDisAmount.toFixed(2),totalTransDiscountPer :value,dueAmount:_totalAmount.toFixed(2)});

                    if(value <= 0 || value == ''){
                        this.setState({totalAmount:_totalTaxAmount,totalTransDiscountAmount:0,totalTransDiscountPer :0});
                    }
                    
                    if(this.state.totalTransTaxAmount > 0 && (value <= 0 || value == '')){
                        let totalFTaxAmt = parseFloat(_totalTaxAmount) + parseFloat(this.state.totalTransTaxAmount);

                        this.setState({totalAmount:totalFTaxAmt.toFixed(2),dueAmount:totalFTaxAmt.toFixed(2)});
                    }
                    
                    if(this.state.totalTransTaxAmount > 0 && (value > 0)){
                        let totalFTaxAmt = parseFloat(_totalAmount) + parseFloat(this.state.totalTransTaxAmount);
                        
                        this.setState({totalAmount:totalFTaxAmt.toFixed(2),dueAmount:totalFTaxAmt.toFixed(2)});
                    }
                }*/
        break;

      case "totalTransDiscountAmount":
        let _totalTaxAmount = this.state.totalAmount;
        if (parseFloat(value) > parseFloat(_totalTaxAmount)) {
          alert("Discount amount cannot be more than total amount.");
        } else {
          let _finalTotalAmt = this.getTotalAmount(
            value,
            "totalTransDiscountAmount"
          );
          let _finalDueAmt = this.getTotalDueAmount(_finalTotalAmt);

          let _totalTranPerAmount =
            parseFloat(value * 100) / parseFloat(_totalTaxAmount);

          this.setState({
            totalAmount: _finalTotalAmt.toFixed(2),
            totalTransDiscountPer: _totalTranPerAmount.toFixed(2),
            totalTransDiscountAmount: value,
            dueAmount: _finalDueAmt.toFixed(2),
          });

          if (value <= 0 || value == "") {
            this.setState({
              totalAmount: _finalTotalAmt,
              totalTransDiscountAmount: value,
              totalTransDiscountPer: 0,
            });
          }
        }
        /*let _totalTaxAmount = this.state.totalAmount ;
                if(parseFloat(value) > parseFloat(_totalTaxAmount))
                {
                    alert("Discount amount cannot be more than total amount.");
                }
                else
                {
                    if(this.state.totalAmountOld >0){
                        _totalTaxAmount = this.state.totalAmountOld ;
                    }              
                    let _totalTranPerAmount = parseFloat(value * 100) / parseFloat(_totalTaxAmount);                 
                    let _totalAmount = parseFloat(_totalTaxAmount) - parseFloat(value);
                            
                    this.setState({totalAmount:_totalAmount.toFixed(2),totalTransDiscountPer :_totalTranPerAmount.toFixed(2),totalTransDiscountAmount:value,dueAmount:_totalAmount.toFixed(2)});

                    if(value <= 0 || value == ''){
                        this.setState({totalAmount:_totalTaxAmount,totalTransDiscountAmount:value,totalTransDiscountPer :0});
                    }

                    if(this.state.totalTransTaxAmount > 0 && (value <= 0 || value == '')){
                        let totalFTaxAmt = parseFloat(_totalTaxAmount) + parseFloat(this.state.totalTransTaxAmount);
                        
                        this.setState({totalAmount:totalFTaxAmt.toFixed(2),dueAmount:totalFTaxAmt.toFixed(2)});
                    }
                    
                    if(this.state.totalTransTaxAmount > 0 && (value > 0)){
                        let totalFTaxAmt = parseFloat(_totalAmount) + parseFloat(this.state.totalTransTaxAmount);
                        
                        this.setState({totalAmount:totalFTaxAmt.toFixed(2),dueAmount:totalFTaxAmt.toFixed(2)});
                    }
                }*/
        break;

      case "TransAdditionalCharge1":
        if (validator.isInt(value)) {
          let _val1 = value;
          if (value == "" || value == null) {
            _val1 = 0;
          }
          let _finalTotalAmt = this.getTotalAmount(
            value,
            "TransAdditionalCharge1"
          );
          let _finalDueAmt = this.getTotalDueAmount(_finalTotalAmt);

          this.setState({
            totalAmount: _finalTotalAmt.toFixed(2),
            TransAdditionalCharge1: _val1,
            dueAmount: _finalDueAmt.toFixed(2),
          });
        } else {
          if (value < 0) {
            alert("Charges can not be less than zero");
          }
        }
        /*
                let _tADDCAmount = this.state.totalAmount ;
                if(this.state.totalAmountOld >0){
                    _tADDCAmount = this.state.totalAmountOld ;
                }  
                let _val1 = value;  
                if(value == '' || value == null){
                    _val1 = 0;
                }     
                let _totalAmountCharge1 = parseFloat(_tADDCAmount) + parseFloat(_val1) + parseFloat(this.state.TransAdditionalCharge2) + parseFloat(this.state.TransAdditionalCharge3);              
                this.setState({totalAmount:_totalAmountCharge1.toFixed(2),TransAdditionalCharge1 :_val1,dueAmount:_totalAmountCharge1.toFixed(2)});*/
        break;

      case "TransAdditionalCharge2":
        if (validator.isInt(value)) {
          let _val1 = value;
          if (value == "" || value == null) {
            _val1 = 0;
          }
          let _finalTotalAmt = this.getTotalAmount(
            value,
            "TransAdditionalCharge2"
          );
          let _finalDueAmt = this.getTotalDueAmount(_finalTotalAmt);

          this.setState({
            totalAmount: _finalTotalAmt.toFixed(2),
            TransAdditionalCharge2: _val1,
            dueAmount: _finalDueAmt.toFixed(2),
          });
        } else {
          if (value < 0) {
            alert("Charges can not be less than zero");
          }
        }
        /*let _tADDCAmount2 = this.state.totalAmount ;
                if(this.state.totalAmountOld >0){
                    _tADDCAmount2 = this.state.totalAmountOld ;
                } 
                let _val2 = value;  
                if(value == '' || value == null){
                    _val2 = 0;
                }      
                let _totalAmountCharge2 = parseFloat(_tADDCAmount2) + parseFloat(_val2) + parseFloat(this.state.TransAdditionalCharge1) + parseFloat(this.state.TransAdditionalCharge3);              
                this.setState({totalAmount:_totalAmountCharge2.toFixed(2),TransAdditionalCharge2 :_val2,dueAmount:_totalAmountCharge2.toFixed(2)});*/
        break;

      case "TransAdditionalCharge3":
        if (validator.isInt(value)) {
          let _val1 = value;
          if (value == "" || value == null) {
            _val1 = 0;
          }
          let _finalTotalAmt = this.getTotalAmount(
            value,
            "TransAdditionalCharge3"
          );
          let _finalDueAmt = this.getTotalDueAmount(_finalTotalAmt);

          this.setState({
            totalAmount: _finalTotalAmt.toFixed(2),
            TransAdditionalCharge3: _val1,
            dueAmount: _finalDueAmt.toFixed(2),
          });
        } else {
          if (value < 0) {
            alert("Charges can not be less than zero");
          }
        }
        /*let _tADDCAmount3 = this.state.totalAmount ;
                if(this.state.totalAmountOld >0){
                    _tADDCAmount3 = this.state.totalAmountOld ;
                } 
                let _val3 = value;  
                if(value == '' || value == null){
                    _val3 = 0;
                }      
                let _totalAmountCharge3 = parseFloat(_tADDCAmount3) + parseFloat(_val3) + parseFloat(this.state.TransAdditionalCharge2) + parseFloat(this.state.TransAdditionalCharge1);              
                this.setState({totalAmount:_totalAmountCharge3.toFixed(2),TransAdditionalCharge3 :_val3,dueAmount:_totalAmountCharge3.toFixed(2)});*/
        break;

      case "settingType":
        this.setState({ settingType: value });
        break;

      case "TransportDetailField1":
        this.setState({ TransportDetailField1: value });
        break;

      case "TransportDetailField2":
        this.setState({ TransportDetailField2: value });
        break;

      case "TransportDetailField3":
        this.setState({ TransportDetailField3: value });
        break;

      case "TransportDetailField4":
        this.setState({ TransportDetailField4: value });
        break;

      case "TransportDetailField5":
        this.setState({ TransportDetailField5: value });
        break;

      case "TransportDetailField6":
        this.setState({ TransportDetailField6: value });
        break;

      default:
        break;
    }
  };

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: true });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: false });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    }
  };
  /*End Modal open close*/

  /* Start Add More Data */
  clickToAddMoreItem = () => {
    let xx = 1;
    this.setState((prevState, props) => {
      xx++;
      let rowHtml = { content: "<tr><td>" + xx + "</td>" };
      return { rows: [...prevState.rows, rowHtml] };
    });
  };

  handleDynamicInputsTransOld(event) {
    let dataVal = event.target.value;
    const finalTaxTrans = dataVal.split("@");
    let _percGstAmt = 0;
    if (finalTaxTrans[1] >= 0) {
      let _totalTaxAmount = this.state.totalAmount;
      if (this.state.totalAmountOld > 0) {
        _totalTaxAmount = this.state.totalAmountOld;
      }
      if (finalTaxTrans[1] >= 0) {
        _percGstAmt =
          (parseFloat(_totalTaxAmount) * parseFloat(finalTaxTrans[1])) /
          parseFloat(100);
        _totalTaxAmount =
          parseFloat(_totalTaxAmount) +
          parseFloat(_percGstAmt) -
          parseFloat(this.state.totalTransDiscountAmount);
      }
      this.setState({
        totalAmount: _totalTaxAmount.toFixed(2),
        totalTransTaxAmount: _percGstAmt.toFixed(2),
        transactionWiseTaxId: finalTaxTrans[0],
        dueAmount: _totalTaxAmount.toFixed(2),
      });
    }
  }

  handleDynamicInputsTrans(event) {
    let dataVal = event.target.value;
    const finalTaxTrans = dataVal.split("@");
    let _percGstAmt = 0;
    if (finalTaxTrans[1] >= 0) {
      let _totalTaxAmount = 0;
      this.state.fields.map((data) => {
        _totalTaxAmount +=
          parseFloat(data.total_amount) +
          parseFloat(data.tax_value) -
          parseFloat(data.discount_in_amount);
      });
      _percGstAmt =
        (parseFloat(_totalTaxAmount) * parseFloat(finalTaxTrans[1])) /
        parseFloat(100);
      _totalTaxAmount = parseFloat(_totalTaxAmount) + parseFloat(_percGstAmt);

      let _finalTotalAmt = this.getTotalAmount(_percGstAmt, "taxTypeTrans");
      let _finalDueAmt = this.getTotalDueAmount(_finalTotalAmt);
      this.setState({
        totalAmount: _finalTotalAmt.toFixed(2),
        dueAmount: _finalDueAmt.toFixed(2),
        totalTransTaxAmount: _percGstAmt.toFixed(2),
        transactionWiseTaxId: finalTaxTrans[0],
      });
    }
  }

  /* End Add More Data */

  /* Add Party Code */
  selectPartiess(name, e, balance, party_contact_no) {
    let errors = this.state.errors;
    errors.party_name = "";
    if (this.state.transactionId == 0) {
      if (this.state.party_id > 0) {
        document.body.dispatchEvent(new CustomEvent("clearData"));
      }
      this.setState({
        _partyReg: "iti__hide",
        party_name: name,
        party_id: e,
        partyRemainBalance: balance,
        party_contact_no: party_contact_no,
        fields: [],
        totalAmount: 0,
        dueAmount: 0,
      });
    } else {
      this.setState({
        _partyReg: "iti__hide",
        party_name: name,
        party_id: e,
        partyRemainBalance: balance,
        party_contact_no: party_contact_no,
        fields: [],
      });
    }
  }

  getReadyPartyList() {
    let data = [];
    if (this.state.isLoaded == true) {
      let _partyArray = this.state.partyArrayFilter;
      if (_partyArray.length > 0) {
        data = this.state.partyArrayFilter.map((party, i) => {
          let _balance = party.totalOutCome;
          if (_balance < 0) {
            _colorVal = "red";
          } else if (_balance == 0 || _balance == "0") {
            _colorVal = "black";
          } else {
            _colorVal = "green";
          }
          return (
            <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.selectPartiess.bind(
                this,
                party.party_name,
                party.party_id,
                _balance,
                party.party_contact_no
              )}
            >
              <span className="iti__party-name">{party.party_name}</span>
              <span className="iti__party-balance" style={{ color: _colorVal }}>
                {numberFormat(_balance)}
              </span>
            </li>
          );
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAdjustNewParty")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Party
            </span>
          </li>
        );
      }
    }
    return data;
  }

  onMouseLeaveHandler(key) {
    if (key == "I") {
      this.setState({ _partyReg: "iti__hide" });
    }
  }

  manageShowPartyFilter(key) {
    if (key == "I") {
      this.setState({ _partyReg: "" });
    }
  }

  onPartyFilterName = (e) => {
    const data = this.state.partyArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ partyArrayFilter: filtered, party_name: keyword });
    this.getReadyPartyList();
  };
  /* End Party Code */

  /* Start Item Code */

  manageShowItemFilter(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "", _itemRegIndex: idx });
    }
  }

  onMouseLeaveHandlerItem(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "iti__hide", _itemRegIndex: idx });
    }
  }

  manageShowPartyFilterItem(key) {
    if (key == "I") {
      this.setState({ _itemReg: "" });
    }
  }

  /* End Item Code */

  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }
  /* End Here*/

  /* Click Submit Button */
  clickToAddSale = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.party_name == "") {
      _isError = true;
      errors["party_name"] = "*Please Enter Party Name";
      this.setState({ errors: errors });
    }
    if (this.state.invoiceNo == "") {
      _isError = true;
      errors["invoiceNo"] = "*Please Enter Invoice No.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.transactionId > 0) {
        this.editPurchaseTransactionMethodCall();
      }
    }
  };

  editPurchaseTransactionMethodCall() {
    let _mainSubTotalAmt = 0; //Add by AK
    let _mainTotalAmt = 0; //Add by AK
    if (this.state.dueAmount < 0) {
      sendNotification(
        "Error Message 😓",
        "Received amount on purchase order can not be more than total order amount.",
        "warning"
      );
      return;
    }
    if (this.state.handleAddDisable.length > 2) {
      alert("Cannot submit empty item row, please select item.");
      return false;
    }
    if (this.state.handleItemSalePurchasePrice.length > 2) {
      alert("Item price can not be zero");
      return false;
    }

    let _prefix = JSON.stringify({
      name: "",
      id: "0",
      isActive: "1",
    });

    if (_mainTotalAmt == 0) {
      _mainTotalAmt = this.state.totalAmount;
      _mainSubTotalAmt = this.state.totalAmount;
    } else {
      this.state.fields.map((data) => {
        _mainSubTotalAmt += parseFloat(data.subtotal);
        _mainTotalAmt += parseFloat(data.total_amount);
        if (
          this.state.totalTransDiscountAmount!=0 ||
          this.state.totalTransTaxAmount!=0
        ) {
          _mainTotalAmt = parseFloat(this.state.totalAmount);
          _mainSubTotalAmt = parseFloat(this.state.totalAmountOld);
        }
      }); //Add by AK
    }

    let _totalSummary = JSON.stringify({
      subtotal: this.state.totalAmountOld, //this.state.totalAmount,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalQty: this.state.totalQuantity,
      totalDiscount: this.state.totalDiscount,
      totalAmount: _mainTotalAmt, //this.state.totalAmount,
      receivedAmount: 0,
      paidAmount: this.state.paidAmount,
      dueAmount: this.state.dueAmount,
    }); //Add by AK

    let _customer = JSON.stringify({
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_opening_balance: 0,
      billing_name: this.state.billing_name,
    });

    let _transactionWiseAmount = JSON.stringify({
      transactionWiseTaxId: this.state.transactionWiseTaxId
        ? this.state.transactionWiseTaxId
        : 0,
      totalTransTaxAmount: this.state.totalTransTaxAmount
        ? this.state.totalTransTaxAmount
        : 0,
      totalTransDiscountPer: this.state.totalTransDiscountPer
        ? this.state.totalTransDiscountPer
        : 0,
      totalTransDiscountAmount: this.state.totalTransDiscountAmount
        ? this.state.totalTransDiscountAmount
        : 0,
    }); //Add by AK

    let _transactionAdditionalCharge = JSON.stringify({
      TransAdditionalCharge1: this.state.TransAdditionalCharge1
        ? this.state.TransAdditionalCharge1
        : 0,
      TransAdditionalCharge2: this.state.TransAdditionalCharge2
        ? this.state.TransAdditionalCharge2
        : 0,
      TransAdditionalCharge3: this.state.TransAdditionalCharge3
        ? this.state.TransAdditionalCharge3
        : 0,
    }); //Add by AK

    let _transacTransportationDetails = JSON.stringify({
      TransportDetailField1: this.state.TransportDetailField1
        ? this.state.TransportDetailField1
        : "",
      TransportDetailField2: this.state.TransportDetailField2
        ? this.state.TransportDetailField2
        : "",
      TransportDetailField3: this.state.TransportDetailField3
        ? this.state.TransportDetailField3
        : "",
      TransportDetailField4: this.state.TransportDetailField4
        ? this.state.TransportDetailField4
        : "",
      TransportDetailField5: this.state.TransportDetailField5
        ? this.state.TransportDetailField5
        : "",
      TransportDetailField6: this.state.TransportDetailField6
        ? this.state.TransportDetailField6
        : "",
    }); //Add by AK

    var purchaseData = new FormData();
    purchaseData.append("transaction_id", this.state.transactionId);
    purchaseData.append("business_id", this.state.businessId);
    purchaseData.append("user_id", this.state.userId);
    purchaseData.append("company_id", this.state.company_id);
    purchaseData.append("type", "DEBIT NOTE");
    purchaseData.append("prefix", _prefix);
    purchaseData.append("invoiceNo", this.state.invoiceNo);
    purchaseData.append("invoiceNoReturn", this.state.invoiceNoReturn);
    purchaseData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );
    purchaseData.append(
      "invoiceDateReturn",
      moment(this.state.invoiceDateReturn).format("MMMM D, YYYY")
    );

    purchaseData.append("invoiceTime", this.state.invoiceTime);

    purchaseData.append("totalSummary", _totalSummary);
    purchaseData.append("transactionWiseAmount", _transactionWiseAmount);
    purchaseData.append(
      "transactionAdditionalCharge",
      _transactionAdditionalCharge
    );
    purchaseData.append(
      "transactionTransportationDetails",
      _transacTransportationDetails
    );
    purchaseData.append("customer", _customer);
    purchaseData.append("items", JSON.stringify(this.state.fields));
    purchaseData.append("paymentType", this.state.paymentType);
    purchaseData.append("paymentRefNo", this.state.paymentRefNo);
    purchaseData.append("selectedCountry", this.state.countryName);
    purchaseData.append("countryState", this.state.countryState);
    purchaseData.append("description", this.state.description);
    purchaseData.append("descImage", this.state.descImage);
    purchaseData.append("bankId", this.state.bankId);
    purchaseData.append("bankName", this.state.bankName);
    purchaseData.append("settingType", this.state.settingType);

    new Promise((resolve, reject) => {
      fetch(global.createPurchaseDuplicateTransaction, {
        method: "POST",
        body: purchaseData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href = this.state.isRedirectUrl; //global.webUrl+'business/purchase-invoice/';
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }
  /* End Submit button */

  handleCallBackDate = (start) => {
    //this.setState({invoiceDate:start.format('MMMM D, YYYY')});
    this.setState({ invoiceDate: start.format("MM/DD/YYYY") });
  };

  handleCallBackReturn = (start) => {
    //this.setState({invoiceDateReturn:start.format('MMMM D, YYYY')});
    this.setState({ invoiceDateReturn: start.format("MM/DD/YYYY") });
  };

  settleAmountType() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              //_selBank = 'selected';
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankName={_disOption}
                data-bankId={_valOption}
              >
                {_disOption}
              </option>
            );
          }

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }
    return _payData;
  }

  isDateRefresh() {
    let _invoiceDate = this.state.invoiceDate;
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceDate}
          />
        </DateRangePicker>
      );
    }
  }

  isDateRtnRefresh() {
    let _invoiceRtnDate = this.state.invoiceDateReturn;
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceRtnDate,
          }}
          onCallback={this.handleCallBackReturn}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceRtnDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceRtnDate,
          }}
          onCallback={this.handleCallBackReturn}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceRtnDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceRtnDate,
          }}
          onCallback={this.handleCallBackReturn}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceRtnDate}
          />
        </DateRangePicker>
      );
    }
  }

  /* Invoice Time Slot */
  onMouseLeaveHandler_Time(key) {
    if (key == "I") {
      this.setState({ _timeReg: "iti__hide" });
    }
  }

  onTimeFilterName = (e) => {
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    this.setState({ invoiceTime: keyword });
    this.getTimeSlotList();
  };

  manageShowTimeFilter(key) {
    if (key == "I") {
      this.setState({ _timeReg: "" });
    }
  }

  selectInvoiceTime(e) {
    this.setState({
      _timeReg: "iti__hide",
      invoiceTime: e,
    });
  }

  getTimeSlotList() {
    let i = 1;
    let menuItems = [];
    while (i <= 24) {
      let amPmCaption = i < 12 ? "AM" : "PM";
      let timeValue = i <= 12 ? i : i - 12;

      let _timeInvoice = `${timeValue}:00 ${amPmCaption}`;
      menuItems.push(
        <li
          key={i}
          className="iti__party iti__standard"
          id="iti-item-in"
          onClick={this.selectInvoiceTime.bind(this, _timeInvoice)}
        >
          <span className="iti__party-name">{_timeInvoice}</span>
        </li>
      );
      i++;
    }
    return menuItems;
  }
  /* Invoice Time Slot End */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideRows = "iti__hide";
    let _readonlyInput = false;
    let _readonlyCss = "";
    if (this.state.fields.length > 0) {
      _hideRows = "";
      _readonlyInput = true;
      _readonlyCss = "disabled";
    } else {
      if (this.state.totalAmount > 0) {
        _hideRows = "";
        _readonlyInput = false;
        _readonlyCss = "";
      }
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    const optionsData = [
      { value: "showall", label: "Show All" },
      { value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Triplicate", label: "Triplicate" },
    ];

    let _handleParamItemPage = this.props.location.search;
    _handleParamItemPage = _handleParamItemPage.split("?auth=");
    let _transId = _handleParamItemPage[1] ? _handleParamItemPage[1] : 0;

    let _colorBal = "text-normal";
    if (this.state.partyRemainBalance < 0) {
      _colorBal = "text-danger";
    } else if (this.state.partyRemainBalance > 0) {
      _colorBal = "text-success";
    } else {
      _colorBal = "text-normal";
    }
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Add / Edit{" "}
                {this.state.typeTrans}
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">
              Add / Edit {this.state.typeTrans}
            </span>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-6">
                        <div
                          className="form-material open"
                          onMouseLeave={this.onMouseLeaveHandler.bind(
                            this,
                            "I"
                          )}
                        >
                          <input
                            type="text"
                            className="form-control search-box"
                            onChange={this.onPartyFilterName}
                            onClick={this.manageShowPartyFilter.bind(this, "I")}
                            onKeyDown={this.manageShowPartyFilter.bind(
                              this,
                              "I"
                            )}
                            placeholder="Search Party Name Here..."
                            autoComplete="off"
                            name="party_name"
                            id="party_name"
                            value={this.state.party_name}
                          />
                          <label htmlFor="login-username">
                            Party Name <span className="text-danger">*</span>
                          </label>
                          <ul
                            className={
                              this.state._partyReg +
                              " iti__party-list iti__party-list--dropup"
                            }
                            id="country-listbox"
                            aria-expanded="false"
                            role="listbox"
                            aria-activedescendant="iti-item-ad"
                          >
                            {this.getReadyPartyList()}
                          </ul>
                          <div
                            id="party_name-error"
                            className="animated fadeInDown"
                          >
                            {errors.party_name.length > 0 && (
                              <span className="error">{errors.party_name}</span>
                            )}
                          </div>

                          <div className={_colorBal}>
                            {this.state.party_name!=""
                              ? "Bal : " +
                                numberFormat(this.state.partyRemainBalance)
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="text"
                            className="form-control search-box"
                            placeholder="Phone Number"
                            autoComplete="off"
                            name="party_contact_no"
                            id="party_contact_no"
                            value={this.state.party_contact_no}
                            onChange={this.handleChange}
                            maxLength="13"
                          />
                          <label htmlFor="login-username">Phone Number</label>
                          <span className="iris_error_txt">
                            {errors.party_contact_no.length > 0 && (
                              <span className="error">
                                {errors.party_contact_no}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      {_businessSetting.Tran_BillingNameParty == 1 && (
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              className="form-control search-box"
                              placeholder="Billing Name Here..."
                              autoComplete="off"
                              name="billing_name"
                              id="billing_name"
                              value={this.state.billing_name}
                              onChange={this.handleChange}
                            />
                            <label htmlFor="login-username">Billing Name</label>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="form-group row " style={{margin: '0 0 0 0'}} >
                                            <div className="col-8 form-material" id="paddingOff">
                                            <label style={{textAlign: 'right'}} className="col-form-label" htmlFor="val-username">Billing Address <span className="text-danger">*</span></label><br/><br/>
                                            </div>
                                            <div className="col-8 form-material" id="paddingOff">
                                                <input type="text" className="form-control" id="billingAddress" name="billingAddress" required="required" onChange={this.handleChange} placeholder="Enter a Billing Address" autoComplete="off" value={this.state.billingAddress} />
                                            </div>
                                        </div>  */}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="" id="formSideRight">
                      {_businessSetting.InvoiceBillNo == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Return No. <span className="text-danger">*</span>
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="invoiceNoReturn"
                              name="invoiceNoReturn"
                              required="required"
                              onChange={this.handleChange}
                              placeholder="Enter a Return No."
                              autoComplete="off"
                              value={this.state.invoiceNoReturn}
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Bill No. <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          <input
                            type="text"
                            className="form-control"
                            id="invoiceNo"
                            name="invoiceNo"
                            required="required"
                            onChange={this.handleChange}
                            placeholder="Enter a Invoice No."
                            autoComplete="off"
                            value={this.state.invoiceNo}
                          />
                          <div
                            id="invoiceNo-error"
                            className="animated fadeInDown"
                          >
                            {errors.invoiceNo.length > 0 && (
                              <span className="error">{errors.invoiceNo}</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Bill Date<span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          {this.isDateRefresh()}
                        </div>
                      </div>
                      {_businessSetting.Tran_AddTime == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Time
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                            onMouseLeave={this.onMouseLeaveHandler_Time.bind(
                              this,
                              "I"
                            )}
                          >
                            <input
                              readOnly="readOnly"
                              type="text"
                              className="form-control search-box"
                              onChange={this.onTimeFilterName}
                              onClick={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              onKeyDown={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Search Party Name Here..."
                              autoComplete="off"
                              name="invoiceTime"
                              id="invoiceTime"
                              value={this.state.invoiceTime}
                            />
                            <ul
                              className={
                                this.state._timeReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getTimeSlotList()}
                            </ul>
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Date<span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          {this.isDateRtnRefresh()}
                        </div>
                      </div>
                      {_businessSetting.SateOfSupply == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            State of Supply
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <select
                              className="form-control "
                              id="countryState"
                              name="countryState"
                              onChange={this.handleChange}
                            >
                              <option value=""> None</option>
                              {this.state.stateArray.map((item, index) => {
                                let _sel = "";
                                if (this.state.state == item.name) {
                                  _sel = "selected";
                                }
                                return (
                                  <option
                                    key={index}
                                    value={item.name}
                                    selected={_sel}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="form-material open"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Item Data Here by other pages */}
            {this.state.party_id > 0 && (
              <QuickTransactionItemSection
                party_id={this.state.party_id}
                transId={_transId}
                callBackItem={(
                  fieldArray,
                  _totalAmountOldSend,
                  _totalAmountNewSend,
                  _totalAmt,
                  _dueAmount,
                  _paidAM,
                  _totalDiscount,
                  _totalGstAmt,
                  _totalQuantity,
                  handleAddDisable,
                  handleItemSalePurchasePrice,
                  _transactionWiseTaxId,
                  _totalTransTaxAmount,
                  _totalTransDiscountPer,
                  _totalTransDiscountAmount
                ) => {
                  let _fieldArray = fieldArray ? fieldArray.length : 0;
                  if (_fieldArray == 0) {
                    this.setState({
                      fields: fieldArray,
                      totalAmountOld: _totalAmountOldSend,
                      totalAmountNew: _totalAmountNewSend,
                      totalAmount: _totalAmt,
                      dueAmount: 0,
                      paidAmount: _paidAM,
                      gstAmount: _totalGstAmt,
                      totalDiscount: _totalDiscount,
                      totalQuantity: _totalQuantity,
                      handleAddDisable: handleAddDisable,
                      handleItemSalePurchasePrice: handleItemSalePurchasePrice,
                      TransAdditionalCharge1: 0,
                      TransAdditionalCharge2: 0,
                      TransAdditionalCharge3: 0,
                      totalTransDiscountAmount: 0,
                      totalTransDiscountPer: 0,
                      totalTransTaxAmount: 0,
                      transactionWiseTaxId: 0,
                    });
                  } else {
                    this.setState({
                      fields: fieldArray,
                      totalAmountOld: _totalAmountOldSend,
                      totalAmountNew: _totalAmountNewSend,
                      totalAmount: _totalAmt,
                      dueAmount: _dueAmount,
                      paidAmount: _paidAM,
                      gstAmount: _totalGstAmt,
                      totalDiscount: _totalDiscount,
                      totalQuantity: _totalQuantity,
                      handleAddDisable: handleAddDisable,
                      handleItemSalePurchasePrice: handleItemSalePurchasePrice,
                      transactionWiseTaxId: _transactionWiseTaxId
                        ? _transactionWiseTaxId
                        : 0,
                      totalTransTaxAmount: _totalTransTaxAmount
                        ? _totalTransTaxAmount
                        : 0,
                      totalTransDiscountPer: _totalTransDiscountPer
                        ? _totalTransDiscountPer
                        : 0,
                      totalTransDiscountAmount: _totalTransDiscountAmount
                        ? _totalTransDiscountAmount
                        : 0,
                    });
                  }
                }}
              />
            )}
            {/* Item Data Here by other pages */}

            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="form-material open" id="paddingOff">
                          <select
                            className="col-8 form-control"
                            defaultValue={
                              this.state.bankId > 0
                                ? this.state.bankId
                                : this.state.paymentType
                            }
                            id="paymentType"
                            name="paymentType"
                            onChange={this.handleChange}
                          >
                            {this.settleAmountType()}
                          </select>
                        </div>
                        {this.state.paymentType!="Cash" && (
                          <div className="form-material open">
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                required="required"
                                id="paymentRefNo"
                                name="paymentRefNo"
                                value={this.state.paymentRefNo}
                                onChange={this.handleChange}
                                placeholder="Enter Reference No.."
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        )}

                        <div className="form-material open">
                          <div className="col-12 form-material" id="paddingOff">
                            <input
                              type="text"
                              className="form-control"
                              required="required"
                              id="description"
                              name="description"
                              onChange={this.handleChange}
                              placeholder="Enter a description"
                              autoComplete="off"
                              value={this.state.description}
                            />
                          </div>
                        </div>
                        <div className="form-material open">
                          <div className="col-8 form-material" id="paddingOff">
                            <input
                              type="file"
                              accept="image/*"
                              style={{ width: "60%", display: "inline-block" }}
                              autoComplete="false"
                              className="form-control"
                              id="descImage"
                              name="descImage"
                              onChange={this.onChangeFileUploadHandler}
                            />
                            {this.previewImagesListing()}
                          </div>
                        </div>
                        {_businessSetting.PrintOriginalDuplicate == 1 && (
                          <div className="form-material open">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-form-label"
                              htmlFor="totalAmount"
                            >
                              Original / Duplicate Setting
                            </label>
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <select
                                className="col-8 form-control"
                                id="settingType"
                                name="settingType"
                                onChange={this.handleChange}
                              >
                                {optionsData.map(({ value, label }, index) => {
                                  let _selSet = "";
                                  if (this.state.settingType == value) {
                                    _selSet = "selected";
                                  }
                                  return (
                                    <option
                                      key={index}
                                      value={value}
                                      selected={_selSet}
                                    >
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    {this.state.fields.length > 0 && (
                      <div id="taxDisData">
                        {_businessSetting.TransactionWiseDiscount == 1 && (
                          <div className="col-md-12" id="formSideRight">
                            <div className="form-group row">
                              <label
                                style={{
                                  textAlign: "right",
                                  margin: "25px 0 0 0",
                                }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalAmount"
                              >
                                Discount %
                              </label>
                              <div
                                className="col-lg-4 form-material"
                                id=""
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransDiscountPer"
                                  name="totalTransDiscountPer"
                                  onChange={this.handleChange}
                                  placeholder="In %"
                                  className="form-control"
                                  autoComplete="off"
                                  value={this.state.totalTransDiscountPer}
                                />
                              </div>
                              <div
                                className="col-lg-4 form-material"
                                id=""
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransDiscountAmount"
                                  name="totalTransDiscountAmount"
                                  onChange={this.handleChange}
                                  placeholder=""
                                  className="form-control"
                                  value={this.state.totalTransDiscountAmount}
                                  autoComplete="off"
                                  style={{
                                    padding: "0 !important",
                                    margin: "0 !important",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {_businessSetting.TransactionWiseTax == 1 && (
                          <div className="col-md-12" id="formSideRight">
                            <div className="form-group row">
                              <label
                                style={{
                                  textAlign: "right",
                                  margin: "25px 0 0 0",
                                }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalAmount"
                              >
                                Tax{" "}
                              </label>
                              <div
                                className="col-lg-4 form-material"
                                id="paddingOff"
                                style={{
                                  padding: "0 !important",
                                  margin: "0 0 !important",
                                }}
                              >
                                <select
                                  style={{ width: "85px" }}
                                  className="form-control"
                                  id="taxTypeTrans"
                                  name="taxTypeTrans"
                                  onChange={this.handleDynamicInputsTrans.bind(
                                    this
                                  )}
                                >
                                  <option value="0@0" selected>
                                    {" "}
                                    None
                                  </option>
                                  {this.state.taxRateArray.map((tax, index) => {
                                    let _sel = "";
                                    if (
                                      this.state.transactionWiseTaxId == tax.id
                                    ) {
                                      _sel = "selected";
                                    }
                                    return (
                                      <option
                                        key={index}
                                        value={tax.id + "@" + tax.ratio}
                                        selected={_sel}
                                      >
                                        {tax.name + "@" + tax.ratio + "%"}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div
                                className="col-lg-4 form-material"
                                id="paddingOff"
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransTaxAmount"
                                  name="totalTransTaxAmount"
                                  placeholder=""
                                  className={"form-control " + _readonlyCss}
                                  autoComplete="off"
                                  value={this.state.totalTransTaxAmount}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {/*Additional Charges */}
                        {_businessSetting.TransactionAdditionalChargeEnable ==
                          1 && (
                          <div id="taxDisData">
                            {_businessSetting.TransactionAdditionalCharge1Enable ==
                              1 && (
                              <div className="col-md-12" id="formSideRight">
                                <div className="form-group row">
                                  <label
                                    style={{ textAlign: "right" }}
                                    className="col-lg-4 col-form-label"
                                    htmlFor="TransAdditionalCharge1"
                                  >
                                    {
                                      _businessSetting.TransactionAdditionalCharge1Name
                                    }
                                  </label>
                                  <div
                                    className="col-lg-8 form-material"
                                    id="paddingOff"
                                  >
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="TransAdditionalCharge1"
                                      name="TransAdditionalCharge1"
                                      onChange={this.handleChange}
                                      placeholder=""
                                      value={this.state.TransAdditionalCharge1}
                                      autoComplete="off"
                                      onWheel={() => document.activeElement.blur()}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {_businessSetting.TransactionAdditionalCharge2Enable ==
                              1 && (
                              <div className="col-md-12" id="formSideRight">
                                <div className="form-group row">
                                  <label
                                    style={{ textAlign: "right" }}
                                    className="col-lg-4 col-form-label"
                                    htmlFor="TransAdditionalCharge2"
                                  >
                                    {
                                      _businessSetting.TransactionAdditionalCharge2Name
                                    }
                                  </label>
                                  <div
                                    className="col-lg-8 form-material"
                                    id="paddingOff"
                                  >
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="TransAdditionalCharge2"
                                      name="TransAdditionalCharge2"
                                      onChange={this.handleChange}
                                      placeholder=""
                                      value={this.state.TransAdditionalCharge2}
                                      autoComplete="off"
                                      onWheel={() => document.activeElement.blur()}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {_businessSetting.TransactionAdditionalCharge3Enable ==
                              1 && (
                              <div className="col-md-12" id="formSideRight">
                                <div className="form-group row">
                                  <label
                                    style={{ textAlign: "right" }}
                                    className="col-lg-4 col-form-label"
                                    htmlFor="TransAdditionalCharge3"
                                  >
                                    {
                                      _businessSetting.TransactionAdditionalCharge3Name
                                    }
                                  </label>
                                  <div
                                    className="col-lg-8 form-material"
                                    id="paddingOff"
                                  >
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="TransAdditionalCharge3"
                                      name="TransAdditionalCharge3"
                                      onChange={this.handleChange}
                                      placeholder=""
                                      value={this.state.TransAdditionalCharge3}
                                      autoComplete="off"
                                      onWheel={() => document.activeElement.blur()}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {/*End Additional Charges */}
                      </div>
                    )}
                    <div className="col-md-12" id="formSideRight">
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="totalAmount"
                        >
                          Total Amount
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="form-control disabled"
                            id="totalAmount"
                            name="totalAmount"
                            placeholder=""
                            onChange={this.handleChange}
                            value={this.state.totalAmount}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className={_hideRows}>
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="receivedAmount"
                          >
                            Received Amount
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="number"
                              className="form-control"
                              id="receivedAmount"
                              name="receivedAmount"
                              placeholder=""
                              value={this.state.receivedAmount}
                              onChange={this.handleChange}
                              autoComplete="off"
                              onWheel={() => document.activeElement.blur()}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="dueAmount"
                          >
                            Balance Due
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="text"
                              readOnly="readOnly"
                              className="form-control"
                              id="dueAmount"
                              name="dueAmount"
                              placeholder=""
                              value={this.state.dueAmount}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {_businessSetting.TransportationDetailsField1Enable == 1 && (
                // Transportation Detail Here
                // <QuickTransportationDetails />
                <div className="col-md-12">
                  <div className="block block-transparent">
                    <div className="block-content">
                      <h5 className="content-heading">
                        Transportation Details
                      </h5>
                      <div className="block">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="block block-transparent">
                              <div className="block-content" id="formSideLeft">
                                <div className="form-group row">
                                  {_businessSetting.TransportationDetailsField1Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField1Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField1"
                                          id="TransportDetailField1"
                                          value={
                                            this.state.TransportDetailField1
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField1Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField2Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField2Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField2"
                                          id="TransportDetailField2"
                                          value={
                                            this.state.TransportDetailField2
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField2Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField3Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField3Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField3"
                                          id="TransportDetailField3"
                                          value={
                                            this.state.TransportDetailField3
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField3Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField4Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField4Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField4"
                                          id="TransportDetailField4"
                                          value={
                                            this.state.TransportDetailField4
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField4Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField5Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField5Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField5"
                                          id="TransportDetailField5"
                                          value={
                                            this.state.TransportDetailField5
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField5Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField6Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField6Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField6"
                                          id="TransportDetailField6"
                                          value={
                                            this.state.TransportDetailField6
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField6Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <div className="row justify-content-center text-right">
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      onClick={this.clickToAddSale}
                      disabled={isDisabled}
                      className="btn btn-alt-primary mb-10 mx-5"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Edit Party Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SP}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewParty"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Party</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewParty"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewParty
                      isRedirectTo={"business/add-purchase-debit-note/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewParty"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Party Details*/}

          {/* Modal Edit Item Details Begins */}
          {/* <Modal isOpen={this.state.modalIsOpen_SITEM} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalAdjustNewItem">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">Add New Item</h3>
                                        <div className="block-options">
                                            <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalAdjustNewItem')}>
                                                <i className="si si-close"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="block-content">
                                        <QuickAddNewItem isRedirectTo={'business/add-purchase-debit-note/'}/>
                                    </div>
                                    <br/>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAdjustNewItem')}>Close</button>
                                </div>
                            </div>
                        </div>
                    </Modal> */}
          {/* Modal End Edit Item Details*/}

          {/* Modal Add Bank Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SBANK}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewBank"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Bank</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewBank"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewBankDetail
                      isRedirectTo={"business/add-purchase-debit-note/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewBank"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Bank Details*/}
        </div>
      </React.Fragment>
    );
  }
}
