import React, { Component } from "react"; 
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import validator from "validator";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";

let maxNumber = 999999;
let _invoiceNumber = Math.floor(Math.random() * maxNumber + 1);

let _loader = "glb-ldr-prt active";
let _isEditMode = true;

export default class partyListPanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession"); 

    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
    }

    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/add-sale/",
      buttonTitle: "",
      hideShow: "",
      isLoaded: true,

      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,

      cashHandId: 0,

      adjust_amount: "",
      as_on_date: moment().format("MM/DD/YYYY"),
      payment_type: "ADDCASH",
      adjust_description: "",
      errors: {
        adjust_amount: "",
        payment_type: "",
        adjust_description: "",
      },
    };
  }

  async componentDidMount() {
    if (this.props.cashHandId == 0) {
      _isEditMode = false;
      this.setState({ isLoaded: true });
    }

    let _cashHandId = this.props.cashHandId;
    if (_cashHandId > 0) {
      _isEditMode = true;
      let _expCashArray = [];
      let _url_GetData =
        global.userCashInHandTransactionDetails + "?cashHandId=" + _cashHandId;
      PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _expCashArray = responseJson.response[0];

              this.setState({
                buttonTitle: "Edit",
                adjust_amount: _expCashArray.total_amount,
                as_on_date: moment(_expCashArray.invoice_date).format(
                  "MM/DD/YYYY"
                ),
                payment_type: _expCashArray.type,
                adjust_description: _expCashArray.description,
                isLoaded: true,
                cashHandId: this.props.cashHandId,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "adjust_amount":
        if (validator.isInt(value) && value > 0) {
          errors.adjust_amount = "";
        } else {
          errors.adjust_amount = "*Adjust amount must be greater than 0!";
        }
        this.setState({ adjust_amount: value });
        break;
      case "as_on_date":
        errors.as_on_date = value.length < 3 ? "*Date must be select!" : "";
        this.setState({ as_on_date: value });
        break;
      case "adjust_description":
        errors.adjust_description =
          value.length < 3 ? "*Enter description" : "";
        this.setState({ adjust_description: value });
        break;

      case "payment_type":
        errors.payment_type = value.length < 1 ? "*Select payment type!" : "";
        this.setState({ payment_type: value });
        break;
      default:
        break;
    }
  };

  clickToAddBankDetails = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.payment_type == "") {
      _isError = true;
      errors["payment_type"] = "*Please select payment type";
      this.setState({ errors: errors });
    }
    if (this.state.adjust_amount == "") {
      _isError = true;
      errors["adjust_amount"] = "*Please Enter Adjust amount.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.props.cashHandId > 0) {
        this.editCashHandDetailsMethodCall();
      } else {
        this.addCashHandDetailsMethodCall();
      }
    }
  };

  addCashHandDetailsMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      businessId: this.state.businessId,
      user_id: this.state.userId,
      adjust_amount: this.state.adjust_amount,
      type: this.state.payment_type,
      asOnDate: moment(this.state.as_on_date).format("MMMM D, YYYY"),
      asOnTime: moment().format("h:mm A"),
      adjust_description: this.state.adjust_description,
    });

    PostData(global.createCashInHandTransaction, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            //window.location.href = global.webUrl+this.state.isRedirectTo;
            this.props.callBack("hello");
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  editCashHandDetailsMethodCall() {
    const requestData = JSON.stringify({
      transaction_id: this.state.cashHandId,
      company_id: this.state.company_id,
      businessId: this.state.businessId,
      user_id: this.state.userId,
      adjust_amount: this.state.adjust_amount,
      type: this.state.payment_type,
      asOnDate: moment(this.state.as_on_date).format("MMMM D, YYYY"),
      asOnTime: moment().format("h:mm A"),
      adjust_description: this.state.adjust_description,
    });
    PostData(global.editCashInHandTransaction, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            //window.location.href = global.webUrl+this.state.isRedirectTo;
            this.props.callBack("hello");
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  handleCallBackDate = (start) => {
    this.setState({ as_on_date: start.format("MMMM D, YYYY") });
  };

  isDateRefresh = () => {
    let _invoiceDate = this.state.as_on_date;
    if (_isEditMode == true && this.state.cashHandId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    } else if (
      this.state.isLoaded == true &&
      _isEditMode == false &&
      this.props.cashHandId == 0
    ) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    }
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <select
                  name="payment_type"
                  id="payment_type"
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option
                    value="ADDCASH"
                    selected={
                      this.state.payment_type == "ADDCASH" ? "selected" : ""
                    }
                  >
                    Add Cash
                  </option>
                  <option
                    value="REDUCECASH"
                    selected={
                      this.state.payment_type == "REDUCECASH" ? "selected" : ""
                    }
                  >
                    Reduce Cash
                  </option>
                </select>
                <label htmlFor="login-username">Adjustment</label>
                <span className="iris_error_txt">
                  {errors.payment_type.length > 0 && (
                    <span className="error">{errors.payment_type}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="adjust_amount"
                  name="adjust_amount"
                  value={this.state.adjust_amount}
                  placeholder="Enter Amount"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Enter Amount <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.adjust_amount.length > 0 && (
                    <span className="error">{errors.adjust_amount}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                {this.isDateRefresh()}
                <label htmlFor="login-username">Adjustment Date</label>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="adjust_description"
                  name="adjust_description"
                  value={this.state.adjust_description}
                  placeholder="Enter Adjustment Description"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">Description</label>
                <span className="iris_error_txt">
                  {errors.adjust_description.length > 0 && (
                    <span className="error">{errors.adjust_description}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddBankDetails}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> {this.state.buttonTitle}{" "}
                Cash Adjust
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
