import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useEffect, useState } from "react";
import SelectNormalQuntity from "./SelectNormalQuntity";
import { set } from "date-fns";
import { de, el } from "date-fns/locale";
import QuickAddNewItem from "../userItem/quickSaleItemAdd";
import SelectSerialQuntity from "./SelectSerialQuntity";
import ViewSerialQuntity from "./ViewSerialQuntity";
import SelectBatchQuntity from "./SelectBatchQuntity";
import ViewBatchQuntity from "./ViewBatchQuntity";
import { confirmAlert } from "react-confirm-alert";
import { PostData } from "../../../service/postData";
import { Loader } from "@progress/kendo-react-indicators";
import ViewNormalQuntity from "./ViewNormalQuntity";

const SaleAddItemTable = ({ callBack, partyItemArray, selectedItemArray, isConvert, isEditMode }) => {
    const [businessSetting, setBusinessSetting] = useState({});
    const [companyId, setCompanyId] = useState(0);
    const [businessId, setBusinessId] = useState(0);
    const [companyLogo, setCompanyLogo] = useState('');
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalFreeQuantity, setTotalFreeQuantity] = useState(0);
    const [totalAdditionalCess, setTotalAdditionalCess] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalGstAmount, setTotalGstAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [unitDataArray, setUnitDataArray] = useState([]);
    const [taxTypeDataArray, setTaxTypeDataArray] = useState([]);
    const [taxRateDataArray, setTaxRateDataArray] = useState([]);
    const [itemRows, setItemRows] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [isPurchasePriceShow, setIsPurchasePriceShow] = useState(0);
    const [isDialog_ItemList, setIsDialog_ItemList] = useState(false);
    const [isDialog_AddNewItem, setIsDialog_AddNewItem] = useState(false);
    const [isDialog_SelectTrackingType, setIsDialog_SelectTrackingType] = useState(false);
    const [isDialog_Normal, setIsDialog_Normal] = useState({ isVisible: false, isFreeQuantity: false, isNewQty: 'OLD' });
    const [isDialog_ViewNormal, setIsDialog_ViewNormal] = useState(false);
    const [isDialog_Serial, setIsDialog_Serial] = useState({ isVisible: false, data: {}, isFreeQuantity: false });
    const [isDialog_Batch, setIsDialog_Batch] = useState({ isVisible: false });
    const [isDialog_ViewSerial, setIsDialog_ViewSerial] = useState({ isVisible: false, data: {} });
    const [isDialog_ViewBatch, setIsDialog_ViewBatch] = useState({ isVisible: false, data: {} });
    const [searchItemName, setSearchItemName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setItemList(partyItemArray);
        const _taxRateDataArray = [{ value: "With Tax", label: "With Tax" },
        { value: "Without Tax", label: "Without Tax" }]
        setTaxTypeDataArray(_taxRateDataArray);

        if (selectedItemArray.length > 0) {
            const idsToRemove = selectedItemArray
                .filter((item) => item.selectedData && item.selectedData.length > 0)
                .flatMap((item) => item.selectedData.map((data) => data.batch_id));
            let items = partyItemArray.map((data) => ({
                ...data,
                itemBatchNo: data.itemBatchNo.filter((item) => !idsToRemove.includes(item.batch_id))
            }));
            let itemRows = selectedItemArray.map((data) => ({
                ...data,
                normal_remaining_quantity: partyItemArray.filter(item => item.item_id == data.item_id)[0].normal_remaining_quantity
            }));


            setItemRows(itemRows);
            setItemList(items);
            calculateTotalTableAmount(itemRows);
        }
    }, []);

    useEffect(() => {
        let _UserSession = localStorage.getItem("vs_UserSession");

        if (_UserSession != undefined || _UserSession != null) {
            if (_UserSession.loginType != "Admin") {
                _UserSession = JSON.parse(_UserSession);
                let _signingId = _UserSession.loginId;
                let _businessId = _UserSession.tokenKey;
                let _company_id = _UserSession.company_id;
                let _businessSetting = _UserSession.businessSetting;

                setCompanyId(_company_id);
                setBusinessId(_businessId);
                setBusinessSetting(_businessSetting);
                setIsPurchasePriceShow(_businessSetting.DisplayPurchasePrice);
            }
        }
        let _companySession = localStorage.getItem("vs_UserCompanySession");
        if (_companySession != undefined || _companySession != null) {
            _companySession = JSON.parse(_companySession);
            let _businessId = _companySession.business_id;
            let _company_id = _companySession.company_id;
            let _countryCode = _companySession.country_code;
            let _countryName = _companySession.country_name;
            let _taxRateArray = _companySession.taxDetails;
            let _company_Logo = _companySession.company_logo;

            setCompanyId(_company_id);
            setBusinessId(_businessId);
            setCompanyLogo('https://www.developer.vyavsay.in/websiteFilesFolder/img/vyavsay.png');
            getUnitData(_company_id);
            _taxRateArray.push({ id: 0, name: 'None', ratio: 0 });
            setTaxRateDataArray(_taxRateArray);
        }
    }, []);

    const handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const getUnitData = async (companyId) => {
        let _unitData = await fetch(
            global.userUnitList + `?business_id=${businessId}&company_id=${companyId}`
        );
        _unitData = await _unitData.json();
        if (_unitData.response) {
            _unitData = _unitData.response;
            setUnitDataArray(_unitData);
        }

    }

    const itemListFilter = itemList.filter(item =>
        item.name.toLowerCase().includes(searchItemName.toLowerCase())
    );
    const addItemRow = () => {
        const newRow = {};
        setItemRows([...itemRows, newRow]);
    };

    const deleteItemRow = (deleteIndex) => {
        const deletedRow = itemRows.find((data, index) => index === deleteIndex);

        let updatedItemRows = itemRows.map((data, index) => {
            if (data.trackingType == 'Serial') {
                let itemSerialNo = data.itemSerialNo;
                if (deletedRow.selectedData != undefined) {
                    let deletedSerialNo = deletedRow.selectedData.map((data) => ({
                        ...data,
                        isSelected: false,
                    }));
                    itemSerialNo.push(...deletedSerialNo);
                }

                return ({
                    ...data,
                    itemSerialNo: data.item_id == deletedRow.item_id ? itemSerialNo : data.itemSerialNo,
                })
            } else if (data.trackingType == 'Batch') {
                let itemBatchNo = data.itemBatchNo;
                if (deletedRow.selectedData != undefined) {
                    let deletedBatchNo = deletedRow.selectedData.map((data) => ({
                        ...data,
                        opening_stock: parseInt(data.opening_stock) + parseInt(data.free_quantity) + parseInt(data.used_stock),
                        free_quantity: 0,
                        used_stock: 0
                    }));
                    //itemBatchNo.push(...deletedBatchNo);
                }
                return ({
                    ...data,
                    itemBatchNo: data.item_id == deletedRow.item_id ? itemBatchNo : data.itemBatchNo,
                })
            } else {
                let qty = deletedRow.normal_remaining_quantity + deletedRow.quantity + deletedRow.free_quantity;
                return ({
                    ...data,
                    normal_remaining_quantity: data.item_id == deletedRow.item_id ? qty : data.normal_remaining_quantity,
                })
            }
        })
        let rows = updatedItemRows.filter((data, index) => index !== deleteIndex);

        setItemRows(rows);
        calculateTotalTableAmount(rows);

        let updateItemList = itemList.map((data) => {
            if (deletedRow.trackingType == 'Serial') {
                let itemSerialNo = data.itemSerialNo;
                if (data.item_id == deletedRow.item_id) {
                    if (deletedRow.selectedData != undefined) {
                        let deletedSerialNo = deletedRow.selectedData.map((data) => ({
                            ...data,
                            isSelected: false,
                        }));
                        itemSerialNo.push(...deletedSerialNo);
                    }
                }

                return ({
                    ...data,
                    itemSerialNo: itemSerialNo,
                })
            } else if (deletedRow.trackingType == 'Batch') {
                let itemBatchNo = data.itemBatchNo;
                if (deletedRow.selectedData != undefined) {
                    let deletedBatchNo = deletedRow.selectedData.map((data) => ({
                        ...data,
                        opening_stock: parseInt(data.opening_stock) + parseInt(data.free_quantity) + parseInt(data.used_stock),
                        free_quantity: 0,
                        used_stock: 0
                    }));
                    if (itemBatchNo.filter((data) => data.batch_id == deletedBatchNo[0].batch_id).length > 0) {

                        if (!deletedBatchNo[0].isNewItem) {
                            itemBatchNo = itemBatchNo.map((data) => ({
                                ...data,
                                opening_stock: data.batch_id == deletedBatchNo[0].batch_id ? (parseInt(data.opening_stock) + parseInt(data.free_quantity) + parseInt(data.used_stock)) : data.opening_stock
                            }))
                        } else {

                        }

                    } else if (data.item_id == deletedRow.item_id) {
                        itemBatchNo.push(...deletedBatchNo);
                    }

                }
                return ({
                    ...data,
                    itemBatchNo: data.item_id == deletedRow.item_id ? itemBatchNo : data.itemBatchNo,
                })
            } else {
                let qty = deletedRow.normal_remaining_quantity + deletedRow.quantity + deletedRow.free_quantity;
                return ({
                    ...data,
                    normal_remaining_quantity: data.item_id == deletedRow.item_id ? qty : data.normal_remaining_quantity,
                })
            }
        })
        setItemList(updateItemList);
    };

    const showItemList = (index) => {
        setSelectedItemIndex(index);
        setIsDialog_ItemList(true);
    }

    const onChangeRate = (value, index) => {
        value = value * 1;
        let data = itemRows.map((data, i) => {
            if (i == index) {

                let price = getPriceWithTaxWithOutTax(data.sale_tax_type, data.tax_rate_id, value);
                //let sub_total_amount = ((data.quantity + data.free_quantity) * price);
                let sub_total_amount = (data.quantity * price);
                let discount_in_amount = (data.discount_in_per * sub_total_amount) / 100;
                discount_in_amount = parseFloat(discount_in_amount.toFixed(2));
                let total_amount = sub_total_amount - discount_in_amount;
                let taxRate = taxRateDataArray.find(tax => tax.id == data.tax_rate_id);
                let taxValue = (taxRate.ratio * total_amount) / 100;
                taxValue = parseFloat(taxValue.toFixed(2));
                total_amount += data.Additional_CESS + taxValue;
                total_amount = parseFloat(total_amount.toFixed(2));
                return {
                    ...data,
                    rate: value,
                    tax_value: taxValue,
                    total_amount: total_amount,
                    subtotal: sub_total_amount,
                    discount_in_amount: discount_in_amount
                }
            } else {
                return data;
            }
        })
        setItemRows(data);
        calculateTotalTableAmount(data);
    }
    const onChangeAdditionalCESS = (value, index) => {
        value = value * 1;
        let data = itemRows.map((data, i) => {
            if (i == index) {
                let price = getPriceWithTaxWithOutTax(data.sale_tax_type, data.tax_rate_id, data.rate);
                //let sub_total_amount = ((data.quantity + data.free_quantity) * price)
                let sub_total_amount = (data.quantity * price)
                let total_amount = sub_total_amount - data.discount_in_amount;
                let taxRate = taxRateDataArray.find(tax => tax.id == data.tax_rate_id);
                let taxValue = (taxRate.ratio * total_amount) / 100;
                taxValue = parseFloat(taxValue.toFixed(2));
                total_amount += value + taxValue;
                total_amount = parseFloat(total_amount.toFixed(2));
                return {
                    ...data,
                    Additional_CESS: value,
                    total_amount: total_amount,
                    subtotal: sub_total_amount
                }
            } else {
                return data;
            }
        })
        setItemRows(data);
        calculateTotalTableAmount(data);
    }
    const onChangeTaxType = (value, index) => {
        let data = itemRows.map((data, i) => {
            if (i == index) {
                let price = getPriceWithTaxWithOutTax(value, data.tax_rate_id, data.rate);

                let sub_total_amount = (data.quantity * price);
                //let sub_total_amount = ((data.quantity + data.free_quantity) * price);

                //let discount_in_amount = (data.discount_in_per * ((data.quantity + data.free_quantity) * price)) / 100;
                let discount_in_amount = (data.discount_in_per * sub_total_amount) / 100;

                discount_in_amount = parseFloat(discount_in_amount.toFixed(2));
                let total_amount = sub_total_amount - discount_in_amount;
                let taxRate = taxRateDataArray.find(tax => tax.id == data.tax_rate_id);
                let taxValue = (taxRate.ratio * total_amount) / 100;
                taxValue = parseFloat(taxValue.toFixed(2));
                total_amount += data.Additional_CESS + taxValue;
                total_amount = parseFloat(total_amount.toFixed(2));
                return {
                    ...data,
                    sale_tax_type: value,
                    tax_rate_id: taxRate.id,
                    tax_value: taxValue,
                    tax_type: taxRate,
                    discount_in_amount: discount_in_amount,
                    total_amount: total_amount,
                    subtotal: sub_total_amount
                }
            } else {
                return data;
            }
        })
        setItemRows(data);
        calculateTotalTableAmount(data);
    }
    const onChangeDiscountPercentage = (value, index) => {
        value = value * 1;
        if (value > 100) {
            alert('Discount percentage cannot be greater than 100.');
            return;

        }
        let data = itemRows.map((data, i) => {
            if (i == index) {
                //let sub_total_amount = ((data.quantity + data.free_quantity) * data.rate);
                let price = getPriceWithTaxWithOutTax(data.sale_tax_type, data.tax_rate_id, data.rate);
                console.log('price', price);
                let sub_total_amount = (data.quantity * price);
                let discount_in_amount = (value * sub_total_amount) / 100;
                discount_in_amount = parseFloat(discount_in_amount.toFixed(2));
                let total_amount = (sub_total_amount - discount_in_amount);
                let taxRate = taxRateDataArray.find(tax => tax.id == data.tax_rate_id);
                let taxValue = (taxRate.ratio * total_amount) / 100;
                console.log('total_amount', total_amount);
                console.log('taxValue', taxValue);
                taxValue = parseFloat(taxValue.toFixed(2));
                total_amount += data.Additional_CESS + taxValue;
                total_amount = parseFloat(total_amount.toFixed(2));
                return {
                    ...data,
                    discount_in_per: value,
                    discount_in_amount: discount_in_amount,
                    tax_value: taxValue,
                    total_amount: total_amount,
                    subtotal: sub_total_amount
                }
            } else {
                return data;
            }
        })
        setItemRows(data);
        calculateTotalTableAmount(data);
    }
    const onChangeDiscountAmount = (value, index) => {
        value = value * 1;
        //if (value > ((itemRows[index].quantity + itemRows[index].free_quantity) * itemRows[index].rate)) {
        if (value > (itemRows[index].quantity * itemRows[index].rate)) {
            alert('Discount amount cannot be greater than total amount.');
            return;
        }
        let data = itemRows.map((data, i) => {
            if (i == index) {
                //let sub_total_amount = ((data.quantity + data.free_quantity) * data.rate)
                let price = getPriceWithTaxWithOutTax(data.sale_tax_type, data.tax_rate_id, data.rate);


                let sub_total_amount = (data.quantity * price);
                let discount_in_per = sub_total_amount == 0 ? 0 : ((value / sub_total_amount) * 100);
                let total_amount = (sub_total_amount - value);
                let taxRate = taxRateDataArray.find(tax => tax.id == data.tax_rate_id);
                let taxValue = (taxRate.ratio * total_amount) / 100;
                taxValue = parseFloat(taxValue.toFixed(2));
                total_amount += data.Additional_CESS + taxValue;
                total_amount = parseFloat(total_amount.toFixed(2));
                return {
                    ...data,
                    discount_in_per: parseFloat(discount_in_per.toFixed(2)),
                    discount_in_amount: value,
                    tax_value: taxValue,
                    total_amount: total_amount,
                    subtotal: sub_total_amount
                }
            } else {
                return data;
            }
        })
        setItemRows(data);
        calculateTotalTableAmount(data);
    }
    const onChangeTaxRate = (value, index) => {
        let data = itemRows.map((data, i) => {
            if (i == index) {
                //let sub_total_amount = ((data.quantity + data.free_quantity) * data.rate);
                let price = getPriceWithTaxWithOutTax(data.sale_tax_type, value, data.rate);

                let sub_total_amount = (data.quantity * price);
                let discount_in_amount = (data.discount_in_per * sub_total_amount) / 100;
                let total_amount = (sub_total_amount - discount_in_amount);
                let taxRate = taxRateDataArray.find(tax => tax.id == value);
                let taxValue = (taxRate.ratio * total_amount) / 100;
                taxValue = parseFloat(taxValue.toFixed(2));

                total_amount += data.Additional_CESS + taxValue;
                total_amount = parseFloat(total_amount.toFixed(2));
                discount_in_amount = parseFloat(discount_in_amount.toFixed(2));
                return {
                    ...data,
                    tax_rate_id: value,
                    tax_value: taxValue,
                    tax_type: taxRate,
                    discount_in_amount: discount_in_amount,
                    total_amount: total_amount,
                    subtotal: sub_total_amount
                }
            } else {
                return data;
            }
        })
        setItemRows(data);
        calculateTotalTableAmount(data);
    }
    function calculateDiscount(discount_type, discount_in_sale_price, quantity, free_quantity, rate) {
        let discount_in_per = 0;
        let discount_in_amount = 0;

        if (discount_type === "Percentage") {
            //discount_in_amount = (discount_in_sale_price * ((quantity + free_quantity) * rate)) / 100;
            discount_in_amount = (discount_in_sale_price * ((quantity) * rate)) / 100;
            discount_in_per = discount_in_sale_price;
        } else {
            console.log('discount_in_sale_price', discount_in_sale_price);
            discount_in_amount = parseFloat(discount_in_sale_price);
            discount_in_per = (discount_in_sale_price / ((quantity) * rate)) * 100;
            //discount_in_per = (discount_in_sale_price / ((quantity + free_quantity) * rate)) * 100;
        }
        discount_in_amount = parseFloat(discount_in_amount.toFixed(2));
        return { discount_in_per, discount_in_amount };
    }
    const getPriceWithTaxWithOutTax = (sale_tax_type, tax_rate_id, price) => {
        if (taxRateDataArray.length > 0) {
            let taxRate = taxRateDataArray.find(tax => tax.id == tax_rate_id);
            if (sale_tax_type == 'With Tax') {
                console.log('price', price);
                price = (price / (100 + taxRate.ratio)) * 100;
            }
        }

        return price;
    }
    const updateItemRowData = (itemList, newData, isFreeQuantity) => {
        let updatedData = itemRows;
        updatedData[selectedItemIndex] = newData;
        let qty = newData.normal_remaining_quantity - (isFreeQuantity ? newData.free_quantity : newData.quantity);

        let price = getPriceWithTaxWithOutTax(newData.sale_tax_type, newData.tax_rate_id, newData.rate);
        const { discount_in_per, discount_in_amount } = calculateDiscount(newData.discount_type, newData.discount_in_sale_price, newData.quantity, newData.free_quantity, price);
        //let sub_total_amount = ((newData.quantity + newData.free_quantity) * price)
        let sub_total_amount = (newData.quantity * price);
        let total_amount = (sub_total_amount - discount_in_amount);
        let taxRate = taxRateDataArray.find(tax => tax.id == newData.tax_rate_id);
        let taxValue = (taxRate.ratio * total_amount) / 100;
        taxValue = parseFloat(taxValue.toFixed(2));
        total_amount += newData.Additional_CESS + taxValue;
        total_amount = parseFloat(total_amount.toFixed(2));
        updatedData = updatedData.map((data, index) => {
            if (data.item_id == newData.item_id) {
                if (newData.trackingType == 'Serial') {
                    let updatedData = itemList;
                    const idsToRemove = newData.selectedData.filter((data) => data.serial_id).map((data) => data.serial_id);
                    const updatedSerialNo = updatedData.filter((data) => data.item_id == newData.item_id)[0].itemSerialNo.filter((data) => !idsToRemove.includes(data.serial_id));
                    return {
                        ...data,
                        itemSerialNo: updatedSerialNo,
                        discount_in_per: selectedItemIndex == index ? discount_in_per : data.discount_in_per,
                        discount_in_amount: selectedItemIndex == index ? discount_in_amount : data.discount_in_amount,
                        tax_value: selectedItemIndex == index ? taxValue : data.tax_value,
                        total_amount: selectedItemIndex == index ? total_amount : data.total_amount,
                        subtotal: selectedItemIndex == index ? sub_total_amount : data.subtotal,
                    }
                } else if (newData.trackingType == 'Batch') {
                    let preSelectedBatch = [];
                    if (data.selectedData != undefined) {
                        preSelectedBatch = data.selectedData.map((data) => ({
                            ...data,
                            opening_stock: newData.selectedData[0].batch_id == data.batch_id ? newData.selectedData[0].opening_stock : data.opening_stock
                        }))
                    }
                    return {
                        ...data,
                        itemBatchNo: selectedItemIndex == index ? newData.itemBatchNo : data.itemBatchNo,
                        selectedData: selectedItemIndex == index ? newData.selectedData : preSelectedBatch,
                        discount_in_per: selectedItemIndex == index ? discount_in_per : data.discount_in_per,
                        discount_in_amount: selectedItemIndex == index ? discount_in_amount : data.discount_in_amount,
                        tax_value: selectedItemIndex == index ? taxValue : data.tax_value,
                        total_amount: selectedItemIndex == index ? total_amount : data.total_amount,
                        subtotal: selectedItemIndex == index ? sub_total_amount : data.subtotal,
                    }
                } else {
                    return {
                        ...data,
                        normal_remaining_quantity: qty,
                        discount_in_per: selectedItemIndex == index ? discount_in_per : data.discount_in_per,
                        discount_in_amount: selectedItemIndex == index ? discount_in_amount : data.discount_in_amount,
                        tax_value: selectedItemIndex == index ? taxValue : data.tax_value,
                        total_amount: selectedItemIndex == index ? total_amount : data.total_amount,
                        subtotal: selectedItemIndex == index ? sub_total_amount : data.subtotal,
                    }
                }

            } else {
                return data;
            }
        });
        console.log('itemRows', JSON.stringify(updatedData));
        setItemRows(updatedData);
        calculateTotalTableAmount(updatedData);
        setSelectedItem({});
        setSelectedItemIndex(-1);
        updateMainItemData(itemList, newData, isFreeQuantity);
    }
    const calculateTotalTableAmount = (updatedData) => {
        const totalQuantity = updatedData.map((data) => data.quantity).reduce((acc, quantity) => acc + quantity, 0);
        setTotalQuantity(totalQuantity);

        const totalFreeQuantity = updatedData.map((data) => data.free_quantity).reduce((acc, free_quantity) => acc + free_quantity, 0);
        setTotalFreeQuantity(totalFreeQuantity);

        const totalAdditionalCess = updatedData.map((data) => data.Additional_CESS).reduce((acc, Additional_CESS) => acc + Additional_CESS, 0);
        setTotalAdditionalCess(totalAdditionalCess);

        const totalDiscount = updatedData.map((data) => data.discount_in_amount).reduce((acc, discount_in_amount) => acc + discount_in_amount, 0);
        setTotalDiscount(totalDiscount);

        const totalGstAmount = updatedData.map((data) => data.tax_value).reduce((acc, tax_value) => acc + tax_value, 0);
        setTotalGstAmount(parseFloat(totalGstAmount.toFixed(2)));

        const totalAmount = updatedData.map((data) => { return parseFloat(data.total_amount); }).reduce((acc, total_amount) => acc + total_amount, 0);
        setTotalAmount(parseFloat(totalAmount.toFixed(2)));

        let itemSubTotalAmount = updatedData.map((data) => { return data.quantity * getPriceWithTaxWithOutTax(data.sale_tax_type, data.tax_rate_id, data.rate); }).reduce((acc, sub_total_amount) => acc + sub_total_amount, 0);
        itemSubTotalAmount = parseFloat(itemSubTotalAmount.toFixed(2));

        callBack(updatedData, totalAmount, (totalQuantity + totalFreeQuantity), itemSubTotalAmount);
    }
    const updateMainItemData = (itemList, newData, isFreeQuantity) => {
        let updatedData = itemList;
        if (newData.trackingType == 'Serial') {
            const idsToRemove = newData.selectedData.filter((data) => data.serial_id).map((data) => data.serial_id);
            const updatedSerialNo = updatedData.filter((data) => data.item_id == newData.item_id)[0].itemSerialNo.filter((data) => !idsToRemove.includes(data.serial_id));
            updatedData = updatedData.map((data) => {
                return ({
                    ...data,
                    itemSerialNo: data.item_id == newData.item_id ? updatedSerialNo : data.itemSerialNo,
                })
            })
        } else if (newData.trackingType == 'Batch') {
            updatedData = updatedData.map((data) => {
                return ({
                    ...data,
                    itemBatchNo: data.item_id == newData.item_id ? newData.itemBatchNo : data.itemBatchNo,
                })
            })
        } else {
            let qty = newData.normal_remaining_quantity - (isFreeQuantity ? newData.free_quantity : newData.quantity);
            updatedData = updatedData.map((data) => {
                return ({
                    ...data,
                    normal_remaining_quantity: data.item_id == newData.item_id ? qty : data.normal_remaining_quantity,
                })
            })
        }
        setItemList(updatedData);
        console.log('ItemList', JSON.stringify(updatedData))
    }

    const selectItemData = (items, rowIndex) => {
        const updatedData = itemRows.map((item, i) => {
            if (i == rowIndex) {
                return { ...item, ...items };
            }
            return item;
        });
        setItemRows(updatedData);
        setSelectedItem(items);
        setIsDialog_ItemList(false);
        setIsDialog_SelectTrackingType(true);
    }
    const selectFreeNormalQty = async (item, index) => {
        item = {
            ...item,
            normal_remaining_quantity: item.normal_remaining_quantity + item.free_quantity,
            //free_quantity: 0,
        };
        await setSelectedItem(item);
        await setSelectedItemIndex(index);
        setIsDialog_Normal({ isVisible: true, isFreeQuantity: true, isNewQty: item.isNewQty });
    }
    const closeDialog_SelectTrackingType = (_index) => {
        setIsDialog_SelectTrackingType(false);
        if (_index) {
            let rows = itemRows.filter((data, index) => index !== _index);
            setItemRows(rows);
        }
    }
    const listStyle = {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    };

    const itemStyle = {
        padding: '10px',
        margin: '5px 0',
        border: '2px solid #ccc',
        borderRadius: '5px',
        transition: 'border-color 0.3s ease',
    };

    const handleItemSearchChange = (e) => {
        setSearchItemName(e.target.value);
    }

    const callBackNormalQuantity = (data, isFreeQuantity) => {
        setIsDialog_Normal({ isVisible: false, isFreeQuantity: false, isNewQty: 'OLD' });
        setIsDialog_ViewNormal(false);
        updateItemRowData(itemList, data, isFreeQuantity);
    }
    const callBackSerialQuantity = (data, isFreeQuantity) => {
        updateItemRowData(itemList, data, isFreeQuantity);
    }
    const callBackDeleteSerialQuantity = (data, isFreeQuantity) => {
        let updatedData = itemList;
        updatedData = updatedData.map((item) => {
            return ({
                ...item,
                itemSerialNo: item.item_id == data.item_id ? data.itemSerialNo : item.itemSerialNo,
                selectedData: item.item_id == data.item_id && data.selectedData,
            })
        });
        setItemList(updatedData);
        updateItemRowData(updatedData, data, isFreeQuantity);
    }
    const callBackBatchQuantity = (data, isFreeQuantity) => {
        updateItemRowData(itemList, data, isFreeQuantity);
    }

    const viewItemList = (rowIndex) => {
        console.log('itemListFilter', JSON.stringify(itemListFilter));
        return (
            itemListFilter.length > 0 ?
                itemListFilter.map((items, index) => {
                    let urlImg = companyLogo;
                    const regex = /\.(jpeg|png)$/i;
                    const isExtensionPresent = items.images.some(item => regex.test(item.file_name));

                    if (isExtensionPresent) {
                        urlImg = items.images[0].file_name;
                    }

                    return (
                        <li
                            key={index}
                            style={itemStyle}
                            onMouseEnter={(e) => (e.target.style.borderColor = '#673ab7')}
                            onMouseLeave={(e) => (e.target.style.borderColor = '#ccc')}
                            onClick={() => selectItemData(items, rowIndex)}
                        >
                            <img src={urlImg} style={{ maxWidth: '30px', maxHeight: '30px', borderRadius: '10px', marginRight: 20 }} />

                            <span className="iti__party-name">
                                {items.name} <br />
                                {isPurchasePriceShow == 1 && (
                                    <span> Purchase Price : {items.purchase_price}</span>
                                )}
                            </span>
                        </li>
                    );
                }) :
                <li>No item found</li>
        )
    }

    const viewItemTable = () => {
        let _dataSet = [];
        itemRows.map((item, index) => {

            _dataSet.push(
                <tr key={index} style={{ backgroundColor: "white" }}>
                    {!isConvert && <td>
                        <button
                            type="button"
                            onClick={() => {
                                deleteItemRow(index);
                            }}
                            className="btn btn-outline-danger"
                        >
                            X
                        </button>
                    </td>}
                    <td>{index + 1}</td>
                    <td  >
                        <div className="col-lg-12">
                            <div
                                className="form-material open"
                                id="paddingOff"
                                onMouseLeave={() => { }}
                            >
                                <input
                                    type="text"
                                    className="form-control search-box"
                                    onChange={() => { }}
                                    onClick={() => !item.name && showItemList(index)}
                                    placeholder="Item Name Here..."
                                    autoComplete="off"
                                    value={item.name}
                                    readOnly
                                />
                            </div>
                        </div>
                    </td>
                    {
                        item.trackingType == 'Batch' ?
                            <td>
                                <button
                                    className="btn btn-info"
                                    title="View Batch"
                                    onClick={() => {
                                        if (
                                            businessSetting.BatchNoChk == 1 ||
                                            businessSetting.ModelNoChk == 1 ||
                                            businessSetting.ExpDateChk == 1 ||
                                            businessSetting.MfgDateChk == 1 ||
                                            businessSetting.SizeChk == 1 ||
                                            businessSetting.BatchNoAdditionalChk1 == 1 ||
                                            businessSetting.BatchNoAdditionalChk2 == 1
                                        ) {
                                            setSelectedItemIndex(index);
                                            setIsDialog_ViewBatch({ isVisible: true, data: item });
                                        } else {
                                            alert('Your batch settings are currently disabled. Please enable the batch relevant fields in the settings section for viewing the batch details.');
                                        }
                                    }}
                                    disabled={isConvert}
                                >
                                    <i className="fa fa-eye" title="View Batch"></i>
                                </button>
                            </td> : (
                                item.trackingType == 'Serial' ?
                                    <td>
                                        <button
                                            onClick={async () => {
                                                if (businessSetting.SerialNoChk == 1 ||
                                                    businessSetting.SerialNoAdditionalChk1 == 1 ||
                                                    businessSetting.SerialNoAdditionalChk2 == 1) {
                                                    setSelectedItemIndex(index);
                                                    setIsDialog_ViewSerial({ isVisible: true, data: item });
                                                } else {
                                                    alert("Your serial settings are currently disabled. Please enable the serial's relevant fields in the settings section for viewing the serial details.");
                                                }


                                            }}
                                            className="btn btn-info"
                                            title="View Serial"
                                            disabled={isConvert}
                                        >
                                            <i className="fa fa-eye" title="View Serial"></i>
                                        </button>
                                    </td> : (
                                        item.trackingType == 'Normal' ?
                                            <td>
                                                <button
                                                    onClick={async () => {
                                                        await setSelectedItem(item);
                                                        await setSelectedItemIndex(index);
                                                        setIsDialog_ViewNormal(true);

                                                    }}
                                                    className="btn btn-info"
                                                    title="View Serial"
                                                    disabled={isConvert}
                                                >
                                                    <i className="fa fa-eye" title="Change Qty"></i>
                                                </button>
                                            </td> : <td></td>
                                    )

                            )

                    }

                    <td>
                        <div className="col-lg-12 form-material" id="paddingOff">
                            <input
                                type="text"
                                required="required"
                                className="form-control"
                                value={item.quantity}
                                onChange={() => { }}
                                readOnly
                            />
                        </div>
                    </td>
                    {
                        businessSetting.FreeItemQuantity > 0 && 
                        <td>
                        <div className="col-lg-12 form-material" id="paddingOff">
                            <input
                                type="text"
                                required="required"
                                className="form-control"
                                value={item.free_quantity}
                                onClick={async () => {
                                    if (item.trackingType == 'Normal') {
                                        selectFreeNormalQty(item, index);
                                    } else if (item.trackingType == 'Serial') {
                                        setSelectedItemIndex(index);
                                        setIsDialog_Serial({ isVisible: true, data: item, isFreeQuantity: true });
                                    }

                                }}
                                disabled={isConvert}
                            />
                        </div>
                    </td>
                    }
                    {
                        businessSetting.Count > 0 && 
                        <td>
                        <div className="col-lg-12 form-material" id="paddingOff">
                            <input
                                type="text"
                                maxLength="100"
                                required="required"
                                className="form-control"
                                onKeyPress={(e) => handleKeyPress(e)}
                                value={item.trans_count}
                                disabled={isConvert}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    value = value * 1;
                                    let data = itemRows.map((data, i) => ({
                                        ...data,
                                        trans_count: i == index ? value : data.trans_count
                                    }))
                                    setItemRows(data);
                                    calculateTotalTableAmount(data);
                                }}
                            />
                        </div>
                    </td>
                    }
                    <td>
                        <div className="col-lg-12 form-material" id="paddingOff">
                            <select
                                style={{ width: "90px" }}
                                className="form-control"
                                onChange={(e) => {
                                    let value = e.target.value;
                                    let data = itemRows.map((data, i) => ({
                                        ...data,
                                        base_unit_id: i == index ? value : data.base_unit_id,
                                        unit: i == index ? unitDataArray.find(unit => unit.unit_id == value) : data.unit
                                    }))
                                    setItemRows(data);
                                    calculateTotalTableAmount(data);
                                }}
                                value={item.base_unit_id}
                                disabled={isConvert}
                            >
                                <option value={0} selected>
                                    None
                                </option>
                                {unitDataArray.map((item, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={item.unit_id}
                                        >
                                            {item.unit_name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </td>
                    <td>
                        <div className="col-lg-12 form-material" id="paddingOff">
                            <input
                                type="text"
                                min="0"
                                required="required"
                                className="form-control"
                                onKeyPress={(e) => handleKeyPress(e)}
                                value={item.rate}
                                onChange={(e) => { onChangeRate(e.target.value, index) }}
                                disabled={isConvert}
                            />
                        </div>
                    </td>
                    {businessSetting.AdditionalCESS == 1 && (
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                                <input
                                    type="text"
                                    min="0"
                                    required="required"
                                    className="form-control"
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    value={item.Additional_CESS}
                                    onChange={(e) => { onChangeAdditionalCESS(e.target.value, index) }}
                                    disabled={isConvert}
                                />
                            </div>
                        </td>
                    )}
                    {businessSetting.AllowInclusiveExclusive == 1 && (
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                                <select
                                    style={{ width: "90px" }}
                                    className="form-control"
                                    value={item.sale_tax_type}
                                    onChange={(e) => { onChangeTaxType(e.target.value, index) }}
                                    disabled={isConvert}
                                >
                                    {taxTypeDataArray.map(({ value, label }, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={value}
                                            >
                                                {label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </td>
                    )}

                    {businessSetting.ItemWiseDiscount == 1 && (
                        <td>
                            <tr style={{ backgroundColor: "white" }}>
                                <td>
                                    <div className="col-lg-12 form-material" id="paddingOff">
                                        <input
                                            type="text"
                                            style={{ width: "60px" }}
                                            min="0"
                                            maxLength="3"
                                            className="form-control"
                                            onKeyPress={(e) => handleKeyPress(e)}
                                            value={item.discount_in_per}
                                            autoComplete="off"
                                            onChange={(e) => onChangeDiscountPercentage(e.target.value, index)}
                                            max="100"
                                            disabled={isConvert}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="col-lg-12 form-material" id="paddingOff">
                                        <input
                                            type="text"
                                            min="0"
                                            maxLength="99999"
                                            className="form-control"
                                            onKeyPress={(e) => handleKeyPress(e)}
                                            value={item.discount_in_amount}
                                            autoComplete="off"
                                            onChange={(e) => onChangeDiscountAmount(e.target.value, index)}
                                            disabled={isConvert}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </td>
                    )}
                    {businessSetting.ItemWiseTax == 1 && (
                        <td>
                            <tr style={{ backgroundColor: "white" }}>
                                <td>
                                    <div className="col-lg-12 form-material" id="paddingOff">
                                        <select
                                            style={{ width: "85px" }}
                                            className="form-control"
                                            onChange={(e) => { onChangeTaxRate(e.target.value, index) }}
                                            value={item.tax_rate_id}
                                            disabled={isConvert}
                                        >
                                            {taxRateDataArray.map((tax, index) => {

                                                return (
                                                    <option
                                                        key={index}
                                                        value={tax.id} >
                                                        {tax.name + (tax.id == 0 ? '' : "@" + tax.ratio + "%")}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div className="col-lg-12 form-material" id="paddingOff">
                                        <input
                                            type="text"
                                            disabled={isConvert}
                                            min="0"
                                            maxLength="100"
                                            className="form-control demo1"
                                            value={item.tax_value}
                                            readOnly
                                        />
                                    </div>
                                </td>
                            </tr>
                        </td>
                    )}
                    <td>
                        <div className="col-lg-12 form-material" id="paddingOff">
                            <input
                                type="text"
                                readOnly
                                className="form-control"
                                value={item.total_amount}
                            />
                        </div>
                    </td>
                </tr>
            );
        });
        return _dataSet;
    }
    return (
        <div className="row">
            <div className="col-md-12" id="formSideItem">
                <div className="block block-transparent">
                    <div className="block-content" id="formSideItem">
                        <table className="table table-bordered table-striped table-vcenter">
                            <thead id="sideTable">
                                <tr>
                                    {(!isConvert && <th style={{ width: "30px" }}></th>)}
                                    <th style={{ width: "30px" }}>#</th>
                                    <th style={{ width: "200px" }}>Item</th>
                                    <th style={{ width: "50px" }}>Tracking</th>
                                    {/* <th style={{ width: "50px" }}>Serial</th> */}
                                    <th style={{ width: "50px" }}>Qty</th>
                                    {businessSetting.FreeItemQuantity > 0 && <th style={{ width: "50px" }}>Free Qty</th>}
                                    {businessSetting.Count > 0 && <th style={{ width: "50px" }}>Count</th>}
                                    <th style={{ width: "90px" }}>Unit</th>
                                    <th style={{ width: "150px" }}>Price / Unit</th>
                                    {businessSetting.AdditionalCESS == 1 && (
                                        <th style={{ width: "90px" }}>Additional CESS</th>
                                    )}
                                    {businessSetting.AllowInclusiveExclusive == 1 && (
                                        <th style={{ width: "150px" }}> Tax Type </th>
                                    )}

                                    {businessSetting.ItemWiseDiscount == 1 && (
                                        <th style={{ width: "250px" }}>
                                            <tr>
                                                <td style={{ width: "194px" }} colSpan="2">
                                                    Discount
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "130px" }}>%</td>
                                                <td style={{ width: "100px" }}>Amount</td>
                                            </tr>
                                        </th>
                                    )}
                                    {businessSetting.ItemWiseTax == 1 && (
                                        <th style={{ width: "300px" }}>
                                            <tr>
                                                <td style={{ width: "194px" }} colSpan="2">
                                                    Tax
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "50px" }}>%</td>
                                                <td style={{ width: "100px" }}>Amount</td>
                                            </tr>
                                        </th>
                                    )}
                                    <th style={{ width: "100px" }}>Amount</th>
                                </tr>
                            </thead>

                            <tbody id="sideTable">
                                {viewItemTable()}

                                {
                                    itemRows.length > 0 &&
                                    <tr>
                                        {(!isConvert && <td></td>)}
                                        <td></td>
                                        <td>
                                            <span
                                                style={{ float: 'left', fontWeight: 600, textTransform: "uppercase" }} >
                                                Total</span>
                                        </td>
                                        {<td></td>}
                                        {/* {<td></td>} */}
                                        <td>
                                            <span
                                                style={{ float: 'left', fontWeight: 600 }} >
                                                {totalQuantity}
                                            </span>
                                        </td>
                                        {businessSetting.FreeItemQuantity > 0 && <td>
                                            <span
                                                style={{ float: 'left', fontWeight: 600 }} >
                                                {totalFreeQuantity}
                                            </span>
                                        </td>}
                                        {businessSetting.Count > 0 && <td></td>}

                                        <td></td>

                                        <td></td>
                                        {businessSetting.AdditionalCESS == 1 && <td>
                                            <span style={{ float: 'left', fontWeight: 600 }} >
                                                {totalAdditionalCess}
                                            </span>
                                        </td>}

                                        {businessSetting.AllowInclusiveExclusive == 1 && (
                                            <td></td>
                                        )}
                                        {businessSetting.ItemWiseDiscount == 1 && (
                                            <td>
                                                <span style={{ float: 'left', fontWeight: 600 }} >
                                                    {totalDiscount}
                                                </span>
                                            </td>
                                        )}

                                        {businessSetting.ItemWiseTax == 1 && (
                                            <td>
                                                <span style={{ float: 'left', fontWeight: 600 }} >
                                                    {totalGstAmount}
                                                </span>
                                            </td>
                                        )}

                                        <td>
                                            <span style={{ float: 'left', fontWeight: 600 }} >
                                                {totalAmount}
                                            </span>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        {
                            !isConvert &&
                            <div>
                                <button
                                    className="badge badge-primary pull-left"
                                    onClick={() => { addItemRow() }}
                                >
                                    Add Items
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                isDialog_ItemList && (
                    <Dialog style={{ zIndex: 1040 }} width={500} height={600} title={'Items'} onClose={() => setIsDialog_ItemList(false)}>

                        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="text"
                                placeholder="Search item name..."
                                value={searchItemName}
                                onChange={(e) => handleItemSearchChange(e)}
                                style={{ padding: '8px', fontSize: '16px', width: '450px', flexGrow: 1 }}
                            />
                            <button
                                type="submit"
                                className="btn btn-sm btn-outline-secondary"
                                onClick={() => {
                                    setIsDialog_AddNewItem(true);
                                }}
                                style={{ padding: '12px 16px', fontSize: '16px', marginLeft: '10px' }}
                            >
                                <i className="si si-plus" style={{ marginRight: 10 }}></i>
                                Add New Item
                            </button>
                        </div>
                        <ul

                            style={listStyle}
                        >
                            {viewItemList(selectedItemIndex)}
                        </ul>

                    </Dialog>
                )
            }
            {
                isDialog_AddNewItem && (
                    <Dialog style={{ zIndex: 1050 }} width={1000} height={600} title={'Items'} onClose={() => setIsDialog_AddNewItem(false)}>

                        <QuickAddNewItem
                            callBack={(data) => {
                                let item = data[0];
                                let newItem = {
                                    ...item,
                                    rate: item.sale_price,
                                    quantity: 0,
                                    free_quantity: 0,
                                    trans_count: 0,
                                    discount_in_per: 0,
                                    discount_in_amount: 0,
                                    total_amount: 0,
                                    Additional_CESS: parseFloat(item.Additional_CESS),
                                    selectedData: []
                                }
                                setItemList([newItem, ...itemList]);
                                setIsDialog_AddNewItem(false);
                            }}
                            newItemId={0}
                            itemName={''}
                            itemCreateType="Sale"
                        />

                    </Dialog>
                )
            }
            {
                isDialog_SelectTrackingType && (
                    <Dialog width={500} title={'Select tracking type'} onClose={() => {
                        closeDialog_SelectTrackingType(selectedItemIndex);
                        let rows = itemRows.filter((data, index) => index !== selectedItemIndex);
                        setItemRows(rows);
                    }}>

                        <span style={{ color: 'black' }}>Please choose tracking type</span>
                        <div style={{ marginTop: 20 }}>
                            <button
                                type="button"
                                className="btn btn-alt-secondary"
                                onClick={() => {
                                    closeDialog_SelectTrackingType();
                                    setIsDialog_Normal({ isVisible: true, isFreeQuantity: false, isNewQty: selectedItem.normal_remaining_quantity > 0 ? 'OLD' : 'NEW' });
                                }}
                            >
                                {selectedItem.normal_remaining_quantity > 0 ? 'Select Normal Qty' : 'Add Normal Qty'}
                            </button>
                            &nbsp;&nbsp;
                            {(selectedItem.itemCreateType != 'SERVICE' && (businessSetting.SerialNoChk == 1 ||
                                businessSetting.SerialNoAdditionalChk1 == 1 ||
                                businessSetting.SerialNoAdditionalChk2 == 1)) && (
                                    <button
                                        type="button"
                                        className="btn btn-alt-secondary"
                                        onClick={() => {
                                            closeDialog_SelectTrackingType();
                                            setIsDialog_Serial({ isVisible: true, data: selectedItem, isFreeQuantity: false });
                                        }}
                                    >
                                        {selectedItem.itemSerialNo.length > 0 ? 'Select Serial' : 'Add Serial'}
                                    </button>
                                )}
                            &nbsp;&nbsp;
                            {(selectedItem.itemCreateType != 'SERVICE' &&
                                (businessSetting.BatchNoChk == 1 ||
                                    businessSetting.ModelNoChk == 1 ||
                                    businessSetting.ExpDateChk == 1 ||
                                    businessSetting.MfgDateChk == 1 ||
                                    businessSetting.SizeChk == 1 ||
                                    businessSetting.BatchNoAdditionalChk1 == 1 ||
                                    businessSetting.BatchNoAdditionalChk2 == 1)
                            ) && (
                                    <button
                                        type="button"
                                        className="btn btn-alt-secondary"
                                        onClick={() => {
                                            closeDialog_SelectTrackingType();
                                            setIsDialog_Batch({ isVisible: true });
                                        }}
                                    >
                                        {selectedItem.itemBatchNo.length > 0 ? 'Select Batch' : 'Add Batch'}
                                    </button>
                                )}
                        </div>

                    </Dialog>
                )
            }
            {
                isDialog_Normal.isVisible && (
                    <Dialog width={500} title={'Normal Quantity'} onClose={() => {
                        setIsDialog_Normal({ isVisible: false, isFreeQuantity: false, isNewQty: 'OLD' });
                        if (!isDialog_Normal.isFreeQuantity) {
                            let rows = itemRows.filter((data, index) => index !== selectedItemIndex);
                            setItemRows(rows);
                        }
                    }}>
                        <SelectNormalQuntity
                            data={selectedItem}
                            isFreeQuantity={isDialog_Normal.isFreeQuantity}
                            isNewQty={isDialog_Normal.isNewQty}
                            callBack={(data, isFreeQuantity) => callBackNormalQuantity(data, isFreeQuantity)}
                        />
                    </Dialog>
                )
            }
            {
                isDialog_ViewNormal && (
                    <Dialog width={500} title={'Change Quantity'} onClose={() => {
                        setIsDialog_ViewNormal(false);
                    }}>
                        <ViewNormalQuntity
                            data={selectedItem}
                            callBack={(data, isFreeQuantity) => callBackNormalQuantity(data, isFreeQuantity)}
                        />
                    </Dialog>
                )
            }
            {
                isDialog_Serial.isVisible && (
                    <Dialog title={'Select Serial'} onClose={() => {
                        setIsDialog_Serial({ isVisible: false, data: {}, isFreeQuantity: false });
                        if (!isDialog_Serial.isFreeQuantity) {
                            let rows = itemRows.filter((data, index) => index !== selectedItemIndex);
                            setItemRows(rows);
                        }
                    }}>
                        <SelectSerialQuntity
                            data={isDialog_Serial.data}
                            businessSetting={businessSetting}
                            isFreeQuantity={isDialog_Serial.isFreeQuantity}
                            callBack={(data, isFreeQuantity) => {
                                setIsDialog_Serial({ isVisible: false, data: {}, isFreeQuantity: false });
                                callBackSerialQuantity(data, isFreeQuantity)
                            }}
                        />
                    </Dialog>
                )
            }
            {
                isDialog_ViewSerial.isVisible && (
                    <Dialog width={900} title={'View Serial'} onClose={() => {
                        setIsDialog_ViewSerial({ isVisible: false, data: {} });
                    }}>
                        <ViewSerialQuntity
                            data={isDialog_ViewSerial.data}
                            businessSetting={businessSetting}
                            isFreeQuantity={false}
                            callBack={(data, isFreeQuantity) => {
                                setIsDialog_ViewSerial({ isVisible: false, data: {} });
                                callBackDeleteSerialQuantity(data, isFreeQuantity)
                            }}
                        />
                    </Dialog>
                )
            }
            {
                isDialog_Batch.isVisible && (
                    <Dialog width={900} title={'Select Batch'} onClose={() => {
                        setIsDialog_Batch({ isVisible: false })
                        let rows = itemRows.filter((data, index) => index !== selectedItemIndex);
                        setItemRows(rows);
                    }}>
                        <SelectBatchQuntity
                            data={selectedItem}
                            isEditMode={isEditMode}
                            businessSetting={businessSetting}
                            callBack={(data) => {
                                setIsDialog_Batch({ isVisible: false });
                                callBackBatchQuantity(data)
                            }}
                        />
                    </Dialog>
                )
            }
            {
                isDialog_ViewBatch.isVisible && (
                    <Dialog width={900} title={'View Batch'} onClose={() => setIsDialog_ViewBatch({ isVisible: false, data: {}, index: -1 })}>
                        <ViewBatchQuntity
                            data={isDialog_ViewBatch.data}
                            isEditMode={isEditMode}
                            businessSetting={businessSetting}
                            callBack={(data) => {
                                setIsDialog_ViewBatch({ isVisible: false, data: {}, index: -1 });
                                callBackBatchQuantity(data)
                            }}
                        />
                    </Dialog>
                )
            }
            {
                isLoading && (
                    <Dialog >
                        <Loader type="converging-spinner" />
                    </Dialog>
                )
            }
        </div>
    )
}
export default SaleAddItemTable