import React, { useState, useEffect, useRef } from "react";
import { Checkbox } from '@progress/kendo-react-inputs';
import { da, tr } from "date-fns/locale";

const SelectSerialQuntity = ({ callBack, data, isFreeQuantity, businessSetting }) => {
    
    let [serialData, setSerialData] = useState([]);
    let [newSerialData, setNewSerialData] = useState([]);

    useEffect(() => {
        let itemSerialNo = data.itemSerialNo;
        
        setSerialData(itemSerialNo);
        if (itemSerialNo.length == 0) {
            let serialData = {
                serial_id: new Date().getTime() * -1,
                serial_no: '',
                serialNoAdditionalValue1: '',
                serialNoAdditionalValue2: '',
                isFreeQuantity: isFreeQuantity,
                isSelected: true,
                isNewItem: true
            };
            setNewSerialData([...newSerialData, serialData]);
        }

    }, []);


    const onChangeSerialNo = (value, index) => {
        let updatedData = [...newSerialData];
        updatedData[index].serial_no = value;
        setNewSerialData(updatedData);
    }
    const onChangeSerialNoNoAdditional1 = (value, index) => {
        let updatedData = [...newSerialData];
        updatedData[index].serialNoAdditionalValue1 = value;
        setNewSerialData(updatedData);
    }
    const onChangeSerialNoNoAdditional2 = (value, index) => {
        let updatedData = [...newSerialData];
        updatedData[index].serialNoAdditionalValue2 = value;
        setNewSerialData(updatedData);
    }
    const onCheckedChangeNew = (value, index) => {
        let updatedData = [...newSerialData];
        updatedData[index].isSelected = !updatedData[index].isSelected;
        setNewSerialData(updatedData);
    }
    const onCheckedChange = (value, index) => {
        let updatedData = [...serialData];
        updatedData[index].isSelected = !updatedData[index].isSelected;
        setSerialData(updatedData);
    }
    const addMoreSerialData = () => {
        const hasEmptyValues = newSerialData.some(obj => 
            (businessSetting.SerialNoChk == 1 && !obj.serial_no) || 
            (businessSetting.SerialNoAdditionalChk1 == 1 && !obj.serialNoAdditionalValue1) || 
            (businessSetting.SerialNoAdditionalChk2 == 1 && !obj.serialNoAdditionalValue2)
        );
        const seen = new Set();
        let data = [...serialData, ...newSerialData];
        const duplicates = data.filter(item => {
            if (seen.has(item.serial_no)) {
                return true; 
            }
            seen.add(item.serial_no); 
            return false;
        });
        if (hasEmptyValues) {
            alert('Some values have empty of new added serial.');
        } else if (duplicates.length > 0) {
            alert('This ' + businessSetting.SerialNoTxt.toLowerCase() + ' (' + duplicates[0].serial_no + ') is already in use.');
        } else {
            let newSerial = {
                serial_id: new Date().getTime() * -1,
                serial_no: '',
                serialNoAdditionalValue1: '',
                serialNoAdditionalValue2: '',
                isFreeQuantity: isFreeQuantity,
                isSelected: true,
                isNewItem: true
            };
            setNewSerialData([...newSerialData, newSerial]);
        }
    }
    const saveSerialData = () => {
        const hasEmptyValues = newSerialData.some(obj => 
            (obj.isSelected && ((businessSetting.SerialNoChk == 1 && !obj.serial_no) || 
            (businessSetting.SerialNoAdditionalChk1 == 1 && !obj.serialNoAdditionalValue1) || 
            (businessSetting.SerialNoAdditionalChk2 == 1 && !obj.serialNoAdditionalValue2)))
        );
        console.log('serialData', JSON.stringify(serialData))
        let _serialData = [...serialData, ...newSerialData];
        const seen = new Set();
        const duplicates = _serialData.filter(item => {
            if (seen.has(item.serial_no)) {
                return true; 
            }
            seen.add(item.serial_no); 
            return false;
        });
        if (hasEmptyValues) {
            alert('Some values have empty of new added serial.');
        } else if (_serialData.filter((element) => element.isSelected).length == 0) {
            alert('Please select at least one serial number.');
        }else if (duplicates.length > 0) {
            alert('This ' + businessSetting.SerialNoTxt.toLowerCase() + ' (' + duplicates[0].serial_no + ') is already in use.');
        } else {
            let selectedData = _serialData.filter((element) => element.isSelected);
            let remaingSerialData = _serialData.filter((element) => !element.isSelected);
            let updatedData = {};
            if (isFreeQuantity) {
                selectedData = selectedData.map((data) => ({
                    ...data,
                    isFreeQuantity: isFreeQuantity
                }))
                let _selectedData = data.selectedData.concat(selectedData);
                let selectedFreeData = _selectedData.filter((element) => element.isFreeQuantity);
                updatedData = {
                    ...data,
                    itemSerialNo: remaingSerialData,
                    selectedData: _selectedData,
                    free_quantity: selectedFreeData.length,
                    trackingType: 'Serial',
                    isNewQty: _selectedData.isNewItem ? 'NEW' : 'OLD'
                }
            } else {
                updatedData = {
                    ...data,
                    itemSerialNo: remaingSerialData,
                    selectedData: selectedData,
                    quantity: selectedData.length,
                    trackingType: 'Serial',
                    isNewQty: selectedData.isNewItem ? 'NEW' : 'OLD'
                }
                
            }
            console.log('updatedData', JSON.stringify(updatedData));
            callBack(updatedData, isFreeQuantity);
            
        }
    }
    let clearBottomBorder = { border: "none", outline: "none", background: "transparent" };
    return (
        <div className="webForm px-30"  style={{maxHeight: 600, overflowY: 'auto', marginBottom: 20}}>
            <div style={{marginBottom: 20}}>
                    {
                        serialData.map((element, index) => {
                            return (
                                <div className="form-material row" key={index} style={{border: '2px solid #ccc', marginBottom: 5, paddingTop: 0, padding: 15}}>
                                    {businessSetting.SerialNoChk == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    style={clearBottomBorder}
                                                    value={element.serial_no}
                                                    placeholder={businessSetting.SerialNoTxt}
                                                    readOnly
                                                />
                                                <label htmlFor="login-username">{businessSetting.SerialNoTxt} </label>
                                            </div>
                                        </div>
                                    }
                                    {businessSetting.SerialNoAdditionalChk1 == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    style={clearBottomBorder}
                                                    value={element.serialNoAdditionalValue1}
                                                    placeholder={businessSetting.SerialNoAdditionalLabel1}
                                                    readOnly
                                                />
                                                <label htmlFor="login-username">{businessSetting.SerialNoAdditionalLabel1}</label>

                                            </div>
                                        </div>
                                    }
                                    {businessSetting.SerialNoAdditionalChk2 == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    style={clearBottomBorder}
                                                    value={element.serialNoAdditionalValue2}
                                                    placeholder={businessSetting.SerialNoAdditionalLabel2}
                                                    readOnly
                                                />
                                                <label htmlFor="login-username">{businessSetting.SerialNoAdditionalLabel2}</label>
                                            </div>
                                        </div>


                                    }
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <Checkbox
                                                label={'Select'}
                                                checked={element.isSelected}
                                                onChange={e => onCheckedChange(e.target.value, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    } 
                    {
                        newSerialData.length > 0 && 
                        <div>
                            <div
                                style={{
                                width: '100%',        // Full width of its parent
                                height: '1px',        // Line thickness
                                backgroundColor: '#e2e2e2', // Line color (black in this case)
                                margin: '20px 0',      // Optional: Add some margin around the line
                                }}
                            ></div>
                            <div style={{marginTop: 10, marginBottom: -30}}>
                                <h5> New Serial </h5>
                            </div>
                        </div>
                    }
                    {
                        newSerialData.length > 0 && newSerialData.map((element, index) => {
                            return (
                                <div className="form-material row" key={index}>
                                    {businessSetting.SerialNoChk == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={element.serial_no}
                                                    placeholder={businessSetting.SerialNoTxt}
                                                    onChange={e => onChangeSerialNo(e.target.value, index)}
                                                />
                                                <label htmlFor="login-username">{businessSetting.SerialNoTxt} </label>
                                            </div>
                                        </div>
                                    }
                                    {businessSetting.SerialNoAdditionalChk1 == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={element.serialNoAdditionalValue1}
                                                    placeholder={businessSetting.SerialNoAdditionalLabel1}
                                                    onChange={e => onChangeSerialNoNoAdditional1(e.target.value, index)}
                                                />
                                                <label htmlFor="login-username">{businessSetting.SerialNoAdditionalLabel1}</label>

                                            </div>
                                        </div>
                                    }
                                    {businessSetting.SerialNoAdditionalChk2 == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={element.serialNoAdditionalValue2}
                                                    placeholder={businessSetting.SerialNoAdditionalLabel2}
                                                    onChange={e => onChangeSerialNoNoAdditional2(e.target.value, index)}
                                                />
                                                <label htmlFor="login-username">{businessSetting.SerialNoAdditionalLabel2}</label>
                                            </div>
                                        </div>


                                    }
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <Checkbox
                                                label={'Select'}
                                                checked={element.isSelected}
                                                onChange={e => onCheckedChangeNew(e.target.value, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            <button
                type="button"
                className="btn btn-alt-primary"
                onClick={() => saveSerialData()} >
                <i className="fa fa-check mr-5"></i>
                Save
            </button>
            <button
                    type="button"
                    style={{marginLeft: 20}}
                    className="btn btn-alt-primary"
                    onClick={() => addMoreSerialData()} >
                    <i className="fa fa-check mr-5"></i>
                    Add more
            </button>
        </div>
    )
}
export default SelectSerialQuntity