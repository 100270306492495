import React,{Component} from 'react';
import {PostData} from '../service/postData';
import Modal from 'react-modal';

//----Include Fragment Pages-----
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';
//----End Include Fragment Pages-----

export default class partnerUsPagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false,
            planArray : [],
            modalIsOpen_AC : false,
            modalActivePlan : 0
        }
    }

    async componentDidMount(){
        this.planListData();
        this.setState({isLoaded:true});
    }

    planListData(){
        let _planArray = [];
        let _url_GetData = global.webistePagePlanList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _planArray = responseJson.response;
                }
                this.setState({planArray:_planArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    viewWebsitePlanListing()
    {   
        if(this.state.isLoaded == true)
        {
            let _planDetails = this.state.planArray;
            let dataList = [];

            if(_planDetails.length > 0)
            {                          
                _planDetails.map((compDatas, i) =>
                {
                    let _feildArray = [];
                    const row = compDatas;

                    let _title = row.plan_name+' ['+row.plan_type+']';
                    if(row.plan_name == row.plan_type){
                        _title = row.plan_name;
                    }

                    if(row.plan_details!=''){
                        _feildArray = JSON.parse(atob(row.plan_details));
                    }

                    let _dataSet = [];
                    _dataSet.push(<li key={"4"+i}>{row.first_tag}</li>);
                    _feildArray.map((field, idx) => {
                        if(field.value == 'Enjoy 1 month free Trial'){
                            _dataSet.push(<li key={idx}><b><i className="fas fa-check"></i> {field.value}</b></li>);
                        }
                        else{
                            _dataSet.push(<li key={idx}>{field.value}</li>);
                        }
                    });

                    let _button = <a href={global.webUrl+"apk/vyavsay.apk"} target='_blank'><button className="btn btn-lg btn-block btn-primary">Download</button></a>; //<button type="button" className="btn btn-lg btn-block btn-outline-primary">Download</button>;
                    
                    if(row.viewPlan > 0){
                        _button = <button type="button" onClick={this.openModalHandler.bind(this,row.plan_id)} className="btn btn-lg btn-block btn-primary">View Plans</button>;
                    }

                    dataList.push(
                        <div className="col-md-6 col-lg-4">
                            <div className="card shadow-sm">
                                <div className="card-header">
                                    <h4 className="my-0 font-weight-normal">{_title}</h4>
                                </div>    
                                <div className="card-body">
                                    <p>{row.plan_heading}</p>
                                    <ul className="list-unstyled mt-3 mb-4">{_dataSet}</ul>
                                    {_button}
                                </div>
                            </div>
                        </div>
                    );
                })
            }
            return dataList;
        }
    }

    openModalHandler(planId)
    {
        this.setState({modalIsOpen_AC: true,modalActivePlan:planId});
    }

    closeModalHandler = () => {
        this.setState({
            modalIsOpen_AC: false
        });
    }

    renderPriceDataPlan()
    {
        if(this.state.isLoaded == true)
        {
            let _planDetails = this.state.planArray;
            let _priceData = [];

            if(_planDetails.length > 0)
            {                          
                _planDetails.map((compDatas, i) =>
                {
                    const row = compDatas;
                    if(this.state.modalActivePlan == row.plan_id)
                    {
                        let planPrice = row.priceDetails;
                        planPrice.map((priceD, i) =>{
                            _priceData.push(
                                <div className="plan-card center shadow-1 shadow '.$_addClass.'">
                                    <div className="plan-header">{priceD.type}</div>
                                    <div className="plan-card--body flex-col flex-x-center">
                                        <p className="normal-price">₹ {priceD.plan_price}</p>
                                        <div className="plan-amt">
                                            <div className="plan-amt">
                                                <h2 className="margin-b-0">₹ {priceD.discount_plan_price}</h2>
                                            </div>
                                        </div>
                                        <div className="valid-time">
                                            <h2>{priceD.year}</h2>
                                        </div> <a className="btn waves primary center" onClick={() => alert('Under process')}>Buy Now</a>
                                    </div>
                                </div>
                            );
                        });
                    }
                })
            }
            return _priceData;
        }
    }

    render(){
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div id="main" className="mainSectionWebsite">
                        <section className="header">
                            <div className="container">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 className="txt-style-6">Best Accounting Software For Small Business</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                             
                        <section className="pricing">
                            <div className="container-lg container-md">
                                <div className="row card-deck mb-3 text-center">
                                    {this.viewWebsitePlanListing()}
                                </div>
                            </div>
                        </section>

                        {/* Footer Code Here */}
                        <FooterPage />
                    </div>
                    
                    {/* Modal for Price Show Begins */}
                    <Modal isOpen={this.state.modalIsOpen_AC} ariaHideApp={false} size="sm" onRequestClose={this.closeModalHandler} id="exampleModal">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <button type="button" className="close" data-dismiss="modal" onClick={this.closeModalHandler} aria-label="Close">x</button>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-lg-8" id="planPriceDetails">
                                            {this.renderPriceDataPlan()}
                                        </div>
                                        <div className="col-lg-4">
                                            <h3 className="txt-style-7">Choose any plan and We commit to provide these benefits for FREE!</h3>
                                            <div className="colg4"> 
                                                <div className="icon">	<span><i className="fas fa-question-circle " ></i></span>
                                                </div>  
                                                <div className="content">
                                                    <h4>Dedicated Support</h4>
                                                    <p>Please let our team know your suggestions or issues. Make a call we are here for your support 24/7</p>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="colg4">
                                                <div className="icon">	<span><i className="fas fa-history"></i></span>
                                                </div>
                                                <div className="content">
                                                    <h4>Get Free Future Updates</h4>
                                                    <p>Every month, when a new Features and  functionalities are released, you will receive an notification for upgrade to our latest versions .</p>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="colg4">
                                                <div className="icon">	<span><i className="fas fa-hand-holding-usd"></i></span>
                                                </div>
                                                <div className="content">
                                                    <h4>7 days money back guarantee</h4>
                                                    <p>If you’re not satisfied, we’re not satisfied. If you don’t love our App, report to us within 7 days of purchase and we will give you 100% money back. Now that’s a real DEAL!</p>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                
                </React.Fragment>                
            </div>
        )
    }
}