import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import QuickViewTransactionDetail from "../viewTransaction";
import { columns } from "../reports/childPages/allTransactionChild/allColumns";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";
import { PaginationControl } from "react-bootstrap-pagination-control";

import ButtonPdf from "../htmlPdfCreation";

let _UserSession = localStorage.getItem("vs_UserSession");
let _signingId = "0";
let _businessId = "0";
let _companyId = "0";
let _businessSetting = {};

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  _businessSetting = _UserSession.businessSetting;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _companyId = _companySession.company_id;
}

let _loader = "glb-ldr-prt active";

export default class userSaleListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonTitle: "Add Purchase",
      isLoaded: true,
      transactionArray: [],
      transactionArrayFilter: [],
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      name: null,

      modalIsOpen_SP: false,
      transactionIdActiveView: 0,

      paidAmount: 0,
      unpaidAmount: 0,
      total: 0,
      startDate: moment().format("MMMM D, YYYY"),
      endDate: moment().format("MMMM D, YYYY"),
      dateLabel: "",
      countList: 0,
      totalTransAmt: 0,
      totalBalanceAmt: 0,
      totalTransAmtSign: "",
      totalBalanceAmtSign: "",
      activeTransactionKey: "PAYMENT-OUT",
      activeTransactionPaymentKey: "ALL",
      pageCount: 10,
      page: 1,
      totalCount: 0,
      pageShow: 0,
      setPage: 1,
      partyData: [],
      activePartyKey: "ALL",
      searchInvNo : '',
      errors: {
        name: "",
      },
    };
  }

  async componentDidMount() {
    this.getSaleListData(
      this.state.activeTransactionKey,
      this.state.activeTransactionPaymentKey,
      this.state.page,
      this.state.activePartyKey,
      this.state.searchInvNo,
      this.state.pageCount
    );
    this.getPartyListData(this.state.company_id);
  }

  getPartyListData() {
    let _partyData = [];
    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _partyData = responseJson.response;
          }
          this.setState({ partyData: _partyData, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getInvoiceSearch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let _valueSend = value;
    this.setState({searchInvNo:_valueSend})
    if(_valueSend.length >= 3 || _valueSend == ''){
        this.getSaleListData(this.state.activeTransactionKey,
          this.state.activeTransactionPaymentKey,
          this.state.page,
          this.state.activePartyKey,_valueSend, this.state.pageCount);
    }
  }
  getSaleListData(_type, _paymentStatus, _page, _partyIDS,searchInvSend, pageCount) {
    let _transArray = [];
    let _paidAmt = 0;
    let _unpaidAmt = 0;
    let _totalAmt = 0;

    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      type: _type,
      payment_status: _paymentStatus,
      partyId: parseFloat(_partyIDS),
      isDateFilter: true,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      pageCount: pageCount,
      page: _page,
      search_inv_no:searchInvSend,
      pageType:'PAYMENT-OUT',   
    });

    PostData(global.userAllTransactionReportList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            transactionArrayFilter: _transArray,
            itemArray: _transArray,
            isLoaded: true,
            paidAmount: _paidAmt,
            unpaidAmount: _unpaidAmt,
            total: _totalAmt,
            countList: 0,
            totalTransAmt: 0,
            totalBalanceAmt: 0,
          });
        } else {
          if (responseJson.response) {
            _transArray = responseJson.response;
            _transArray.map((itm, i) => {
              _unpaidAmt = parseFloat(_unpaidAmt) + parseFloat(itm.balance);
              _paidAmt = parseFloat(_paidAmt) + parseFloat(itm.received_amount);
              _totalAmt = parseFloat(_totalAmt) + parseFloat(itm.total_amount);
            });
          }
          //_totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);
          this.setState({
            transactionArrayFilter: _transArray,
            itemArray: _transArray,
            isLoaded: true,
            paidAmount: _paidAmt,
            unpaidAmount: _unpaidAmt,
            total: _totalAmt,
            countList: responseJson.countList,
            totalTransAmt: responseJson.totalTransAmt,
            totalBalanceAmt: responseJson.totalBalanceAmt,
            totalCount: responseJson.totalCount,
            pageShow: responseJson.pageShow,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  deletePaymentOut(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getSaleListData(
                    this.state.activeTransactionKey,
                    this.state.activeTransactionPaymentKey,
                    this.state.page,
                    this.state.activePartyKey,this.state.searchInvNo,
                      this.state.pageCount
                  );
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  loadTranscListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.transactionArrayFilter;
      if (_transDetails.length > 0) {
        let _i = 0;
        _transDetails.map((value, index) => {
          _i++;
          let _trans_duplicate_normal = value.trans_duplicate_normal ? value.trans_duplicate_normal : '';
          
          let _showDupL = '';
          if(_trans_duplicate_normal == 'Duplicate'){
              _showDupL = ' (Duplicate)';
          }
          let _type = "&tType=" + value.type;
          let _textShow = "Credit Note";
          if (value.type == "SALE") {
            _textShow = "Sale";
          }
          let _actionHistory = "";
          if (value.paymentHistory!="" && value.payment_paid == "PARTIAL") {
            _actionHistory = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                data-id={value.id}
                onClick={this.clickToPaymentHistory.bind(
                  this,
                  value.id,
                  value.paymentHistory,
                  value.received_amount
                )}
              >
                Payment History
              </a>
            );
          }
          let _editActionUrl =
            global.webUrl + "business/add-sale/?auth=" + value.id;
          let _editDuplicateActionUrl =
            global.webUrl + "business/add-sale/?auth=" + value.id + '&tType=DUPLICATE';
          if (value.typeS == "SALE") {
            _editActionUrl =
              global.webUrl + "business/add-sale/?auth=" + value.id;
            _editDuplicateActionUrl =
              global.webUrl + "business/add-sale/?auth=" + value.id + '&tType=DUPLICATE';
          } else if (value.typeS == "CREDIT NOTE") {
            _editActionUrl =
              global.webUrl + "business/add-sale-credit-note/?auth=" + value.id;
            _editDuplicateActionUrl =
              global.webUrl +
              "business/add-sale-return-duplicate/?auth=" +
              value.id;
          }

          let _actionExtra = "";
          let _showInv = value.customPrefix+' #'+value.invoice_no_web;
          if(value.type == "DEBIT NOTE"){
              _showInv = value.customPrefix+' #'+value.invoice_no_return_web;
          }

          if (value.typeS == "PAYMENT-IN") {
            if (value.from_invoice_no > 0 || value.against_invoice_no > 0) {
              _actionExtra = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    {_trans_duplicate_normal == 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-sale-payment-in/?auth=" +
                        value.id +
                        _type +
                        _type
                      }
                    >
                      View / Edit Details
                    </a>
                    }
                    <a
                      className="dropdown-item"
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalAdjustViewTransaction",
                        value.id
                      )}
                    >
                      View Details
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={this.clickToDelete.bind(
                        this,
                        value.id,
                        "PAYMENT-IN"
                      )}
                    >
                      Delete
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Download PDF
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Preview
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Print
                    </a>
                  </div>
                </div>
              );
            } else {
              _actionExtra = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                     {_trans_duplicate_normal == 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-sale-payment-in/?auth=" +
                        value.id +
                        _type
                      }
                    >
                      View / Edit Details
                    </a>
                    }
                    <a
                      className="dropdown-item"
                      onClick={this.clickToDelete.bind(
                        this,
                        value.id,
                        "PAYMENT-IN"
                      )}
                    >
                      Delete
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Download PDF
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Preview
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Print
                    </a>
                    {/* <ButtonPdf transactionId={value.id}/> */}
                  </div>
                </div>
              );
            }
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i, //_i,//value.srNo,
              invoice_date: value.invoice_date,
              invoice_no: _showInv+_showDupL,
              party_name: value.party_name,
              payment_type: "Payment-In", //value.payment_type,
              //total_amount:numberFormat(value.total_amount),
              total_amount: numberFormat(value.received_amount),
              //received_amount: numberFormat(value.received_amount),
              //balance_amount : numberFormat(value.balance),
              balance_amount: numberFormat(value.unUsedAmount),
              action: _actionExtra,
            });
          } else if (value.typeS == "SALE") {
            
            if(_trans_duplicate_normal == 'Duplicate'){
                _actionHistory = '';
            }
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {_trans_duplicate_normal == 'Normal' &&
                    <a className="dropdown-item" href={_editDuplicateActionUrl}>
                      Duplicate
                    </a>
                  }
                  {_trans_duplicate_normal == 'Normal' &&
                  <a className="dropdown-item" href={_editActionUrl}>
                    View/Edit Details
                  </a>
                  }
                  {_trans_duplicate_normal == 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-receive-payment-in/?auth=" +
                        value.id +
                        _type
                      }
                    >
                      Receive Payment
                    </a>
                  }
                  {_trans_duplicate_normal == 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +'/business/add-sale-credit-note?auth='+value.id+"&tType=CREDIT NOTE&isConvert=true"
                      }
                    >
                      Convert to Return
                    </a>
                  }
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDelete.bind(this, value.id, "Sale")}
                  >
                    Delete
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview as Delivery Challan
                  </a>
                  {_actionHistory}
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>

                  {/* <ButtonPdf transactionId={value.id} isChallanPreview={true}/> */}
                </div>
              </div>
            );

            let _saleType = <span className="badge badge-warning">UNPAID</span>;
            if (value.payment_paid == "PARTIAL") {
              _saleType = (
                <span className="badge badge-info">{value.payment_paid}</span>
              );
            } else if (value.payment_paid == "PAID") {
              _saleType = (
                <span className="badge badge-success">
                  {value.payment_paid}
                </span>
              );
            }
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              invoice_no: _showInv+_showDupL,
              party_name: value.party_name,
              sale_type: value.type,
              total_amount: numberFormat(value.total_amount),
              balance: numberFormat(value.balance),
              actionExtra: _actionExtra,
            });
          } else if (value.typeS == "ESTIMATE") {
            let _status = <span className="text-primary">Open</span>;
            let _action = (
              <a
                href={
                  global.webUrl +
                  "business/add-sale/?auth=" +
                  value.id + '&tType=ESTIMATE'
                }
              >
                <span className="btn btn-sm btn-secondary">
                  Convert To Sale
                </span>
              </a>
            );
            let _extraAction = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {_trans_duplicate_normal == 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-sale-estimate/?auth=" +
                        value.id +
                        _type
                      }
                    >
                      View / Edit Details
                    </a>
                  }
                  <a
                    className="dropdown-item"
                    onClick={this.clickToDelete.bind(
                      this,
                      value.id,
                      "Delete Estimate"
                    )}
                  >
                    Delete
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                </div>
              </div>
            );

            if (value.open_status == "1") {
              _status = <span className="text-successDark">Closed</span>;
              _action = (
                <span className="text-secondary">
                  Sale Invoice No. {value.against_invoice_no}
                </span>
              );
              _extraAction = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    <a
                      className="dropdown-item"
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalAdjustViewTransaction",
                        value.id
                      )}
                    >
                      View Details
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Download PDF
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Preview
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Print
                    </a>
                  </div>
                </div>
              );
            }
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              invoice_no: _showInv+_showDupL,
              party_name: value.party_name,
              payment_type: value.payment_type,
              total_amount: numberFormat(value.total_amount),
              balance: numberFormat(value.balance),
              status: _status,
              action: _action,
              extraAction: _extraAction,
            });
          } else if (value.typeS == "DELIVERY CHALLAN") {
            let _status = <span className="text-primary">Open</span>;
            let _action = (
              <a
                href={
                  global.webUrl +
                  "business/add-sale/?auth=" +
                  value.id + '&tType=CHALLAN'
                }
              >
                <span className="btn btn-sm btn-secondary">
                  Convert To Sale
                </span>
              </a>
            );
            let _extraAction = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {_trans_duplicate_normal == 'Normal' &&
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "business/add-sale-delivery-challan/?auth=" +
                      value.id +
                      _type
                    }
                  >
                    View / Edit Details
                  </a>
                  }
                  <a
                    className="dropdown-item"
                    onClick={this.clickToDelete.bind(
                      this,
                      value.id,
                      "Delivery Challan"
                    )}
                  >
                    Delete
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                </div>
              </div>
            );

            if (value.open_status == "1") {
              _status = <span className="text-successDark">Closed</span>;
              _action = (
                <span className="text-secondary">
                  Sale Invoice No. {value.against_invoice_no}
                </span>
              );
              _extraAction = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    <a
                      className="dropdown-item"
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalAdjustViewTransaction",
                        value.id
                      )}
                    >
                      View Details
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Download PDF
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Preview
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Print
                    </a>
                  </div>
                </div>
              );
            }
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              invoice_no: _showInv+_showDupL,
              party_name: value.party_name,
              due_date: value.due_date,
              payment_type: value.payment_type,
              total_amount: numberFormat(value.total_amount),
              balance: numberFormat(value.balance),
              status: _status,
              action: _action,
              extraAction: _extraAction,
            });
          } else if (value.typeS == "SALE ORDER") {
            let _status = <span className="text-info">Order Open</span>;
            let _currentDate = moment().format("MMMM D, YYYY");
            var msDiff =
              new Date(value.due_date).getTime() - new Date().getTime(); //Future date - current date
            var daysTill = Math.floor(msDiff / (1000 * 60 * 60 * 24));

            if (daysTill < 0) {
              _status = <span className="text-primary">Order Overdue</span>;
            }
            let _action = (
              <a
                href={
                  global.webUrl +
                  "business/add-sale/?auth=" +
                  value.id + '&tType=ORDER'
                }
              >
                <span className="btn btn-sm btn-secondary">
                  Convert To Sale
                </span>
              </a>
            );

            let _extraAction = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {_trans_duplicate_normal == 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-sale-order/?auth=" +
                        value.id +
                        _type
                      }
                    >
                      View / Edit Details
                    </a>
                  }
                  <a
                    className="dropdown-item"
                    onClick={this.clickToDelete.bind(
                      this,
                      value.id,
                      "Sale Order"
                    )}
                  >
                    Delete
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                </div>
              </div>
            );

            if (value.open_status == "1") {
              _status = (
                <span className="text-successDark">Order Completed</span>
              );
              _action = (
                <span className="text-secondary">
                  Sale Invoice No. {value.against_invoice_no}
                </span>
              );
              //_extraAction = '';
              _extraAction = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    <a
                      className="dropdown-item"
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalAdjustViewTransaction",
                        value.id
                      )}
                    >
                      View Details
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={this.clickToDelete.bind(
                        this,
                        value.id,
                        "Sale Order"
                      )}
                    >
                      Delete
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Download PDF
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Preview
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Print
                    </a>
                  </div>
                </div>
              );
            }
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              due_date: value.due_date,
              invoice_no: _showInv+_showDupL,
              party_name: value.party_name,
              payment_type: "Sale Order", //value.payType,
              total_amount: numberFormat(value.total_amount),
              balance: numberFormat(value.balance),
              status: _status,
              action: _action,
              extraAction: _extraAction,
            });
          } else if (value.typeS == "CREDIT NOTE") {
            let _editActionUrl =
              global.webUrl +
              "business/add-sale-credit-note/?auth=" +
              value.id +
              _type;
            let _extraAction = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                   {_trans_duplicate_normal == 'Normal' &&
                    <a className="dropdown-item" href={_editActionUrl}>
                      View / Edit Details
                    </a>
                   }
                  <a
                    className="dropdown-item"
                    onClick={this.clickToDelete.bind(
                      this,
                      value.id,
                      "Sale Return"
                    )}
                  >
                    Delete
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                </div>
              </div>
            );
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              invoice_no: _showInv+_showDupL,
              party_name: value.party_name,
              payment_type: value.type,
              total_amount: numberFormat(value.total_amount),
              received_amount: numberFormat(value.received_amount),
              balance_amount: numberFormat(value.balance),
              action: _extraAction,
            });
          } else if (value.typeS == "PURCHASE") {
            let _actionExtra = "";
            let _actionHistory = "";
            if (value.paymentHistory!="" && value.payment_paid == "PARTIAL") {

              _actionHistory = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToPaymentHistory.bind(
                    this,
                    value.id,
                    value.paymentHistory,
                    value.paid_amount
                  )}
                >
                  Payment History
                </a>
              );
            }
            
            if(_trans_duplicate_normal == 'Duplicate'){
              _actionHistory = '';
            }
            let _duplicateUrl =
              global.webUrl +
              "business/manage-purchase-to-duplicate/?auth=" +
              value.id;
            let _editActionUrl =
              global.webUrl + "business/add-purchase/?auth=" + value.id + _type;

            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {_trans_duplicate_normal == 'Normal' &&
                    <a className="dropdown-item" href={_duplicateUrl}>
                      Duplicate
                    </a>
                  }
                  {_trans_duplicate_normal === 'Normal' &&
                    <a className="dropdown-item" href={_editActionUrl}>
                      View/Edit Details
                    </a>
                  }
                  {_trans_duplicate_normal == 'Normal' &&
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "business/manage-purchase-make-payment/?auth=" +
                      value.id
                    }
                  >
                    Make Payment
                  </a>
                  }
                  {_trans_duplicate_normal == 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-purchase-debit-note/?auth=" +
                        value.id+"&tType=DEBIT NOTE&isConvert=true"
                      }
                    >
                      Convert to Return
                    </a>
                  }
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDelete.bind(
                      this,
                      value.id,
                      "Purchase "
                    )}
                  >
                    Delete
                  </a>
                  {_actionHistory}
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                </div>
              </div>
            );
            let _saleType = <span className="badge badge-warning">UNPAID</span>;
            if (value.payment_paid == "PARTIAL") {
              _saleType = (
                <span className="badge badge-info">{value.payment_paid}</span>
              );
            } else if (value.payment_paid == "PAID") {
              _saleType = (
                <span className="badge badge-success">
                  {value.payment_paid}
                </span>
              );
            }
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              invoice_no: _showInv+_showDupL,
              party_name: value.party_name,
              sale_type: value.type,
              total_amount: numberFormat(value.total_amount),
              balance: numberFormat(value.balance),
              actionExtra: _actionExtra,
            });
          } else if (value.typeS == "PURCHASE ORDER") {
            let _currentDate = moment().format("MMMM D, YYYY");
            let _status = <span className="text-info">Order Open</span>;
            var msDiff =
              new Date(value.due_date).getTime() - new Date().getTime(); //Future date - current date
            var daysTill = Math.floor(msDiff / (1000 * 60 * 60 * 24));

            if (daysTill < 1) {
              _status = <span className="text-primary">Order Overdue</span>;
            }

            let _action = (
              <a
                href={
                  global.webUrl +
                  "business/manage-purchase-to-duplicate/?auth=" +
                  value.id
                }
              >
                <span className="btn btn-sm btn-secondary">
                  Convert To Purchase
                </span>
              </a>
            );

            let _extraAction = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {_trans_duplicate_normal == 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-purchase-order/?auth=" +
                        value.id
                      }
                    >
                      View / Edit Details
                    </a>
                  }
                  <a
                    className="dropdown-item"
                    onClick={this.clickToDelete.bind(
                      this,
                      value.id,
                      "Purchase Order"
                    )}
                  >
                    Delete
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                </div>
              </div>
            );

            if (value.open_status == "1") {
              _status = (
                <span className="text-successDark">Order Completed</span>
              );
              _action = (
                <span className="text-secondary">
                  Purchase Invoice No. {value.against_invoice_no}
                </span>
              );
              //_extraAction = '';
              _extraAction = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    <a
                      className="dropdown-item"
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalAdjustViewTransaction",
                        value.id
                      )}
                    >
                      View Details
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={this.clickToDelete.bind(
                        this,
                        value.id,
                        "Purchase Order"
                      )}
                    >
                      Delete
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Download PDF
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Preview
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Print
                    </a>
                  </div>
                </div>
              );
            }
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              due_date: value.due_date,
              invoice_no: _showInv+_showDupL,
              party_name: value.party_name,
              payment_type: value.type,
              total_amount: numberFormat(value.total_amount),
              balance: numberFormat(value.balance),
              status: _status,
              action: _action,
              extraAction: _extraAction,
            });
          } else if (value.typeS == "DEBIT NOTE") {
            let _editActionUrl =
              global.webUrl +
              "business/add-purchase-debit-note/?auth=" +
              value.id +
              _type;
            let _actionExtra = "";
            if (value.from_invoice_no > 0 || value.against_invoice_no > 0) {
              _actionExtra = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    {_trans_duplicate_normal == 'Normal' &&
                      <a className="dropdown-item" href={_editActionUrl}>
                        View / Edit Details
                      </a>
                    }
                    {_trans_duplicate_normal == 'Normal' &&
                      <a
                        className="dropdown-item"
                        href={
                          global.webUrl +
                          "business/manage-purchase-to-duplicate-return/?auth=" +
                          value.id
                        }
                      >
                        Duplicate
                      </a>
                    }
                    <a
                      className="dropdown-item"
                      onClick={this.clickToDelete.bind(
                        this,
                        value.id,
                        "Purchase Return"
                      )}
                    >
                      Delete
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Download PDF
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Preview
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Print
                    </a>
                  </div>
                </div>
              );
            } else {
              _actionExtra = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    {_trans_duplicate_normal == 'Normal' &&
                      <a
                        className="dropdown-item"
                        href={
                          global.webUrl +
                          "business/manage-purchase-to-duplicate-return/?auth=" +
                          value.id
                        }
                      >
                        Duplicate
                      </a>
                    }
                    {_trans_duplicate_normal == 'Normal' &&
                      <a className="dropdown-item" href={_editActionUrl}>
                        View / Edit Details
                      </a>
                    }
                    <a
                      className="dropdown-item"
                      onClick={this.clickToDelete.bind(
                        this,
                        value.id,
                        "Purchase Return"
                      )}
                    >
                      Delete
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Download PDF
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Preview
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Print
                    </a>
                  </div>
                </div>
              );
            }
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              invoice_no: _showInv+_showDupL,
              party_name: value.party_name,
              payment_type: value.type,
              total_amount: numberFormat(value.total_amount),
              received_amount: numberFormat(value.received_amount),
              balance_amount: numberFormat(value.balance),
              action: _actionExtra,
            });
          } else if (value.typeS == "PAYMENT-OUT") {
            let _actionExtra = "";
            if (value.from_invoice_no > 0 || value.against_invoice_no > 0) {
              _actionExtra = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    {_trans_duplicate_normal == 'Normal' &&
                      <a
                        className="dropdown-item"
                        href={
                          global.webUrl +
                          "business/add-purchase-payment-out/?auth=" +
                          value.id +
                          _type
                        }
                      >
                        View / Edit Details
                      </a>
                    }
                    <a
                      className="dropdown-item"
                      onClick={this.clickToDelete.bind(
                        this,
                        value.id,
                        "PAYMENT-OUT"
                      )}
                    >
                      Delete
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Download PDF
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Preview
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Print
                    </a>
                  </div>
                </div>
              );
            } else {
              _actionExtra = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    {_trans_duplicate_normal == 'Normal' &&
                      <a
                        className="dropdown-item"
                        href={
                          global.webUrl +
                          "business/add-purchase-payment-out/?auth=" +
                          value.id +
                          _type
                        }
                      >
                        View / Edit Details
                      </a>
                    }
                    <a
                      className="dropdown-item"
                      onClick={this.clickToDelete.bind(
                        this,
                        value.id,
                        "Payment Out"
                      )}
                    >
                      Delete
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Download PDF
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Preview
                    </a>
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/download-pdf/?auth=" +
                        value.id
                      }
                      target="_blank"
                    >
                      Print
                    </a>
                  </div>
                </div>
              );
            }
            let _payType = <span className="badge badge-warning">UNPAID</span>;
            if (value.payment_paid == "PARTIAL") {
              _payType = (
                <span className="badge badge-info">{value.payment_paid}</span>
              );
            } else if (value.payment_paid == "PAID") {
              _payType = (
                <span className="badge badge-success">
                  {value.payment_paid}
                </span>
              );
            }
            if (_businessSetting.PaymentLink == 0) {
              let _pageAK = this.state.setPage;
              
              let BalAmt = numberFormat(value.unUsedAmount);
              if(value.paymentHistory.length == 0 || value.paymentHistory.length == '0'){
                  BalAmt =  numberFormat(value.balance);;
              }
              rows.push({
                srNo: (_pageAK - 1) * this.state.pageCount + _i,
                invoice_date: value.invoice_date,
                invoice_no: _showInv+_showDupL,
                party_name: value.party_name,
                payment_type: "Payment-Out",
                total_amount: numberFormat(value.total_amount),
                balance_amount: BalAmt,//numberFormat(value.unUsedAmount),
                action: _actionExtra,
              });
            }
            if (_businessSetting.PaymentLink > 0) {
              let _pageAK = this.state.setPage;
              
              let BalAmt = numberFormat(value.unUsedAmount);
              if(value.paymentHistory.length == 0 || value.paymentHistory.length == '0'){
                  BalAmt =  numberFormat(value.balance);;
              }
              rows.push({
                srNo: (_pageAK - 1) * this.state.pageCount + _i,
                invoice_date: value.invoice_date,
                invoice_no: _showInv+_showDupL,
                party_name: value.party_name,
                payment_type: "Payment-Out",
                total_amount: numberFormat(value.total_amount),
                //balance_amount : numberFormat(value.balance),
                balance_amount: BalAmt,//numberFormat(value.unUsedAmount),
                pay_status: _payType,
                action: _actionExtra,
              });
            }
          } else if (value.typeS == "EXPENSE") {
            let _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/add-expense/?auth=" + value.id
                    }
                  >
                    View/Edit Details
                  </a>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDelete.bind(this, value.id, "Expense")}
                  >
                    Delete
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                </div>
              </div>
            );
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              invoice_no: _showInv+_showDupL,
              party_name: value.party_name,
              payment_type: value.type,
              total_amount: numberFormat(value.total_amount),
              balance_amount: numberFormat(value.balance),
              actionextra: _actionExtra,
            });
          } else if (value.typeS == "ALL") {
            let _actionUrl = "";
            let _previewChallan = '';
            if (value.type == "SALE" && _trans_duplicate_normal === 'Normal') {
              _actionUrl = (
                <Link
                  className="dropdown-item"
                  to={"/business/add-sale/?auth=" + value.id + _type}
                >
                  View/Edit Details
                </Link>
              );
              
              _previewChallan = <Link className="dropdown-item"  to={'/business/return-challan-pdf/?auth='+value.id} target="_blank">
                                    Preview as Delivery Challan
                                </Link>
            } else if (value.type == "PAYMENT-IN") {
              _actionUrl = (
                <Link
                  to={"/business/add-sale-payment-in/?auth=" + value.id + _type}
                >
                  View/Edit Details
                </Link>
              );
            } else if (value.type == "PAYMENT-OUT") {
              _actionUrl = (
                <Link
                  className="dropdown-item"
                  to={
                    "/business/add-purchase-payment-out/?auth=" +
                    value.id +
                    _type
                  }
                >
                  View/Edit Details
                </Link>
              );
            } else if (value.type == "DEBIT NOTE" && _trans_duplicate_normal === 'Normal') {
              _actionUrl = (
                <Link
                  className="dropdown-item"
                  to={
                    "/business/add-purchase-debit-note/?auth=" +
                    value.id +
                    _type
                  }
                >
                  View/Edit Details
                </Link>
              );
            } else if (value.type == "PURCHASE ORDER" && _trans_duplicate_normal === 'Normal') {
              _actionUrl = (
                <Link
                  className="dropdown-item"
                  to={"/business/add-purchase-order/?auth=" + value.id + _type}
                >
                  View/Edit Details
                </Link>
              );
            } else if (value.type == "PURCHASE" && _trans_duplicate_normal === 'Normal') {
              _actionUrl = (
                <Link
                  className="dropdown-item"
                  to={"/business/add-purchase/?auth=" + value.id + _type}
                >
                  View/Edit Details
                </Link>
              );
              
            } else if (value.type == "CREDIT NOTE" && _trans_duplicate_normal === 'Normal') {
              _actionUrl = (
                <Link
                  className="dropdown-item"
                  to={
                    "/business/add-sale-credit-note/?auth=" + value.id + _type
                  }
                >
                  View/Edit Details
                </Link>
              );
            } else if (value.type == "SALE ORDER" && _trans_duplicate_normal === 'Normal') {
              _actionUrl = (
                <Link
                  className="dropdown-item"
                  to={"/business/add-sale-order/?auth=" + value.id + _type}
                >
                  View/Edit Details
                </Link>
              );
            } else if (value.type == "DELIVERY CHALLAN" && _trans_duplicate_normal === 'Normal') {
              _actionUrl = (
                <Link
                  className="dropdown-item"
                  to={
                    "/business/add-sale-delivery-challan/?auth=" +
                    value.id +
                    _type
                  }
                >
                  View/Edit Details
                </Link>
              );
            } else if (value.type == "ESTIMATE" && _trans_duplicate_normal === 'Normal') {
              _actionUrl = (
                <Link
                  className="dropdown-item"
                  to={"/business/add-sale-estimate/?auth=" + value.id + _type}
                >
                  View/Edit Details
                </Link>
              );
            } else if (value.type == "EXPENSE" && _trans_duplicate_normal === 'Normal') {
              _type = "&tType=manage-expense";
              _actionUrl = (
                <Link
                  className="dropdown-item"
                  to={"/business/add-sale-payment-in/?auth=" + value.id + _type}
                >
                  View/Edit Details
                </Link>
              );
            }
            let _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {_actionUrl}
                  {_previewChallan}
                  <Link
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDelete.bind(this, value.id, "Expense")}
                  >
                    Delete
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={"/business/download-pdf/?auth=" + value.id}
                    target="_blank"
                  >
                    Download PDF
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={"/business/download-pdf/?auth=" + value.id}
                    target="_blank"
                  >
                    Preview
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={"/business/download-pdf/?auth=" + value.id}
                    target="_blank"
                  >
                    Print
                  </Link>
                </div>
              </div>
            );

            let _payType = <span className="badge badge-warning">UNPAID</span>;
            if (value.payment_paid == "PARTIAL") {
              _payType = (
                <span className="badge badge-info">{value.payment_paid}</span>
              );
            } else if (value.payment_paid == "PAID") {
              _payType = (
                <span className="badge badge-success">
                  {value.payment_paid}
                </span>
              );
            }
            let invNoD = value.customPrefix + " #" + value.invoice_no_web;

            if (value.invoice_no == "" || value.invoice_no == "0") {
              invNoD = "NA";
            }
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              invoice_no: invNoD+_showDupL,
              party_name: value.party_name,
              payment_type: value.type,
              total_amount: numberFormat(value.total_amount),
              balance_amount: numberFormat(value.balance),
              pay_status: _payType,
              actionextra: _actionExtra,
            });
          } else if (value.typeS == "PPAID" || value.typeS == "PRECV") {
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "business/party-to-party-transfer/?auth=" +
                      value.id
                    }
                  >
                    View/Edit Details
                  </a>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDeletePartyTransfer.bind(
                      this,
                      value.id
                    )}
                  >
                    Delete
                  </a>
                </div>
              </div>
            );
            let _pageAK = this.state.setPage;
            rows.push({
              srNo: (_pageAK - 1) * this.state.pageCount + _i,
              invoice_date: value.invoice_date,
              party_name: value.party_name,
              payment_type: value.type,
              total_amount: numberFormat(value.total_amount),
              balance_amount: numberFormat(value.balance),
              actionextra: _actionExtra,
            });
          } else {
            rows.push({});
          }
        });
      }
    }

    if (rows) {
      let _columnsRow = columns.Sale_Columns;
      if (this.state.activeTransactionKey == "SALE") {
        _columnsRow = columns.Sale_Columns;
      } else if (this.state.activeTransactionKey == "PAYMENT-IN") {
        _columnsRow = columns.PaymentIN_Columns;
      } else if (this.state.activeTransactionKey == "ESTIMATE") {
        _columnsRow = columns.SaleEstimate_Columns;
      } else if (this.state.activeTransactionKey == "DELIVERY CHALLAN") {
        _columnsRow = columns.SaleDelvChallan_Columns;
      } else if (this.state.activeTransactionKey == "SALE ORDER") {
        _columnsRow = columns.SaleOrder_Columns;
      } else if (this.state.activeTransactionKey == "CREDIT NOTE") {
        _columnsRow = columns.SaleReturn_Columns;
      } else if (this.state.activeTransactionKey == "PURCHASE") {
        _columnsRow = columns.Purchase_Columns;
      } else if (this.state.activeTransactionKey == "PURCHASE ORDER") {
        _columnsRow = columns.PurchaseOrder_Columns;
      } else if (this.state.activeTransactionKey == "DEBIT NOTE") {
        _columnsRow = columns.PurchaseReturn_Columns;
      } else if (
        this.state.activeTransactionKey == "PAYMENT-OUT" &&
        _businessSetting.PaymentLink == 0
      ) {
        _columnsRow = columns.PurchasePaymentOut_Columns;
      } else if (
        this.state.activeTransactionKey == "PAYMENT-OUT" &&
        _businessSetting.PaymentLink > 0
      ) {
        _columnsRow = columns.PurchasePaymentOutLink_Columns;
      } else if (this.state.activeTransactionKey == "EXPENSE") {
        _columnsRow = columns.Expense_Columns;
      } else if (this.state.activeTransactionKey == "ALL") {
        _columnsRow = columns.AllTrans_Columns;
      } else if (
        this.state.activeTransactionKey == "PRECV" ||
        this.state.activeTransactionKey == "PPAID"
      ) {
        _columnsRow = columns.AllTrans_Columns;
      }

      dataList = {
        columns: _columnsRow,
        rows: rows,
      };
    }
    return dataList;
  }

  handleCallBackDate = (start, end) => {
    this.setState({
      startDate: start.format("MMMM D, YYYY"),
      endDate: end.format("MMMM D, YYYY"),
    });
    this.getSaleListData(
      this.state.activeTransactionKey,
      this.state.activeTransactionPaymentKey,
      this.state.page,
      this.state.activePartyKey,this.state.searchInvNo,
        this.state.pageCount
    );
  };

  /*For Modal open close*/
  openModalHandler(e, transId) {
    if (e == "modalAdjustViewTransaction") {
      this.setState({ modalIsOpen_SP: true, transactionIdActiveView: transId });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustViewTransaction") {
      this.setState({ modalIsOpen_SP: false, transactionIdActiveView: 0 });
    }
  };
  /*End Modal open close*/

  changeTypeFilter = (e) => {
    this.setState({ activeTransactionKey: e.target.value });
    this.getSaleListData(
      e.target.value,
      this.state.activeTransactionPaymentKey,
      this.state.page,
      this.state.activePartyKey,this.state.searchInvNo,
        this.state.pageCount
    );
  };
  changeTypePayStausFilter = (e) => {
    this.setState({ activeTransactionPaymentKey: e.target.value });
    this.getSaleListData(
      this.state.activeTransactionKey,
      e.target.value,
      this.state.page,
      this.state.activePartyKey,this.state.searchInvNo,
        this.state.pageCount
    );
  };
  changePartyFilter = (e) => {
    this.setState({ activePartyKey: e.target.value });
    this.getSaleListData(
      this.state.activeTransactionKey,
      this.state.activeTransactionPaymentKey,
      this.state.page,
      e.target.value,this.state.searchInvNo,
        this.state.pageCount
    );
  };
  clickToDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getSaleListData(
                    this.state.activeTransactionKey,
                    this.state.activeTransactionPaymentKey,
                    this.state.page,
                    this.state.activePartyKey,this.state.searchInvNo,
                      this.state.pageCount
                  );
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToDeletePartyTransfer(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              party_transfer_id: value,
            });
            PostData(global.userPartyTransferDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getSaleListData(
                    this.state.activeTransactionKey,
                    this.state.activeTransactionPaymentKey,
                    this.state.page,
                    this.state.activePartyKey,this.state.searchInvNo,
                      this.state.pageCount
                  );
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }
  clickToPaymentHistory(value, paymentHistory, receivedAmount) {
    this.setState({
      modalPaymentHistory: true,
      activePaymentHistory: paymentHistory,
      trasactionHisId: value,
      receivedAmount: receivedAmount,
    });
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Payment-Out
                Transactions
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/add-purchase-payment-out/"}
            >
              Create New Purchase Payment-Out
            </a>
            <span className="breadcrumb-item active">
              Manage Payment-Out Transactions
            </span>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  <div className="block-content">
                    <div className="row items-push">
                      <div className="row col-lg-12">
                        <div className="col-lg-10">
                          <span>Filter : </span>
                          <DateRangePicker
                            initialSettings={{
                              startDate: this.state.startDate,
                              endDate: this.state.endDate,
                              locale: {
                                format: "MMMM D, YYYY",
                              },
                              ranges: {
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                  moment().subtract(1, "days").toDate(),
                                  moment().subtract(1, "days").toDate(),
                                ],
                                "Last 7 Days": [
                                  moment().subtract(6, "days").toDate(),
                                  moment().toDate(),
                                ],
                                "Last 30 Days": [
                                  moment().subtract(29, "days").toDate(),
                                  moment().toDate(),
                                ],
                                "This Month": [
                                  moment().startOf("month").toDate(),
                                  moment().endOf("month").toDate(),
                                ],
                                "Last Month": [
                                  moment()
                                    .subtract(1, "month")
                                    .startOf("month")
                                    .toDate(),
                                  moment()
                                    .subtract(1, "month")
                                    .endOf("month")
                                    .toDate(),
                                ],
                              },
                            }}
                            onCallback={this.handleCallBackDate}
                          >
                            <input
                              type="text"
                              className="form-control col-4"
                              style={{ display: "inline-block" }}
                            />
                          </DateRangePicker>
                          <select
                            onChange={this.changeTypeFilter}
                            className="form-control col-2"
                            style={{
                              display: "inline-block",
                              marginLeft: "5px",
                            }}
                          >
                            <option value="ALL">All Transaction</option>
                            <option value="PURCHASE">Purchase</option>
                            <option value="EXPENSE">Expense</option>
                            <option value="SALE">Sale</option>
                            <option value="PPAID">Party to Party [Paid]</option>
                            <option value="PRECV">Party to Party [Rcvd]</option>
                            <option value="PAYMENT-IN">Payment-In</option>
                            <option value="PAYMENT-OUT" selected>
                              Payment-Out
                            </option>
                            <option value="CREDIT NOTE">Credit Note</option>
                            <option value="DEBIT NOTE">Debit Note</option>
                            <option value="SALE ORDER">Sale Order</option>
                            <option value="PURCHASE ORDER">
                              Purchase Order
                            </option>
                            <option value="ESTIMATE">Estimate</option>
                            <option value="DELIVERY CHALLAN">
                              Delivery Challan
                            </option>
                          </select>
                          <select
                            onChange={this.changeTypePayStausFilter}
                            className="form-control col-2"
                            style={{
                              display: "inline-block",
                              marginLeft: "5px",
                            }}
                          >
                            <option value="ALL">All Status</option>
                            <option value="Paid">Paid</option>
                            <option value="Partial">Partial</option>
                            <option value="Unpaid">Unpaid</option>
                          </select>
                          <select
                            onChange={this.changePartyFilter}
                            className="form-control col-3"
                            style={{
                              display: "inline-block",
                              marginLeft: "5px",
                            }}
                          >
                            <option value="ALL">All Party</option>
                            {this.state.partyData.map((item, index) => {
                              let _sel = "";
                              if (this.state.activePartyKey == item.party_id) {
                                _sel = "selected";
                              }
                              return (
                                <option
                                  key={index}
                                  value={item.party_id}
                                  selected={_sel}
                                >
                                  {item.party_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-2">
                          <div className="row pb-20 pull-right">
                            <div className="text-right">
                              <div className="js-appear-enabled text-center">
                                <div className="text-info">
                                  <i className="fa fa-file-excel-o"></i>
                                </div>
                                <div className="font-size-sm text-muted">
                                  Excel Export
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-6 text-right border-r">
                                                            <div className="js-appear-enabled text-center">
                                                                <div className="text-info"><i className="fa fa-file-excel-o"></i></div>
                                                                <div className="font-size-sm text-muted">Excel Export</div>
                                                            </div>
                                                        </div> */}
                            {/* <div className="col-6">
                                                            <div className="js-appear-enabled text-center">
                                                                <div className="text-success"><i className="fa fa-print"></i></div>
                                                                <div className="font-size-sm text-muted">Print</div>
                                                            </div>
                                                        </div> */}
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                    <h2 className="block-title ml-10">Transactions</h2>
                    <div className="text-center">
                      <strong>
                        <span>No Of Txn : {this.state.countList}</span>
                        &nbsp;&nbsp;&nbsp;
                        <span>
                          Total Amount - :{" "}
                          {numberFormat(this.state.totalTransAmt)}
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        <span>
                          Total Balance :{" "}
                          {numberFormat(this.state.totalBalanceAmt)}
                        </span>
                        &nbsp;&nbsp;&nbsp;
                      </strong>
                    </div>
                    
                    <input type="text" className="form-control col-6" style={{display:'inline-block'}} placeholder='search invoice number (type at least three characters)'onChange={this.getInvoiceSearch}/>

                    <a href={ global.webUrl + "business/add-purchase-payment-out/"}
                      style={{}} className="pull-right btn btn-sm btn-primary" >
                      <i className="fa fa-plus-circle"></i> Add Payment-Out
                    </a>
                  </div>
                  <div className="block-content">
                    <MDBDataTableV5
                      hover
                      span
                      displayEntries={false}
                      entries={100}
                      pagingTop={false}
                      searchTop={false}
                      searchBottom={false}
                      info={false}
                      barReverse
                      data={this.loadTranscListing()}
                    />
                    <div style={{ float: "right", marginTop: "-15px" }}>
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="d-flex align-items-center">
                          <div role="status" aria-live="polite"> Rows per page:</div>
                        </div>
                        <div className="d-flex align-items-center" >
                          <select className=" d-flex align-items-center custom-select custom-select-sm form-control form-control-sm"
                                  onChange={(event) => {
                                    event.preventDefault();
                                    const { name, value } = event.target;
                                    //this.setState({ pageCount: value, setPage: 1 });
                                      this.setState(prevState => ({
                                            pageCount : value,
                                            setPage : 1,
                                          }),
                                          () => {
                                            this.getSaleListData(
                                                this.state.activeTransactionKey,
                                                this.state.activeTransactionPaymentKey,
                                                1,
                                                this.state.activePartyKey,this.state.searchInvNo,
                                                value
                                            );
                                          });
                                  }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div className="d-flex align-items-center">
                          <div role="status" aria-live="polite"> Total: {this.state.totalCount}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block-content">
                    <div style={{ float: "left", marginTop: "-45px" }}>
                      <PaginationControl
                        between={this.state.pageCount}
                        total={this.state.totalCount}
                        limit={this.state.pageCount}
                        changePage={(page) => {
                          this.setState({ setPage: page });
                          this.getSaleListData(
                            this.state.activeTransactionKey,
                            this.state.activeTransactionPaymentKey,
                            page,
                            this.state.activePartyKey,this.state.searchInvNo,
                              this.state.pageCount
                          );
                        }}
                        ellipsis={1}
                        activeClass={this.state.setPage}
                        page={this.state.setPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Edit Item Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SP}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustViewTransaction"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">View Transaction Detail</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustViewTransaction"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickViewTransactionDetail
                    transactionIdActiveView={this.state.transactionIdActiveView}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustViewTransaction"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Item Details*/}
      </React.Fragment>
    );
  }
}
