import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];
let _businessSetting = {};

let _BatchNoAdditionalChk1 = 0;
let _BatchNoAdditionalChk2 = 0;
let _BatchNoAdditionalLabel1 = "";
let _BatchNoAdditionalLabel2 = "";
let _BatchNoChk = 0;
let _BatchNoTxt = "";
let _MRPChK = 0;
let _MRPTxt = "";
let _ExpDateChk = 0;
let _ExpDateTxt = "";
let _MfgDateChk = 0;
let _MfgDateTxt = "";
let _ModelNoChk = 0;
let _ModelNoTxt = "";
let _SizeChk = 0;
let _SizeTxt = "";

let _loader = "glb-ldr-prt active";

export default class QuickAddItem extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }
    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      country_code = _companySession.country_code;
      tax_name = _companySession.tax_name;
      gstTaxDetails = _companySession.taxDetails;
      if (_UserSession!=null && _UserSession!="") {
        if (_UserSession.loginType!="Admin") {
          if (_UserSession!=undefined || _UserSession!=null) {
            _businessSetting = _UserSession.businessSetting;
            _BatchNoAdditionalChk1 = _businessSetting.BatchNoAdditionalChk1;
            _BatchNoAdditionalChk2 = _businessSetting.BatchNoAdditionalChk2;
            _BatchNoAdditionalLabel1 = _businessSetting.BatchNoAdditionalLabel1;
            _BatchNoAdditionalLabel2 = _businessSetting.BatchNoAdditionalLabel2;
            _BatchNoChk = _businessSetting.BatchNoChk;
            _BatchNoTxt = _businessSetting.BatchNoTxt;
            _MRPChK = _businessSetting.MRPChK;
            _MRPTxt = _businessSetting.MRPTxt;
            _ExpDateChk = _businessSetting.ExpDateChk;
            _ExpDateTxt = _businessSetting.ExpDateTxt;
            _MfgDateChk = _businessSetting.MfgDateChk;
            _MfgDateTxt = _businessSetting.MfgDateTxt;
            _ModelNoChk = _businessSetting.ModelNoChk;
            _ModelNoTxt = _businessSetting.ModelNoTxt;
            _SizeChk = _businessSetting.SizeChk;
            _SizeTxt = _businessSetting.SizeTxt;
          }
        }
      }
    }
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-unit/",
      itemSelectData: this.props.itemSelectData
        ? this.props.itemSelectData
        : "0",
      itemId: this.props.itemId ? this.props.itemId : "0",
      _dataType: this.props.dataType ? this.props.dataType : "ITEM",
      buttonTitle: "Add New",
      isLoaded: true,
      unitArray: [],
      secUnitArray: [],
      checkedItems: new Map(),
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      BatchNoAdditionalValue1: "",
      BatchNoAdditionalValue2: "",

      BatchNoAdditionalChk1: _BatchNoAdditionalChk1,
      BatchNoAdditionalChk2: _BatchNoAdditionalChk2,
      BatchNoAdditionalLabel1: _BatchNoAdditionalLabel1,
      BatchNoAdditionalLabel2: _BatchNoAdditionalLabel2,
      BatchNoChk: _BatchNoChk,
      BatchNoTxt: _BatchNoTxt,
      MRPChK: _MRPChK,
      MRPTxt: _MRPTxt,
      ExpDateChk: _ExpDateChk,
      ExpDateTxt: _ExpDateTxt,
      MfgDateChk: _MfgDateChk,
      MfgDateTxt: _MfgDateTxt,
      ModelNoChk: _ModelNoChk,
      ModelNoTxt: _ModelNoTxt,
      SizeChk: _SizeChk,
      SizeTxt: _SizeTxt,

      opening_stock: this.props.opening_stock ? this.props.opening_stock : 0,
      itemBatchNoAll: this.props.itemBatchNoAll
        ? this.props.itemBatchNoAll
        : [],
      openingNewStock: 0,
      isError: false,
      noCheck: "",
      errors: {
        noCheck: "",
      },
    };
  }
  async componentDidMount() {
    if (this.state.opening_stock == 0 && this.state.itemId > 0) {
      this.setState({
        itemBatchNoAll: [],
      });
    }
    let _itemBatchNoAllMain = this.state.itemBatchNoAll;
    _itemBatchNoAllMain = _itemBatchNoAllMain.map((data, index) => ({
      ...data,
      exp_date: moment(data.exp_date, "MMMM D, YYYY").format("MM/DD/YYYY"),
      mfg_date: moment(data.mfg_date, "MMMM D, YYYY").format("MM/DD/YYYY"),
    }));
    this.setState({ itemBatchNoAll: _itemBatchNoAllMain });
    console.log('itemBatchNoAll', JSON.stringify(_itemBatchNoAllMain))
  }

  handleChange(i, e, _type) {
    let errors = this.state.errors;
    let _isError = false;
    let itemBatchNoAll = this.state.itemBatchNoAll;
    let _openingStock = this.state.openingNewStock
      ? this.state.openingNewStock
      : 0;

    if (_type == "mfg_date") {
      itemBatchNoAll[i][_type] = e;
    } else if (_type == "exp_date") {
      itemBatchNoAll[i][_type] = e;
    } else if (_type == "batch_no" && _businessSetting.BatchNoChk == 1) {
      _isError = false;
      itemBatchNoAll.map((data, index) => {
        //data['error'] = "";
        if (data.batch_no == e.target.value) {
          _isError = true;
          data["error"] = "*Batch number already exists.";
        } else {
          _isError = false;
          data["error"] = "";
        }
      });
      itemBatchNoAll[i][_type] = e.target.value;
      itemBatchNoAll[i][e.target.name] = e.target.value;
    } else if (_type == "model_no" && _businessSetting.ModelNoChk == 1) {
      _isError = false;
      itemBatchNoAll[i][_type] = e.target.value;
      itemBatchNoAll[i][e.target.name] = e.target.value;
      itemBatchNoAll.map((data, index) => {
        data["error3"] = "";
      });
    } else if (_type == "size" && _businessSetting.SizeChk == 1) {
      _isError = false;
      itemBatchNoAll[i][_type] = e.target.value;
      itemBatchNoAll[i][e.target.name] = e.target.value;
      itemBatchNoAll.map((data, index) => {
        data["error4"] = "";
      });
    } else if (
      _type == "batchNoAdditionalValue1" &&
      _businessSetting.BatchNoAdditionalChk1 == 1
    ) {
      _isError = false;
      itemBatchNoAll[i][_type] = e.target.value;
      itemBatchNoAll[i][e.target.name] = e.target.value;
      itemBatchNoAll.map((data, index) => {
        data["error5"] = "";
      });
    } else if (
      _type == "batchNoAdditionalValue2" &&
      _businessSetting.BatchNoAdditionalChk1 == 1
    ) {
      _isError = false;
      itemBatchNoAll[i][_type] = e.target.value;
      itemBatchNoAll[i][e.target.name] = e.target.value;
      itemBatchNoAll.map((data, index) => {
        data["error6"] = "";
      });
    } else if (_type == "opening_stock") {
      _isError = false;
      if (validator.isInt(e.target.value)) {
        //data['error7'] = "";
        itemBatchNoAll[i][_type] = e.target.value;
        itemBatchNoAll[i][e.target.name] = e.target.value;
        itemBatchNoAll[i]["used_stock"] = e.target.value;
        itemBatchNoAll.map((data, index) => {
          data["error7"] = "";
        });
      } else {
        itemBatchNoAll.map((data, index) => {
          data["error7"] = "*Please enter only numeric value.";
        });
      }

      itemBatchNoAll[i][e.target.name] = e.target.value;
    }
    //itemBatchNoAll[i][e.target.name] = e.target.value;
    this.setState({ itemBatchNoAll, isError: _isError, errors: errors });
  }

  addFormFields() {
    let _changeDate = moment().format("MM/DD/YYYY");
    this.setState({
      itemBatchNoAll: [
        ...this.state.itemBatchNoAll,
        {
          batch_no: "",
          mrp_price: "",
          exp_date: _changeDate,
          mfg_date: _changeDate,
          model_no: "",
          size: "",
          batchNoAdditionalValue1: "",
          batchNoAdditionalValue2: "",
          opening_stock: 0,
          isSaleBatch: false,
          isBatchType: "New",
        },
      ],
    });
  }

  removeFormFields(i) {
    let itemBatchNoAll = this.state.itemBatchNoAll;
    itemBatchNoAll.splice(i, 1);
    this.setState({ itemBatchNoAll });
  }

  clickToAddData = (event) => {
    event.preventDefault();
    let _itemBatchData = this.state.itemBatchNoAll;
    let _opening_stock = 0; // this.state.openingNewStock ?  this.state.openingNewStock: 0;
    /*if(this.state.openingNewStock <= this.state.opening_stock){
                _opening_stock = this.state.opening_stock;
            }else{
                _opening_stock = this.state.openingNewStock;
            }*/

    let _isError = false;
    let errors = this.state.errors;
    // console.log("O D", _itemBatchData);
    // return false;
    _itemBatchData.map((data, index) => {
      _opening_stock += data.opening_stock ? parseInt(data.opening_stock) : 0;
      data.exp_date = moment(data.exp_date, "MM/DD/YYYY").format("MMMM D, YYYY");
      data.mfg_date = moment(data.mfg_date, "MM/DD/YYYY").format("MMMM D, YYYY");
      // console.log("N D", data.exp_date);
      // return false;
      if (data.batch_no == "" && _businessSetting.BatchNoChk == 1) {
        _isError = true;
        //errors['noCheck'] = "Please Enter "+this.state.BatchNoTxt;
        data["error"] = "*Please Enter " + this.state.BatchNoTxt + " ";
        this.setState({ isError: _isError, errors: errors });
        this.state.isError = true;
      } else if (data.mfg_date == "" && _businessSetting.MfgDateChk == 1) {
        _isError = true;
        //errors['noCheck'] = "Please Enter "+this.state.MfgDateTxt;
        data["error1"] = "*Please Enter " + this.state.MfgDateTxt + " ";
        this.setState({ isError: _isError, errors: errors });
        this.state.isError = true;
      } else if (data.exp_date == "" && _businessSetting.ExpDateChk == 1) {
        _isError = true;
        //errors['noCheck'] = "Please Enter "+this.state.ExpDateTxt;
        data["error2"] = "*Please Enter " + this.state.ExpDateTxt + " ";
        this.setState({ isError: _isError, errors: errors });
        this.state.isError = true;
      } else if (data.model_no == "" && _businessSetting.ModelNoChk == 1) {
        _isError = true;
        //errors['noCheck'] = "Please Enter "+this.state.ModelNoTxt;
        data["error3"] = "*Please Enter " + this.state.ModelNoTxt + " ";
        this.setState({ isError: _isError, errors: errors });
        this.state.isError = true;
      } else if (data.size == "" && _businessSetting.SizeChk == 1) {
        _isError = true;
        //errors['noCheck'] = "Please Enter "+this.state.SizeTxt;
        data["error4"] = "*Please Enter " + this.state.SizeTxt + " ";
        this.setState({ isError: _isError, errors: errors });
        this.state.isError = true;
      } else if (
        data.batchNoAdditionalValue1 == "" &&
        _businessSetting.BatchNoAdditionalChk1 == 1
      ) {
        _isError = true;
        //errors['noCheck'] = "Please Enter "+this.state.BatchNoAdditionalLabel1;
        data["error5"] =
          "*Please Enter " + this.state.BatchNoAdditionalLabel1 + " ";
        this.setState({ isError: _isError, errors: errors });
        this.state.isError = true;
      } else if (
        data.batchNoAdditionalValue2 == "" &&
        _businessSetting.BatchNoAdditionalChk2 == 1
      ) {
        _isError = true;
        //errors['noCheck'] = "Please Enter "+this.state.BatchNoAdditionalLabel2;
        data["error6"] =
          "*Please Enter " + this.state.BatchNoAdditionalLabel2 + " ";
        this.setState({ isError: true, errors: errors });
        this.state.isError = true;
      } else if (
        data.opening_stock == "" ||
        data.opening_stock == 0 ||
        data.opening_stock == "0"
      ) {
        _isError = true;
        //errors['noCheck'] = "Please Enter Opening Stock";
        if (data.opening_stock == 0 || data.opening_stock == "0") {
          data["error7"] = "*Opening stock cannot be blank or 0";
        } else {
          data["error7"] = "*Please Enter Opening Stock ";
        }
        this.setState({ isError: _isError, errors: errors });
        this.state.isError = true;
      } else {
        data["error"] = "";
        data["error1"] = "";
        data["error2"] = "";
        data["error3"] = "";
      }
    });
    if (_opening_stock == 0 || _opening_stock == "0") {
      _opening_stock = this.state.opening_stock;
    }
    if (this.state.isError == false && _opening_stock > 0) {
      console.log("==_itemBatchData==SEND==",_itemBatchData);
      this.props.callBack(_itemBatchData, _opening_stock);
    }
  };

  handleCallBackDate = (start, index) => {
    this.setState({ mfg_date: start.format("MM/DD/YYYY") });
    this.handleChange(index, this.state.mfg_date, "mfg_date");
  };
  handleCallBackExpDate = (start, index) => {
    //this.setState({exp_date:start.format('MMMM D, YYYY')});
    this.setState({ exp_date: start.format("MM/DD/YYYY") });
    this.handleChange(index, this.state.exp_date, "exp_date");
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    let _disableBtn = "";

    if (this.state.itemBatchNoAll.length == 0) {
      let _changeDate = moment().format("MM/DD/YYYY");
      this.setState({
        itemBatchNoAll: [
          {
            batch_no: "",
            mrp_price: "",
            exp_date: _changeDate,
            mfg_date: _changeDate,
            model_no: "",
            size: "",
            batchNoAdditionalValue1: "",
            batchNoAdditionalValue2: "",
            opening_stock: 0,
            isSaleBatch: false,
            isBatchType: "New",
            error: "",
            error1: "",
            error2: "",
            error3: "",
            error4: "",
            error5: "",
            error6: "",
            error7: "",
          },
        ],
      });
    } else {
      this.state.itemBatchNoAll.map((data, index) => {
        if (
          (data.error ? data.error.length > 0 : 0) ||
          (data.error1 ? data.error1.length > 0 : 0) ||
          (data.error2 ? data.error2.length > 0 : 0) ||
          (data.error3 ? data.error3.length > 0 : 0) ||
          (data.error4 ? data.error4.length > 0 : 0) ||
          (data.error5 ? data.error5.length > 0 : 0) ||
          (data.error6 ? data.error6.length > 0 : 0) ||
          (data.error7 ? data.error7.length > 0 : 0)
        ) {
          _disableBtn = "disbaled";
        }
      });
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="claerfix"></div>
          {this.state.itemBatchNoAll.map((element, index) => (
            <div id="allBatchD">
              {element.isSaleBatch == true && (
                <div className="form-group row">
                  {this.state.BatchNoChk == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="batch_no"
                          name="batch_no"
                          value={element.batch_no || ""}
                          placeholder={this.state.BatchNoTxt}
                          onChange={(e) =>
                            this.handleChange(index, e, "batch_no")
                          }
                          readOnly
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          {this.state.BatchNoTxt}{" "}
                          <span class="text-danger">*</span>{" "}
                        </label>

                        <span className="iris_error_txt">
                          {element.error?.length > 0 && (
                            <span className="error">{element.error}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.MfgDateChk == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <DateRangePicker
                          initialSettings={{
                            singleDatePicker: true,
                            startDate:
                              moment(element.mfg_date).format("MM/DD/YYYY") ||
                              this.state.mfg_date, //this.state.mfg_date
                          }}
                          onCallback={(date) =>
                            this.handleCallBackDate(date, index)
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={
                              moment(element.mfg_date).format("MM/DD/YYYY") ||
                              this.state.mfg_date
                            }
                            name="mfg_date"
                            id="mfg_date"
                            readOnly
                            onWheel={() => document.activeElement.blur()}
                          />
                        </DateRangePicker>
                        <label htmlFor="login-username">
                          {this.state.MfgDateTxt}
                        </label>

                        <span className="iris_error_txt">
                          {element.error1?.length > 0 && (
                            <span className="error">{element.error1}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.ExpDateChk == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <DateRangePicker
                          initialSettings={{
                            singleDatePicker: true,
                            startDate1:
                              moment(element.exp_date).format("MM/DD/YYYY") ||
                              this.state.exp_date, //this.state.exp_date
                          }}
                          onCallback={(date) =>
                            this.handleCallBackExpDate(date, index)
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={
                              moment(element.exp_date).format("MM/DD/YYYY") ||
                              this.state.exp_date
                            }
                            name="exp_date"
                            id="exp_date"
                            readOnly
                            onWheel={() => document.activeElement.blur()}
                          />
                        </DateRangePicker>
                        <label htmlFor="login-username">
                          {this.state.ExpDateTxt}
                        </label>

                        <span className="iris_error_txt">
                          {element.error2?.length > 0 && (
                            <span className="error">{element.error2}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.ModelNoChk == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="model_no"
                          name="model_no"
                          value={element.model_no || ""}
                          placeholder={this.state.ModelNoTxt}
                          onChange={(e) =>
                            this.handleChange(index, e, "model_no")
                          }
                          maxLength="30"
                          readOnly
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          {this.state.ModelNoTxt}{" "}
                          <span class="text-danger">*</span>{" "}
                        </label>

                        <span className="iris_error_txt">
                          {element.error3?.length > 0 && (
                            <span className="error">{element.error3}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.SizeChk == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="size"
                          name="size"
                          value={element.size || ""}
                          placeholder={this.state.SizeTxt}
                          onChange={(e) => this.handleChange(index, e, "size")}
                          readOnly
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          {this.state.SizeTxt}{" "}
                          <span class="text-danger">*</span>{" "}
                        </label>
                        <span className="iris_error_txt">
                          {element.error4?.length > 0 && (
                            <span className="error">{element.error4}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {this.state.BatchNoAdditionalChk1 == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="batchNoAdditionalValue1"
                          name="batchNoAdditionalValue1"
                          value={element.batchNoAdditionalValue1 || ""}
                          placeholder="Enter Value 1"
                          onChange={(e) =>
                            this.handleChange(
                              index,
                              e,
                              "batchNoAdditionalValue1"
                            )
                          }
                          readOnly
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          {this.state.BatchNoAdditionalLabel1}{" "}
                          <span class="text-danger">*</span>{" "}
                        </label>
                        <span className="iris_error_txt">
                          {element.error5?.length > 0 && (
                            <span className="error">{element.error5}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {this.state.BatchNoAdditionalChk2 == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="batchNoAdditionalValue2"
                          name="batchNoAdditionalValue2"
                          value={element.batchNoAdditionalValue2 || ""}
                          placeholder="Enter Value 2"
                          onChange={(e) =>
                            this.handleChange(
                              index,
                              e,
                              "batchNoAdditionalValue2"
                            )
                          }
                          readOnly
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          {this.state.BatchNoAdditionalLabel2}{" "}
                          <span class="text-danger">*</span>{" "}
                        </label>
                        <span className="iris_error_txt">
                          {element.error6?.length > 0 && (
                            <span className="error">{element.error6}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="col-3">
                    <div className="form-material open">
                      <input
                        type="text"
                        className="form-control"
                        id="opening_stock"
                        name="opening_stock"
                        value={element.opening_stock || ""}
                        placeholder="Opening Stock"
                        onChange={(e) =>
                          this.handleChange(index, e, "opening_stock")
                        }
                        onWheel={() => document.activeElement.blur()}
                        readOnly
                      />
                      <label htmlFor="login-username">
                        Opening Stock <span class="text-danger">*</span>{" "}
                      </label>
                      <span className="iris_error_txt">
                        {element.error7?.length > 0 && (
                          <span className="error">{element.error7}</span>
                        )}
                      </span>
                    </div>
                  </div>
                  {this.state.itemBatchNoAll.length >= 1 && index >= 1 && (
                    <div className="col-1">
                      {this.state.itemId == 0 && (
                        <div className="form-material open">
                          <a
                            className="badge badge-danger pull-right"
                            onClick={() => this.removeFormFields(index)}
                          >
                            <i className="fa fa-solid fa-trash"></i>
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              {element.isSaleBatch == false && (
                <div className="form-group row">
                  {this.state.BatchNoChk == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="batch_no"
                          name="batch_no"
                          value={element.batch_no || ""}
                          placeholder={this.state.BatchNoTxt}
                          onChange={(e) =>
                            this.handleChange(index, e, "batch_no")
                          }
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          {this.state.BatchNoTxt}{" "}
                          <span class="text-danger">*</span>{" "}
                        </label>

                        <span className="iris_error_txt">
                          {element.error?.length > 0 && (
                            <span className="error">{element.error}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.MfgDateChk == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <DateRangePicker
                          initialSettings={{
                            singleDatePicker: true,
                            startDate:
                              moment(element.mfg_date).format("MM/DD/YYYY") ||
                              this.state.mfg_date, //this.state.mfg_date
                          }}
                          onCallback={(date) =>
                            this.handleCallBackDate(date, index)
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={
                              moment(element.mfg_date).format("MM/DD/YYYY") ||
                              this.state.mfg_date
                            }
                            name="mfg_date"
                            id="mfg_date"
                            onWheel={() => document.activeElement.blur()}
                          />
                        </DateRangePicker>
                        <label htmlFor="login-username">
                          {this.state.MfgDateTxt}
                        </label>

                        <span className="iris_error_txt">
                          {element.error1?.length > 0 && (
                            <span className="error">{element.error1}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.ExpDateChk == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <DateRangePicker
                          initialSettings={{
                            singleDatePicker: true,
                            startDate1:
                              moment(element.exp_date).format("MM/DD/YYYY") ||
                              this.state.exp_date, //this.state.exp_date
                          }}
                          onCallback={(date) =>
                            this.handleCallBackExpDate(date, index)
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={
                              moment(element.exp_date).format("MM/DD/YYYY") ||
                              this.state.exp_date
                            }
                            name="exp_date"
                            id="exp_date"
                            onWheel={() => document.activeElement.blur()}
                          />
                        </DateRangePicker>
                        <label htmlFor="login-username">
                          {this.state.ExpDateTxt}
                        </label>

                        <span className="iris_error_txt">
                          {element.error2?.length > 0 && (
                            <span className="error">{element.error2}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.ModelNoChk == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="model_no"
                          name="model_no"
                          value={element.model_no || ""}
                          placeholder={this.state.ModelNoTxt}
                          onChange={(e) =>
                            this.handleChange(index, e, "model_no")
                          }
                          maxLength="30"
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          {this.state.ModelNoTxt}{" "}
                          <span class="text-danger">*</span>{" "}
                        </label>

                        <span className="iris_error_txt">
                          {element.error3?.length > 0 && (
                            <span className="error">{element.error3}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.SizeChk == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="size"
                          name="size"
                          value={element.size || ""}
                          placeholder={this.state.SizeTxt}
                          onChange={(e) => this.handleChange(index, e, "size")}
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          {this.state.SizeTxt}{" "}
                          <span class="text-danger">*</span>{" "}
                        </label>
                        <span className="iris_error_txt">
                          {element.error4?.length > 0 && (
                            <span className="error">{element.error4}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {this.state.BatchNoAdditionalChk1 == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="batchNoAdditionalValue1"
                          name="batchNoAdditionalValue1"
                          value={element.batchNoAdditionalValue1 || ""}
                          placeholder="Enter Value 1"
                          onChange={(e) =>
                            this.handleChange(
                              index,
                              e,
                              "batchNoAdditionalValue1"
                            )
                          }
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          {this.state.BatchNoAdditionalLabel1}{" "}
                          <span class="text-danger">*</span>{" "}
                        </label>
                        <span className="iris_error_txt">
                          {element.error5?.length > 0 && (
                            <span className="error">{element.error5}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {this.state.BatchNoAdditionalChk2 == 1 && (
                    <div className="col-4">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="batchNoAdditionalValue2"
                          name="batchNoAdditionalValue2"
                          value={element.batchNoAdditionalValue2 || ""}
                          placeholder="Enter Value 2"
                          onChange={(e) =>
                            this.handleChange(
                              index,
                              e,
                              "batchNoAdditionalValue2"
                            )
                          }
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          {this.state.BatchNoAdditionalLabel2}{" "}
                          <span class="text-danger">*</span>{" "}
                        </label>
                        <span className="iris_error_txt">
                          {element.error6?.length > 0 && (
                            <span className="error">{element.error6}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {element.isBatchType == "New" && (
                    <div className="col-3">
                      <div className="form-material open">
                        <input
                          type="text"
                          className="form-control"
                          id="opening_stock"
                          name="opening_stock"
                          value={element.opening_stock || ""}
                          placeholder="Opening Stock"
                          onChange={(e) =>
                            this.handleChange(index, e, "opening_stock")
                          }
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          Opening Stock <span class="text-danger">*</span>{" "}
                        </label>
                        <span className="iris_error_txt">
                          {element.error7?.length > 0 && (
                            <span className="error">{element.error7}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {element.isBatchType == "Old" && (
                    <div className="col-3">
                      <div className="form-material open">
                        <input
                          type="number"
                          className="form-control"
                          id="opening_stock"
                          name="opening_stock"
                          value={element.opening_stock || ""}
                          placeholder="Opening Stock"
                          onChange={(e) =>
                            this.handleChange(index, e, "opening_stock")
                          }
                          readOnly
                          onWheel={() => document.activeElement.blur()}
                        />
                        <label htmlFor="login-username">
                          Opening Stock <span class="text-danger">*</span>{" "}
                        </label>
                        <span className="iris_error_txt">
                          {element.error7?.length > 0 && (
                            <span className="error">{element.error7}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {this.state.itemBatchNoAll.length > 1 && index >= 0 && (
                    <div className="col-1">
                      {element.isBatchType == "New" && (
                        <div className="form-material open">
                          <a
                            className="badge badge-danger pull-right"
                            onClick={() => this.removeFormFields(index)}
                          >
                            <i className="fa fa-solid fa-trash"></i>
                          </a>
                        </div>

                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}

          

          <span className="iris_error_txt">
            {errors.noCheck.length > 0 && (
              <span className="error">{errors.noCheck}</span>
            )}
          </span>
          <div className="form-group row">
            <div className="col-12">
              {_disableBtn == "" && (
                <div>
                  <button
                    type="submit"
                    className="btn btn-alt-primary"
                    onClick={this.clickToAddData}
                    disabled={isDisabled}
                  >
                    <i className="fa fa-check mr-5"></i> Save
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-alt-primary"
                    onClick={() => this.addFormFields()}
                    disabled={isDisabled}
                  >
                    <i className="fa fa-plus mr-5"></i> Add
                  </button>
                </div>
              )}
              {_disableBtn == "disbaled" && (
                <div>
                  <button
                    type="submit"
                    className="btn btn-alt-primary"
                    onClick={this.clickToAddData}
                    disabled
                  >
                    <i className="fa fa-check mr-5"></i> Save
                  </button>{" "}
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    className="btn btn-alt-primary"
                    onClick={() => this.addFormFields()}
                    disabled
                  >
                    <i className="fa fa-plus mr-5"></i> Add
                  </button>
                </div>
              )}
              &nbsp;&nbsp;
              {/* <a className="btn btn-alt-primary" onClick={() => this.addFormFields()}>Add </a> */}
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
