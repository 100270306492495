import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import QuickTermsConnditions from './termsConditionPrint';
import QuickChangeTransName from './quickChangeTransName';
import QuickItemTableCustomization from './quickItemTableCustomization';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _companyLogo = '';
let _companyName = '';
let _companyAddress = '';
let _companyPhone = '';
let _companyEmail = '';
let _allCompanyFirm = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class GeneralSettingPanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }


        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _companyLogo = _companySession.company_logo;
            _companyName = _companySession.company_name;
            _companyAddress = _companySession.company_address;
            _companyPhone = _companySession.company_contact;
            _companyEmail = _companySession.email;

            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : false,         
            modalIsOpen_Info : false,     
            modalIsOpen_TermsCond : false,
            modalIsOpen_CompanyLogo : false,
            modalIsOpen_ChangeTransName : false,
            modalIsOpen_ItemTableCustomization : false,
            companyLogo : _companyLogo,
            companyName : _companyName,
            companyAddress : _companyAddress,
            companyPhone : _companyPhone,
            companyEmail : _companyEmail,
            _infoData : '',
            modalHeader : '',
            modalBody : '',
        }
    }

    componentDidMount()
    {  
       
    }
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        if(_key == 'CompanyLogo'){
            if(_value == 1){
                if(this.state.companyLogo == '' || this.state.companyLogo == 'NA'){
                    document.getElementById('CompanyLogo_ther').checked = false;
                    document.getElementById('CompanyLogo').checked = false;
                    this.openModalCommon('openModalCompanyLogo','Company Logo','Logo is not provided, Please edit your profile to provide the Logo first and then enable the setting again.');
                    _value = 0;
                }
            }
        }
        if(_key == 'PartyCompanyName'){
            if(_value == 1){
                if(this.state.companyName == '' || this.state.companyName == 'NA'){
                    document.getElementById('PartyCompanyName_ther').checked = false;
                    document.getElementById('PartyCompanyName').checked = false;
                    this.openModalCommon('openModalCompanyLogo','Party Company Name','Party Company Name is not provided, Please edit your profile to provide the Logo first and then enable the setting again.');
                    _value = 0;
                }
            }
        }
        if(_key == 'Address'){
            if(_value == 1){
                if(this.state.companyAddress == '' || this.state.companyAddress == 'NA'){
                    document.getElementById('Address_ther').checked = false;
                    document.getElementById('Address').checked = false;
                    this.openModalCommon('openModalCompanyLogo','Company Address','Company Address is not provided, Please edit your profile to provide the Logo first and then enable the setting again.');
                    _value = 0;
                }
            }
        }
        if(_key == "PhoneNumber"){
            if(_value == 1){
                if(this.state.companyPhone == '' || this.state.companyPhone == 'NA'){
                    document.getElementById('PhoneNumber_ther').checked = false;
                    document.getElementById('PhoneNumber').checked = false;
                    this.openModalCommon('openModalCompanyLogo','Company Phone','Company Phone is not provided, Please edit your profile to provide the Logo first and then enable the setting again.');
                    _value = 0;
                }
            }
        }
        if(_key == 'Email'){
            if(_value == 1){
                if(this.state.companyEmail == '' || this.state.companyEmail == 'NA'){
                    document.getElementById('Email_ther').checked = false;
                    document.getElementById('Email').checked = false;
                    this.openModalCommon('openModalCompanyLogo','Company Email','Company Email is not provided, Please edit your profile to provide the Logo first and then enable the setting again.');
                    _value = 0;
                }
            }
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        
        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {

                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                
                if(_key == 'regularPrintDefault'){
                    if(_value == 1){
                        _businessSettingData['thermalPrintDefault'] = 0;
                        document.getElementById('thermalPrintDefault').checked = false;
                    }
                    else{
                        _businessSettingData['thermalPrintDefault'] =1;
                        document.getElementById('thermalPrintDefault').checked = true;
                    }
                }
                else if(_key == 'thermalPrintDefault'){
                    if(_value == 1){
                        _businessSettingData['regularPrintDefault'] = 0;
                        document.getElementById('regularPrintDefault').checked = false;
                    }
                    else{
                        _businessSettingData['regularPrintDefault'] = 1;
                        document.getElementById('regularPrintDefault').checked = true;
                    }
                }
                else if(_key == 'PartyCompanyName'){
                    if(_value == 1){
                        if(this.state.companyName == '' || this.state.companyName == 'NA'){
                            document.getElementById('PartyCompanyName_ther').checked = false;
                            document.getElementById('PartyCompanyName').checked = false;
                            this.openModalCommon('openModalCompanyLogo','Party Company Name','Party Company Name is not provided, Please edit your profile to provide the Logo first and then enable the setting again.');
                        }else{
                            document.getElementById('PartyCompanyName_ther').checked = true;
                            document.getElementById('PartyCompanyName').checked = true;
                        }
                    }
                    else{
                        document.getElementById('PartyCompanyName_ther').checked = false;
                        document.getElementById('PartyCompanyName').checked = false;
                    }
                }
                else if(_key == 'CompanyLogo'){
                    if(_value == 1){
                        if(this.state.companyLogo == '' || this.state.companyLogo == 'NA'){
                            document.getElementById('CompanyLogo_ther').checked = false;
                            document.getElementById('CompanyLogo').checked = false;
                            this.openModalCommon('openModalCompanyLogo','Company Logo','Logo is not provided, Please edit your profile to provide the Logo first and then enable the setting again.');
                        }else{
                            document.getElementById('CompanyLogo_ther').checked = true;
                            document.getElementById('CompanyLogo').checked = true;
                        }
                    }
                    else{
                        document.getElementById('CompanyLogo_ther').checked = false;
                        document.getElementById('CompanyLogo').checked = false;
                    }
                }
                else if(_key == 'Address'){
                    if(_value == 1){
                        if(this.state.companyAddress == '' || this.state.companyAddress == 'NA'){
                            document.getElementById('Address_ther').checked = false;
                            document.getElementById('Address').checked = false;
                            this.openModalCommon('openModalCompanyLogo','Company Address','Company Address is not provided, Please edit your profile to provide the Logo first and then enable the setting again.');
                        }else{
                            document.getElementById('Address_ther').checked = true;
                            document.getElementById('Address').checked = true;
                        }
                    }
                    else{
                        document.getElementById('Address_ther').checked = false;
                        document.getElementById('Address').checked = false;
                    }
                }
                else if(_key == 'Email'){
                    if(_value == 1){
                        if(this.state.companyEmail == '' || this.state.companyEmail == 'NA'){
                            document.getElementById('Email_ther').checked = false;
                            document.getElementById('Email').checked = false;
                            this.openModalCommon('openModalCompanyLogo','Company Email','Company Email is not provided, Please edit your profile to provide the Logo first and then enable the setting again.');
                        }else{
                            document.getElementById('Email_ther').checked = true;
                            document.getElementById('Email').checked = true;
                        }
                    }
                    else{
                        document.getElementById('Email_ther').checked = false;
                        document.getElementById('Email').checked = false;
                    }
                }
                else if(_key == 'PhoneNumber'){
                    if(_value == 1){
                        if(this.state.companyPhone == '' || this.state.companyPhone == 'NA'){
                            document.getElementById('PhoneNumber_ther').checked = false;
                            document.getElementById('PhoneNumber').checked = false;
                            this.openModalCommon('openModalCompanyLogo','Company Phone','Company Phone is not provided, Please edit your profile to provide the Logo first and then enable the setting again.');
                        }else{
                            document.getElementById('PhoneNumber_ther').checked = true;
                            document.getElementById('PhoneNumber').checked = true;
                        }
                    }
                    else{
                        document.getElementById('PhoneNumber_ther').checked = false;
                        document.getElementById('PhoneNumber').checked = false;
                    }
                }
                else if(_key == 'GSTINSale'){
                    if(_value == 1){
                        document.getElementById('GSTINSale_ther').checked = true;
                        document.getElementById('GSTINSale').checked = true;
                    }
                    else{
                        document.getElementById('GSTINSale_ther').checked = false;
                        document.getElementById('GSTINSale').checked = false;
                    }
                }
                else if(_key == 'PrintBillSupFNTaxInv'){
                    if(_value == 1){
                        document.getElementById('PrintBillSupFNTaxInv_ther').checked = true;
                        document.getElementById('PrintBillSupFNTaxInv').checked = true;
                    }
                    else{
                        document.getElementById('PrintBillSupFNTaxInv_ther').checked = false;
                        document.getElementById('PrintBillSupFNTaxInv').checked = false;
                    }
                }
                else if(_key == 'PrintOriginalDuplicate'){
                    if(_value == 1){
                        document.getElementById('PrintOriginalDuplicate_ther').checked = true;
                        document.getElementById('PrintOriginalDuplicate').checked = true;
                    }
                    else{
                        document.getElementById('PrintOriginalDuplicate_ther').checked = false;
                        document.getElementById('PrintOriginalDuplicate').checked = false;
                    }
                }
                else if(_key == 'TotalItemQuantity'){
                    if(_value == 1){
                        document.getElementById('TotalItemQuantity_ther').checked = true;
                        document.getElementById('TotalItemQuantity').checked = true;
                    }
                    else{
                        document.getElementById('TotalItemQuantity_ther').checked = false;
                        document.getElementById('TotalItemQuantity').checked = false;
                    }
                }
                else if(_key == 'AmountWithDecimal'){
                    if(_value == 1){
                        document.getElementById('AmountWithDecimal_ther').checked = true;
                        document.getElementById('AmountWithDecimal').checked = true;
                    }
                    else{
                        document.getElementById('AmountWithDecimal_ther').checked = false;
                        document.getElementById('AmountWithDecimal').checked = false;
                    }
                }
                else if(_key == 'ReceivedAmount'){
                    if(_value == 1){
                        document.getElementById('ReceivedAmount_ther').checked = true;
                        document.getElementById('ReceivedAmount').checked = true;
                    }
                    else{
                        document.getElementById('ReceivedAmount_ther').checked = false;
                        document.getElementById('ReceivedAmount').checked = false;
                    }
                }
                else if(_key == 'BalanceAmount'){
                    if(_value == 1){
                        document.getElementById('BalanceAmount_ther').checked = true;
                        document.getElementById('BalanceAmount').checked = true;
                    }
                    else{
                        document.getElementById('BalanceAmount_ther').checked = false;
                        document.getElementById('BalanceAmount').checked = false;
                    }
                }
                else if(_key == 'PrintCurrentBalanceParty'){
                    if(_value == 1){
                        document.getElementById('PrintCurrentBalanceParty_ther').checked = true;
                        document.getElementById('PrintCurrentBalanceParty').checked = true;
                    }
                    else{
                        document.getElementById('PrintCurrentBalanceParty_ther').checked = false;
                        document.getElementById('PrintCurrentBalanceParty').checked = false;
                    }
                }
                else if(_key == 'TaxDetails'){
                    if(_value == 1){
                        document.getElementById('TaxDetails_ther').checked = true;
                        document.getElementById('TaxDetails').checked = true;
                    }
                    else{
                        document.getElementById('TaxDetails_ther').checked = false;
                        document.getElementById('TaxDetails').checked = false;
                    }
                }
                else if(_key == 'PrintDescription'){
                    if(_value == 1){
                        document.getElementById('PrintDescription_ther').checked = true;
                        document.getElementById('PrintDescription').checked = true;
                    }
                    else{
                        document.getElementById('PrintDescription_ther').checked = false;
                        document.getElementById('PrintDescription').checked = false;
                    }
                }
                else if(_key == 'PrintSignatureText'){
                    if(_value == 1){
                        document.getElementById('PrintSignatureText_ther').checked = true;
                        document.getElementById('PrintSignatureText').checked = true;
                    }
                    else{
                        document.getElementById('PrintSignatureText_ther').checked = false;
                        document.getElementById('PrintSignatureText').checked = false;
                    }
                }
                else if(_key == 'PaymentMode'){
                    if(_value == 1){
                        document.getElementById('PaymentMode_ther').checked = true;
                        document.getElementById('PaymentMode').checked = true;
                    }
                    else{
                        document.getElementById('PaymentMode_ther').checked = false;
                        document.getElementById('PaymentMode').checked = false;
                    }
                }
                else if(_key == 'PrintAcknowledgement'){
                    if(_value == 1){
                        document.getElementById('PrintAcknowledgement_ther').checked = true;
                        document.getElementById('PrintAcknowledgement').checked = true;
                    }
                    else{
                        document.getElementById('PrintAcknowledgement_ther').checked = false;
                        document.getElementById('PrintAcknowledgement').checked = false;
                    }
                }
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                
                //sendNotification("Success Message 👍",result.message,"success");   
                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /*Start Textbox Chnage*/
    handleLTxtKeyUp= (event) => {
        let activeValue = event.target.value;
        let _keyVal = event.target.attributes['data-slug'].value; 

        if(_keyVal == 'PartyAddField1Name'){
            this.setState({PartyAddField1Name:activeValue}); 
        }        
        //console.log(_keyVal+'=='+activeValue+'===STATE=='+this.state.PartyAddField1Name+'==END==');
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _keyVal,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyVal] = activeValue;   
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));     
                this.setState({_keyVal:activeValue});          
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    /*End Textbox Chnage*/
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
        
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',_infoTitle:''});
        }
        else if(e == 'openModalTermsCondition'){
            this.setState({modalIsOpen_TermsCond: false});            
        }
        else if(e == 'openModalCompanyLogo'){
            this.setState({modalIsOpen_CompanyLogo: false});            
        }
        else if(e == 'openModalChangeTransNames'){
            this.setState({modalIsOpen_ChangeTransName: false});            
        }
        else if(e == 'openModalItemTblCust'){
            this.setState({modalIsOpen_ItemTableCustomization: false});            
        }
        
    }   
    openModalTerms(e)
    {
       
        if(e == 'openModalTermsCondition'){
            this.setState({modalIsOpen_TermsCond: true});            
        }
        // else if(e == 'openModalCompanyLogo'){
        //     this.setState({modalIsOpen_CompanyLogo: true});            
        // }
        else if(e == 'openModalChangeTransNames'){
            this.setState({modalIsOpen_ChangeTransName: true});            
        }
        else if(e == 'openModalItemTblCust'){
            this.setState({modalIsOpen_ItemTableCustomization: true});            
        }

        
    }
     
    openModalCommon(e,_header,_bodyText)
    {       
        console.log(e+'=>>='+_header+'=>>>='+_bodyText+'==dd==');
        if(e == 'openModalCompanyLogo'){
            this.setState({modalIsOpen_CompanyLogo: true,modalHeader:_header,modalBody:_bodyText});            
        }        
    }
    
    /* Selectbox Chnage */
    onFilterSelChange = (e) => {
        let activeValue = e.target.value;
        let _key = e.target.attributes['data-slug'].value;
        
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        this.setState({selectedOption:activeValue});

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = activeValue;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    /* End Selectbox Chnage */

    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        let _toolPrintPartyCompanyName = "What is this?  <br/>Enables you to print company name on invoices/bills and party statement.";

        let _toolPrintCompanyLogo = "What is this?  <br/> Enables you to print company logo on invoices/bills and party statement.";

        let _toolPrintAddress = "What is this?  <br/> Enables you to print your company address on invoices/bills and party statement.";

        let _toolPrintEmail = "What is this?  <br/>Print your company email on invoices/bills.";

        let _toolPrintPhoneNumber = "What is this?  <br/> Enables you to print your company contact number on invoices/bills and party statement.";

        let _toolPrintGSTINSale = "What is this?  <br/>  Print your GSTIN on sale and Sale/Purchase orders.";

        let _toolPrintPrintBillSupFNTaxInv = "What is this?  <br/>  Enables you to print bill of supply for non tax transaction";

        let _toolPrintPrintOriginalDuplicate = "What is this?  <br/> Enables you to print check-boxes for original /duplicate/Triplicate on header of invoices/Bills  <br/>   <br/> How it is used?  <br/> This will just print the checkboxes Original, Duplicate and Triplicate on header of invoice/bills. You can tick any checkbox while giving printed invoice to Parties.  <br/>   <br/> Why to use?  <br/> If you want two copies of invoice, one for yourself & one to give it to transporter, you can enable this";

        let _toolPrintTotalItemQuantity = "What is this?  <br/>  Enables you to print total item quantity on invoices.";

        let _toolPrintAmountWithDecimal = "What is this? <br/>  Enables you to print amount upto specified decimal places always";
        
        let _toolPrintReceivedAmount ="What is this?   <br/>  Enables you to print received amount on invoices/ bills";
        
        let _toolPrintBalanceAmount ="What is this?  <br/>  Enables you to print balance amount (Pending Payment) on invoices/bills";
        
        let _toolPrintPrintCurrentBalanceParty ="What is this?   <br/>  Enables you to print current balance of party on invoice.  <br/>  This will also print previous balance on the last invoice/bill of that party.   <br/><br/>  Why to use?   <br/>  Enabling this setting will help the party know how much is the current balance pending to be paid to";
        
        let _toolPrintTaxDetails ="What is this?    <br/>  Print Tax distribution of multiple taxes applied transaction   <br/>     <br/>  Why to use?   <br/>  This is used so that the Party is aware of the various taxes implied on the transaction.";
        
        let _toolPrintAmountInWords = "What is this?   <br/> Enables you to print amount with grouping using commas as separator. It support two formats- Indian & English Number System.   <br/> <br/> How it is used?   <br/> Once enabled in indian format, amount will come as 2,00,000";
        
        let _toolPrintDescription ="What is this?    <br/>  Enables you to print transaction description on the invoices";
        
        let _toolPrintSignatureText ="What is this?    <br/>  Authorized Signatory is written by default below your signature area, however you can change the text to whatever you want to.";
        
        let _toolPrintPaymentMode ="What is this?  <br/> Enables you to print payment mode used in transaction  <br/>   <br/> Why to use?  <br/> It is enabled to know the payment mode through which the payment came, like cash/ cheque/ in bank.";
        
        let _toolPrintAcknowledgement ="What is this?   <br/> Enables you to print acknowledgment in transaction";

        let _toolPrintTermsCondition = "What is this?  <br/> Enables you to print Terms and Conditions on Sale Invoices, Delivery Challan, Sale Order, Estimate/ Quotation, Purchase and Purchase Order";

        let _toolPrintChangeTransNames = "Why to use?  <br/> For eg: You can change the invoice header of the transaction, if you want to print Bill of SUPPLY on sale transaction instead of SALE INVOICE.";

        let _toolPrintItemTblCust = "What is this?  <br/> Enables you to change the item table's column names & the width of the columns in transaction print.  <br/>   <br/> How it is used?  <br/>  You can rename the invoice/bill columns based on your needs. You can also adjust the width of the columns to suit your invoice printing needs.";

        const optionsData = [
            { value: 'Indian Eng 1,00,00,000', label: 'Indian Eg 1,00,00,000' },
            { value: 'English Eg 10,000,000', label: 'English Eg 10,000,000' },
        ];
        return(
            <React.Fragment>
                <div className="row items-push myownh1" style={{marginTop: '-50px'}}>
                    <div className="col-md-6">
                        <h5 className="content-heading">Regular</h5>
                        <ul>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.regularPrintDefault > 0) ? true : false} id="regularPrintDefault" name="regularPrintDefault" value="1" data-slug="regularPrintDefault" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Make Regular Printer Default  </span>
                            </li>
                        </ul>
                        <h5 className="content-heading">Print Company Info/Header</h5>
                        <ul>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PartyCompanyName > 0) ? true : false} id="PartyCompanyName" name="PartyCompanyName" value="1" data-slug="PartyCompanyName" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Company Name  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPartyCompanyName,'Print Company Name')}></i></span>
                            </li>   
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.CompanyLogo > 0) ? true : false} id="CompanyLogo" name="CompanyLogo" value="1" data-slug="CompanyLogo" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Company Logo  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintCompanyLogo,'Company Logo')}></i></span>
                            </li>     
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.Address > 0) ? true : false} id="Address" name="Address" value="1" data-slug="Address" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Address <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintAddress,'Address')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.Email > 0) ? true : false} id="Email" name="Email" value="1" data-slug="Email" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Email <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintEmail,'Email')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PhoneNumber > 0) ? true : false} id="PhoneNumber" name="PhoneNumber" value="1" data-slug="PhoneNumber" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Phone Number  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPhoneNumber,'Phone Number')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.GSTINSale > 0) ? true : false} id="GSTINSale" name="GSTINSale" value="1" data-slug="GSTINSale" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>GSTIN On Sale  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintGSTINSale,'GSTIN On Sale')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintBillSupFNTaxInv > 0) ? true : false} id="PrintBillSupFNTaxInv" name="PrintBillSupFNTaxInv" value="1" data-slug="PrintBillSupFNTaxInv" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Bill of Supply For Non Tax Invoices  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPrintBillSupFNTaxInv,'Print Bill of Supply For Non Tax Invoices')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintOriginalDuplicate > 0) ? true : false} id="PrintOriginalDuplicate" name="PrintOriginalDuplicate" value="1" data-slug="PrintOriginalDuplicate" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Original Duplicate <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPrintOriginalDuplicate,'Print Original Duplicate')}></i></span>
                            </li>  
                            <li style={{listStyle: 'none'}}>   

                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><i className='fa fa-forward'></i><a href='javascript:void(0)' onClick={this.openModalTerms.bind(this,'openModalChangeTransNames')} style={{color: '#6c6b6b'}}>Change Transaction Names</a>  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintChangeTransNames,'Change Transaction Names')}></i></span>
                            </li>                         

                        </ul>

                        
                        <h5 className="content-heading">Totals & Taxes</h5>
                        <ul>
                            
                            <li style={{listStyle: 'none'}}>   
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><i className='fa fa-forward'></i><a href='javascript:void(0)' onClick={this.openModalTerms.bind(this,'openModalItemTblCust')} style={{color: '#6c6b6b'}}>Item Table Customization</a>  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintItemTblCust,'Item Table Customization')}></i></span>
                            </li>   
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.TotalItemQuantity > 0) ? true : false} id="TotalItemQuantity" name="TotalItemQuantity" value="1" data-slug="TotalItemQuantity" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Total Item Quantity  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintTotalItemQuantity,'Total Item Quantity')}></i></span>
                            </li>   
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.AmountWithDecimal > 0) ? true : false} id="AmountWithDecimal" name="AmountWithDecimal" value="1" data-slug="AmountWithDecimal" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Amount With Decimal  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintAmountWithDecimal,'Amount With Decimal')}></i></span>
                            </li>     
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.ReceivedAmount > 0) ? true : false} id="ReceivedAmount" name="ReceivedAmount" value="1" data-slug="ReceivedAmount" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Received Amount <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintReceivedAmount,'Received Amount')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.BalanceAmount > 0) ? true : false} id="BalanceAmount" name="BalanceAmount" value="1" data-slug="BalanceAmount" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Balance Amount <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintBalanceAmount,'Balance Amount')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintCurrentBalanceParty > 0) ? true : false} id="PrintCurrentBalanceParty" name="PrintCurrentBalanceParty" value="1" data-slug="PrintCurrentBalanceParty" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Current Balance Of Party  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPrintCurrentBalanceParty,'Print Current Balance Of Party')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.TaxDetails > 0) ? true : false} id="TaxDetails" name="TaxDetails" value="1" data-slug="TaxDetails" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Tax Details  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintTaxDetails,'Tax Details')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                            <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Amount In Words<i className="si si-info" data-toggle="tooltip" data-placement="bottom" data-original-title=""  onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintAmountInWords,'Amount In Words')}></i></span>      
                            <select  className="form-control" id="AmountInWords" name="AmountInWords"  data-slug="AmountInWords" onChange={this.onFilterSelChange}
                            >
                            {
                                optionsData.map(({ value, label },index) => {
                                    let _sel = '';
                                    if(this.state.selectedOption == value)
                                    {_sel = 'selected';}
                                    return(            
                                    <option key={index} value={value}  selected={_sel}>{label}</option>
                                    )
                                })
                            }
                            </select>
                            </li>                              
                        </ul>

                        
                        <h5 className="content-heading">Footer</h5>
                        <ul>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintDescription > 0) ? true : false} id="PrintDescription" name="PrintDescription" value="1" data-slug="PrintDescription" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Description  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintDescription,'Print Description')}></i></span>
                            </li>   
                            <li style={{listStyle: 'none'}}>
                                
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><i className='fa fa-forward'></i><a href='javascript:void(0)' onClick={this.openModalTerms.bind(this,'openModalTermsCondition')} style={{color: '#6c6b6b'}}>Terms and conditions</a>  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintTermsCondition,'Terms and conditions')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintSignatureText > 0) ? true : false} id="PrintSignatureText" name="PrintSignatureText" value="1" data-slug="PrintSignatureText" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Signature Text  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintSignatureText,'Print Signature Text')}></i></span>
                            </li>     
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PaymentMode > 0) ? true : false} id="PaymentMode" name="PaymentMode" value="1" data-slug="PaymentMode" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Payment Mode <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPaymentMode,'Payment Mode')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintAcknowledgement > 0) ? true : false} id="PrintAcknowledgement" name="PrintAcknowledgement" value="1" data-slug="PrintAcknowledgement" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Acknowledgement <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintAcknowledgement,'Print Acknowledgement')}></i></span>
                            </li>  
                                                          
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <h5 className="content-heading">Thermal</h5>
                        <ul>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.thermalPrintDefault > 0) ? true : false} id="thermalPrintDefault" name="thermalPrintDefault" value="1" data-slug="thermalPrintDefault" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Make Thermal Printer Default  </span>
                            </li>
                        </ul>
                        <h5 className="content-heading">Print Company Info/Header</h5>
                        <ul>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PartyCompanyName > 0) ? true : false} id="PartyCompanyName_ther" name="PartyCompanyName" value="1" data-slug="PartyCompanyName" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Company Name  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPartyCompanyName,'Print Company Name')}></i></span>
                            </li>   
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.CompanyLogo > 0) ? true : false} id="CompanyLogo_ther" name="CompanyLogo" value="1" data-slug="CompanyLogo" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Company Logo  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintCompanyLogo,'Company Logo')}></i></span>
                            </li>     
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.Address > 0) ? true : false} id="Address_ther" name="Address" value="1" data-slug="Address" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Address <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintAddress,'Address')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.Email > 0) ? true : false} id="Email_ther" name="Email" value="1" data-slug="Email" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Email <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintEmail,'Email')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PhoneNumber > 0) ? true : false} id="PhoneNumber_ther" name="PhoneNumber" value="1" data-slug="PhoneNumber" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Phone Number  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPhoneNumber,'Phone Number')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.GSTINSale > 0) ? true : false} id="GSTINSale_ther" name="GSTINSale" value="1" data-slug="GSTINSale" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>GSTIN On Sale  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintGSTINSale,'GSTIN On Sale')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintBillSupFNTaxInv > 0) ? true : false} id="PrintBillSupFNTaxInv_ther" name="PrintBillSupFNTaxInv" value="1" data-slug="PrintBillSupFNTaxInv" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Bill of Supply For Non Tax Invoices  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPrintBillSupFNTaxInv,'Print Bill of Supply For Non Tax Invoices')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintOriginalDuplicate > 0) ? true : false} id="PrintOriginalDuplicate_ther" name="PrintOriginalDuplicate" value="1" data-slug="PrintOriginalDuplicate" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Original Duplicate <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPrintOriginalDuplicate,'Print Original Duplicate')}></i></span>
                            </li>   
                            <li style={{listStyle: 'none'}}>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><i className='fa fa-forward'></i><a href='javascript:void(0)' onClick={this.openModalTerms.bind(this,'openModalChangeTransNames')} style={{color: '#6c6b6b'}}>Change Transaction Names</a>  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintChangeTransNames,'Change Transaction Names')}></i></span>
                            </li>  
                        </ul>

                        
                        <h5 className="content-heading">Totals & Taxes</h5>
                        <ul>
                            
                            <li style={{listStyle: 'none'}}>   
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><i className='fa fa-forward'></i><a href='javascript:void(0)' onClick={this.openModalTerms.bind(this,'openModalItemTblCust')} style={{color: '#6c6b6b'}}>Item Table Customization</a>  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintItemTblCust,'Item Table Customization')}></i></span>
                            </li>   
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.TotalItemQuantity > 0) ? true : false} id="TotalItemQuantity_ther" name="TotalItemQuantity" value="1" data-slug="TotalItemQuantity" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Total Item Quantity  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintTotalItemQuantity,'Total Item Quantity')}></i></span>
                            </li>   
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.AmountWithDecimal > 0) ? true : false} id="AmountWithDecimal_ther" name="AmountWithDecimal" value="1" data-slug="AmountWithDecimal" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Amount With Decimal  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintAmountWithDecimal,'Amount With Decimal')}></i></span>
                            </li>     
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.ReceivedAmount > 0) ? true : false} id="ReceivedAmount_ther" name="ReceivedAmount" value="1" data-slug="ReceivedAmount" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Received Amount <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintReceivedAmount,'Received Amount')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.BalanceAmount > 0) ? true : false} id="BalanceAmount_ther" name="BalanceAmount" value="1" data-slug="BalanceAmount" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Balance Amount <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintBalanceAmount,'Balance Amount')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintCurrentBalanceParty > 0) ? true : false} id="PrintCurrentBalanceParty_ther" name="PrintCurrentBalanceParty" value="1" data-slug="PrintCurrentBalanceParty" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Current Balance Of Party  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPrintCurrentBalanceParty,'Print Current Balance Of Party')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.TaxDetails > 0) ? true : false} id="TaxDetails_ther" name="TaxDetails" value="1" data-slug="TaxDetails" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Tax Details  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintTaxDetails,'Tax Details')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                            <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Amount In Words<i className="si si-info" data-toggle="tooltip" data-placement="bottom" data-original-title=""  onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintAmountInWords,'Amount In Words')}></i></span>      
                            <select  className="form-control" id="AmountInWords_ther" name="AmountInWords"  data-slug="AmountInWords" onChange={this.onFilterSelChange}
                            >
                            {
                                optionsData.map(({ value, label },index) => {
                                    let _sel = '';
                                    if(this.state.selectedOption == value)
                                    {_sel = 'selected';}
                                    return(            
                                    <option key={index} value={value}  selected={_sel}>{label}</option>
                                    )
                                })
                            }
                            </select>
                            </li>                              
                        </ul>

                        
                        <h5 className="content-heading">Footer</h5>
                        <ul>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintDescription > 0) ? true : false} id="PrintDescription_ther" name="PrintDescription" value="1" data-slug="PrintDescription" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Description  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintDescription,'Print Description')}></i></span>
                            </li>   
                            <li style={{listStyle: 'none'}}>
                                
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><i className='fa fa-forward'></i><a href='javascript:void(0)' onClick={this.openModalTerms.bind(this,'openModalTermsCondition')} style={{color: '#6c6b6b'}}> <i className='fa fa-arrow'></i>Terms and conditions</a>  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintTermsCondition,'Terms and conditions')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintSignatureText > 0) ? true : false} id="PrintSignatureText_ther" name="PrintSignatureText" value="1" data-slug="PrintSignatureText" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Signature Text  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintSignatureText,'Print Signature Text')}></i></span>
                            </li>     
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PaymentMode > 0) ? true : false} id="PaymentMode_ther" name="PaymentMode" value="1" data-slug="PaymentMode" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Payment Mode <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintPaymentMode,'Payment Mode')}></i></span>
                            </li>  
                            
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PrintAcknowledgement > 0) ? true : false} id="PrintAcknowledgement_ther" name="PrintAcknowledgement" value="1" data-slug="PrintAcknowledgement" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Acknowledgement <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPrintAcknowledgement,'Print Acknowledgement')}></i></span>
                            </li>  
                                                          
                        </ul>
                    </div>
                </div>

            {/* Modal  Tooltip Information Begins */}
            <Modal isOpen={this.state.modalIsOpen_Info} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalInfoHandler">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="block block-themed block-transparent mb-0">
                            <div className="block-header bg-primary-dark">
                                <h3 className="block-title">{this.state._infoTitle}</h3>
                                <div className="block-options">
                                    <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'openModalInfoHandler')}>
                                        <i className="si si-close"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                            {parse(this.state._infoData)}
                            </div>
                            <br/>
                        </div>
                        
                    </div>
                </div>
            </Modal>
            {/* Modal End Tooltip Information*/}

            

                {/* Modal List Unit Conversion Details Begins */}
                <Modal isOpen={this.state.modalIsOpen_TermsCond} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalTermsCondition">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">Terms and conditions</h3>
                                    
                                    <div className="block-options">
                                        {/* <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'openModalTermsCondition')}>
                                            <i className="si si-close"></i>
                                        </button> */}
                                    </div>
                                </div>
                                <div className="block-content">
                                    <QuickTermsConnditions  />
                                </div>
                                <br/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'openModalTermsCondition')}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>                
                {/* Modal List Unit Conversion Details*/}

                {/* Modal List Unit Conversion Details Begins */}
                <Modal isOpen={this.state.modalIsOpen_CompanyLogo} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalCompanyLogo">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">{this.state.modalHeader}</h3>
                                    
                                </div>
                                <div className="block-content">
                                    <p>{this.state.modalBody}</p>
                                </div>
                                <br/>
                            </div> 
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'openModalCompanyLogo')}>Close</button>
                                <a type="button" className="btn btn-alt-primary" href={global.webUrl+'business/manage-company/'}>Edit Profile Data</a>
                            </div>
                        </div>
                    </div>
                </Modal>                
                {/* Modal List Unit Conversion Details*/}

                {/* Modal Change Transaction Names */}
                <Modal isOpen={this.state.modalIsOpen_ChangeTransName} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalChangeTransNames">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">Change Transactions Names</h3>
                                    
                                </div>
                                <div className="block-content">
                                    <QuickChangeTransName  />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'openModalChangeTransNames')}>Close</button>
                                
                            </div>
                        </div>
                    </div>
                </Modal>                
                {/* Modal Change Transaction Names*/}

                {/* Modal Item Table Customization */}
                <Modal isOpen={this.state.modalIsOpen_ItemTableCustomization} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalItemTblCust">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">Item Table Customization</h3>
                                    
                                </div>
                                <div className="block-content">
                                    <QuickItemTableCustomization  />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'openModalItemTblCust')}>Close</button>
                                
                            </div>
                        </div>
                    </div>
                </Modal>                
                {/* Modal Item Table Customization*/}
            </React.Fragment>
        )
    }
} 