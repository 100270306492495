import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};
let _BatchNoAdditionalChk1 = 0;
let _BatchNoAdditionalChk2 = 0;
let _BatchNoAdditionalLabel1 = '';
let _BatchNoAdditionalLabel2 = '';
let _BatchNoChk = 0;
let _BatchNoTxt = '';
let _MRPChK = 0;
let _MRPTxt = '';
let _ExpDateChk = 0;
let _ExpDateTxt = '';
let _MfgDateChk = 0;
let _MfgDateTxt = '';
let _ModelNoChk = 0;
let _ModelNoTxt = '';
let _SizeChk = 0;
let _SizeTxt = '';


let _loader = 'glb-ldr-prt active';

export default class quickAddTransBatchNo extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                        _BatchNoAdditionalChk1 = _businessSetting.BatchNoAdditionalChk1;
                        _BatchNoAdditionalChk2 = _businessSetting.BatchNoAdditionalChk2;
                        _BatchNoAdditionalLabel1 = _businessSetting.BatchNoAdditionalLabel1;
                        _BatchNoAdditionalLabel2 = _businessSetting.BatchNoAdditionalLabel2;
                        _BatchNoChk = _businessSetting.BatchNoChk;
                        _BatchNoTxt = _businessSetting.BatchNoTxt;
                        _MRPChK = _businessSetting.MRPChK;
                        _MRPTxt = _businessSetting.MRPTxt;
                        _ExpDateChk = _businessSetting.ExpDateChk;
                        _ExpDateTxt = _businessSetting.ExpDateTxt;
                        _MfgDateChk = _businessSetting.MfgDateChk;
                        _MfgDateTxt = _businessSetting.MfgDateTxt;
                        _ModelNoChk = _businessSetting.ModelNoChk;
                        _ModelNoTxt = _businessSetting.ModelNoTxt;
                        _SizeChk = _businessSetting.SizeChk;
                        _SizeTxt = _businessSetting.SizeTxt;
                    }
                }
            } 
        }
        this.state = {
            isRedirectTo: (this.props.isRedirectTo) ? this.props.isRedirectTo : 'business/manage-unit/',
            itemSelectData : this.props.itemSelectData ? this.props.itemSelectData : '0',
            itemId : this.props.itemId ? this.props.itemId : '0',
            _dataType: (this.props.dataType) ? this.props.dataType : 'ITEM',
            batchSendType  : this.props.batchSendType ? this.props.batchSendType : 'New',
            buttonTitle:'Add New',
            isLoaded : true, 
            unitArray : [],
            secUnitArray : [],
            checkedItems: new Map(),
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            batch_no :  '',
            BatchNoAdditionalValue1 : '',
            BatchNoAdditionalValue2 : '',
            BatchNoAdditionalChk1 : _BatchNoAdditionalChk1,
            BatchNoAdditionalChk2 : _BatchNoAdditionalChk2,
            BatchNoAdditionalLabel1 : _BatchNoAdditionalLabel1,
            BatchNoAdditionalLabel2 : _BatchNoAdditionalLabel2,
            FreeItemQuantity : _businessSetting.FreeItemQuantity,
            BatchNoChk : _BatchNoChk,
            BatchNoTxt : _BatchNoTxt,
            MRPChK : _MRPChK,
            MRPTxt : _MRPTxt,
            ExpDateChk : _ExpDateChk,
            ExpDateTxt : _ExpDateTxt,
            MfgDateChk : _MfgDateChk,
            MfgDateTxt : _MfgDateTxt,
            ModelNoChk : _ModelNoChk,
            ModelNoTxt : _ModelNoTxt,
            SizeChk : _SizeChk,
            SizeTxt : _SizeTxt,
            used_stock : this.props.used_stock ? this.props.used_stock : 0,
            free_quantity : this.props.free_quantity ? this.props.free_quantity : 0,
            totalQuantity : this.props.totalQuantity ? this.props.totalQuantity : 0,
            batchNo : this.props.batchNo ? this.props.batchNo : 0,
            OldChnageQuantity : this.props.OldChnageQuantity ? this.props.OldChnageQuantity : 0,
            OldUsedStockQty : this.props.OldUsedStockQty ? this.props.OldUsedStockQty : 0,
            totalAmountOld : this.props.totalAmountOld ? this.props.totalAmountOld : 0,
            itemPrice : this.props.itemPrice ? this.props.itemPrice : 0,
            itemBatchNoAll : this.props.itemBatchNoAll ? this.props.itemBatchNoAll : [],   
            itemFields :  this.props.itemFields ? this.props.itemFields : [],
            indexPosition: this.props.indexPosition ? this.props.indexPosition : 0,
            openingNewStock : 0,
            noCheck : '',
            isSelect : false,
            isError : true,
            transactionId : this.props.transactionId ? this.props.transactionId : 0,
            duplicateSale : this.props.duplicateSale ? this.props.duplicateSale : '',
            errors: {
                batch_no  :  '',
                noCheck : '',
            }
        }
    }
    async componentDidMount ()
    {   

        let _itemBatchNoAllAK = this.props.itemBatchNoAll;

        console.log('_itemBatchNoAllAK', JSON.stringify(_itemBatchNoAllAK));

        if(this.state.duplicateSale.length > 2){
            let mainBatchData = _itemBatchNoAllAK.map((batchData) => ({
                ...batchData,
                batch_id : batchData.batch_id ? batchData.batch_id : 0,
                //actualOpeningStock : batchData.actualOpeningStock ? batchData.actualOpeningStock : 0,
                actualOpeningStock : parseFloat(batchData.opening_stock) - (parseFloat(batchData.used_stock ? batchData.used_stock : 0)  + parseFloat(batchData.free_quantity ? batchData.free_quantity : 0))
            }))
            this.setState({itemBatchNoAll:mainBatchData});
        }else{            
            if(this.state.transactionId == 0){
                let mainBatchData = _itemBatchNoAllAK.map((batchData) => ({
                    ...batchData,
                    batch_id : batchData.batch_id ? batchData.batch_id : 0,
                    //actualOpeningStock : batchData.actualOpeningStock ? batchData.actualOpeningStock : 0,
                    actualOpeningStock : parseFloat(batchData.opening_stock) - (parseFloat(batchData.used_stock ? batchData.used_stock : 0)  + parseFloat(batchData.free_quantity ? batchData.free_quantity : 0))
                }))

                this.setState({itemBatchNoAll:mainBatchData});
            }
            if(this.state.transactionId > 0){
                let mainBatchData = _itemBatchNoAllAK.map((batchData) => ({
                    ...batchData,
                    batch_id : batchData.batch_id ? batchData.batch_id : 0,
                    
                    //opening_stock : parseFloat(batchData.opening_stockWeb) + (parseFloat(batchData.used_stock ? batchData.used_stock : 0)  + parseFloat(batchData.free_quantity ? batchData.free_quantity : 0)),

                    //actualOpeningStock : batchData.actualOpeningStock ? batchData.actualOpeningStock :0,// commnet for issue 2515 
                    //actualOpeningStock : parseFloat(batchData.opening_stock) - (parseFloat(batchData.used_stock ? batchData.used_stock : 0)  + parseFloat(batchData.free_quantity ? batchData.free_quantity : 0))// ISSUE 3451
                    actualOpeningStock : parseFloat(batchData.opening_stockWeb) - parseFloat(batchData.used_stock)
                }))
                console.log('mainBatchData', JSON.stringify(mainBatchData))
                this.setState({itemBatchNoAll:mainBatchData});

            }
        }
    }
    
    handleChange(i, e, _type,_batchId) {
        let errors = this.state.errors;
        let _isError = false;
        let itemBatchNoAll = this.state.itemBatchNoAll;
        let _openingStock = this.state.openingNewStock ? this.state.openingNewStock : 0; 
        if(_type == 'mfg_date'){
            itemBatchNoAll[i][_type] = moment(e).format('MMMM D, YYYY');//e;
        }
        else if(_type == 'exp_date' ){
            itemBatchNoAll[i][_type] = moment(e).format('MMMM D, YYYY');//e;
        }
        else{
            /*onChangeText={text => {
                text = text ? parseInt(text) : 0;

                let aa = batchNoData.map((data, subindex) => ({
                    ...data,
                    used_stock: index == subindex ? text : data.used_stock,
                    //remainingStock: index == subindex ? (text(item.remainingStock - text)) : item.remainingStock,
                    remainingStock: index == subindex ? ((data.total_opening_stock - (text ? parseInt(text) : 0)) - item.free_quantity) : data.opening_stock,
                }))
                setBatchNoData(aa);
              }}*/

        /*let data = route.params.batchNoData;
        setItemTypeSelected(data.filter((item) => item.isSelected).length > 0);

        data = data.map((item) => ({
            ...item,
            remainingStock: item.remainingStock > 0 ? item.remainingStock : item.opening_stock,
            total_opening_stock: item.total_opening_stock ? item.total_opening_stock : (item.opening_stock + item.used_stock + item.free_quantity),
        }));
        setBatchNoData(data);*/
            let finalStock = 0 ;
            itemBatchNoAll[i][e.target.name] = e.target.value;
            //if(this.state.batchSendType == 'Old' && this.state.transactionId > 0){
                itemBatchNoAll.map((batchData) =>{
                    finalStock = parseFloat(batchData.used_stock) + parseFloat(batchData.free_quantity);
                    if(this.state.duplicateSale.length > 2){
                        batchData.actualOpeningStock = (parseFloat(batchData.opening_stock)
                        - (parseFloat(batchData.used_stock ? batchData.used_stock : 0) + parseFloat( batchData.free_quantity ? batchData.free_quantity : 0)) ) || 0;
                        if(batchData.actualOpeningStock < 0){
                            batchData.actualOpeningStock = 0;
                        }
                        errors['noCheck'] = "";       
                        if( (parseFloat(finalStock) > parseFloat(batchData.opening_stock)) && (batchData.batch_id > 0) ){
                            _isError = true;
                            this.state.isError = true;
                            errors['noCheck'] = "Quantity cannot be more than in-stock quantity";         
                            this.setState({errors: errors});
                            return false;
                        }
                    }
                    else{
                        if(this.state.transactionId == 0){
                            batchData.actualOpeningStock = (parseFloat(batchData.opening_stock)
                            - (parseFloat(batchData.used_stock ? batchData.used_stock : 0) + parseFloat( batchData.free_quantity ? batchData.free_quantity : 0)) ) || 0;
                            if(batchData.actualOpeningStock < 0){
                                batchData.actualOpeningStock = 0;
                            }
                        }
                        if(this.state.transactionId > 0){
    
                            batchData.actualOpeningStock = parseFloat(batchData.opening_stockWeb) - (parseFloat(batchData.used_stock ? batchData.used_stock : 0) + parseFloat( batchData.free_quantity ? batchData.free_quantity : 0));
                            
                            if(batchData.actualOpeningStock < 0){
                                batchData.actualOpeningStock = 0;
                            }
                        }
                        if(this.state.transactionId > 0){
                            errors['noCheck'] = "";         
                            this.setState({errors: errors});
                            if( (parseFloat(finalStock) > parseFloat(batchData.opening_stockWeb)) && (batchData.batch_id > 0) ){
                                _isError = true;
                                this.state.isError = true;
                                //errors['noCheck'] = "Quantity cannot be more than in-stock quantity";         
                                this.setState({errors: errors});
                                return false;
                            }
                        }
                        else if(this.state.transactionId == 0){
                            errors['noCheck'] = "";       
                            if( (parseFloat(finalStock) > parseFloat(batchData.opening_stock)) && (batchData.batch_id > 0) ){
                                //batchData.opening_stock = finalStock;
                                _isError = true;
                                this.state.isError = true;
                                errors['noCheck'] = "Quantity cannot be more than in-stock quantity";         
                                this.setState({errors: errors});
                                return false;
                            }
                        }
                       
                        else{
                            errors['noCheck'] = ""; 
                            if(this.state.batchSendType == 'Old' && this.state.transactionId > 0){                           
                                //batchData.opening_stock = finalStock;                            
                                //batchData.opening_stock = parseFloat(batchData.actualOpeningStock)  - parseFloat(finalStock);
                            }else{                            
                                //batchData.opening_stock = parseFloat(batchData.actualOpeningStock)  - parseFloat(finalStock);
                            }
                            this.setState({ itemBatchNoAll });
                        }
                    }                    
                })
            //}
        }               
        this.setState({errors: errors});
    }
    
     
    
    clickToSelectData = (batchIds) => {   
        // event.preventDefault();  
        let _itemSerialData = (this.state.itemBatchNoAll);
        //const character = this.state.itemBatchNoAll.find(character => character.batch_id == batchIds); 
        //let  character = this.state.itemBatchNoAll;
        let character = this.state.itemBatchNoAll.filter(character => character.batch_id == batchIds)
        
       let _used_stock = 0;
       let freeQuantity = 0;
       let _totalAmount = 0;
       let _mrpPrice = 0;
       let _totalOldQuantity = this.state.totalQuantity;
       let OldChnageQuantity = this.state.OldChnageQuantity;
       let totalStock  = 0;
       let totalOldStockOld = 0;
       let _totalQuantity = this.state.totalQuantity;
       let _totalAmountOld = this.state.totalAmountOld;
       
       let errors = this.state.errors;
       let _isError = false;
       let finaleUpdatedQty = 0;
       let _currSelectOpenStock =  0;
       
       

        character.map((batchData) =>{
            let free_quantity = batchData.free_quantity;
            if(batchData.free_quantity == ''){
                free_quantity = 0;
            }

            _used_stock = batchData.used_stock ? parseInt(batchData.used_stock) : 0;
            freeQuantity = batchData.free_quantity ? parseInt(batchData.free_quantity) : 0;
            _totalQuantity = _used_stock + freeQuantity;
            if(this.state.batchSendType == 'Old' && this.state.transactionId > 0){
                totalOldStockOld = parseFloat(batchData.used_stock) + parseFloat(free_quantity);
            }
            if(batchData.opening_stock > 0){
                totalStock  = parseFloat(batchData.used_stock) + parseFloat(free_quantity);
            }
            _currSelectOpenStock = batchData.opening_stock; 
            
            if((parseInt(batchData.used_stock) <= 0 || batchData.used_stock == '') && this.state.transactionId == 0){
                _isError = true;
                this.state.isError = true;
                errors['noCheck'] = "Entered quantity should be greater than zero ";            
                this.setState({errors: errors});
                return false;
            }
            if((parseInt(batchData.used_stock) <= 0 || batchData.used_stock == '')){
                _isError = true;
                this.state.isError = true;
                errors['noCheck'] = "Entered quantity should be greater than zero ";
                this.setState({errors: errors});
                return false;
            }
            /*else if(totalStock > batchData.opening_stock) {//batchData.opening_stock
                
                _isError = true;
                this.state.isError = true;
                errors['noCheck'] = "Quantity cannot be more than in-stock quantity";            
                this.setState({errors: errors});
                return false;
            }*/
            else if(this.state.transactionId > 0){
                errors['noCheck'] = "";         
                this.setState({errors: errors});
                if( (parseFloat(totalStock) > parseFloat(batchData.opening_stockWeb)) && (batchData.batch_id > 0) ){
                    _isError = true;
                    this.state.isError = true;
                    errors['noCheck'] = "Quantity cannot be more than in-stock quantity";            
                    this.setState({errors: errors});
                    return false;
            }
            }else if(this.state.transactionId == 0){
                if( (parseFloat(totalStock) > parseFloat(batchData.opening_stock)) && (batchData.batch_id > 0) ){
                    _isError = true;
                    this.state.isError = true;
                    errors['noCheck'] = "Quantity cannot be more than in-stock quantity";            
                    this.setState({errors: errors});
                    return false;
                }
            }
            else{                      
                if(batchData.used_stock >=0) {
                    character = batchData;
                    if( (batchIds  == batchData.batch_id) && (batchData.used_stock == '' && batchData.free_quantity == ''))
                    {
                        _isError = true;
                        this.state.isError = true;
                        errors['noCheck'] = "Please enter quantity before select";            
                        this.setState({errors: errors});
                        return false;
                    }
                    
                    else if(  (batchIds  == batchData.batch_id) && (batchData.used_stock == 0 || batchData.used_stock == '') ){              
                        _isError = true;                    
                        this.state.isError = true;
                        errors['noCheck'] = "Entered quantity should not be greater than or equal 1";            
                        this.setState({errors: errors});
                        return false;
                    }
                    else{
                        _isError = false;
                        this.state.isError = false;
                        errors['noCheck'] = "";            
                        this.setState({errors: errors});
                        _used_stock = batchData.used_stock ? parseInt(batchData.used_stock) : 0;
                        freeQuantity = batchData.free_quantity ? parseInt(batchData.free_quantity) : 0;
                        _totalAmount = batchData.used_stock * this.state.itemPrice;
                        _mrpPrice = this.state.itemPrice * _used_stock ;                        
                    }                    
                }
                else if(  (batchIds  == batchData.batch_id) && (batchData.used_stock == 0 || batchData.used_stock == '') ){                               
                    _isError = true;
                    this.state.isError = true;
                    errors['noCheck'] = "Entered quantity should not be greater than or equal ";            
                    this.setState({errors: errors});
                    return false;
                }
            }
        })

        if(this.state.isError ==  false && this.state.batchSendType!='Old'){
            if(_totalQuantity >= 1){ 

                if(_totalQuantity == (_used_stock + freeQuantity)){
                    _totalQuantity = parseFloat(_used_stock + freeQuantity) + parseFloat(_totalQuantity);
                    _totalAmountOld =  parseFloat(_totalAmount) + parseFloat(_totalAmountOld);
                    _totalAmount = _totalAmount;
                }else{
                    if(_totalQuantity > (parseFloat(_used_stock) + parseFloat(freeQuantity))){
                        
                        _totalQuantity = (((parseFloat(_used_stock) + parseFloat(freeQuantity)) + _totalQuantity));
                    }
                    if(_totalQuantity < ((parseFloat(_used_stock) + parseFloat(freeQuantity)))){
                        
                        _totalQuantity = ( (parseFloat(_used_stock) + parseFloat(freeQuantity) )  + parseFloat(_totalQuantity));
                    }
                    _totalAmount = _totalAmount;
                    _totalAmountOld = parseFloat(_totalAmountOld) + parseFloat(_totalAmount);
                }
                
            }
            if(_totalQuantity  == 0){  

                _totalAmountOld =  parseFloat(_totalAmount);
                _totalQuantity = (_used_stock + freeQuantity);
                _totalAmount = _totalAmount;
            }            
        } 
            

        if(_used_stock == 0){
            _used_stock =  this.state.used_stock;
        }
        if(freeQuantity == 0){
            freeQuantity =  this.state.free_quantity;
        }
        if(_used_stock == 0 || _used_stock == ''){
            _totalQuantity = 1;
        }

        
        if(this.state.batchSendType == 'Old'){

            let OldMinusQty = parseFloat(_totalOldQuantity) - parseFloat(OldChnageQuantity);
            if(this.state.batchSendType == 'Old' && this.state.transactionId > 0){
                
                finaleUpdatedQty = parseFloat(OldMinusQty) + parseFloat(totalOldStockOld);
            }
            if(this.state.batchSendType == 'Old' && this.state.transactionId == 0){
                
                finaleUpdatedQty = parseFloat(OldMinusQty) + parseFloat(totalStock);
                if(OldMinusQty ==  0 && totalStock ===0){
                    
                    finaleUpdatedQty = _totalOldQuantity;
                }
            }
           
            _totalQuantity =  finaleUpdatedQty;

            
            let oldQtyAmt =   (parseFloat(this.state.itemPrice) * parseFloat(this.state.OldUsedStockQty)).toFixed(2);
           

            
            _totalAmount =  (parseFloat(this.state.itemPrice) * parseFloat(_used_stock)).toFixed(2);

            _totalAmountOld = parseFloat(_totalAmountOld) - parseFloat(oldQtyAmt);

            
            _totalAmountOld =(parseFloat(_totalAmountOld) + parseFloat(_totalAmount));

        }
        var newCharacter = [character];
        if(this.state.transactionId == 0){
            _totalAmountOld = _totalAmount;
        }
            
        let _fieldData =  this.state.itemFields;
        let discountAmt = 0;   
        let totalAmount = 0;
        let tax = 0;
        let amountBeforeTaxWithoutDisc = 0;
        let disc = 0;
        let amountBeforeTax = 0 ;
        let subTotal = 0;    
        let _discountAmtSend = 0;
        let _taxAmtSend = 0;
        let _itemToalAmtSend = 0;
        let _costPrice = 0;

        if(_isError ==  false){            
            _fieldData.map((itemData, i) => {
                if (i == this.state.indexPosition) {          
                    let qty = _used_stock ? _used_stock : 0;
                    let rateValue = parseFloat(itemData.rate);
                    let taxType = parseFloat(itemData.tax_type.ratio) ? parseFloat(itemData.tax_type.ratio) : 0.0;
                    let additionalCess = parseFloat(itemData.Additional_CESS) ? parseFloat(itemData.Additional_CESS) : 0;
                    let discount_in_per =  parseFloat(itemData.discount_in_per) ?  parseFloat(itemData.discount_in_per) : 0;
                    let discount_in_amount =  parseFloat(itemData.discount_in_amount) ?  parseFloat(itemData.discount_in_amount) : 0;
               
                    subTotal = rateValue * qty;  
                    if (itemData.sale_pruchase_tax_type == 'With Tax' && taxType > 0)
                    {   
                        if(discount_in_per > 0){           
                            _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);       
                            discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                            amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                            disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                            tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
                            totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);
            
                            //Discount amont == disc;
                            disc = disc.toFixed(2);
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = disc;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                        else{
                            if(taxType > 0){
                                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                            }else{
                                _costPrice = subTotal;
                            }     
                            discountAmt = discount_in_amount;// * qty;;
                            amountBeforeTax = _costPrice - discountAmt;
                            disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                            tax = amountBeforeTax * taxType / 100;
                            totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                    }
        
                    else if (itemData.sale_pruchase_tax_type == 'With Tax' && (taxType == 0 || taxType == '0')) 
                    {              
                        if(discount_in_per > 0){                
                            _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);  
                            discountAmt = (discount_in_per / 100) * subTotal;
                            amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                            disc = amountBeforeTaxWithoutDisc * discount_in_per / 100;
                            tax = (amountBeforeTaxWithoutDisc - disc) * taxType / 100;
                            totalAmount = (amountBeforeTaxWithoutDisc - disc) + tax + additionalCess;
            
                            //Discount amont == disc;
                            disc = disc.toFixed(2);
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = disc;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                        else{    
                            if(taxType > 0){
                                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                            }else{
                                _costPrice = subTotal;
                            }          
                            discountAmt = discount_in_amount;// * qty;;
                            amountBeforeTax = _costPrice - discountAmt;
                            disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                            tax = amountBeforeTax * taxType / 100;
                            totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                    }
                    
                    else {
                        if(discount_in_amount > 0){
                            _costPrice = subTotal;             
                            discountAmt = discount_in_amount;// * qty;;
                            amountBeforeTax = subTotal - discountAmt;
                            tax = amountBeforeTax * taxType / 100;
                            //totalAmount = amountBeforeTax + tax + (qty * additionalCess);
                            totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                        else{
                            _costPrice = subTotal;      
                            discountAmt = (discount_in_per / 100) * subTotal;
                            amountBeforeTaxWithoutDisc = subTotal - discountAmt;
                            tax = (amountBeforeTaxWithoutDisc) * taxType / 100;
                            totalAmount = parseFloat(amountBeforeTaxWithoutDisc) + parseFloat(tax) + parseFloat(additionalCess);            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);

                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                                
                    }
                }
            })
            if(this.state.itemFields.length > 1){
                _totalQuantity = parseFloat(_totalQuantity) + parseFloat(this.state.totalQuantity);
            }
            if(this.state.OldChnageQuantity > 0 && this.state.batchSendType == 'Old'){
                _totalQuantity = parseFloat(_totalQuantity) //- parseFloat(this.state.totalQuantity); // issue for 2371

            }

            this.props.callBack (newCharacter, _used_stock,freeQuantity,_totalAmount,_totalQuantity,_totalAmountOld,_mrpPrice,batchIds,_discountAmtSend,_taxAmtSend,_itemToalAmtSend,this.state.indexPosition,_costPrice);
        }
    }
    
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
              
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        let _itemBatchNoAll = this.state.itemBatchNoAll;


        return(
            <React.Fragment>
                <form method="post" id="form-login" className="webForm px-30">              
                    {_itemBatchNoAll.map((element, index) => (
                    <div>
                        {/* && element.used_stock == 0 */}
                        {console.log('element', JSON.stringify(element))}
                        {/*{ (element.actualOpeningStock >= 0) &&

                        }*/}
                        <div className="form-group row" style={{border :'2px solid !important',borderColor :'#cacaca !important' }}>
                            <div className="claerfix"></div>
                            {this.state.BatchNoChk == 1 &&
                            <div className="col-2">
                                <div className="form-material open">
                                    <input type="hidden" className="form-control" id="batch_no" name="batch_no" value={element.batch_no || ""} placeholder={this.state.BatchNoTxt} onChange={e => this.handleChange(index, e)} readOnly  autoComplete="off"/>
                                    <p style={{'wordBreak' : 'break-all' }}>{element.batch_no || ""} </p>
                                    <label htmlFor="login-username">{this.state.BatchNoTxt}</label>
                                </div>
                            </div>
                            }


                            {this.state.ExpDateChk == 1 &&
                            <div className="col-2">
                                <div className="form-material open">
                                    <DateRangePicker
                                        initialSettings={{
                                            singleDatePicker: true,
                                            startDate1: moment(element.exp_date ).format('MM/DD/YYYY') || this.state.exp_date//this.state.exp_date
                                        }}
                                        onCallback={(date) => this.handleCallBackExpDate(date,index)}
                                    >
                                        <input type="hidden" className="form-control" value={moment(element.exp_date ).format('MM/DD/YYYY') || this.state.exp_date} name="exp_date" id='exp_date' readOnly  autoComplete="off"/>
                                    </DateRangePicker >
                                    <p>{moment(element.exp_date ).format('MM/DD/YYYY') || this.state.exp_date}</p>
                                    <label htmlFor="login-username">{this.state.ExpDateTxt}</label>
                                </div>
                            </div>
                            }

                            {this.state.MfgDateChk == 1 &&
                            <div className="col-2">
                                <div className="form-material open">
                                    <DateRangePicker
                                        initialSettings={{
                                            singleDatePicker: true,
                                            startDate: moment(element.mfg_date).format('MM/DD/YYYY') || this.state.mfg_date//this.state.mfg_date
                                        }}
                                        onCallback={(date) => this.handleCallBackDate(date,index)}
                                    >
                                        <input type="hidden" className="form-control" value={moment(element.mfg_date).format('MM/DD/YYYY') || this.state.mfg_date} name="mfg_date" id='mfg_date' readOnly  autoComplete="off"/>
                                    </DateRangePicker>
                                    <p>{moment(element.mfg_date).format('MM/DD/YYYY') || this.state.mfg_date}</p>
                                    <label htmlFor="login-username">{this.state.MfgDateTxt}</label>
                                </div>
                            </div>
                            }

                            {this.state.ModelNoChk == 1 &&
                            <div className="col-2">
                                <div className="form-material open">
                                    <input type="hidden" className="form-control" id="model_no" name="model_no" value={element.model_no || ""} placeholder={this.state.ModelNoTxt} onChange={e => this.handleChange(index, e)} maxLength="30" readOnly  autoComplete="off"/>
                                    <p style={{'wordBreak' : 'break-all' }}>{element.model_no || ""} </p>
                                    <label htmlFor="login-username">{this.state.ModelNoTxt}</label>
                                </div>
                            </div>
                            }

                            {this.state.SizeChk == 1 &&
                            <div className="col-2">
                                <div className="form-material open">
                                    <input type="hidden" className="form-control" id="size" name="size" value={element.size || ""} placeholder={this.state.SizeTxt} onChange={e => this.handleChange(index, e)} readOnly  autoComplete="off"/>
                                    <p style={{'wordBreak' : 'break-all' }}>{element.size || ""} </p>
                                    <label htmlFor="login-username">{this.state.SizeTxt}</label>
                                </div>
                            </div>
                            }

                            {this.state.BatchNoAdditionalChk1 == 1 &&
                            <div className="col-2">
                                <div className="form-material open">
                                    <input type="hidden" className="form-control" id="batchNoAdditionalValue1" name="batchNoAdditionalValue1" value={element.batchNoAdditionalValue1 || ""} placeholder="Enter Value 1" onChange={e => this.handleChange(index, e)} readOnly  autoComplete="off"/>
                                    <p style={{'wordBreak' : 'break-all' }}>{element.batchNoAdditionalValue1 || ""} </p>
                                    <label htmlFor="login-username">{this.state.BatchNoAdditionalLabel1}</label>

                                </div>
                            </div>
                            }
                            {this.state.BatchNoAdditionalChk2 == 1 &&
                            <div className="col-2">
                                <div className="form-material open">
                                    <input type="hidden" className="form-control" id="batchNoAdditionalValue2" name="batchNoAdditionalValue2" value={element.batchNoAdditionalValue2 || ""} placeholder="Enter Value 2" onChange={e => this.handleChange(index, e)} readOnly  autoComplete="off"/>
                                    <p style={{'wordBreak' : 'break-all' }}>{element.batchNoAdditionalValue2 || ""} </p>
                                    <label htmlFor="login-username">{this.state.BatchNoAdditionalLabel2}</label>
                                </div>
                            </div>
                            }

                            <div className="col-2">
                                <div className="form-material open">
                                    {element.batch_id > 0 &&
                                    <input type="hidden" className="form-control" id="opening_stock" name="opening_stock" value={element.actualOpeningStock ? element.actualOpeningStock : element.opening_stock } placeholder="In Stock" onChange={e => this.handleChange(index, e)} readOnly  autoComplete="off"/>
                                    }
                                    {element.batch_id > 0 &&
                                    <p style={{'wordBreak' : 'break-all' }}>
                                        {
                                         element.actualOpeningStock ?
                                            (element.actualOpeningStock <= 0 ? <strong>0</strong> : <strong> {element.actualOpeningStock } </strong> ) :
                                            <strong> {element.opening_stock } </strong>   
                                        }
                                    </p>
                                    }
                                    {element.batch_id == 0 &&
                                    <input type="hidden" className="form-control" id="opening_stock" name="opening_stock" value={((parseFloat(element.used_stock ? element.used_stock : 0) + parseFloat(element.free_quantity ? element.free_quantity :0)) ) || ""} placeholder="In Stock" onChange={e => this.handleChange(index, e)} readOnly  autoComplete="off"/>
                                    }
                                    {element.batch_id == 0 &&
                                    <p style={{'wordBreak' : 'break-all' }}>
                                        <strong>{((parseFloat(element.used_stock ? element.used_stock : 0) + parseFloat( element.free_quantity ? element.free_quantity : 0)) ) || 0}
                                        </strong>
                                    </p>
                                    }
                                    <label htmlFor="login-username">In Stock</label>
                                </div>
                            </div>
                            {this.state.FreeItemQuantity == 1 &&
                            <div className="col-2">
                                <div className="form-material open">
                                    <input type="number" className="form-control" id="free_quantity" name="free_quantity" value={element.free_quantity || ""} placeholder="Free Quantity" onChange={e => this.handleChange(index, e,'free_quantity',element.batch_id)}   autoComplete="off"/>
                                    <label htmlFor="login-username">Free Quantity</label>
                                </div>
                            </div>
                            }
                            <div className="col-2">
                                <div className="form-material open">
                                    <input type="number" className="form-control" id="used_stock" name="used_stock" value={element.used_stock || ""} placeholder="Quantity" onChange={e => this.handleChange(index, e,'used_stock',element.batch_id)}   autoComplete="off"/>
                                    <label htmlFor="login-username">Quantity
                                        <span className="text-danger"> * </span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-1">
                                <div className="form-material open">
                                    <div className="wrapperRadio">
                                        {/* <input type="radio" name="select" id={"batch_id_"+element.batch_id}  onClick={this.clickToSelectData.bind(this,element.batch_id)}/> */}

                                        {/* <label for="option-1" className="option option-1">
                                                <div className="dot"></div>
                                                <span>Select</span>
                                            </label>*/}

                                        {/* <a  name="select" id={"batch_id_"+element.batch_id}  onClick={this.clickToSelectData.bind(this,element.batch_id)} className="btn btn-info" disabled={isDisabled} >Select</a> */}

                                        <button type="submit" id={"batch_id_"+element.batch_id}  onClick={this.clickToSelectData.bind(this,element.batch_id)} disabled={isDisabled} className="btn btn-alt-info mb-10 mx-5">Select</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>  
                    ))}
                    
                    <span className="iris_error_txt">{errors.noCheck.length > 0 && <span className='error'>{errors.noCheck}</span>}</span>
                </form>
            </React.Fragment>
        )    
    }
}