import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import QuickAdditionalCharge from './quickAdditionalCharge';
import QuickTransportationDetails from './quickTranspotationDetails';
import QuickDueDatesPaymentsTerms from './quickDueDatesPaymentsTerms';
import QuickAdditionalFields from './quickAdditionalTransactionsFields';


let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class TransactionSettingPanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        let _isTimeTransactionActive =  true;
        if(_businessSetting.Tran_AddTime == 0){
            _isTimeTransactionActive =  false;
        }
        this.state = {
            isLoaded : false,
            modalIsOpen_Info : false,
            modalIsOpen_AddNewPrefix : false,
            _infoData : '',
            _infoData2 : '',
            _infoTitle2 : '', 
            _infoTitlePre :'',   
            _infoPrefixId :'0',
            prefixArray : [],
            prefixName : '',
            modalIsOpen_Info2 : false,
            modalIsOpen_AdditionalCharge : false,
            modalIsOpen_DueDatePaymentTermsDetails : false,
            modalIsOpen_TransportationDetails : false,
            modalIsOpen_AdditionalFields : false,
            isTimeTransactionActive : _isTimeTransactionActive,
            _infoTitle : 'Information',
            company_id : _companyId,
            businessId : _businessId,
            errors: {
                prefixName : '',
            }
        }
    }

    async componentDidMount ()
    {
        let _prefixArray = [];          
        const requestData = JSON.stringify({
            company_id: _companyId,
            businessId:_businessId,
        });      
        PostData(global.businessTypePrefixList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _prefixArray = responseJson.response;
                    //_prefixArray= _prefixArray.filter((itemData) => itemData.prefix!=null);
                }           
            }   
            this.setState({prefixArray:_prefixArray,isLoaded:true});           
        })
        .catch(error => this.setState({ error, isLoaded: false }));
        this.getPrefixList();
    }

    getPrefixList(){
        let _prefixArray = [];          
        const requestData = JSON.stringify({
            company_id: _companyId,
            businessId:_businessId,
        });      
        PostData(global.businessTypePrefixList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _prefixArray = responseJson.response;
                    //_prefixArray= _prefixArray.filter((itemData) => itemData.prefix!=null); 
                }        
            }             
            this.setState({prefixArray:_prefixArray,isLoaded:true});   
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }
    
    /* Checkbox Chnage */
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        if(_key == 'TransactionWiseTax'){
            if(_businessSetting.ItemWiseTax == 1 && _value == 1){
                this.openModalInfoHandler2('openModalInfoHandler2','According to goverment there should not be tax on tax. You have enabled item wise tax, so you should not enable transaction level tax.','Warning'); 
            }
        }  
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');                
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");
                if(_key == 'Tran_AddTime'){
                    this.setState({isTimeTransactionActive : (_value > 0) ? true : false});
                }
                
                if(_key == 'Tran_AddTime'){
                    if(_value == 1){
                        _businessSettingData['Tran_AddTimePrint'] = 1;
                        this.setState({isTimeTransactionActive :true});
                    }
                    if(_value == 0){
                        _businessSettingData['Tran_AddTimePrint'] = 0;
                        this.setState({isTimeTransactionActive :false});
                    }
                } 
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }

    /* End Checkbox Chnage */
    
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',infoTitle:''});
        }
        else if(e == 'openModalAdditionalCharge'){
            this.setState({modalIsOpen_AdditionalCharge: false});            
        } 
        else if(e == 'openModalTransportationDetails'){
            this.setState({modalIsOpen_TransportationDetails: false});            
        }   
        else if(e == 'openModalDueDatePaymentTermsDetails'){
            this.setState({modalIsOpen_DueDatePaymentTermsDetails: false});            
        }   
        else if(e == 'openModalAdditionalFields'){
            this.setState({modalIsOpen_AdditionalFields: false});            
        } 
    } 
    
    
    openModalInfoHandler2(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler2'){
            this.setState({modalIsOpen_Info2: true,_infoData2:infoData,_infoTitle2:infoTitle})            
        }
    } 
    closeModalWarning = (e) => {
        if(e == 'openModalInfoHandler2'){
            this.setState({modalIsOpen_Info2: false, _infoData2:'', _infoTitle2:''});
        }
    } 
    
    openModalOtherTransac(e)
    {       
        if(e == 'openModalAdditionalCharge'){
            this.setState({modalIsOpen_AdditionalCharge: true});            
        }   
        else if(e == 'openModalTransportationDetails'){
            this.setState({modalIsOpen_TransportationDetails: true});            
        }   
        else if(e == 'openModalDueDatePaymentTermsDetails'){
            this.setState({modalIsOpen_DueDatePaymentTermsDetails: true});            
        }   
        else if(e == 'openModalAdditionalFields'){
            this.setState({modalIsOpen_AdditionalFields: true});            
        }  
        
          
    }
    
    updateWarningData = (e) => {
        let _keyyyy = 'TransactionWiseTax';
        let _valueee = 0;
        
        const requestDataaa = JSON.stringify({
            settingValue: _valueee,
            settingKey: _keyyyy,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        PostData(global.businessSettingUpdate, requestDataaa,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');            
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyyyy] = _valueee;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));    
                this.setState({modalIsOpen_Info2: false, _infoData2:'', _infoTitle2:''});   
                document.getElementById('TransactionWiseTax').checked = false;        
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    handleDataPrefixChange = (e) => {
        let activeValue = e.target.value;
        let _keyId = e.target.attributes['data-slug'].value;
        let _keyName = e.target.attributes['data-slugName'].value;
        //console.log(activeValue+"===>>=="+_keyId);
        if(activeValue == 'AddNewPrefix'){
            this.openModalHandlerPrefix('modalAdjustNewPrefix',_keyName,_keyId);
        }
        else{
            let activeValue = e.target.value;
            let _key = e.target.attributes['data-slug'].value;
            
            
            const requestData = JSON.stringify({
                settingValue: activeValue,
                settingKey: _key,
                businessId : _businessId,
                company_id:_companyId,
                passcode : 0
            });
            //this.setState({selectedOption:activeValue});

            PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
                if(result.success == true)
                {
                    let _UserSession = localStorage.getItem('vs_UserSession');            
                    _UserSession = JSON.parse(_UserSession);
                    let _updateLocal = _UserSession;
                    let _businessSettingData = _updateLocal.businessSetting;
                    _businessSettingData[_key] = activeValue;
                    
                    localStorage.removeItem('vs_UserSession');
                    localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                    //sendNotification("Success Message 👍",result.message,"success");                
                }
                else
                {
                    sendNotification("Error Message 😓",result.message,"danger");
                    return;
                }
            });
        }
    }
    
    
    openModalHandlerPrefix(e,infoTitlePre,infoPrefixId)
    {
        if(e == 'modalAdjustNewPrefix'){
            
            let _isError = false;
            let errors = this.state.errors;
            errors['prefixName'] = "";
            this.setState({modalIsOpen_AddNewPrefix: true,_infoPrefixId:infoPrefixId,_infoTitlePre:infoTitlePre,errors: errors})            
        }
    } 
    closeModalWarningPrefix = (e) => {
        if(e == 'modalAdjustNewPrefix'){
            this.setState({modalIsOpen_AddNewPrefix: false, _infoPrefixId:'', _infoTitlePre:'',prefixArray:[],prefixName:''});            
            this.getPrefixList();
        }
    }
    

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {

            case 'prefixName':
                errors.prefixName = value.length < 0 ? '*Please Enter Prefix' : '';
                this.setState({prefixName:value}); 
            break;
            
            default:
            break;
        }
    }   
    
    clickToAddPrefix = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.prefixName == '')
        {
            _isError = true;
            errors['prefixName'] = "*Please Enter Prefix";
            this.setState({errors: errors});
        }
        
        
        if(_isError == false){
            this.addPrefixMethodCall();            
        }
    }

    addPrefixMethodCall(){
        
        const requestData = JSON.stringify({
            company_id: this.state.company_id,
            businessId : this.state.businessId,
            type : this.state._infoTitlePre,
            name : this.state.prefixName
        });
        PostData(global.businessAddNewPrefix,requestData,'POST').then((result)=>{
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                //this.closeModalWarningPrefix.bind(this,'modalAdjustNewPrefix');
                this.setState({modalIsOpen_AddNewPrefix: false, _infoPrefixId:'', _infoTitlePre:''
                , prefixArray:[],prefixName:''});
                this.getPrefixList();
            }
               
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    } 
    clickToDelete(value,type)
    {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        prefix_id: value
                    });
                    PostData(global.busiessPrefixDelete, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getSaleListData();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',

            }
            ]
        });
    }
    render()
    {
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);

        let _toolTran_AddTimePrint = "What is this? <br/>Enabling this allows you to print the time of transaction on your invoices (both regular and thermal) <br/> <br/>How it is used? . <br/>Vyavsay would pick the time entered on the transaction, and show it below the usual date field <br/> <br/>Why to use? <br/>If you have time critical invoices or want to communicate the time of generation to your users, you can use this setting."; 

        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        let _subMenuTimePrint = '';
        //console.log(this.state.isTimeTransactionActive+"--Tran_AddTimePrint--",_businessSetting.Tran_AddTimePrint);
        if(this.state.isTimeTransactionActive == true || this.state.isTimeTransactionActive == 1){
            _subMenuTimePrint = (
                <ul style={{marginLeft: '15px',marginTop: '15px'}}>
                    <li style={{listStyle: 'none',marginBottom: '10px'}}>
                        <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.Tran_AddTimePrint > 0) ? true : false} name="activeItem" value="1" data-slug="Tran_AddTimePrint" onChange={this.onFilterChange}/>
                        <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Time on Invoices <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTran_AddTimePrint,'Show Time on Print')}></i></span>
                    </li>
                </ul>
            );
        }
        
        let _toolInvoiceBillNo = "What is this? <br/> Vyavsay will assign an invoice/bill numbers automatically to your transactions like Sale, expenses etc. <br/><br/> How it is used? <br/> Invoice/bill numbers will be assigned to every invoice & the no. will keep on increasing as you keep making invoices in vyavsay, You can also change the number manually. <br/> <br/> Why to use? <br/> Keep track of your invoices and assign unique invoice numbers automatically."; 

        let _toolTran_AddTime = "What is this? <br/>This setting allows Vyavsay users to enter the time of transaction along with the date at the time of creating it.<br/><br/> How it is used?<br/> You can enter the time of the transaction whenever you open a new transaction form or open an existing transaction for edit.<br/><br/>Why to use?<br/>Time on transaction allows users to keep record of time critical transactions like retail slips. It also allows users to differentiate between two transactions with the same party on the same date.";


        let _toolCashSaleToggle = 'What is this? <br/> All Sale Invoices will be treated as Paid in Full without any pending dues. Vyavsay will allow you to make Sale invoices without selecting any party too.  <br/> <br/> How it is used? <br/> Cash Sale option will be selected by default in sale Invoices. You can always change it to credit invoice manually.  <br/> <br/> Why to use? <br/> For retailers and businessman doing over the counter sales where customer pays you the full amount upfront, this setting can be used.';

        let _toolTran_BillingNameParty = "What is this? <br/>Enables you to add billing name in sale invoice and sale orders. This billing name will be printed in invoices in place of party name.  <br/> <br/> How it is used? <br/> On enabling this, you wiill be able to enter the billing name in transactions to print on invoices. <br/> <br/> Why to use? <br/> Can be used when Customer names are very similar and proper names need to be printed on invoices for every customer."; 

        let _toolTran_CustomerPO = "What is this? <br/> Enables you to add PO date and PO number in Sale/ Purchase transactions.  <br/> <br/>Why to use? <br/> You can mention Customer's PO number and date on sale transactions. You can also mention your PO number and date on Purchase Transactions.";

        let _toolTran_Passcode = "What is this? <br/> Set Passcode or Pin for deleting any transaction.  <br/> <br/> Why to use? <br/>This will save you from deleting any transaction by mistake and will not allow anyone else to delete any transaction"; 

        let _toolPaymentDiscount = "What is this? <br/> Enables you to add/track discount during Payment-In and Payment -Out transactions  <br/> <br/> Why to use? <br/> For eg. A customer had to pay you 1000 Rupees but while making the payment, she/e paid only 900 & you agreed to take 900 with 100 as discount amount such that total of bill remains 1000. This discount can be entered while doing the payment-in transaction"; 

        let _toolPaymentLink = "What is this? <br/> You will be able to link the Payment in transactions or money you received to the unpaid invoices/ bills of party. On linking received payment to an invoice it will be marked as paid  <br/> <br/> How it is used? While receiving payments by Payment-in transaction or while editing any sale/purchase transaction, Vyavsay will show options to receive and link payments.  <br/> <br/> Why to use? <br/> Invoices statuses can be tracked from Unpaid, Partially Paid and Paid. Received payments can be linked to invoices for compelet tracking."; 

        let _toolAllowInclusiveExclusive = "What is this?<br/>You can make the Rate(price/unit) field on transactions to include or exculde tax. Now you can set inclusive and exculsive tax for sale and purchase seperately.  For Example, if price/unit of an item is 100 and tax is 5% then rate inclusive of tax is 105 and exclusive of tax is 100.<br/><br/>Why to use?<br/>Bills received from vendors or suppliers may have rate either inclusive or exculsive tax. This setting helps you to enter the bills and transactions easily in vyavsay for both types of bills.";

        let _toolDisplayPurchasePrice = "What is this? <br/>Purchase price of items will be shown in item list while adding any item to sale, purchase etc transactions. <br/> <br/>Why to use? <br/>This will help you in understanding at what price you bought the item & what should be the margin for selling that item.";

        let _toolFreeItemQuantity = "What is this? <br/>Once enabled you can add additional free quantity in invoices that you are giving to the party for free. <br/> <br/>Why to use? <br/>For giving promotional free items to Customers this can be used. Example- Free Sample or a Sale giving Free items to Customers like Buy 1 Get  1 etc.";

        let _toolCount = "What is this?<br/>Enables you to enter the count of the packets that you have given to the customer seprately<br/><br/> How it is used?<br/>For eg: 1 sack which has 5kg of Rice & you have given that in seperate packets of 1 kg each.";

        let _toolTransactionWiseTax= 'What is this?<br/>Allows you to apply a single tax on entire sale/Purchase transaction bill instead of applying different taxes on each item being sold or purchased.<br/><br/>How it is used?<br/>You will be able to apply a single tax on all the line items of any transaction. This tax will be applied on subtotal of all the individual line items.<br/><br/>Why to use?<br/>In case you have same type of stock items having same tax rates, you can enable this setting and easily apply tax just once on invoice for all sold/purchased items together.';
        
        let _toolTransactionWiseDiscount= "What is this?<br/>Allows you to apply a single discount on full sale/Purchase transaction bill instead of applying different discounts on each item being sold or purchased.<br/><br/>How it is used?<br/>you will be able to apply a single discount on all the line items of any transaction. This discount will be applied on subtotal of all the individual line items.<br/><br/> Why to use?<br/>If you are giving discounts on entire Invoice or Bill rather than separate discounts on each stock item, then you can use this option.";
        
        let _toolTaxReverseCharge = "What is this?  <br/> Enables reverse charge for your purchase transaction" ; 

        let _toolTaxSateOfSupply = "What is this?  <br/> Enables you to add place of supply while making transaction";

        let _toolTaxEWayBillNo = "What is this?  <br/> Enables you to add E-way bill number transaction";


        let _toolDueDatesPaymentTermsCharge = "What is this?  <br/>You can enter Due Dates and define Payment Terms on invoices/bills. Vyavsay will also show invoice aging reports that will let you track Paid, Unpaid and Overdue invoices.<br/>  <br/>How it is used? <br/>You can define Payment terms and due dates while making invoices/bills. Vyavsay will show them as Overdue if they are not paid by the Due date.<br/>  <br/> Why to use?<br/> You can track overdue payments on sale and purchase invoices. This will also give you sale ageing report, which gives you details about payments which are pending with their due dates.";

        let _toolAdditionalCharge = "What is this?  <br/>Enables you to add extra charges like shipping, packaging etc on your invoices. You can change the name of the extra charges based on your business type & requirement.  <br/>  <br/>Why to use?  <br/> This helps you keep a track of your extra charges apart from item cost & show it to the customer in the invoice so that customers are clear about what they are paying for.";

        let _toolTransportationDetails = "What is this?  <br/>Enter and print transportation details like transaporter name, vehicle number, delivery date & delivery location etc. on Sale Invoices and Delivery Challans.  <br/>  <br/>How it is used?  <br/>You will be able to enter transport details while making Sale invoices or Delivery Challans and Vyavsay will print them in PDFs.  <br/>  <br/>Why to use?  <br/>For Businesses delivering goods outside states or those who need transport details printed on invoices/bills";
        
        let _toolAdditionalFields = "What is this?<br/> Add extra fields on any transaction like Sale, Purchase etc.<br/><br/> How it is used? .<br/> Enable extra fields and give their names. These fields will be shown while entering transactions<br/><br/> Why to use? <br/> In case you have more information to be entered & tracked on transaction level or firm level you can enable these fields & name them based on your business requirement.";

        return(
            <React.Fragment>
                <div className="row items-push" style={{marginTop: '-50px'}}>
                        <div className="col-md-6">
                            <h5 className="content-heading">Transaction Header</h5>
                            <ul>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.InvoiceBillNo > 0) ? true : false} name="activeItem" value="1" data-slug="InvoiceBillNo" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Invoice / Bill Number <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolInvoiceBillNo,'Invoice / Bill Number ')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.CashSaleToggle > 0) ? true : false} name="activeItem" value="1" data-slug="CashSaleToggle" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Cash Sale by Default <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolCashSaleToggle,'Cash Sale by Default')}></i></span>
                                </li>
                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.Tran_BillingNameParty > 0) ? true : false} name="activeItem" value="1" data-slug="Tran_BillingNameParty" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Billing Name of parties <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTran_BillingNameParty,'Billing Name of parties ')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.Tran_CustomerPO > 0) ? true : false} name="activeItem" value="1" data-slug="Tran_CustomerPO" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>PO Details (of Customer) <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTran_CustomerPO,'Customer P.O. Details on Transactions')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    {/* {_businessSetting.Tran_AddTime+'==>>=='} */}
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.Tran_AddTime > 0) ? true : false} name="activeItem" value="1" data-slug="Tran_AddTime" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Add Time on Transactions <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTran_AddTime,'Add Time on Transactions')}></i></span>
                                    {_subMenuTimePrint}
                                </li>
                            </ul>

                            
                            <h5 className="content-heading">Items Table</h5>
                            <ul>                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="AllowInclusiveExclusive" defaultChecked={(_businessSetting.AllowInclusiveExclusive > 0) ? true : false} name="AllowInclusiveExclusive" data-slug="AllowInclusiveExclusive" value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Allow Inclusive/Exclusive tax on Rate(Price/unit) <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolAllowInclusiveExclusive,'Allow Inclusive/Exclusive tax on Rate(Price/unit)')}></i></span>
                                </li>

                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="DisplayPurchasePrice" defaultChecked={(_businessSetting.DisplayPurchasePrice > 0) ? true : false} name="DisplayPurchasePrice" data-slug="DisplayPurchasePrice" value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Display Purchase Price <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolDisplayPurchasePrice,'Display Purchase Price')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="FreeItemQuantity" defaultChecked={(_businessSetting.FreeItemQuantity > 0) ? true : false} name="FreeItemQuantity" data-slug="FreeItemQuantity" value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Free Item Quantity <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolFreeItemQuantity,'Free Item Quantity')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="Count" defaultChecked={(_businessSetting.Count > 0) ? true : false} name="Count" value="1" data-slug="Count" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Count <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolCount,'Count')}></i></span>
                                </li>
                            </ul>

                            <h5 className="content-heading">More Transaction Features</h5>
                            <ul>                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.Tran_Passcode > 0) ? true : false} name="activeItem" data-slug="Tran_Passcode" value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Enable Passcode for Transaction Edit/Delete <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTran_Passcode,'Enable Passcode for Transaction Edit/Delete')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.PaymentDiscount > 0) ? true : false} name="activeItem" data-slug="PaymentDiscount" value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Discount During Payments <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolInvoiceBillNo,'Discount During Payments')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.PaymentLink > 0) ? true : false} name="activeItem" value="1" data-slug="PaymentLink" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Link Payments to Invoice <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPaymentLink,'Link Payments to Invoice')}></i></span>
                                </li>                                  
                                  
                                <li style={{listStyle: 'none'}}>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><i className='fa fa-forward'></i><a href='javascript:void(0)' onClick={this.openModalOtherTransac.bind(this,'openModalDueDatePaymentTermsDetails')} style={{color: '#6c6b6b'}}>Due Dates and Payment terms</a>  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolDueDatesPaymentTermsCharge,'Due Dates and Payment terms')}></i></span>
                                </li>  

                                <li style={{listStyle: 'none'}}>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><i className='fa fa-forward'></i><a href='javascript:void(0)' onClick={this.openModalOtherTransac.bind(this,'openModalAdditionalCharge')} style={{color: '#6c6b6b'}}>Additional Charges</a>  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolAdditionalCharge,'Additional Charges')}></i></span>
                                </li>  
                                  
                                <li style={{listStyle: 'none'}}>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><i className='fa fa-forward'></i><a href='javascript:void(0)' onClick={this.openModalOtherTransac.bind(this,'openModalTransportationDetails')} style={{color: '#6c6b6b'}}>Transportation Details</a>  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTransportationDetails,'Transportation Details')}></i></span>
                                </li>  
                                
                                <li style={{listStyle: 'none'}}>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><i className='fa fa-forward'></i><a href='javascript:void(0)' onClick={this.openModalOtherTransac.bind(this,'openModalAdditionalFields')} style={{color: '#6c6b6b'}}>Additional Fields</a>  <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolAdditionalFields,'Additional Fields')}></i></span>
                                </li>  
                            </ul>

                            <h5 className="content-heading">Taxes, Discount & Total</h5>
                            <ul>                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="TransactionWiseTax" defaultChecked={(_businessSetting.TransactionWiseTax > 0) ? true : false} name="TransactionWiseTax" data-slug="TransactionWiseTax" value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Transaction Wise Tax <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTransactionWiseTax,'Transaction Wise Tax')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="TransactionWiseDiscount" defaultChecked={(_businessSetting.TransactionWiseDiscount > 0) ? true : false} name="TransactionWiseDiscount" data-slug="TransactionWiseDiscount" value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Transaction Wise Discount <i class="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTransactionWiseDiscount,'Transaction Wise Discount')}></i></span>
                                </li>
                            </ul>


                            <h5 className="content-heading">GST</h5>
                            <ul>
                                <li style={{listStyle: 'none'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.ReverseCharge > 0) ? true : false} id="ReverseCharge" name="ReverseCharge" value="1" data-slug="ReverseCharge" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Reverse Charge <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTaxReverseCharge,'Reverse Charge')}></i></span>
                                </li>
                                    
                                <li style={{listStyle: 'none'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.SateOfSupply > 0) ? true : false} id="SateOfSupply" name="SateOfSupply" value="1" data-slug="SateOfSupply" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>State Of Supply <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTaxSateOfSupply,'Sate Of Supply')}></i></span>
                                </li>

                                <li style={{listStyle: 'none'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.EWayBillNo > 0) ? true : false} id="EWayBillNo" name="EWayBillNo" value="1" data-slug="EWayBillNo" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>E-Way Bill No <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTaxEWayBillNo,'E-Way Bill No')}></i></span>
                                </li>
                            </ul>
                            
                            
                        </div>
                        <div className="col-md-6">
                            <h5 className="content-heading">Transaction Prefixes</h5>
                                <div className="form-group row">
                                    {
                                        this.state.prefixArray.map((item,index) => {
                                            let _itemName = item.name;
                                            let _itemData = item.data;
                                            let _itemPrefixTypeID = item.prefix_typeID;
                                            let _itemPrefixType = item.prefix_type;
                                            let _selectedPrefix = _businessSetting[_itemPrefixTypeID];
                                            let _Delete = '<span><i className="fa fa-trash"></i></span>'; 
                                            return(
                                                <div className="col-lg-6 col-form-label">
                                                    <label style={{textAlign: 'right'}} className="col-lg-12 col-form-label" htmlFor="val-username"> {_itemName}</label>
                                                    <div className="col-lg-12 form-material" id="paddingOff">
                                                        <select className="form-control " id="selectPrefix" name="selectPrefix" required="required" data-slug={_itemPrefixTypeID} data-slugName={_itemPrefixType} onChange={this.handleDataPrefixChange}>
                                                            {      
                                                                
                                                                _itemData.map((dataList) => {
                                                                    let _selPrefix =  '';
                                                                    if(_selectedPrefix == dataList.prefix_id){
                                                                        _selPrefix = 'selected';
                                                                    }
                                                                    return (<option value={dataList.prefix_id} selected={_selPrefix}>{dataList.prefix}
                                                                    
                                                                    </option>)
                                                                }) 
                                                            }
                                                            <option value="AddNewPrefix" >Add {_itemName} Prefix</option>
                                                            
                                                        </select>
                                                    </div>
                                                </div>
                                            ) 
                                        })
                                    }                         
                                </div>
                                <Link to={"/business/all-prefix-list/"} className='btn btn-primary pull-right'> All Prefix List </Link>
                        </div>
                </div>
                
                {/* Modal  Tooltip Information Begins */}
                <Modal isOpen={this.state.modalIsOpen_Info} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalInfoHandler">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">{this.state._infoTitle}</h3>
                                        <div className="block-options">
                                            <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'openModalInfoHandler')}>
                                                <i className="si si-close"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                    {parse(this.state._infoData)}
                                    </div>
                                    <br/>
                                </div>
                                
                            </div>
                        </div>
                    </Modal>
                    {/* Modal End Tooltip Information*/}
                    
                    {/* Modal  Warning Information Uncheck Begins */}
                    <Modal isOpen={this.state.modalIsOpen_Info2} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalWarning} id="openModalInfoHandler2">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="block block-themed block-transparent mb-0">
                                        <div className="block-header bg-primary-dark">
                                            <h3 className="block-title">{this.state._infoTitle2}</h3>
                                            <div className="block-options">
                                                <button type="button" className="btn-block-option" onClick={this.closeModalWarning.bind(this,'openModalInfoHandler2')}>
                                                    <i className="si si-close"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                        {parse(this.state._infoData2)}
                                        </div>
                                        <br/>
                                        
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-alt-secondary" onClick={this.updateWarningData.bind(this,'TransactionWiseTax')}>No</button>
                                            
                                            <button type="button" className="btn btn-alt-primary" onClick={this.closeModalWarning.bind(this,'openModalInfoHandler2')}>Enable Anyway</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </Modal>
                    {/* Modal End Warning Information */}

                    {/* Modal Additional Charge */}
                    <Modal isOpen={this.state.modalIsOpen_AdditionalCharge} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalAdditionalCharge">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">Additional Charges Settings</h3>
                                        
                                    </div>
                                    <div className="block-content">
                                        <QuickAdditionalCharge  />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'openModalAdditionalCharge')}>Close</button>
                                    
                                </div>
                            </div>
                        </div>
                    </Modal>                
                    {/* Modal Additional Charge*/}

                    {/* Modal Transportation Details */}
                    <Modal isOpen={this.state.modalIsOpen_TransportationDetails} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalTransportationDetails">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">Transportation Details</h3>
                                        
                                    </div>
                                    <div className="block-content">
                                        <QuickTransportationDetails  />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'openModalTransportationDetails')}>Close</button>
                                    
                                </div>
                            </div>
                        </div>
                    </Modal>                
                    {/* Modal Transportation Details*/}

                    {/* Modal Due date payment terms */}
                    <Modal isOpen={this.state.modalIsOpen_DueDatePaymentTermsDetails} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalDueDatePaymentTermsDetails">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">Due Dates and Payment terms</h3>
                                        
                                    </div>
                                    <div className="block-content">
                                        <QuickDueDatesPaymentsTerms  />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'openModalDueDatePaymentTermsDetails')}>Close</button>
                                    
                                </div>
                            </div>
                        </div>
                    </Modal>                
                    {/* Modal Due date payment terms*/}
                    
                    {/* Modal Additional Fields */}
                    <Modal isOpen={this.state.modalIsOpen_AdditionalFields} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalAdditionalFields">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">Additional Fields</h3>
                                        
                                    </div>
                                    <div className="block-content">
                                        <QuickAdditionalFields  />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'openModalAdditionalFields')}>Close</button>
                                    
                                </div>
                            </div>
                        </div>
                    </Modal>                
                    {/* Modal Additional Fields*/}

                    {/* Modal Add New prefix */}
                    <Modal isOpen={this.state.modalIsOpen_AddNewPrefix} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalAdjustNewPrefix">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">Add {this.state._infoTitlePre} Prefix</h3>
                                        
                                    </div>
                                    <div className="block-content">
                                    <form method="post" id="form-login" className="webForm px-30">
                    
                                        <div className="form-group row">
                                            <div className="col-6">
                                                <div className="form-material open">
                                                    <input type="text" required="required" className="form-control" id="prefixName" name="prefixName" value={this.state.prefixName} placeholder="Enter Prefix Name" onChange={this.handleChange}/>
                                                    <label htmlFor="login-username">Prefix Name</label>
                                                    <span className="iris_error_txt">{errors.prefixName.length > 0 && <span className='error'>{errors.prefixName}</span>}</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="claerfix"></div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddPrefix} disabled={isDisabled}>
                                                    <i className="fa fa-check mr-5"></i> Add New
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalWarningPrefix.bind(this,'modalAdjustNewPrefix')}>Close</button>
                                    
                                </div>
                            </div>
                        </div>
                    </Modal>                
                    {/* Modal Add New prefix Details*/}

            </React.Fragment>
        )
    }
} 